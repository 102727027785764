import React, { useContext, useRef } from 'react'
import { useTailwind } from 'tailwind-rn'
import { i18nContext } from '../context'
import { useTranslation } from '../i18n'
import { Text, View, ScrollView, TouchableOpacity } from 'react-native'

import Header from '../components/Header'
import SwitchNav from '../components/SwitchNav'
import Heading from '../components/Heading'
import UpTopButton from '../components/UpTopButton'
import ArrowLeftSvg from '../components/ArrowLeftSvg'
import LineSquare from '../components/LineSquare'

const TreatmentLogistics = ({ component, navigation }) => {
  let viewRef = useRef()
  const tw = useTailwind()
  const { lang, setLang } = useContext(i18nContext)
  const t = useTranslation(lang)

  if (component)
    return (
      <>
        <Heading text={t('pages.treatmentLogistics.title')} />
        <Text style={tw('mt-5 text-base')}>
          {t('pages.treatmentLogistics.ascoGuidelines')}
          <Text style={tw('font-semibold')}>
            {t('pages.treatmentLogistics.ascoGuidelinesBold')}
          </Text>
          {t('pages.treatmentLogistics.ascoGuidelinesCont')}
        </Text>
        <Text style={tw('mt-5 text-base')}>
          {t('pages.treatmentLogistics.studyLine')}
        </Text>
        <View style={tw('mt-5 flex flex-row items-start')}>
          <LineSquare />
          <Text style={tw('ml-2 text-base')}>
            {t('pages.treatmentLogistics.preference')}
          </Text>
        </View>
        <View style={tw('mt-5 flex flex-row items-start')}>
          <LineSquare />
          <Text style={tw('ml-2 text-base')}>
            <Text style={tw('font-semibold')}>
              {t('pages.treatmentLogistics.citedReasonBold')}
            </Text>
            {t('pages.treatmentLogistics.were')}
            <Text style={tw('font-semibold')}>
              {t('pages.treatmentLogistics.reasonBold')}
            </Text>
            {t('pages.treatmentLogistics.citedReason')}
          </Text>
        </View>
        <View style={tw('mt-5 flex flex-row items-start')}>
          <LineSquare />
          <Text style={tw('ml-2 text-base')}>
            {t('pages.treatmentLogistics.convenience')}
          </Text>
        </View>
        {t('pages.treatmentLogistics.abbreviations') ? (
          <Text style={tw('mt-5 text-xs')}>
            {t('pages.treatmentLogistics.abbreviations')}
          </Text>
        ) : null}
      </>
    )

  return (
    <View style={tw('flex-1')}>
      <Header navigation={navigation} />
      <ScrollView
        ref={viewRef}
        contentContainerStyle={tw('p-10 flex justify-start items-start')}
      >
        <View style={tw('w-full flex items-center')}>
          <SwitchNav
            navigation={navigation}
            current="Additional Considerations"
          />
        </View>
        <TouchableOpacity
          onPress={() => navigation.goBack()}
          style={tw('mt-7 flex flex-row items-center')}
        >
          <ArrowLeftSvg />
          <Text
            style={[
              tw('ml-4 text-base'),
              { textDecorationLine: 'underline', color: '#506e7a' }
            ]}
          >
            {t('additionalConsiderations')}
          </Text>
        </TouchableOpacity>
        <Heading
          style={tw('mt-10')}
          text={t('pages.treatmentLogistics.title')}
        />
        <Text style={tw('mt-5 text-base')}>
          {t('pages.treatmentLogistics.ascoGuidelines')}
        </Text>
        <Text style={tw('mt-5 text-base')}>
          {t('pages.treatmentLogistics.studyLine')}
        </Text>
        <View style={tw('mt-5 flex flex-row items-start')}>
          <LineSquare />
          <Text style={tw('ml-2 text-base')}>
            {t('pages.treatmentLogistics.preference')}
          </Text>
        </View>
        <View style={tw('mt-5 flex flex-row items-start')}>
          <LineSquare />
          <Text style={tw('ml-2 text-base')}>
            {t('pages.treatmentLogistics.citedReason')}
          </Text>
        </View>
        <View style={tw('mt-5 flex flex-row items-start')}>
          <LineSquare />
          <Text style={tw('ml-2 text-base')}>
            {t('pages.treatmentLogistics.convenience')}
          </Text>
        </View>
        {t('pages.treatmentLogistics.abbreviations') ? (
          <Text style={tw('mt-5 text-sm')}>
            {t('pages.treatmentLogistics.abbreviations')}
          </Text>
        ) : null}
        <View style={tw('mt-10 w-full flex items-end')}>
          <UpTopButton
            onPress={() =>
              viewRef.current.scrollTo({ x: 0, y: 0, animated: true })
            }
          />
        </View>
      </ScrollView>
    </View>
  )
}

export default TreatmentLogistics
