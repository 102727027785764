import React, { useContext, useState } from 'react'
import Modal from 'react-native-modal'
import { useTailwind } from 'tailwind-rn'
import { i18nContext } from '../context'
import { useTranslation } from '../i18n'
import { useMediaQuery } from 'react-responsive'
import { ScrollView, Text, View, TouchableOpacity } from 'react-native'
import ModalCloseButton from './ModalCloseButton'
import media from '../constants/media'

const rows = {
  en: [
    ['Adverse Event (%)', 'Any Grade', 'Grade 3/4', 'Any Grade', 'Grade 3/4'],
    ['Overall', 80, '', 52, ''],
    ['Fatigue', 22, 4, 16, 3],
    ['Alopecia', '14*', 0, 2, 0],
    ['Hand-foot skin reaction', '21*', '8*', 3, '<1'],
    ['Rash or desquamation', '16', '1', 11, '0'],
    ['Anorexia', '14*', '<1', 3, '1'],
    ['Diarrhea', '39*', '8*', 11, '2'],
    ['Nausea', '11', '<1', 8, '1']
  ],
  fr: [
    [
      'Manifestation indésirable (%)',
      'Tout grade',
      'Grade 3 ou 4',
      'Tout grade',
      'Grade 3 ou 4'
    ],
    ['Total', 80, '', 52, ''],
    ['Fatigue', 22, 4, 16, 3],
    ['Alopécie', '14*', 0, 2, 0],
    ['Réaction cutanée mains-pieds', '21*', '8*', 3, '<1'],
    ['Éruption cutanée ou desquamation', '16', '1', 11, '0'],
    ['Anorexie', '14*', '<1', 3, '1'],
    ['Diarrhée', '39*', '8*', 11, '2'],
    ['Nausées', '11', '<1', 8, '1']
  ]
}

const SharpSafetyModal = ({ modalVisible, setModalVisible }) => {
  const tw = useTailwind()
  const { lang, setLang } = useContext(i18nContext)
  const t = useTranslation(lang)
  const isDesktop = useMediaQuery({
    minDeviceWidth: media.desktop
  })

  return (
    <Modal
      animationType="slide"
      transparent={true}
      isVisible={modalVisible}
      propagateSwipe
      onRequestClose={() => {
        setModalVisible(!modalVisible)
      }}
    >
      <ModalCloseButton
        setModalVisible={setModalVisible}
        offset={312}
        style={{ marginTop: 68 }}
      />
      <ScrollView
        style={[
          tw('bg-white px-4 mt-20 mb-10 rounded py-5'),
          {
            [isDesktop ? 'width' : 'maxWidth']: 600,
            margin: 'auto'
          }
        ]}>
        <View
          style={[
            tw('pb-5 flex justify-start flex-row items-center'),
            { borderBottomWidth: 1, borderColor: '#f1f1f1' }
          ]}
        >
          <Text style={tw('text-base font-bold')}>
            {t('pages.sharpSafetyModal.title')}
          </Text>
        </View>
        <View style={{ ...styles.row, ...styles.firstRow }}>
          <View style={styles.firstCell}>
            <Text style={styles.text}>
              {t('pages.sharpSafetyModal.averseEvents')}
            </Text>
          </View>
          <View style={{ ...styles.cell, width: '30%' }}>
            <Text style={styles.text}>Sorafenib{'\n'}(n=297) %</Text>
          </View>
          <View style={{ ...styles.cell, width: '30%' }}>
            <Text style={styles.text}>Placebo{'\n'}(n=302) %</Text>
          </View>
        </View>

        {rows[lang].map((row, i) => (
          <View key={i} style={styles.row}>
            {row.map((cell, index) => (
              <View
                key={`${cell}-${index}`}
                style={[
                  index == 0 ? styles.firstCell : styles.cell,
                  tw('text-left')
                ]}
              >
                <Text
                  style={[
                    styles.text,
                    i == 0 ? { fontWeight: 'bold' } : null,
                    index !== 0 && tw('text-left')
                  ]}
                >
                  {cell}
                </Text>
              </View>
            ))}
          </View>
        ))}

        <Text style={tw('mt-5 mb-20')}>
          {`* P<0.001 `} {'\n'}
          Llovet JM, et al. N Engl J Med 2008;359:378-90.
        </Text>
      </ScrollView>
    </Modal>
  )
}

export default SharpSafetyModal

const styles = {
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  firstRow: {
    borderTopWidth: 1,
    borderColor: '#000'
  },
  cell: {
    padding: 4,
    textAlign: 'left',
    width: '15%',
    borderBottomWidth: 1,
    borderRightWidth: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: '#000',
    fontSize: 9
  },
  firstCell: {
    padding: 8,
    width: '40%',
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    borderColor: '#000'
  },
  lastCell: {
    borderRightWidth: 1,
    borderColor: '#000'
  },
  text: {
    fontSize: 11
  }
}
