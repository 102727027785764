import React from 'react'
import { TouchableOpacity } from 'react-native'
import { useTailwind } from 'tailwind-rn'
import { useMediaQuery } from 'react-responsive'

import CloseSvg from './CloseSvg'

const ModalCloseButton = ({
  setModalVisible,
  style,
  offset
}) => {
  const tw = useTailwind()

  // change position of close button so that it
  // doesnt go too fat
  const closeButtonShouldSwitch = useMediaQuery({
    minDeviceWidth: 600
  })

  return (
    <TouchableOpacity
      onPress={() => setModalVisible(false)}
      style={[
        tw('absolute top-0 mt-7 z-10'),
        { right: closeButtonShouldSwitch
          ? `calc(50% - ${offset}px)`
          : -10
        },
        style
      ]}
    >
      <CloseSvg />
    </TouchableOpacity>
  )
}

export default ModalCloseButton
