import React, { useContext, useRef } from 'react'
import { useTailwind } from 'tailwind-rn'
import { i18nContext } from '../context'
import { useTranslation } from '../i18n'
import { Text, View, Image, ScrollView, TouchableOpacity } from 'react-native'

import Card from '../components/Card'
import Header from '../components/Header'
import SwitchNav from '../components/SwitchNav'
import Heading from '../components/Heading'
import UpTopButton from '../components/UpTopButton'
import ArrowLeftSvg from '../components/ArrowLeftSvg'
import EvolutionChartSVG from '../components/EvolutionChartSVG'
import EvolutionChartFrSVG from '../components/EvolutionChartFrSVG'

import { ToAdditionalConsiderations } from '../screens/AdditionalConsiderations'
import evolutionChart from '../assets/evolution-chart.svg'

const Circle = ({ color }) => {
  const tw = useTailwind()
  return (
    <View
      style={[tw('mt-0 rounded-full w-3 h-3'), { backgroundColor: color }]}
    ></View>
  )
}

const Square = ({ color }) => {
  const tw = useTailwind()
  return <View style={[tw('mt-0 w-3 h-3'), { backgroundColor: color }]}></View>
}

const legend = {
  '#506E7A': {
    en: 'BCLC C',
    fr: 'BCLC C',
    square: true
  },
  '#DD6E42': {
    en: 'Prior L(R)T',
    fr: 'TL(R) préalable',
    square: true
  },
  '#C2CACC': {
    en: 'Viral Etiology',
    fr: 'Cause virale',
    square: true
  },
  '#F2E3BC': { en: 'MVI', fr: 'EVM', square: true },
  '#414141': { en: 'Sorafenib mOS', fr: 'SGm de sorafenib' },
  '#81B29A': { en: 'Lenvatinib mOS', fr: 'SGm de lenvatinib' }
}

const colours = [
  '#506E7A',
  '#DD6E42',
  '#C2CACC',
  '#F2E3BC',
  '#414141',
  '#81B29A'
]

const ColourLegend = ({ tiny, style, title }) => {
  const tw = useTailwind()
  const { lang } = useContext(i18nContext)

  return (
    <Card style={[tw('p-0'), { maxWidth: 150 }, style]}>
      <Text style={tw('p-3 text-xs font-bold')}>{title}</Text>
      {colours.map(colour => (
        <View
          key={colour}
          style={[
            tw('flex flex-row items-center p-3 border-t'),
            { borderColor: '#F1F1F1' }
          ]}
        >
          {legend[colour].square ? (
            <Square color={colour} />
          ) : (
            <Circle color={colour} />
          )}
          <Text style={tw('text-xs ml-2 flex-1')}>{legend[colour][lang]}</Text>
        </View>
      ))}
    </Card>
  )
}

const EvolutionControlArms = ({ component, navigation }) => {
  let viewRef = useRef()
  const tw = useTailwind()
  const { lang, setLang } = useContext(i18nContext)
  const t = useTranslation(lang)

  if (component)
    return (
      <>
        <Heading text={t('pages.evolution.title')} />
        <Text style={tw('mt-5 text-base')}>
          {t('pages.evolution.paragraph1')}
        </Text>
        <Text style={tw('mt-5 text-base')}>
          {t('pages.evolution.paragraph2')}
        </Text>
        <Heading
          style={tw('mt-5')}
          wraps
          smaller
          text={t('pages.evolution.subheading')}
        />
        <View
          style={tw(
            'w-full gap-6 flex overflow-visible flex-row justify-between items-start'
          )}
        >
          {lang == 'fr' ? <EvolutionChartFrSVG /> : <EvolutionChartSVG />}
          <ColourLegend title={t('pages.evolution.colourLegend')} />
        </View>
        <Text style={tw('mt-5 text-xs')}>{t('pages.evolution.ref')}</Text>
      </>
    )

  return (
    <View style={tw('flex-1')}>
      <Header navigation={navigation} />
      <ScrollView
        ref={viewRef}
        contentContainerStyle={tw('p-10 flex justify-start items-start')}
      >
        <View style={tw('w-full flex items-center')}>
          <SwitchNav
            navigation={navigation}
            current="Additional Considerations"
          />
        </View>
        <ToAdditionalConsiderations
          navigation={navigation}
          text={t('additionalConsiderations')}
        />

        <Heading style={tw('mt-10')} text={t('pages.evolution.title')} />
        <Text style={tw('mt-5 text-base')}>
          {t('pages.evolution.paragraph1')}
        </Text>
        <Text style={tw('mt-5 text-base')}>
          {t('pages.evolution.paragraph2')}
        </Text>
        <Text style={tw('mt-5 text-xs')}>{t('pages.evolution.ref')}</Text>
        <Heading
          style={tw('text-lg mt-5')}
          wraps
          smaller
          text={t('pages.evolution.subheading')}
        />
        <View style={tw('flex flex-1 w-full h-auto')}>
          {lang == 'fr' ? <EvolutionChartFrSVG /> : <EvolutionChartSVG />}
          <ColourLegend title={t('pages.evolution.colourLegend')} />
        </View>
        <View style={tw('mt-10 w-full flex items-end')}>
          <UpTopButton
            onPress={() =>
              viewRef.current.scrollTo({ x: 0, y: 0, animated: true })
            }
          />
        </View>
      </ScrollView>
    </View>
  )
}

export default EvolutionControlArms
