import * as React from "react"
import Svg, { Path } from "react-native-svg"

const PlusSvg = (props) => (
  <Svg
    width={12}
    height={13}
    viewBox="0 0 22 13"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M9.215 11.336a1.496 1.496 0 0 0 1.503.974 1.496 1.496 0 0 0 1.502-.974l8.654-8.654A1.5 1.5 0 0 0 18.753.56l-8.035 8.035L2.682.561A1.5 1.5 0 0 0 .561 2.68l8.654 8.655Z"
      fill="#506E7A"
      fillRule="evenodd"
    />
  </Svg>
)

export default PlusSvg

