import React, { useState, useContext } from 'react'
import { useTailwind } from 'tailwind-rn'
import { i18nContext } from '../context'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from '../i18n'
import media from '../constants/media'
import {
  Text,
  View,
  ScrollView,
  TextInput,
  TouchableOpacity
} from 'react-native'
import { childPughSurveyQuestions as factors } from '../constants'
import { LinearGradient } from 'expo-linear-gradient'

const gradeOne = `Grade 1-2:
Altered mood/confusion
Inappropriate behavior, impending stupor, somnolence
`
const gradeThree = `Grade 3-4
Markedly confused, stuporous but arousable
Comatose/unresponsive`

const keyz = {
  Absent: 'Aucune',
  None: 'Aucune',
  Slight: 'Légères',
  Moderate: 'Modérées',
  '<2 mg/dL (<34.2 μmol/L)': '<2 mg/dL (<34.2 μmol/L)',
  '>3 mg/dL (>51.3 μmol/L)': '>3 mg/dL (>51.3 μmol/L)',
  '>3.5 g/dL (>35 g/L)': '>3.5 g/dL (>35 g/L)',
  '2 - 3 mg/dL (34.2 to 51.3 μmol/L)': 'De 2 à 3 mg/dL (34,2 à 51,3 μmol/L)',
  '2.8 - 3.5 g/dL (28 - 35 g/L)': 'De 2,8 à 3,5 g/dL (28 à 35 g/L)',
  '<2.8 g/dL (<28 g/L)': '<2.8 g/dL (<28 g/L)',
  '<4': '<4',
  '4 to 6': 'De 4 à 6',
  '>6': '>6',
  '<1.7': '<1.7',
  '1.7 to 2.2': '1,7 à 2,2',
  '>2.3': '>2.3',
  [gradeOne]: `Grade 1 et 2 :
Altération de l’humeur/état confusionnel
Comportement inapproprié, stupeur imminente, somnolence`,
  [gradeThree]: `Grade 3 et 4 :
Confusion marquée, état de stupeur, mais éveil possible
Coma/inconscience`,
  'Child Pugh class A': 'Classe A de Child-Pugh',
  'Child Pugh class B': 'Classe B de Child-Pugh',
  'Child Pugh class C': 'Classe C de Child-Pugh'
}

// [formula: ALBI = (log10 bilirubin × 0.66) + (albumin × -0.085),
// where bilirubin is in μmol/L and albumin in g/L]
// [if user inputs bilirubin in mg/dL, need to convert to µmol/L before calculating ALBI.
// Bilirubin in µmol/L = (bilirubin in mg/dL) × 17.1]
// [if user inputs albumin in g/dL, need to convert to g/L before calculating ALBI.
// Albumin in g/L = (albumin in g/dL) × 10]

function calculateALBIScore({
  bilirubin,
  bilirubinUnit,
  albumin,
  albuminUnit
}) {
  if (!bilirubin || !albumin) {
    return null
  }

  let b = Number(bilirubin)
  let a = Number(albumin)

  if (isNaN(b) || isNaN(a)) {
    return null
  }

  if (bilirubinUnit == 'mg/dL') {
    // convert to µmol/L
    b = bilirubin * 17.1
  }

  if (albuminUnit == 'g/dL') {
    // convert to g/L
    a = albumin * 10
  }

  let albiGrade = Math.log10(b) * 0.66 + a * -0.085
  return albiGrade
}

function calculateALBIGrade(score) {
  if (score == null) return null
  if (score <= -2.6) return 1
  if (score > -2.6 && score <= -1.39) return 2
  else return 3
}

const mapKey = (key, lang) => (lang == 'en' ? key : keyz[key] || key)

const ChildPughSurvey = ({
  navigation,
  hasCallback,
  onSurveyResult,
  forceClose
}) => {
  const [albiGradeInput, setAlbiGradeInput] = useState({
    bilirubin: null,
    bilirubinUnit: 'mg/dL', // or µmol/L
    albumin: null,
    albuminUnit: 'g/dL' // or g/L
  })

  const isDesktop = useMediaQuery({
    minDeviceWidth: media.desktop
  })

  const { lang } = useContext(i18nContext)
  const t = useTranslation(lang)

  const defaultState = factors.reduce(
    (state, { name }) => ({
      ...state,
      [name]: [null, 0]
    }),
    {}
  )

  const handleLinkToConsiderations = () => {
    isDesktop
      ? navigation.navigate('Additional Considerations', {
          tab: 'ALBI Grade'
        })
      : navigation.navigate('ALBI Grade')

    forceClose()
  }

  const [survey, setSurvey] = useState(defaultState)
  const tw = useTailwind()

  const points = Object.values(survey).reduce(
    (sum, [_, point]) => (sum += point),
    0
  )

  const calculateChildPughClass = () => {
    switch (true) {
      case points < 7:
        return 'A'
      case points < 10:
        return 'B'
      default:
        return 'C'
    }
  }

  let albiScore = calculateALBIScore(albiGradeInput)
  let albiGrade = calculateALBIGrade(albiScore)

  const exclusiveNames = {
    'or INR': 'Prothrombin time (seconds above control)',
    'Prothrombin time (seconds above control)': 'or INR'
  }

  const allFilled = factors.every(({ name }) => {
    if (survey[name][1] != 0) return true

    let flip = exclusiveNames[name]
    if (!flip) return
    if (survey[name][1] || survey[flip][1]) return true
  })

  let childPughClass = `Child Pugh class ${calculateChildPughClass()}`

  const renderALBI = () => {
    if (albiScore == undefined) return null
    return (
      <>
        <View
          style={tw('mt-1 flex flex-row flex-1 justify-between items-center')}
        >
          <View
            style={[
              {
                borderRadius: 4,
                width: '49%',
                borderColor: '#E1E1E1',
                borderWidth: 1
              },
              tw('py-5 flex justify-center items-center')
            ]}
          >
            <Text> ALBI Score: {albiScore.toFixed(2)}</Text>
          </View>
          <View
            style={[
              {
                borderRadius: 4,
                width: '49%',
                borderColor: '#E1E1E1',
                borderWidth: 1
              },
              tw('py-5 flex justify-center items-center')
            ]}
          >
            <Text> ALBI Grade: {albiGrade}</Text>
          </View>
        </View>
        <Text style={tw('mt-5 text-xs')}>
          {t('pages.calculatorModal.learnAboutALBI')}{' '}
          <Text
            style={tw('font-semobold underline')}
            onPress={handleLinkToConsiderations}
          >
            {t('additionalConsiderations')}
          </Text>{' '}
          {t('pages.calculatorModal.section')}
        </Text>
        <Text style={tw('mt-5 text-xs italic')}>
          {t('pages.calculatorModal.linkNote')}
        </Text>
      </>
    )
  }

  return (
    <View>
      {factors.map(({ name, options }) => (
        <View
          key={name}
          style={[
            tw('py-5 px-7'),
            {
              borderTopWidth: 1,
              borderColor: '#F1F1F1'
            }
          ]}
        >
          <Text style={tw('mb-3 font-bold text-base')}>
            {t(`pages.childPughCalculator.${name}`)}
          </Text>
          {options.map(({ text, points }) => (
            <TouchableOpacity
              key={text}
              onPress={() => {
                if (
                  name == 'or INR' ||
                  name == 'Prothrombin time (seconds above control)'
                ) {
                  let flip = exclusiveNames[name]
                  setSurvey({
                    ...survey,
                    [name]: [text, points],
                    [flip]: [null, 0]
                  })
                  return
                }
                setSurvey({
                  ...survey,
                  [name]: [text, points]
                })
              }}
              style={[
                tw('mt-1 p-4 rounded flex flex-row justify-between'),
                {
                  borderColor: survey[name][0] == text ? '#506E7A' : '#E1E1E1',
                  borderWidth: survey[name][0] == text ? 2 : 1
                }
              ]}
            >
              <Text style={tw('text-xs')}>{mapKey(text, lang)}</Text>
              <Text style={tw('text-xs')}>+{points}</Text>
            </TouchableOpacity>
          ))}

          {name === 'Bilirubin' && (
            <View style={tw('mt-1 ')}>
              <Text>{t('pages.childPughCalculator.enterBilirubin')} </Text>
              <View style={tw('mt-1 flex flex-row items-end')}>
                <TextInput
                  style={[
                    tw('border text-right'),
                    {
                      outline: 'rgba(80, 110, 122, 1)',
                      borderColor: '#E1E1E1',
                      height: 30,
                      width: 70
                    }
                  ]}
                  inputmode="numeric"
                  keyboardType="numeric"
                  placeholder=""
                  value={
                    albiGradeInput.bilirubinUnit == 'mg/dL'
                      ? albiGradeInput.bilirubin || ''
                      : ''
                  }
                  maxLength={8}
                  onChangeText={val => {
                    setAlbiGradeInput({
                      ...albiGradeInput,
                      bilirubin: val,
                      bilirubinUnit: 'mg/dL'
                    })
                  }}
                />
                <Text style={{ paddingBottom: '6px'}}> mg/dL {t('pages.childPughCalculator.or')} </Text>
                <TextInput
                  style={[
                    tw('border text-right'),
                    {
                      outline: 'rgba(80, 110, 122, 1)',
                      borderColor: '#E1E1E1',
                      height: 30,
                      width: 70
                    }
                  ]}
                  inputmode="numeric"
                  keyboardType="numeric"
                  value={
                    albiGradeInput.bilirubinUnit == 'µmol/L'
                      ? albiGradeInput.bilirubin || ''
                      : ''
                  }
                  maxLength={8}
                  placeholder=""
                  onChangeText={val => {
                    setAlbiGradeInput({
                      ...albiGradeInput,
                      bilirubin: val,
                      bilirubinUnit: 'µmol/L'
                    })
                  }}
                />
                <Text style={{ paddingBottom: '6px'}}> µmol/L</Text>
              </View>
            </View>
          )}
          {name === 'Albumin' && (
            <View style={tw('mt-1 ')}>
              <Text>{t('pages.childPughCalculator.enterAlbumin')} </Text>
              <View style={tw('mt-1 flex flex-row items-end')}>
                <TextInput
                  style={[
                    tw('border text-right'),
                    {
                      outline: 'rgba(80, 110, 122, 1)',
                      borderColor: '#E1E1E1',
                      height: 30,
                      width: 70
                    }
                  ]}
                  inputmode="numeric"
                  keyboardType="numeric"
                  placeholder=""
                  maxLength={8}
                  value={
                    albiGradeInput.albuminUnit == 'g/dL'
                      ? albiGradeInput.albumin || ''
                      : ''
                  }
                  onChangeText={val => {
                    setAlbiGradeInput({
                      ...albiGradeInput,
                      albumin: val,
                      albuminUnit: 'g/dL'
                    })
                  }}
                />
                <Text style={{ paddingBottom: '6px'}}> g/dL {t('pages.childPughCalculator.or')} </Text>
                <TextInput
                  style={[
                    tw('border text-right'),
                    {
                      outline: 'rgba(80, 110, 122, 1)',
                      borderColor: '#E1E1E1',
                      height: 30,
                      width: 70
                    }
                  ]}
                  inputmode="numeric"
                  keyboardType="numeric"
                  maxLength={8}
                  placeholder=""
                  value={
                    albiGradeInput.albuminUnit == 'g/L'
                      ? albiGradeInput.albumin || ''
                      : ''
                  }
                  onChangeText={val => {
                    setAlbiGradeInput({
                      ...albiGradeInput,
                      albumin: val,
                      albuminUnit: 'g/L'
                    })
                  }}
                />
                <Text style={{ paddingBottom: '6px'}}> g/L</Text>
              </View>
            </View>
          )}
        </View>
      ))}

      <View
        style={[
          tw('py-5 px-7'),
          {
            borderTopWidth: 1,
            borderBottomWidth: 1,
            borderColor: '#F1F1F1'
          }
        ]}
      >
        <Text style={tw('text-xs')}>
          {t('pages.childPughCalculator.footNote1')}
          {'\n'}
          {t('pages.childPughCalculator.footNote2')}
          {'\n'}
          {t('pages.childPughCalculator.footNote3')}
          {'\n'}
          {t('pages.childPughCalculator.footNote4')}
        </Text>
      </View>
      <Text style={tw('mb-3 pt-5 px-7 font-bold text-base')}>
        {t('pages.calculatorModal.results')}
      </Text>
      {allFilled && childPughClass == 'Child Pugh class C' && (
        <Text style={[tw('mb-3 px-7 font-bold text-xs'), { color: '#E07A5F' }]}>
          {t('pages.calculatorModal.notAvailable')}
        </Text>
      )}
      {!allFilled ? (
        <>
          <TouchableOpacity
            style={[
              albiScore == undefined && tw('mb-20'),
              tw('mx-7 p-4 flex justify-center items-center'),
              {
                borderColor: '#E1E1E1',
                borderWidth: 1
              }
            ]}
          >
            <Text style={tw('text-xs')}>{t('pages.calculatorModal.note')}</Text>
          </TouchableOpacity>
          <View style={tw('mx-7 mb-20')}>{renderALBI()}</View>
        </>
      ) : (
        <View style={tw('mx-7 mb-20')}>
          {hasCallback && allFilled && childPughClass != 'Child Pugh class C' && (
            <LinearGradient
              colors={['#506E7A', '#9BAAB0']}
              start={{ x: 0, y: 0 }}
              end={{ x: 1, y: 0 }}
              style={[
                { borderRadius: 4 },
                tw('flex justify-center items-center')
              ]}
            >
              <TouchableOpacity
                style={tw('flex-1 w-full')}
                onPress={() => {
                  let option = calculateChildPughClass()
                  if (option == 'C') return
                  onSurveyResult(option)
                }}
              >
                <View style={tw('py-5 flex justify-center items-center')}>
                  <Text
                    style={tw(
                      'flex-1 px-6 text-white text-center font-bold text-base'
                    )}
                  >
                    {t('pages.calculatorModal.tapToAdd')}
                  </Text>
                </View>
              </TouchableOpacity>
            </LinearGradient>
          )}
          <View
            style={[
              {
                borderRadius: 4,
                borderColor: '#E1E1E1',
                borderWidth: 1
              },
              tw('my-2 py-5 flex flex-1 justify-center items-center')
            ]}
          >
            <Text style={tw('font-bold text-base')}>
              {mapKey(childPughClass, lang)}
            </Text>
          </View>
          {renderALBI()}
        </View>
      )}
    </View>
  )
}

export default ChildPughSurvey
