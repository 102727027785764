import React, { useContext } from 'react'
import { useTailwind } from 'tailwind-rn'
import { useTranslation } from '../i18n'
import { i18nContext } from '../context'
import { Text, TouchableOpacity } from 'react-native'
import { LinearGradient } from 'expo-linear-gradient'

const DrawerButton = ({ text, isActive, onPress }) => {
  const { lang } = useContext(i18nContext)
  const t = useTranslation(lang)
  const tw = useTailwind()

  return (
    <LinearGradient
      colors={['#fff', isActive ? '#eaeaea' : '#fff']}
      start={{ x: 0, y: 0 }}
      end={{ x: 1, y: 0 }}
    >
      <TouchableOpacity
        style={[
          tw('py-5 px-12 flex justify-start items-start'),
          {
            borderLeftWidth: 6,
            borderLeftColor: isActive ? '#DD6E42' : '#fff'
          }
        ]}
        onPress={onPress}
      >
        <Text style={tw('text-xl')}>{t(text)}</Text>
      </TouchableOpacity>
    </LinearGradient>
  )
}

export default DrawerButton
