import React, { useContext, useState } from 'react'
import { i18nContext } from '../context'
import { useTailwind } from 'tailwind-rn'
import { useTranslation } from '../i18n'
import { TouchableOpacity, Modal, Text, View } from 'react-native'

import CheckboxBasic from './CheckboxBasic'
import PrimaryButton from './PrimaryButton'

const DisclaimerModal = ({ onClickYes, modalVisible, setModalVisible }) => {
  const tw = useTailwind()
  const { lang  } = useContext(i18nContext)
  const t = useTranslation(lang)

  const onSubmit = () => {
    setModalVisible(false)
    onClickYes()
  }

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={modalVisible}
      onRequestClose={() => {
        setModalVisible(!modalVisible)
      }}
    >
      <View
        style={[
          { backgroundColor: 'rgba(0, 0, 0, .7)' },
          tw('flex-1 justify-center items-center')
        ]}
      >
        <View style={tw('bg-white mx-5 p-10')}>
          <Text style={[tw('text-2xl pr-8 font-bold'), { color: '#506e7a' }]}>
            {t('pages.updateNotice.main')}
          </Text>
          <PrimaryButton
            onPress={onSubmit}
            style={tw('mt-7')}
            text={t('pages.updateNotice.submit')}
          />
        </View>
      </View>
    </Modal>
  )
}

export default DisclaimerModal
