export const references = [
  `Kudo M, et al. Lenvatinib as an Initial Treatment in Patients with Intermediate-Stage Hepatocellular Carcinoma Beyond Up-To-Seven Criteria and Child-Pugh A Liver Function: A Proof-Of-Concept Study. Cancers (Basel). 2019;11(8):1084.`,

  `Vogel A, et al. Updated treatment recommendations for hepatocellular carcinoma (HCC) from the ESMO Clinical Practice Guidelines. Ann  Oncol. 2021;32(6):801-805.`,

  `Kudo M, et al. Lenvatinib versus sorafenib in first-line treatment of patients with unresectable hepatocellular carcinoma: a randomised phase 3 non-inferiority trial. Lancet 2018; 391: 1163–73.`,

  `Bruix J, et al. Efficacy and safety of sorafenib in patients with advanced hepatocellular carcinoma: Subanalyses of a phase III trial. J Hepatol. 2012 ;57:821.`,

  `Finn RS, et al. IMbrave150 Investigators. Atezolizumab plus Bevacizumab in Unresectable Hepatocellular Carcinoma. N Engl J Med. 2020;382(20):1894-1905.`,

  `Gordan JD, et al. Systemic Therapy for Advanced Hepatocellular Carcinoma: ASCO Guideline. J Clin Oncol. 2020; 38:4317-4345.`,

  `LENVIMA® (lenvatinib) Product Monograph. Eisai Limited. Mississauga, Ontario. July 22, 2021.`,

  `Llovet, JM et al. Sorafenib in Advanced Hepatocellular Carcinoma. N Engl J Med. 2008; 359:378-390`,

  `NEXAVAR® (sorafenib) Product Monograph. Bayer Inc. Mississauga, Ontario. March 9, 2020.`,

  `Tecentriq® (atezolizumab) Product Monograph. Hoffmann-La Roche Limited. Mississauga, Ontario. January 14, 2022.`,

  `Western Canadian Gastrointestinal Cancer Consensus Conference: WC5 Consensus Statement. September 21, 2019. https://static1.squarespace.com/static/55425012e4b035082e15684a/t/5d9df19a608e736d2a587dbf/1570632091100/WC5_2019_FinalConsensusStatements.pdf. Accessed September 1, 2021`,

  `Knox J. 2013 WCGCCC Consensus Statements. https://static1.squarespace.com/static/55425012e4b035082e15684a/t/55427ef5e4b090e0afa4ecec/1430421237789/WC5-2013-ConsensusStatement.pdf. Accessed September 1,2021.`,

  `Roderburg C, et al. Antiviral Therapy in Patients with Viral Hepatitis and Hepatocellular Carcinoma: Indications and Prognosis. Visc Med 2016;32:121-126. doi: 10.1159/000444990`,

  `Finn RS, et al. Atezolizumab plus bevacizumab in unresectable hepatocellular carcinoma. N Engl J Med 2020;382 (suppl):1894-905. DOI: 10.1056/NEJMoa1915745`,

  `A Multicenter, Open-Label, Phase 3 Trial to Compare the Efficacy and Safety of Lenvatinib (E7080) Versus Sorafenib in First-line Treatment of Participants With Unresectable Hepatocellular Carcinoma. https://clinicaltrials.gov/ct2/show/NCT01761266`,

  `A Phase III Study of Sorafenib in Patients With Advanced Hepatocellular Carcinoma (SHARP). https://clinicaltrials.gov/ct2/show/NCT00105443`,

  `A Randomized, Open-label, Multi-center Phase III Study of Durvalumab and Tremelimumab as First-line Treatment in Patients With Advanced Hepatocellular Carcinoma. https://classic.clinicaltrials.gov/ct2/show/NCT03298451`,

  `Abou-Alfa GK, et al. Tremelimumab plus durvalumab in unresectable hepatocellular carcinoma. NEJM Evid. 2022;1(8). \n\nProtocol for: Abou-Alfa GK, et al. Tremelimumab plus durvalumab in unresectable hepatocellular carcinoma. NEJM Evid. 2022;1(8).`,

  'ALBI (Albumin-Bilirubin) Grade for Hepatocellular Carcinoma (HCC). https://www.mdcalc.com/calc/10070/albi-albumin-bilirubin-grade-hepatocellular-carcinoma-hcc. Accessed September 14, 2023.',

  'Demirtas CO, et al. ALBI grade: Evidence for an improved model for liver functional estimation in patients with hepatocellular carcinoma. JHEP Rep. 2021;3(5):100347',

  'Finn RS, et al. LBA34 - Primary results from the phase III LEAP-002 study: Lenvatinib plus pembrolizumab versus lenvatinib as first-line therapy for advanced hepatocellular carcinoma. Ann Oncol. 2022;33:S1401.',

  'Amaro C, et al. Dosing, efficacy and safety of lenvatinb in the real‐world treatment of hepatocellular carcinoma: Results from a Canadian database. Liver Cancer Int. 2022;3(3):119-127.',

  'Casadei-Gardini A, et al. Atezolizumab plus bevacizumab versus lenvatinib for unresectable hepatocellular carcinoma: A large real-life worldwide population. Eur J Cancer. 2023;180:9-20.',

  'Casadei-Gardini A, et al. Real life study of lenvatinib therapy for hepatocellular carcinoma: RELEVANT study. Liver Cancer. 2022;11(6):527-539.',

  `Haber PK, et al. Evidence-Based Management of Hepatocellular Carcinoma: Systematic Review and Meta-analysis of Randomized Controlled Trials (2002-2020). Gastroenterology. 2021:S0016-5085(21)03119-X.`,

  `IMJUDO® (tremelimumab) Product Monograph. AstraZeneca. Mississauga, Ontario. August 31, 2023.`,

  `Pfister D, et al. NASH limits anti-tumour surveillance in immunotherapy-treated HCC. Nature. 2021; 592: 450–456.`,

  `Yau T, et al. CheckMate 459: A randomized, multi-center phase III study of nivolumab (NIVO) vs sorafenib (SOR) as first-line (1L) treatment in patients (pts) with advanced hepatocellular carcinoma (aHCC). Ann. Oncol. 2019; 30 (Supp.5): v874–v875.`,

  `Finn RS, et al. Pembrolizumab as second-line therapy in patients with advanced hepatocellular carcinoma in KEYNOTE-240: a randomized, double-blind, phase III trial. J. Clin. Oncol. 2020;38:193–202.`,

  `Fulgenzi CA, et al. Reproducible safety and efficacy of atezolizumab plus bevacizumab for HCC in clinical practice: Results of the AB-Real Study. Eur J Cancer. 2022;175:204-213.`,

  `Johnson PJ, et al. Assessment of liver function in patients with hepatocellular carcinoma: A new evidence-based approach—the ALBI Grade. J Clin Oncol. 2015;33(6):550-558.`,

  `Kelley RK, et al. Cabozantinib plus atezolizumab versus sorafenib for advanced hepatocellular carcinoma (COSMIC-312): a multicentre, open-label, randomised, phase 3 trial. Lancet Oncol. 2022;23:995-1008.`,

  `Kudo M. Newly developed modified ALBI grade shows better prognostic and predictive value for hepatocellular carcinoma. Liver Cancer. 2021;11(1):1-8.`,

  `Marrero JA, et al. Diagnosis, staging, and management of hepatocellular carcinoma: 2018 practice guidance by the American Association for the Study of Liver Diseases. Hepatology. 2018;68:723-750.`,

  `Meyers BM, et al. The evolution of immune checkpoint inhibitor combinations in advanced hepatocellular carcinoma – A systematic review. Cancer Treat Rev. 2023;118:102584.`,

  `A Study of Atezolizumab in Combination With Bevacizumab Compared With Sorafenib in Patients With Untreated Locally Advanced or Metastatic Hepatocellular Carcinoma [IMbrave150]. https://www.clinicaltrials.gov/ct2/show/NCT03434379`,

  `Kennedy, LC, et al. Preexisting Autoimmune Disease: Implications for Immune Checkpoint Inhibitor Therapy in Solid Tumors. J Natl Compr Canc Netw. 2019 17(6), 750-757.`,

  `Pinter M, et al. Immunotherapy for advanced hepatocellular carcinoma: a focus on special subgroups. Gut. 2021;70:204–214.`,

  `Danlos FX, et al. Safety and efficacy of anti-programmed death 1 antibodies in patients with cancer and pre-existing autoimmune or inflammatory disease. Eur J Cancer. 2018;91:21–29.`,

  `Abdel-Wahab N, et al. Use of immune checkpoint inhibitors in the treatment of patients with cancer and preexisting autoimmune disease: a systematic review. Ann Intern Med. 2018;168:121–130.`,

  `Tison A, et al. Safety and efficacy of immune checkpoint inhibitors in patients with cancer and preexisting autoimmune disease: a nationwide, multicenter cohort study. Arthritis Rheumatol 2019;71:2100–11.`,

  `Leonardi GC, et al. Safety of programmed death-1 pathway inhibitors among patients with non-small-cell lung cancer and preexisting autoimmune disorders. J Clin Oncol 2018;36:1905–12.`,

  `Menzies AM, et al. Anti-PD-1 therapy in patients with advanced melanoma and preexisting autoimmune disorders or major toxicity with ipilimumab. Ann Oncol 2017;28:368–76.`,

  `Johnson DB, et al. Ipilimumab therapy in patients with advanced melanoma and preexisting autoimmune disorders. JAMA Oncol. 2016;2:234–40.`,

  `Gutzmer R, et al. Programmed cell death protein-1(PD-1) inhibitor therapy in patients with advanced melanoma and preexisting autoimmunity or ipilimumab-triggered autoimmunity. Eur J Cancer. 2017;75:24–32.`,

  `Richter MD, et al. Brief report: cancer immunotherapy in patients with preexisting rheumatic disease: the Mayo Clinic experience. Arthritis Rheumatol. 2018;70:356–360.`,

  `Lee B, et al. The use of ipilimumab in patients with rheumatoid arthritis and metastatic melanoma. Ann Oncol. 2016;27:1174–1177.`,

  `Mazzaferro V, et al. Liver transplantation for the treatment of small hepatocellular carcinomas in patients with cirrhosis N Engl J Med.1996;334:693–700.`,

  `Mazzaferro V, et al. Metroticket 2.0 Model for Analysis of Competing Risks of Death After Liver Transplantation for Hepatocellular Carcinoma.Gastroenterology 2018;154:128–39`,

  `Sposito C, et al. Comparative efficacy of sorafenib versus best supportive care in recurrent hepatocellular carcinoma after liver transplantation: a case–control study. J Hepatol. 2013; 59:59–66.`,

  `d'Izarny-Gargas T, et al. Efficacy and tolerance of immune checkpoint inhibitors in transplant patients with cancer: A systematic review. Am J Transplant. 2020;20(9):2457-2465.`,

  `Congly SE, et al. Practical management of esophageal varices in the context of SARS-CoV-2 (COVID-19): the Alberta protocol. Canadian Liver Journal. 2020;3(3):300-303.`,

  `Kovalak M, et al. Endoscopic screening for varices in cirrhotic patients: data from a national endoscopic database. Gastrointest Endosc. 2007;65(1):82–8.`,

  `Garcia-Tsao G, et al. Portal hypertensive bleeding in cirrhosis: risk stratification, diagnosis, and management: 2016 practice guidance by the American Association for the Study of Liver Diseases. Hepatology. 2017;65(1):310–35.`,

  `Fallowfield L, et al. Patients' preference for administration of endocrine treatments by injection or tablets: results from a study of women with breast cancer. Ann Oncol. 2006;17(2):205-210.`,

  `Ganguli A, et al. The impact of patient support programs on adherence, clinical, humanistic, and economic patient outcomes: a targeted systematic review. Patient Prefer Adherence. 2016;10:711-725. doi:10.2147/PPA.S101175]`,

  `Finn RS, et al. IMbrave150: Updated overall survival data from a global, randomized, open-label phase III study of atezolizumab + bevacizumab versus sorafenib in patients with unresectable hepatocellular carcinoma. Presented at  2021 Gastrointestinal Cancers Symposium.`,

  `Child Pugh Classification for Severity of Liver Disease. https://www.merckmanuals.com/medical-calculators/ChildTurPuScore.htm. Accessed November 2, 2021.`,

  `Child-Pugh Score for Cirrhosis Mortality. https://www.mdcalc.com/child-pugh-score-cirrhosis-mortality. Accessed November 2, 2021.`,

  `Reig M, et al. BCLC strategy for prognosis prediction and treatment recommendation Barcelona Clinic Liver Cancer (BCLC) staging system.The 2022 update. Journal of Hepatology. 2021.https://doi.org/10.1016/j.jhep.2021.11.018`,

  `Rimini M, et al. Nonalcoholic steatohepatitis in hepatocarcinoma: new insights about its prognostic role in patients treated with lenvatinib. ESMO Open. 2021 Dec;6(6):100330.`,

  `Rimini M, et al. Atezolizumab plus bevacizumab versus lenvatinib or sorafenib in non-viral unresectable hepatocellular carcinoma: an international propensity score matching analysis. ESMO Open. 2022 Dec;7(6):100591.`,

  `Vitale A, et al. Treatment of hepatocellular carcinoma in the precision medicine era: From treatment stage migration to therapeutic hierarchy. Hepatology. 2020;72(6):2206-2218.`,

  `Singal AG, et al. Real-world effectiveness of lenvatinib monotherapy among unresectable hepatocellular carcinoma patients in the USA. Future Oncol. 2021;17(21):2759-2768.`,

  `Vogel A, et al. Hepatoceulluar carcinoma: ESMO Clinical Practice Guidelines for diagnosis, treatment and follow-up. Ann Oncol. 2018; 29(S4):iv239-iv255. `,

  `Welland S, et al. Real-world data for lenvatinib in hepatocellular carcinoma (ELEVATOR): A retrospective multicenter study. Liver Cancer. 2022;11(3):219-232.`,

  `Wong JK, et al. Clinical consensus statement: Establishing the roles of Locoregional and systemic therapies for the treatment of intermediate-stage hepatocellular carcinoma in Canada. Cancer Treat Rev. 2023;115:102526.`
].sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
