const en = {
  title: 'HCCcare',
  home: 'Home',
  assessment: 'Assessment',
  additionalConsiderations: 'Additional Considerations',
  clinicalTrialSummaries: 'Clinical Trial Summaries',
  background: 'About HCCcare',
  references: 'References',
  legal: 'Legal',
  pages: {
    disclaimer: {
      main: 'HCCcare is only intended for Canadian medical professionals.',
      declare: 'I declare that I am a Canadian medical professional.',
      submit: 'Submit'
    },
    updateNotice: {
      main: 'Thank you for using the latest HCCcare application,\nwith updates as of 2023',
      submit: 'Ok'
    },
    index: {
      intro:
        'HCCcare is an educational resource that was developed to support medical oncologists in Canada when selecting the most appropriate first line systemic therapy for patients with hepatocellular carcinoma, based on current drug approvals.',
      trigger:
        'Click on the start button below to begin your patient assessment!',
      start: 'Start Assessment',
      lastUpdated: 'Last updated October 2023'
    },
    assessment: {
      desktopIntro1:
        'Please provide the following information on your patient to generate a customized summary of treatment considerations.',
      desktopIntro2: 'For more information on BCLC Staging, click on ',
      desktopIntro2Definitions: 'Staging Definitions. ',
      desktopIntro3:
        'To determine the Child Pugh Class for your patient, as well as an option to determine their ALBI grade, click on ',
      desktopIntro3Calculator: 'Child Pugh and ALBI Grade Calculator.',
      desktopIntro4: '',
      desktopIntro4Bold: '',
      desktopIntro4Cont: '',
      patientAssessment: 'Patient Assessment',
      patientFactors: 'Patient Factors',
      note: 'Please provide the following information on your patient',
      stagingDefinitions: 'Staging Definitions',
      calculator: 'Child Pugh Class and ALBI Grade Calculator',
      diseaseFactors: 'Disease Factors',
      summaryButton: 'Generate Assessment Summary',
      error: 'Please complete all the fields.',
      Sorafenib: 'Sorafenib',
      Lenvatinib: 'Lenvatinib',
      'Atezolizumab + Bevacizumab': 'Atezolizumab + Bevacizumab',
      'Durvalumab + Tremelimumab': 'Durvalumab + Tremelimumab',
      abbreviations: 'Treatment abbreviation',
      legend: 'Colour legend'
    },
    calculatorModal: {
      title:
        'Child Pugh Classification and ALBI Grade\nfor Severity of Liver Disease',
      results: 'Results',
      tapToAdd:
        'Click here to add the Child Pugh class to your patient assessment',
      note: 'Please fill out fields above.',
      notAvailable:
        'Treatment considerations for patients with Child-Pugh Class C are not available on this web application.',
      learnAboutALBI: 'Learn more about the ALBI Grade in the ',
      linkNote:
        "If you would like to return to the Assessment Summary after reviewing the Additional Considerations content, please select the Assessment button at the top of the page, not your browser's “Back” button",
      section: 'section'
    },
    background: {
      intro: `HCCcare is an educational resource tool that was developed to support medical oncologists in Canada when selecting the most appropriate first line therapy for patients with hepatocellular carcinoma (HCC). The content was developed by a Scientific Planning Committee of four members who are key opinion leaders and clinicians in the field of HCC.
`,

      committee: 'Meet the Scientific Planning Committee'
    },
    legal: {
      terms: 'Terms of Use',
      contactUs: 'CONTACT US',
      questions:
        'If you have any questions about this Disclaimer, you can contact Us:',
      byEmail: 'By email:',
      inWriting: 'In writing at:',
      privacy: 'Privacy Policy',
      address:
        '2100 Bloor Street West, Suite 6, PO Box 173, Toronto, Ontario, M6S 5A5'
    },
    assessmentSummary: {
      title: 'Assessment Summary',
      considerations: 'Treatment Considerations',
      summaryIntro:
        'Click on a factor to read more about the rationale and evidence behind each treatment consideration.',
      appropriate: 'Treatment is appropriate for the selected response.',
      etiologyLearnMore:
        'Data on the use of etiology as a factor for treatment selection continues to evolve, learn more in the ',
      reviewData: 'section to review the emerging data on this topic.',
      caution:
        'Treatment should be used with caution for the selected response.',
      notAppropriate: 'Treatment is not appropriate for the selected response.',
      footerNote:
        'A visual summary of treatment considerations is displayed, generated based on the data entered for each patient and disease factor. Click on a factor to read more about the rationale and evidence behind each treatment consideration.',
      disclaimer:
        'The above treatment considerations are only advice based on the current scientific evidence; medical co-morbidities may be additional factors to consider.',
      footNote1:
        'Sorafenib, lenvatinib, atezolizumab in combination with bevacizumab, and durvalumab in combination with tremelimumab are indicated for the first line treatment of unresectable HCC',
      footNote2: '',
      nexavar:
        'NEXAVAR® (sorafenib) Product Monograph. Bayer Inc. Mississauga, Ontario. March 9, 2020.',
      lenvima:
        'LENVIMA® (lenvatinib) Product Monograph. Eisai Limited. Mississauga, Ontario. July 22, 2021',
      tecentriq:
        'Tecentriq® (atezolizumab) Product Monograph. Hoffmann-La Roche Limited. Mississauga, Ontario. January 14, 2022',
      imjudo:
        'IMJUDO® (tremelimumab) Product Monograph. AstraZeneca. Mississauga, Ontario. August 31, 2023'
    },
    additionalConsiderations: {
      treatmentLogistics: 'Treatment Logistics',
      supportPrograms: 'Patient Support Programs',
      albiGrade: 'ALBI Grade',
      emergingData: 'Emerging Data',
      resources: 'Resources',
      subsections: {
        evidence: 'Real-World Evidence',
        etiology: 'Etiology of Liver Disease',
        evolution: 'Evolution of Control Arms',
        migration: 'Treatment Stage Migration'
      }
    },
    albiGrade: {
      title: 'ALBI Grade',
      paragraph1:
        'ALBI grade is an evidence-based method used to assess liver function in HCC by stratifying it into three grades [Johnson 2014]. Using only serum albumin and bilirubin levels, advantages of the ALBI grade scoring system include its simple, reproducible and objective utility, extensively tested in international settings [Johnson 2014; Kudo 2021].',
      paragraph2:
        'Recent research supports the use of ALBI grade as a prognostic biomarker for optimizing patient selection and therapeutic sequencing in HCC [Demirtas 2021]. ',
      paragraph3:
        'The ESMO HCC Clinical Practice Guidelines and NCCN HCC guidelines v1.2023 mention ALBI Grade in the context of the CP Class A measurement, where ALBI score can identify best prognosis (ALBI 1) and lesser prognosis (ALBI 2), with median survivals of 26 versus 14 months, respectively [Vogel 2018]. Moreover, ALBI grade can categorize patients based on BCLC stages and CP classes [Demirtas 2021]. ',
      paragraph4:
        'The ALBI Grade Calculator represents a promising tool in the HCC treatment landscape and its use should be validated in a prospective clinical trial.',
      ref: 'ALBI, albumin-bilirubin; BCLC, Barcelona Clinic Liver Cancer; CP, Child-Pugh; ESMO, European Society for Medical Oncology; HCC, hepatocellular carcinoma; NCCN, National Comprehensive Cancer Network'
    },
    evidence: {
      title: 'Real-World Evidence',
      paragraph1:
        'Real-world evidence for lenvatinib and atezolizumab + bevacizumab continues to emerge, providing valuable insights on these treatments in addition to the established clinical data.',
      paragraph2:
        'In a retrospective study of 220 patients treated with lenvatinib (79% as first-line therapy) from 10 Canadian cancer centres (CHORD database), median OS for first-line vs later line treatment was 12.5 vs 11.8 months (P = 0.83), respectively. [Amaro 2022]',
      paragraph3:
        'In a retrospective study of 233 patients treated with first-line lenvatinib in the USA, the landmark OS at 12 months was 73%. Median OS was not reached over a median follow-up of 10 months. [Singal 2021]',
      paragraph4:
        'In a retrospective study of 205 patients treated with first-line lenvatinib in Germany and Austria (ELEVATOR cohort), median OS was 12.8 months. [Welland 2022]',
      paragraph5:
        'In the retrospective RELEVANT study of 1325 patients treated with first-line lenvatinib across Europe and East Asia, median OS was 16.1 months. Multivariate analysis for OS showed that HBsAg positive, NLR >3, and AST >38 were independently associated with poor prognosis, while NAFLD/NASH-related etiology was independently associated with good prognosis. [Casadei-Gardini 2022]',
      paragraph6:
        'In a retrospective study of patients receiving lenvatinib (n=1341) and atezolizumab + bevacizumab (n=864) across Europe and East Asia, after inverse probability of treatment weighting (IPTW), no survival benefit was seen for atezolizumab + bevacizumab versus lenvatinib (16.4 vs 15.8 months, HR 0.97, 95% CI 0.80-1.17, P = 0.739). [Casadei-Gardini 2023]',
      paragraph7:
        'In the retrospective AB-Real study of 433 patients treated with first-line atezolizumab + bevacizumab across Europe, Asia, and the USA, median OS was 15.7 months. Multivariate analysis showed that ALBI (P < 0.001) and PVTT (P = 0.03) were independent prognostic factors for OS. [Fulgenzi 2022]',
      ref: 'ALBI, albumin-bilirubin; BCLC, Barcelona Clinic Liver Cancer; CP, Child-Pugh; ESMO, European Society for Medical Oncology; HCC, hepatocellular carcinoma; NCCN, National Comprehensive Cancer Network'
    },
    etiology: {
      title: 'Etiology of Liver Disease',
      paragraph1:
        'A recent meta-analysis of impact of molecular and immunotherapies on outcome per HCC etiology showed no impact of etiology on outcome with TKI/anti-VEGF therapies. [Haber 2021]',
      paragraph2:
        'HRs for OS were 0.81(95% CI 0.71–0.92) in patients with viral etiologies and 0.82 (95% CI 0.67–1.01) in patients with nonviral etiologies. Difference in efficacy was not statistically significant (P = 0.8828). [Haber 2021]',
      paragraph3:
        'A meta-analysis of 3 randomized phase III clinical trials (IMBrave150, CheckMate-459, KEYNOTE-240) in >1,600 patients with advanced HCC showed that, although immunotherapy improved survival in overall population (HR 0.77; 95% CI 0.63–0.94), survival was superior to control in patients with HBV-related HCC (n = 574; P = 0.0008) and HCV-related HCC (n = 345; P = 0.04), but not in patients with non-viral HCC (n = 737; P = 0.39). [Pfister 2021; Finn (IMBrave150) 2020; Yau 2019; Finn (KEYNOTE-240) 2020] ',
      paragraph4:
        'Patients with HCC of viral etiology (HBV or HCV infection) showed a benefit from checkpoint inhibition (HR 0.64; 95% CI 0.48–0.94) whereas patients with HCC of non-viral etiology did not (HR 0.92; 95% CI 0.77–1.11; P of interaction = 0.03. [Pfister 2021] ',
      paragraph5:
        'A meta-analysis on the impact of HCC etiology on the outcomes with TKI/anti-VEGF and immunotherapies showed pooled HR for OS in patients with non-viral–related HCC treated with ICIs was 0.92 (95% CI 0.77–1.11). [Haber 2021]  ',
      paragraph6:
        'Difference in efficacy between viral- and non-viral-related HCC treated with ICIs was significant (P of heterogeneity = 0.0259). Results were supported by sensitivity analysis. Effect of immune checkpoint inhibitors was similar in hepatitis HBV- and HCV-related HCC (HR 0.64 [95% CI 0.49–0.83] vs HR 0.68 [95% CI 0.47–0.98], respectively). [Haber 2021]',
      paragraph7:
        'There is real-world evidence to suggest patients with viral etiology might benefit more from immunotherapy versus TKI inhibitors and patients with non-viral etiology might benefit more from lenvatinib versus atezolizumab + bevacizumab:',
      paragraph8:
        'In a retrospective multicentre study of patients with unresectable HCC, a survival benefit was observed for atezolizumab + bevacizumab (n = 864) over lenvatinib (n = 1341) in patients with viral etiology (HR 0.76; P = 0.024). Patients treated with lenvatinib (n=190) showed a prolonged OS vs atezolizumab + bevacizumab (n=126) in patients with NASH/NAFLD etiology (HR: 1.88, P=0.014). [Casadei-Gardini 2023]',
      paragraph9:
        'In a retrospective analysis of patients with non-viral HCC, treatment with lenvatinib (n=569) was associated with a longer OS (HR 0.65; 95% CI 0.44-0.95; P=0.0268) vs atezolizumab + bevacizumab (n=190). In the NAFLD/NASH population, multivariate analysis showed that lenvatinib (n = 254) was associated with longer OS (HR 0.46; 95% CI 0.26-0.84; P = 0.0110) vs atezolizumab + bevacizumab (n = 82). [Rimini 2022]',
      paragraph10:
        'NASH was identified as an independent prognostic factor for OS in a retrospective analysis of patients with advanced HCC treated with first-line lenvatinib after multivariate analysis (HR 0.64; 95% CI 0.48-0.86; P=0.0028). NASH etiology was also shown to be predictive for patients treated with lenvatinib vs sorafenib (P = 0.0047). [Rimini 2021]',
      ref: 'CI, confidence interval; HBV, hepatitis B virus; HCC, hepatocellular carcinoma; HCV, hepatitis C virus; HR, hazard ratio; ICI, immune checkpoint inhibitor; NAFLD, nonalcoholic fatty liver disease; NASH, nonalcoholic steatohepatitis; OS, overall survival; TKI, tyrosine kinase inhibitors; VEGF, vascular endothelial growth factor'
    },
    evolution: {
      title: 'Evolution of Control Arms',
      paragraph1:
        'Since the REFLECT data in 2018 (mOS of 13.6 and 12.3 months for lenvatinib and sorafenib arms, respectively), phase 3 trials of first-line TKIs and ICIs in HCC have shown a trend of longer overall survival in control arms (13.4, 13.8, and 15.5 months for sorafenib in IMbrave150, HIMALAYA, and COSMIC-312, respectively, and 19.0 months for lenvatinib in LEAP-002). This could be reflective of differences in eligibility criteria, improved supportive care, stage migration, and the impact of post-progression therapies. [Kudo 2018; Finn 2020; Abou-Alfa 2022; Kelley 2022; Finn 2022; Kelley 2022]',
      paragraph2:
        'In a systematic review of phase 3 trials investigating ICI combinations for advanced HCC, differences in key prognostic baseline factors may help explain differences in the outcomes of control arms across trials (see figure below). Notably, median OS for single-agent lenvatinib in LEAP-002 was higher than that observed in any single-agent sorafenib arm, irrespective of differences in baseline characteristics. [Meyers 2023]',
      ref: 'BCLC, Barcelona Clinic Liver Cancer; HCC, hepatocellular carcinoma; ICI, immune checkpoint inhibitor; L(R)T, locoregional treatments; mOS, median overall survival; MVI, microvascular invasion; OS, overall survival; TKI; tyrosine kinase inhibitors',
      subheading:
        'Select patient characteristics (bars) and median OS of control arms (dots) from phase 3 trials of ICI combinations in advanced HCC',
      colourLegend: 'Colour legend'
    },
    migration: {
      title: 'Treatment Stage Migration',
      paragraph1:
        'The BCLC scheme represents a “stage hierarchy” in which each stage (or substage) of HCC is linked to a specific treatment. However, to adapt to real-world clinical practice, the latest versions of European and American guidelines, as well as the most recent BCLC system, have introduced elements of flexibility. [Vitale 2020; Marrero 2018; Reig 2022]',
      paragraph2:
        'The treatment stage migration strategy allows for moving to another treatment if the approach linked with the current stage proves to be unfeasible. It is usually applied in a “left-to-right” direction, offering the treatment option recommended for the subsequent tumour stage because patients are not suitable for their first-line therapy. [Vitale 2020; Marrero 2018]',
      paragraph3:
        'However, in certain patients with parameters close to the thresholds defining the previous stage, a right-to-left migration strategy, offering a therapy recommended for an earlier stage, could be the best opportunity, pending multidisciplinary decision. [Vitale 2020; Marrero 2018]',
      paragraph4:
        'Similarly, the 2023 CAIR clinical consensus statement for the treatment of intermediate-stage HCC in Canada indicates that lenvatinib, atezolizumab + bevacizumab, and durvalumab + tremelimumab have shown non-inferiority or superiority in survival, predictable and manageable toxicity, and higher response rates versus sorafenib, which is of clinical interest as this can lead to tumour reduction and downstaging. [Wong 2023]',
      paragraph5:
        'CAIR also states that management of HCC requires multidisciplinary input at the time of treatment decision making. Management should be perceived as a cycle of care, incorporating strategic, personalized use of therapies rather than sequential lines of therapy. Patients with BCLC-C disease who are radiographically responding to therapy may be re-evaluated as BCLC-B if clinical parameters permit. [Wong 2023] ',
      ref: 'BCLC, Barcelona Clinic Liver Cancer; CAIR, Canadian Association for International Radiology; HCC, hepatocellular carcinoma'
    },
    treatmentLogistics: {
      title: 'Treatment Logistics',
      ascoGuidelines:
        'ASCO guidelines state that choice of treatment should be discussed by physician and patient (and caregiver) and should include factors such as ',
      ascoGuidelinesBold: 'goals of treatment and patient preference ',
      ascoGuidelinesCont:
        '(as well as associated toxicities and expected treatment benefit). [Gordan 2020]',
      studyLine:
        'In a study of 208 women post-breast cancer diagnosis, regarding their preference for daily tablets or monthly intramuscular injections [Fallowfield 2006]:',
      preference:
        '63% of patients preferred tablets, 24.5% preferred injection and 12.5% had no preference.',
      citedReasonBold: 'Most cited reasons for tablet preference ',
      were: 'were',
      reasonBold: ' convenience ',
      citedReason:
        'and dislike of needles; for injection preference, adherence and convenience. About half admitted they sometimes forgot or chose not to take current oral medication.',
      convenience:
        'The convenient administration of tablet format for HCC may be considered for patients who live far from cancer centers, especially during winter. [SPC recommendation]',
      abbreviations:
        'ASCO, American Society of Clinical Oncology; HCC, hepatocellular carcinoma; SPC, Scientific Planning Committee'
    },
    patientSupport: {
      title: 'Patient Support Programs',
      intro:
        'Evidence demonstrates that patient support programs have a positive impact on treatment adherence, clinical and humanistic (e.g., quality of life, functional status) outcomes, health care utilization and costs in diseases with complex therapies. [Ganguli 2016]',
      impactTitle: 'Impact of patient support programs on outcomes assessed',
      adapted: 'Adapted from Ganguli A, et al. Patient Prefer Adherence. 2016'
    },
    resources: {
      title: 'Resources',
      phone: 'Phone',
      fax: 'Fax',
      email: 'Email',
      bther4u: 'BTher4U Patient Support Program (sorafenib)',
      eisai: 'Eisai Cares Patient Support Program (lenvatinib)',
      oncare: 'OnCare Patient Assistance (atezolizumab + bevacizumab)',
      reimbursement: 'Patient reimbursement navigation services',
      reimbursementAid: 'Patient reimbursement navigation services',
      financialAssistance: 'Patient financial assistance programs',
      financialAid: 'Patient financial assistance programs',
      delivery: 'Drug delivery to patients’ homes',
      infusionSupport:
        'Infusion support: to help facilitate access to infusion clinics across Canada'
    },
    trialSummaries: {
      design: 'Design',
      inclusion: 'Inclusion',
      bclcStage: 'BCLC Stage',
      childPughClass: 'Child-Pugh class',
      ecogPs: 'ECOG PS',
      exclusion: 'Exclusions',
      renalFailure:
        'Renal failure, cardiac disease, GI bleeding within 30 days',
      liverInvolvement:
        '≥50% liver involvement, portal vein involvement, bile duct invasion, untreated varices, bleeding risk',
      autoimmuneDisease:
        'autoimmune disease / deficiency, lung disease (e.g., pneumonitis), untreated varices, bleeding risk, HBV/HCV coinfection',
      ascites:
        'Clinically meaningful ascites (requiring nonpharmacologic intervention), main portal vein thrombosis, HBV/HCV coinfection',
      endpoints: 'Endpoints',
      primary: 'Primary',
      secondary: 'Secondary',
      eval: {
        os: 'OS',
        stride: 'STRIDE',
        vsSoraph: 'vs sorafenib',
        ttsp: 'TTSP',
        ttrp: 'TTRP',
        dcr: 'DCR',
        safety: 'Safety',
        osNoNothing: 'OS (non-inferiority and superiority), ',
        oss: 'OSS†',
        osNoInferiority: 'OS (non-inferiority)',
        pfs: 'PFS',
        ttp: 'TTP',
        orr: 'ORR',
        pro: 'PRO',
        antidrug: 'antidrug antibodies',
        QoL: 'QoL',
        psfIndependent: 'PFS† (Independent)',
        or: 'OR',
        dor: 'DOR'
      },
      etilology: 'Etiology',
      etilologyKeys: {
        hbv: 'HBV',
        hcv: 'HCV',
        nonViral: 'Non-viral',
        alcohol: 'Alcohol',
        unknown: 'Unknown/Other'
      },
      mvi: 'MVI',
      ehs: 'EHS',
      mviOrEhs: 'MVI or EHS',
      varices: 'Varices',
      varicesKeys: {
        notEvaluated: 'Not evaluated',
        present: 'Present: 26 \nEvaluated by mandatory EGD on trial'
      },
      medianOs: 'Median OS (months)',
      medianOsKeys: {
        first: '10.7 vs 7.9 \nHR 0.69 (95% CI, 0.55 - 0.87) \nP <0.001',
        second:
          '13.6 vs 12.3\nHR 0.92 (95% CI, 0.79 - 1.06) \n* confirmation of non-inferiority',
        third: '19.2 vs 13.4\nstratified HR=0.66 (95% CI: 0.52-0.85)',
        fourth: '16.43 vs 13.77\nHR 0.78 (96% CI, 0.65-0.93)'
      },
      medianPfs: 'Median PFS (months)',
      medianPfsKeys: {
        notEndpoint: 'PFS was not a reported endpoint',
        reflect: '7.4 vs 3.7\nHR 0.66 (95% CI, 0.57 – 0.77)\np<0.0001',
        imbrave: 'stratified HR=0.65 (95% CI: 0.53–0.81)',
        hima: '3.78 vs 4.07\nHR 0.90 (95% CI, 0.77-1.05)'
      },
      orr: 'ORR',
      orrKeys: {
        second:
          'mRECIST: by Investigator: 24.1 vs 9.2\nmRECIST: by IRR: 40.6 vs 12.4\nRECIST: v1.1 by IRR: 18.8 vs 6.5',
        third: 'RECIST: v1.1: 30 vs 11\nmRECIST: 35 vs 14',
        fourth: 'RECIST v1.1: 20.1 vs 5.1'
      },
      safety: 'Safety',
      safetyKeys: {
        commonAdverseEffects: 'Common Adverse Effects',
        first: {
          diarrhea: 'Diarrhea, fatigue, hand-foot skin reaction',
          effects:
            'Drug-related adverse events occurring in >10% if any grade or >5% if Grade 3/4 in either group'
        },
        second: {
          hypertension:
            'Hypertension, decreased appetite, decreased body weight, proteinuria',
          effects:
            'Adverse events occurring in ≥15% of patients in either treatment arm'
        },
        third: {
          hypertension:
            'Hypertension, fatigue, proteinuria; increase AST level',
          effects:
            'Drug-related adverse events occurring in >10% if any grade or >5% if Grade 3/4 in either group'
        },
        fourth: {
          diarrhea:
            'Diarrhea, constipation, abdominal pain, nausea, pruritis, rash, alopecia, palmar-plantar erythrodysesthesia syndrome, increased AST, increased ALT, increased amylase, increased bilirubin, increased GGT, increased lipase, decreased appetite, asthenia, fatigue, pyrexia, peripheral edema, cough, insomnia, hypothyroidism, hypertension, anemia, hyperkalemia, hypokalemia, hyponatremia  ',
          effects:
            'Drug-related adverse events occurring in >10% if any grade or >2% if Grade 3/4 in either group'
        }
      },
      healthCanada: `Health Canada Approved Indications`,
      healthCanadaKeys: {
        first:
          'Sorafenib is indicated for the treatment of patients with unresectable hepatocellular carcinoma. There are limited safety data available for Child-Pugh Class B patients.⁸',
        firstSuperscript: '',
        second:
          'Lenvatinib is indicated for the first-line treatment of adult patients with unresectable hepatocellular carcinoma. Efficacy and safety data for Child-Pugh Class B and Class C are not available.⁹',
        secondSuperscript: '',
        third:
          'Atezolizumab in combination with bevacizumab is indicated for the first-line treatment of adult patients with unresectable or metastatic hepatocellular carcinoma who require systemic therapy.¹⁰',
        thirdSuperscript: '',
        fourth:
          'Tremelimumab in combination with durvalumab is indicated for the first-line treatment of adult patients with unresectable hepatocellular carcinoma who require systemic therapy.¹³',
        fourthSuperscript: ''
      },
      footnotes: '† RECIST, ‡ mRECIST,',
      nonInferiority: `* Non-inferiority was declared if the upper limit of the 2-sided 95% CI for HR was less than 1.08, overall type I error rate at α=0.05 (2-sided); § OS did not meet the predefined threshold of statistical significance (HR 0.84, P = 0.0419); ¥ Stratification factors included in the Cox model are geographic region (Asia excluding Japan vs Rest of World), and MVI and/or EHS (Yes vs No) per interactive voice/web response system (IxRS). P value for descriptive purpose only.`,
      strideNote:
        '# STRIDE regimen: durvalumab (1500 mg q4w) + tremelimumab (300 mg, one dose). Durvalumab (1500 mg IV q4w) + tremelimumab (75 mg q4w x 4 doses) efficacy did not meaningfully differentiate from durvalumab monotherapy, so enrollment in this arm was closed and the protocol was amended to assign patients 1:1:1 to STRIDE, durvalumab, and sorafenib. Safety data for this arm were reported.',
      afp: `ALT, alanine aminotransferase; AST, aspartate aminotransferase; BCLC, Barcelona Clinic Liver Cancer, BSC, best supportive care; CI, confidence interval; DCR, disease control rate; DOR, duration of response; ECOG, Eastern Cooperative Oncology Group; EGD, esophagogastroduodenoscopy; EHS, extrahepatic spread;  GGT, gammaglutamyl transferase; GI, gastrointestinal; HBV, hepatitis B virus; HCV, hepatitis C virus; HCC, hepatocellular carcinoma; HR, hazard ratio; IV, intravenous; mRECIST, modified RECIST; MVI, macroscopic vein invasion; ORR, overall response rate; OS, overall survival; PFS, progression free survival; PRO, patient reported outcomes; PS, performance status; q, every; QoL, quality of life; RECIST, Response Evaluation Criteria in Solid Tumors; TTRP, time to radiologic progression TTSP, time to symptomatic progression; TTP, time to progression; w, week`,
      references: `1. Llovet JM, et al. N Engl J Med 2008;359:378-90. 2. SHARP. ClinicalTrials.gov. NCT00105443. 3. Kudo M et al. Lancet 2018;391:1163-1173. 4. REFLECT. ClinicalTrials.gov. NCT01761266. 5. Finn RS, et al. N Engl J Med 2020; 382:1894-1905. 6. IMBrave150. ClinicalTrials.gov. NCT03434379. 7. Finn RS, et al. GI21. Gastrointestinal Cancers Symposium 2021. `,
      nexavar: `NEXAVAR® (sorafenib) Product Monograph. Bayer Inc. Mississauga, Ontario. March 9, 2020. `,
      levinma: `LENVIMA® (lenvatinib) Product Monograph. Eisai Limited. Mississauga, Ontario. July 22, 2021. `,
      tecentriq: `Tecentriq® (atezolizumab) Product Monograph. Hoffmann-La Roche Limited. Mississauga, Ontario. January 14, 2022.`,
      imjudo: `IMJUDO® (tremelimumab) Product Monograph. AstraZeneca Canada Inc. Mississauga, Ontario. August 31, 2023.`,
      rest: ' 11. Abou-Alfa G, et al. NEJM Evid 2022;1(8). 12. HIMALAYA. ClinicalTrials.gov. NCT03298451.'
    },
    sharpSafetyModal: {
      title: 'SHARP: SAFETY',
      averseEvents:
        'Drug-related adverse events occurring in >10% if any grade or >5% if Grade 3/4 in either group'
    },
    reflectSafetyModal: {
      title: 'REFLECT: SAFETY',
      averseEvents:
        'Adverse events occurring in ≥ 15% of patients in either treatment arm.'
    },
    imbraveSafetyModal: {
      title: 'IMBRAVE 150: SAFETY',
      adverseEvents:
        'Drug-related adverse events occurring in >10% if any grade or >5% if Grade 3/4 in either group',
      immuneMediated: 'Immune-Mediated Adverse Reactions',
      adverseReactions: `Immune-mediated adverse reactions, including severe and fatal cases, have occurred in patients receiving atezolizumab. In clinical trials, most immune-mediated adverse reactions were reversible and managed with interruptions of atezolizumab, administration of corticosteroids and/or supportive care. Immune-mediated adverse reactions have also occurred after the last dose of atezolizumab. Immune-mediated adverse reactions affecting more than one body system can occur simultaneously.${'\n'}Immune-mediated adverse reactions requiring systemic corticosteroid therapy occurred in 12.2% of patients receiving atezolizumab+ bevacizumab for HCC. Refer to the Tecentriq Product Monograph for complete list of immune-mediated reactions, dose modification and treatment. [Tecentriq® (atezolizumab) Product Monograph 2021]`
    },
    himalayaSafetyModal: {
      title: 'HIMALAYA: SAFETY',
      teas: 'TEAEs occurring in >10% if any grade or >2% if Grade 3/4 in any group'
    },
    stagingModal: {
      title: 'BCLC Staging',
      veryEarly: 'Very Early',
      singleNodule:
        '• Single nodule ≤ 2cm\n• Preserved liver function \n• PS 0\n',
      early: 'Early',
      earlyNodule:
        '• Up to 3 nodules ≤3cm\n• Preserved liver function \n• PS 0\n',
      intermediate: 'Intermediate',
      multinodular: '• Multinodular\n• Preserved liver function \n• PS 0\n',
      advanced: 'Advanced',
      portal:
        '• Portal invasion and/ or EHS\n• Preserved liver function \n• PS 1-2\n',
      terminal: 'Terminal',
      tumor: '• Any tumor burden\n• End stage liver function \n• PS 3-4 \n',
      footNote1:
        'BCLC, Barcelona Clinic Liver Cancer; HCC, hepatocellular carcinoma; EHS, extrahepatic spread; PS, performance status.',
      footNote2:
        'Adapted from Reig M, et al. Journal of Hepatology.\n2021.https://doi.org/10.1016/j.jhep.2021.11.018.'
    },
    childPughCalculator: {
      Ascites: 'Ascites',
      Absent: 'Absent',
      Slight: 'Slight',
      Moderate: 'Moderate',
      Bilirubin: 'Bilirubin',
      Albumin: 'Albumin',
      or: 'or',
      'or INR': 'or INR',
      'Prothrombin time (seconds above control)':
        'Prothrombin time (seconds above control)',
      Encephalopathy: 'Encephalopathy',
      enterAlbumin:
        'To calculate your patient’s ALBI grade, please enter the numerical albumin value:',
      enterBilirubin:
        'To calculate your patient’s ALBI grade, please enter the numerical bilirubin value:',
      footNote1:
        'ALBI: albumin-bilirubin \nINR: international normalized ratio.\n',
      footNote2:
        'Child Pugh Classification for Severity of Liver Disease. https://www.merckmanuals.com/medical-calculators/ChildTurPuScore.htm. Accessed November 2, 2021.\n',
      footNote3:
        'Child-Pugh Score for Cirrhosis Mortality. https://www.mdcalc.com/child-pugh-score-cirrhosis-mortality. Accessed November 2, 2021.\n',
      footNote4: `Johnson PJ, et al. J Clin Oncol. 2015;33(6):550-558.

ALBI (Albumin-Bilirubin) Grade for Hepatocellular Carcinoma (HCC). https://www.mdcalc.com/calc/10070/albi-albumin-bilirubin-grade-hepatocellular-carcinoma-hcc. Accessed September 14, 2023.`
    },
    privacy: {
      date: 'January 1st, 2022',
      paragraph1: `Bridge Medical Communications Inc. (“we” or “us” or “our”) respects the privacy of our users (“user” or “you”). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our mobile application (the “Mobile Application”) or our Internet-based application (the “Web Application” and together with the Mobile Application, the “Applications”).   Please read this Privacy Policy carefully.  IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY, PLEASE DO NOT ACCESS THE APPLICATION.

We reserve the right to make changes to this Privacy Policy at any time and for any reason.  We will alert you about any changes by updating the “Last updated” date of this Privacy Policy.  You are encouraged to periodically review this Privacy Policy to stay informed of updates. You will be deemed to have been made aware of, will be subject to, and will be deemed to have accepted the changes in any revised Privacy Policy by your continued use of the Application after the date such revised Privacy Policy is posted.

This Privacy Policy does not apply to the third-party online/mobile store (collectively, “Third Party Platforms”) from which you install the Mobile Application and the terms of your use of such Third Party Platforms shall be determined by the third party that administers the relevant Third Party Platform..  We are not responsible for any of the data collected by any such third party. `,
      collection: 'COLLECTION OF YOUR INFORMATION',
      paragraph2:
        'The Applications do not collect or retain any personal information about our users. Data collection is limited to device data only and is collected on an aggregate basis only. ',
      deviceData: 'Data Collected via Mobile Application',
      paragraph3:
        'Device information such as your mobile device ID number, model, and manufacturer, version of your operating system, phone number, country, location, and any other data you choose to provide. ',

      deviceData2: 'Data Collected via Web Application',
      'paragraph3,25':
        'When using the Web Application, we collect information about your device type, device ID, operating system, browser type, country, location and any data you choose to provide. ',
      infoUse: 'USE OF YOUR INFORMATION',
      mobileApp: 'Mobile Application',
      'paragraph3,5':
        'Having accurate information about your mobile device usage permits us to provide you with a smooth, efficient, and customized experience.  Specifically, we may use information collected about Mobile Application usage to:\n',
      paragraph4:
        '1. Increase the efficiency and operation of the Mobile Application.\n2. Monitor and analyze usage and trends to improve your experience with the Mobile Application.',
      webApp: 'Web Application',
      'paragraph4,5':
        'We may use information collected via the Web Application to:\n',

      'paragraph4,75':
        '1. Make improvements to the Web Application; and\n2. Monitor and analyze usage trends to improve users’ experience with the Web Application.',
      disclosure: 'DISCLOSURE OF YOUR INFORMATION',
      paragraph5:
        'We may share aggregate information we have collected in certain situations. Your information may be disclosed in aggregate as follows:',
      paragraph6: 'Third-Party Service Providers',
      paragraph7:
        'We may share aggregated user data with third parties that perform services for us or on our behalf, including data analysis and hosting services.',
      other: 'Other Third Parties',
      paragraph8:
        'We may share aggregate user information with investors for the purpose of conducting general business analysis.',
      tracking: 'TRACKING TECHNOLOGIES',
      analytics: 'Website Analytics',
      paragraph9: 'We may also partner with selected third-party vendors',
      googleAnalytics: '[such as Google Analytics]',
      'paragraph9,2':
        'to allow tracking technologies on the Applications through the use of first party cookies and third-party cookies, to, among other things, analyze and track users’ use of the Applications, determine the popularity of certain content, and better understand online activity. By accessing the Applications, you consent to the collection and use of your information by these third-party vendors. You are encouraged to review their privacy policy and contact them directly for responses to your questions. We do not transfer personal information to these third-party vendors. However, if you do not want any information to be collected and used by tracking technologies, you can install and/or update your settings for one of the following: ',
      aboutAdsOptOutLink: 'http://www.aboutads.info/choices/',
      andAdobe: ', ',
      adobeOptOutLink: 'http://www.adobe.com/privacy/opt-out.html',
      andGoogle: ', ',
      googleAnalyticsOptOut: 'Google Analytics Opt-Out Plugin',
      websites: 'THIRD-PARTY WEBSITES',
      paragraph10:
        'The Applications may contain links to third-party websites and applications of interest, including advertisements and external services, that are not affiliated with us. Once you have used these links to leave the Applications, any information you provide to these third parties is not covered by this Privacy Policy, and we cannot guarantee the safety and privacy of your information. Before visiting and providing any information to any third-party websites, you should inform yourself of the privacy policies and practices (if any) of the third party responsible for that website, and should take those steps necessary to, in your discretion, protect the privacy of your information. We are not responsible for the content or privacy and security practices and policies of any third parties, including other sites, services or applications that may be linked to or from the Applications.',
      contact: 'CONTACT US',
      paragraph11:
        'If you have any questions about this Privacy Policy, you can contact Us:',
      paragraph12: 'By email:',
      paragraph13:
        'In writing at: 2100 Bloor Street West, Suite 6, PO Box 173, Toronto, Ontario, M6S 5A5'
    }
  }
}

const fr = {
  title: 'soinsCHC',
  home: 'Accueil',
  assessment: 'Évaluation',
  additionalConsiderations: 'Autres considérations',
  clinicalTrialSummaries: 'Résumés des essais cliniques',
  background: 'À propos de soinsCHC',
  references: 'Références',
  legal: 'Avis juridiques',
  pages: {
    disclaimer: {
      main: 'La plateforme soinsCHC est réservée aux professionnels de la santé canadiens.',
      declare: 'Je suis un professionnel de la santé canadien. ',
      submit: 'Soumettre'
    },
    updateNotice: {
      main: `Merci d'utiliser la dernière application HCCcare,\navec des mises à jour à partir de 2023`,
      submit: 'Ok'
    },
    index: {
      intro:
        'La plateforme soinsCHC est une ressource éducative qui a été élaborée pour aider les oncologues médicaux du Canada à choisir le traitement systémique de première intention le plus approprié pour les patients atteints d’un carcinome hépatocellulaire, en fonction des approbations de médicaments actuelles.',
      trigger:
        'Cliquez sur le bouton ci-dessous pour commencer l’évaluation de votre patient.',
      start: 'Commencer l’évaluation',
      lastUpdated: 'Dernière mise à jour : octobre 2023'
    },
    assessment: {
      desktopIntro1:
        'Veuillez fournir les renseignements suivants à propos de votre patient pour générer un résumé personnalisé des considérations thérapeutiques.',
      desktopIntro2:
        'Pour en savoir plus sur la classification BCLC, cliquez sur ',
      desktopIntro2Definitions: 'Définitions des stades. ',
      desktopIntro3:
        'Pour déterminer la classification de Child-Pugh qui s’applique à votre patient, cliquez sur ',
      desktopIntro3Calculator:
        'Calculateur de la classe de Child-Pugh et du grade ALBI.',
      patientAssessment: 'Évaluation du patient',
      patientFactors: 'Facteurs du patient',
      note: 'Veuillez fournir les informations suivantes à propos de votre patient',
      stagingDefinitions: 'Définitions des stades',
      calculator: 'Calculateur de la classe de Child-Pugh et du grade ALBI',
      diseaseFactors: 'Facteurs de la maladie',
      summaryButton: 'Générer le résumé de l’évaluation',
      error: 'Veuillez remplir tous les champs.',
      Sorafenib: 'Sorafenib',
      Lenvatinib: 'Lenvatinib',
      'Atezolizumab + Bevacizumab': 'Atézolizumab +  bévacizumab',
      'Durvalumab + Tremelimumab': 'Durvalumab + Trémélimumab',
      abbreviations: 'Abréviations des traitements',
      legend: 'Légende des couleurs'
    },
    background: {
      intro: `La plateforme soinsCHC est une ressource éducative qui a été élaborée pour aider les oncologues médicaux du Canada à choisir le traitement de première intention le plus approprié pour les patients atteints d’un carcinome hépatocellulaire (CHC). Le contenu a été élaboré par un comité de planification scientifique composé de quatre membres qui sont des leaders d’opinion et des cliniciens dans le domaine du CHC.`,
      committee: 'Membres du comité de planification scientifique'
    },
    references: {},
    legal: {
      terms: 'Conditions d’utilisation',
      privacy: 'Politique de confidentialité',
      contactUs: 'POUR NOUS JOINDRE',
      questions:
        'Si vous avez des questions au sujet des présentes modalités, vous pouvez communiquer avec nous :',
      byEmail: 'Par courriel : ',
      inWriting: 'Par écrit :',
      address:
        '2100, rue Bloor Ouest, bureau 6, C.P. 173 Toronto (Ontario)  M6S 5A5'
    },
    assessmentSummary: {
      title: 'Résumé de l’évaluation',
      considerations: 'Considérations thérapeutiques',
      etiologyLearnMore:
        'Les données sur l’utilisation de l’étiologie comme facteur dans le choix du traitement continuent d’évoluer; pour en savoir plus, consultez la section ',
      reviewData: 'afin de passer en revue les nouvelles données sur ce sujet.',
      summaryIntro:
        'Cliquez sur un facteur pour connaître les données probantes et la justification qui sous-tendent chaque considération thérapeutique.',
      appropriate: 'Le traitement convient à la réponse choisie.',
      footerNote:
        'Vous trouverez ci-dessous un résumé visuel des considérations thérapeutiques, généré à partir des données saisies pour chaque facteur du patient et de la maladie. Cliquez sur un facteur pour connaître les données probantes et la justification qui sous-tendent chaque considération thérapeutique.',
      caution:
        'Le traitement doit être utilisé avec précaution pour la réponse choisie.',
      notAppropriate: 'Le traitement ne convient pas à la réponse choisie.',
      disclaimer:
        'Les considérations thérapeutiques présentées ci-dessus ne sont que des conseils fondés sur les données probantes actuelles; les comorbidités pourraient donner lieu à d’autres facteurs à prendre en compte.',
      footNote1:
        `Le sorafenib, le lenvatinib, l'atezolizumab en association avec le bevacizumab, et le durvalumab en association avec le tremelimumab sont indiqués pour le traitement de première intention du CHC non résécable`,
      footNote2: '',
      nexavar:
        'Monographie de NEXAVAR® (sorafenib). Bayer Inc. Mississauga (Ontario). 9 mars 2020.',
      lenvima:
        'Monographie de LENVIMA® (lenvatinib). Eisai Limitée. Mississauga (Ontario). 22 juillet 2021.',
      tecentriq:
        'Monographie de Tecentriq® (atézolizumab). Hoffmann-La Roche Limited/Limitée. Mississauga (Ontario). 14 janvier 2022.',
      imjudo:
        'Monographie d’IMJUDO® (trémélimumab). AstraZeneca Canada Inc. Mississauga (Ontario). 31 août 2023.'
    },
    additionalConsiderations: {
      treatmentLogistics: 'Logistique du traitement',
      supportPrograms: 'Programmes d’aide aux patients',
      albiGrade: 'Grade ALBI',
      emergingData: 'Nouvelles données',
      resources: 'Ressources',
      subsections: {
        evidence: 'Données probantes obtenues en contexte réel',
        etiology: 'Étiologie de la maladie hépatique',
        evolution: 'Évolution des groupes témoins',
        migration: 'Transition thérapeutique (migration à un autre stade)'
      }
    },
    albiGrade: {
      title: 'Grade ALBI ',
      paragraph1:
        'Le grade ALBI est une méthode fondée sur les données probantes qui sert à évaluer la fonction hépatique des patients atteints de CHC par une stratification en trois grades [Johnson 2014]. Reposant uniquement sur les taux sériques d’albumine et de bilirubine, le système de classification des grades ALBI a l’avantage d’être utile d’une manière simple, reproductible et objective et d’avoir fait l’objet d’évaluations approfondies à l’échelle internationale. [Johnson 2014; Kudo 2021] ',
      paragraph2:
        'Des recherches récentes étayent l’utilisation du grade ALBI comme biomarqueur pronostique pour optimiser la sélection des patients et la séquence de traitements du CHC. [Demirtas 2021] ',
      paragraph3:
        'Les lignes directrices de pratique clinique de l’ESMO et les lignes directrices du NCCN, v1.2023, pour le traitement du CHC font mention du grade ALBI mesuré dans le contexte de la classe A de Child-Pugh, où le score ALBI permet de déterminer le meilleur pronostic (ALBI 1) et un pronostic moins favorable (ALBI 2), les survies médianes étant de 26 et de 14 mois, respectivement. [Vogel 2018] En outre, le grade ALBI peut servir à classer les patients selon les stades BCLC et les classes de Child-Pugh. [Demirtas 2021] ',
      paragraph4:
        'Le calculateur de grade ALBI représente un outil prometteur dans le domaine du traitement du CHC, et son utilisation doit être validée dans le cadre d’un essai clinique prospectif. ',
      ref: 'ALBI = albumine-bilirubine; BCLC = Barcelona Clinic Liver Cancer; CHC = carcinome hépatocellulaire; ESMO = European Society for Medical Oncology; NCCN = National Comprehensive Cancer Network'
    },
    evidence: {
      title: 'Données probantes obtenues en contexte réel',
      paragraph1:
        'Les données probantes obtenues en contexte réel continuent de s’accumuler pour le lenvatinib et l’association atézolizumab + bevacizumab, fournissant un précieux éclairage sur ces traitements en plus des données cliniques établies.',
      paragraph2:
        'Dans une étude rétrospective de 220 patients recevant le lenvatinib (79 % en première intention) dans 10 centres d’oncologie canadiens (base de données CHORD), la SG médiane a été de 12,5 mois avec un traitement de première intention contre 11,8 mois avec un traitement de deuxième intention ou plus (p = 0,83). [Amaro 2022]',
      paragraph3:
        'Dans une étude rétrospective de 233 patients recevant le lenvatinib en première intention aux États-Unis, la SG repère à 12 mois a été de 73 %. La SG médiane n’a pas été atteinte durant un suivi d’une durée médiane de 10 mois. [Singal 2021]',
      paragraph4:
        'Dans une étude rétrospective de 205 patients recevant un traitement de première intention par le lenvatinib en Allemagne et en Autriche (cohorte ELEVATOR), la SG médiane a été de 12,8 mois. [Welland 2022]',
      paragraph5:
        'Dans l’étude rétrospective RELEVANT menée chez 1 325 patients recevant un traitement de première intention par le lenvatinib en Europe et en Asie de l’Est, la SG médiane a été de 16,1 mois. Une analyse multivariée de la SG a montré que la présence de l’antigène de surface de l’hépatite B (AgHBs), un rapport neutrophiles/lymphocytes > 3 et un taux d’AST > 38 UI/L étaient indépendamment associés à un pronostic défavorable, alors qu’une cause liée à une NAFLD/SHNA était indépendamment associée à un bon pronostic. [Casadei-Gardini 2022]',
      paragraph6:
        'Dans une étude rétrospective de patients recevant le lenvatinib (n = 1 341) et l’association atézolizumab + bevacizumab (n = 864) en Europe et en Asie de l’Est, après pondération par l’inverse de la probabilité d’être traité (IPTW), aucun gain de survie n’a été observé avec l’association atézolizumab + bevacizumab comparativement au lenvatinib (16,4 vs 15,8 mois; RRI : 0,97; IC à 95 % : 0,80; 1,17; p = 0,739). [Casadei-Gardini 2023]',
      paragraph7:
        'Dans l’étude rétrospective AB-Real menée chez 433 patients traités en première intention par l’association atézolizumab + bevacizumab en Europe, en Asie et aux États-Unis, la SG médiane a été de 15,7 mois. Une analyse multivariée a montré que le grade ALBI (p < 0,001) et une thrombose tumorale de la veine porte (TTVP) (p = 0,03) étaient des facteurs pronostiques indépendants pour la SG. [Fulgenzi 2022]',
      ref: 'ALBI = albumine-bilirubine; BCLC = Barcelona Clinic Liver Cancer; ESMO = European Society for Medical Oncology; IC = intervalle de confiance; NAFLD = stéatose hépatique non alcoolique; NCCN = National Comprehensive Cancer Network; RRI = rapport des risques instantanés; SG = survie globale; SHNA = stéatohépatite non alcoolique'
    },
    etiology: {
      title: 'Étiologie de la maladie hépatique',
      paragraph1:
        'Une méta-analyse récente visant à évaluer les effets des thérapies moléculaires et des immunothérapies sur les résultats par cause du CHC a montré que la cause n’influait pas sur les résultats et qu’il n’y avait pas de différence entre les traitements par des ITK et des anti-VEGF. [Haber 2021]',
      paragraph2:
        'Les RRI de la SG étaient de 0,81 (IC à 95 % : 0,71; 0,92) chez les patients atteints d’un CHC de cause virale et de 0,82 (IC à 95 % : 0,67; 1,01) chez les patients atteints d’un CHC de cause non virale. La différence quant à l’efficacité n’était pas significative sur le plan statistique (p = 0,8828). [Haber 2021]',
      paragraph3:
        'Une méta-analyse de trois essais cliniques de phase III à répartition aléatoire (IMBrave150, CheckMate-459 et KEYNOTE-240) menés chez plus de 1 600 patients atteints d’un CHC avancé a montré que la survie était supérieure à celle des patients atteints de CHC lié à une infection par le VHB (n = 574; p = 0,0008) et de CHC lié à une infection par le VHC (n = 345; p = 0,04), mais pas à celle des patients atteints de CHC de cause non virale (n = 737; p = 0,39), bien que l’immunothérapie ait amélioré le taux de survie dans la population globale (RRI : 0,77; IC à 95 % : 0,63; 0,94). [Pfister 2021; Finn (IMBrave150) 2020; Yau 2019; Finn (KEYNOTE-240) 2020] ',
      paragraph4:
        'Les patients atteints de CHC de cause virale (infection par le VHB ou le VHC) ont retiré des bienfaits de l’inhibition du point de contrôle immunitaire (RRI : 0,64; IC à 95 % : 0,48; 0,94), contrairement aux patients atteints de CHC de cause non virale (RRI : 0,92; IC à 95 % : 0,77; 1,11; p de l’interaction = 0,03). [Pfister 2021] ',
      paragraph5:
        'D’après une méta-analyse visant à déterminer si les ITK/anti-VEGF et les immunothérapies influaient sur le CHC lié à une SHNA, le RRI groupé de la SG chez les patients atteints de CHC de cause non virale ayant reçu des ICI était de 0,92 (IC à 95 % : 0,77; 1,11). [Haber 2021]  ',
      paragraph6:
        'La différence quant à l’efficacité entre les patients atteints de CHC de cause virale et les patients atteints de CHC de cause non virale ayant reçu des ICI était significative (p de l’hétérogénéité = 0,0259). Les résultats étaient appuyés par l’analyse de sensibilité. L’effet des ICI était similaire chez les patients atteints de CHC lié à une infection par le VHB ou le VHC (RRI : 0,64 [IC à 95 % : 0,49; 0,83] vs 0,68 [IC à 95 % : 0,47; 0,98], respectivement). [Haber 2021]',
      paragraph7:
        'Selon des données probantes obtenues en contexte réel, les patients atteints de CHC de cause virale pourraient davantage bénéficier d’une immunothérapie que d’un traitement par des ITK, et les patients atteints de CHC de cause non virale pourraient tirer plus de bienfaits du lenvatinib que de l’association atézolizumab + bevacizumab : ',
      paragraph8:
        'Dans une étude multicentrique rétrospective de patients atteints d’un CHC non résécable, l’association atézolizumab + bevacizumab (n = 864) a amélioré la survie comparativement au lenvatinib (n = 1 341) chez les patients atteints d’un CHC de cause virale (RRI : 0,76; p = 0,024). Dans le groupe atteint d’un CHC lié à une NASH/NAFLD, les patients traités par le lenvatinib (n = 190) ont eu une SG prolongée comparativement aux patients recevant l’association atézolizumab + bevacizumab (n = 126) (RRI : 1,88, p = 0,014). [Casadei-Gardini 2023]',
      paragraph9:
        'Dans une analyse rétrospective de patients atteints de CHC de cause non virale, le lenvatinib (n = 569) a été associé à une SG plus longue (RRI : 0,65; IC à 95 % : 0,44; 0,95; p = 0,0268) qu’avec le schéma atézolizumab + bevacizumab (n = 190). Dans la population atteinte d’un CHC lié à une NAFLD/NASH, une analyse multivariée a montré que le lenvatinib (n = 254) a prolongé la SG (RRI : 0,46; IC à 95 % : 0,26; 0,84; p = 0,0110) comparativement à l’association atézolizumab + bevacizumab (n = 82). [Rimini 2022]',
      paragraph10:
        'La NASH a été reconnue comme un facteur pronostique indépendant pour la SG dans une analyse rétrospective de patients atteints d’un CHC avancé traités en première intention par le lenvatinib, après une analyse multivariée (RRI : 0,64; IC à 95 % : 0,48; 0,86; p = 0,0028). Un CHC lié à une NASH s’est aussi révélé un facteur prédictif chez les patients traités par le lenvatinib comparativement aux patients traités par le sorafenib (p = 0,0047). [Rimini 2021]',
      ref: 'CHC = carcinome hépatocellulaire; IC = intervalle de confiance; ICI = inhibiteurs du point de contrôle immunitaire; ITK = inhibiteurs de la tyrosine kinase; NAFLD = stéatose hépatique non alcoolique; RRI = rapport des risques instantanés; SG = survie globale; SHNA = stéatohépatite non alcoolique; VEGF = facteur de croissance de l’endothélium des vaisseaux; VHB = virus de l’hépatite B; VHC = virus de l’hépatite C '
    },
    evolution: {
      title: 'Évolution des groupes témoins',
      paragraph1:
        'Depuis les données de REFLECT en 2018 (SG médiane de 13,6 et de 12,3 mois dans les groupes lenvatinib et sorafenib, respectivement), des essais de phase III sur des ITK et des ICI administrés en première intention dans le traitement d’un CHC ont fait ressortir une tendance vers une SG plus longue dans les groupes témoins (13,4, 13,8 et 15,5 mois avec le sorafenib dans les études IMbrave150, HIMALAYA et COSMIC-312, respectivement, et 19,0 mois avec le lenvatinib dans l’étude LEAP-002). Cette tendance pourrait refléter des différences quant aux critères d’admissibilité, une amélioration des soins de soutien, une transition thérapeutique et l’effet des traitements administrés après une progression. [Kudo 2018; Finn 2020; Abou-Alfa 2022; Kelley 2022; Finn 2022; Kelley 2022]',
      paragraph2:
        'Dans une revue systématique d’essais de phase III ayant porté sur des associations d’ICI pour le traitement d’un CHC avancé, des différences entre des facteurs pronostiques clés au départ pourraient aider à expliquer les écarts entre les résultats dans les groupes témoins des différents essais (voir la figure ci-dessous). Point à noter, la SG médiane avec le lenvatinib en monothérapie dans l’étude LEAP-002 a été supérieure à celle observée dans tout groupe recevant le sorafenib en monothérapie, sans égard aux différences quant aux caractéristiques initiales. [Meyers 2023]',
      ref: 'CHC = carcinome hépatocellulaire; ICI = inhibiteurs du point de contrôle immunitaire; ITK = inhibiteurs de la tyrosine kinase; SG = survie globale',
      subheading:
        'Certaines caractéristiques des patients (barres) et SG médiane dans les groupes témoins (points) d’essais de phase III portant sur des associations d’ICI dans le traitement du CHC avancé',
      colourLegend: 'Légende des couleurs'
    },
    migration: {
      title: 'Transition thérapeutique (migration à un autre stade)',
      paragraph1:
        'Selon le système hiérarchisé de la classification BCLC, chaque stade (ou sous-stade) du CHC est associé à un traitement précis. Toutefois, aux fins d’adaptation à la pratique clinique en contexte réel, des éléments de souplesse ont été introduits dans les plus récentes versions des lignes directrices européennes et américaines, de même que dans le plus récent système BCLC. [Vitale 2020; Marrero 2018; Reig 2022]',
      paragraph2:
        'La stratégie de « migration à un autre stade » permet de passer à un autre traitement si la méthode liée au stade actuel s’avère irréalisable. Elle est généralement appliquée dans une direction « de gauche à droite », offrant l’option thérapeutique recommandée pour le stade subséquent de la tumeur lorsque le traitement de première intention ne convient pas aux patients. [Vitale 2020; Marrero 2018]',
      paragraph3:
        'Toutefois, chez certains patients ayant des paramètres proches des seuils définissant le stade précédent, une stratégie de migration dans une direction « de droite à gauche », offrant un traitement recommandé pour un stade plus précoce, pourrait être le meilleur choix, dans l’attente d’une décision multidisciplinaire. [Vitale 2020; Marrero 2018]',
      paragraph4: `De même, l'énoncé de consensus clinique 2023 de la CAIR pour le traitement du CHC de stade intermédiaire au Canada indique que le lenvatinib, l’association atézolizumab + bevacizumab et l’association durvalumab + trémélimumab ont une non-infériorité ou une supériorité démontrées sur le plan de la survie, ont des effets toxiques prévisibles et maîtrisables et entraînent des taux de réponse plus élevés par rapport au sorafenib, ce qui est d’intérêt clinique puisque ces effets peuvent mener à une réduction de la taille et du stade de la tumeur. [Wong 2023]`,
      paragraph5:
        'La CAIR déclare aussi que la prise en charge du CHC requiert une approche multidisciplinaire au moment de la prise de décision sur le traitement. La prise en charge doit être perçue comme un cycle de soins, intégrant des traitements utilisés de façon stratégique et personnalisée plutôt que de façon séquentielle. Chez les patients atteints d’un CHC de stade BCLC-C qui obtiennent une réponse radiographique au traitement, la maladie peut être réévaluée et redéfinie comme étant de stade BCLC-B si les paramètres cliniques le permettent. [Wong 2023] ',
      ref: 'BCLC = Barcelona Clinic Liver Cancer; CAIR (Canadian Association for International Radiology) = Association canadienne pour la radiologie d’intervention; CHC = carcinome hépatocellulaire;'
    },
    treatmentLogistics: {
      title: 'Logistique du traitement',
      ascoGuidelines:
        'D’après les lignes directrices de l’ASCO, le médecin et le patient (et son aidant) doivent discuter du choix du traitement. La discussion doit aborder certains facteurs comme ',
      ascoGuidelinesBold:
        'les objectifs du traitement et la préférence du patient ',
      ascoGuidelinesCont:
        '(ainsi que les toxicités et bienfaits attendus). [Gordan 2020]',
      studyLine:
        'D’après une étude sur la préférence entre les comprimés quotidiens et les injections intramusculaires mensuelles menée auprès de 208 femmes ayant reçu un diagnostic de cancer du sein : [Fallowfield 2006]',
      preference:
        '63 % des patientes préféraient les comprimés et 24,5 %, les injections; 12,5 % des patientes n’avaient pas de préférence.',
      citedReason:
        'et l’aversion pour les aiguilles; dans le cas des injections, les raisons étaient la fidélité au traitement et la commodité. Près de la moitié des patientes ont reconnu qu’elles oubliaient parfois de prendre leur médicament oral ou décidaient parfois de ne pas le prendre.',
      convenience:
        "La commodité des comprimés devrait être envisagée chez les patientes atteintes de CHC vivant loin des centres d'oncologie, particulièrement pendant l’hiver. [SPC recommendation]",
      abbreviations:
        'ASCO = American Society of Clinical Oncology; CHC = carcinome hépatocellulaire; SPC = Scientific Planning Committee (comité de planification scientifique)',
      citedReasonBold:
        'Les principales raisons citées pour expliquer la préférence pour les comprimés ',
      were: 'étaient la',
      reasonBold: ' commodité '
    },
    patientSupport: {
      title: 'Programmes d’aide aux patients',
      intro:
        'D’après les données probantes, les programmes d’aide aux patients ont un effet positif sur la fidélité au traitement, les résultats cliniques et humanistes des soins (p. ex. qualité de vie, indice de performance), l’utilisation des soins de santé et les coûts associés aux maladies dont le traitement est complexe. [Ganguli, 2016]',
      impactTitle:
        'Influence des programmes d’aide aux patients sur les résultats évalués',
      adapted: 'D’après Ganguli A, et al. Patient Prefer Adherence. 2016'
    },
    resources: {
      title: 'Ressources',
      phone: 'Téléphone',
      fax: 'Télécopieur',
      email: 'Courriel',
      bther4u: 'Programme d’aide aux patients BTher4U (sorafenib)',
      eisai: 'Programme de soutien aux patients Eisai Cares (lenvatinib)',
      oncare:
        'Programme d’assistance aux patients Oncare (atézolizumab + bévacizumab)',
      reimbursement:
        'Services d’exploration des options de remboursement pour les patients',
      reimbursementAid:
        'Services d’assistance en matière de remboursement pour les patients',
      financialAssistance:
        'Programmes d’assistance financière pour les patients',
      financialAid: 'Programmes d’aide financière pour les patients',
      delivery: 'Livraison des médicaments aux domiciles des patients',
      infusionSupport:
        'Soutien relatif à la perfusion pour faciliter l’accès aux centres de perfusion partout au Canada'
    },
    trialSummaries: {
      design: 'Méthodologie',
      inclusion: 'Critères d’inclusion',
      bclcStage: 'Classification du BCLC',
      childPughClass: 'Classe de Child-Pugh',
      ecogPs: 'IP ECOG',
      exclusion: 'Critères d’exclusion',
      renalFailure:
        'Insuffisance rénale, cardiopathie, hémorragie GI dans les 30 jours précédents',
      liverInvolvement:
        'Atteinte ≥ 50 % du foie, atteinte de la veine porte, envahissement du cholédoque, varices non traitées, risque d’hémorragie',
      autoimmuneDisease:
        'Maladie auto-immune ou immunodéficience, pneumopathie (p. ex. pneumonite), varices non traitées, risque d’hémorragie, co-infection par le VHB ou le VHC',
      ascites:
        'Ascite cliniquement importante (nécessitant une intervention non pharmacologique), thrombose de la veine porte principale, co-infection par le VHB ou le VHC',
      endpoints: 'Paramètres d’évaluation',
      primary: 'Principaux',
      secondary: 'Secondaires',
      eval: {
        os: 'SG',
        stride: 'STRIDE',
        vsSoraph: 'vs sorafenib',
        ttsp: 'DPS',
        ttrp: 'DPR',
        dcr: 'TMM',
        oss: 'SG à différents moments†',
        safety: 'innocuité',
        osNoNothing: 'SG (non-infériorité et supériorité),',
        osNoInferiority: 'SG (non-infériorité)',
        pfs: 'SSP',
        pro: 'résultats signalés par les patients',
        antidrug: 'anticorps antimédicament',
        ttp: 'DP',
        orr: 'TRG',
        QoL: 'QdV',
        psfIndependent: 'SSP† (indépendant)',
        or: 'SG',
        dor: 'DR'
      },
      etilology: 'Étiologie',
      etilologyKeys: {
        hbv: 'VHB',
        hcv: 'VHC',
        nonViral: 'Non virale',
        alcohol: 'Consommation d’alcool',
        unknown: 'Inconnue/autre'
      },
      mvi: 'EVM',
      ehs: 'DEH',
      mviOrEhs: 'EVM ou DEH',
      varices: 'Varices',
      varicesKeys: {
        notEvaluated: 'Non évaluées',
        present:
          'Présentes : 26 \nÉvaluation par EGD obligatoire pendant l’étude'
      },
      medianOs: 'SG médiane (mois)',
      medianOsKeys: {
        first: '10,7 vs 7,9\nRRI : 0,69 (IC à 95 % : 0,55; 0,87)\np < 0,001',
        second:
          '13,6 vs 12,3\nRRI : 0,92 (IC à 95 % : 0,79; 1,06)\n* Confirmation de la non-infériorité',
        third: '19,2 vs 13,4\nRRI stratifié : 0,66 (IC à 95 % : 0,52; 0,85)',
        fourth: '16,43 vs 13,77\n RRI : 0,78 (IC à 96 % : 0,65; 0,93)'
      },
      medianPfs: 'SSP médiane (mois)',
      medianPfsKeys: {
        notEndpoint: 'La SSP n’était pas un paramètre d’évaluation',
        reflect: '7,4 vs 3,7\nRRI : 0,66 (IC à 95 % : 0,57; 0,77)\np < 0,0001',
        imbrave: 'RRI stratifié : 0,65 (IC à 95 % : 0,53; 0,81)',
        hima: '3,78 vs 4,07\nRRI : 0,90 (IC à 95 % : 0,77; 1,05)'
      },
      orr: 'TRG',
      orrKeys: {
        second:
          'mRECIST: by Investigator: 24.1 vs 9.2\nmRECIST: by IRR: 40.6 vs 12.4\nRECIST: v1.1 by IRR: 18.8 vs 6.5',
        second:
          'mRECIST, par investigateur : 24,1 vs 9,2\nmRECIST, par RLP : 40,6 vs 12,4\nRECIST v1.1, par RLP : 18,8 vs 6,5',
        third: 'RECIST v1.1 : 30 vs 11\nmRECIST : 35 vs 14',
        fourth: 'RECIST v1.1: 20,1 vs 5,1'
      },
      safety: 'Innocuité',
      safetyKeys: {
        commonAdverseEffects: 'Manifestations indésirables courantes',
        first: {
          diarrhea: 'Diarrhée, fatigue, réaction cutanée mains-pieds',
          effects:
            'Manifestations indésirables liées au médicament survenues chez plus de 10 % des sujets (tous grades confondus) et chez plus de 5 % des sujets (grade 3 ou 4) dans l’un ou l’autre groupe'
        },
        second: {
          hypertension:
            'Hypertension, baisse de l’appétit, perte de poids, protéinurie',
          effects:
            'Manifestations indésirables survenues chez au moins 15 % des patients de l’un ou l’autre groupe de traitement'
        },
        third: {
          hypertension:
            'Hypertension, fatigue, protéinurie, augmentation des taux d’AST',
          effects:
            'Manifestations indésirables liées au médicament survenues chez plus de 10 % des sujets (tous grades confondus) et chez plus de 5 % des sujets (grade 3 ou 4) dans l’un ou l’autre groupe'
        },
        fourth: {
          diarrhea:
            'Diarrhée, constipation, douleur abdominale, nausées, prurit, éruption cutanée, alopécie, érythrodysesthésie palmo-plantaire, hausse du taux d’AST, hausse du taux d’ALT, hausse du taux d’amylase, hausse du taux de bilirubine, hausse du taux de GGT, hausse du taux de lipase, diminution de l’appétit, asthénie, fatigue, fièvre, œdème périphérique, toux, insomnie, hypothyroïdie, hypertension, anémie, hyperkaliémie, hypokaliémie, hyponatrémie  ',
          effects:
            'Manifestations indésirables liées au médicament survenues chez plus de 10 % des sujets (tous grades confondus) et chez plus de 2 % des sujets (grade 3 ou 4) dans l’un ou l’autre groupe'
        }
      },
      healthCanada: 'Indications approuvées par Santé Canada',
      healthCanadaKeys: {
        first:
          'Le sorafenib est indiqué pour le traitement du carcinome hépatocellulaire (CHC) non résécable. Les données sur l’innocuité chez les patients présentant une insuffisance hépatique de classe B de ChildPugh sont limitées.⁸',
        firstSuperscript: '',
        second:
          'Le lenvatinib est indiqué pour le traitement de première intention du carcinome hépatocellulaire (CHC) non résécable ou métastatique chez les adultes. Il n’existe pas de données sur l’efficacité et l’innocuité en cas d’insuffisance hépatique pour les classes B et C de Child-Pugh.⁹',
        secondSuperscript: '',
        third:
          'L’atézolizumab en association avec le bevacizumab est indiqué pour le traitement de première intention des patients adultes atteints d’un carcinome hépatocellulaire (CHC) non résécable ou métastatique nécessitant un traitement à action systémique.¹⁰',
        thirdSuperscript: '',
        fourth: `Trémélimumab en association avec le durvalumab est indiqué pour le traitement de première intention des patients adultes atteints d’un carcinome hépatocellulaire (CHC) inopérable et nécessitant un traitement à action générale.¹³`,
        fourthSuperscript: ''
      },
      footnotes: '† Critères RECIST, ‡ critères RECIST modifiés',
      nonInferiority: `* La non-infériorité était confirmée si la limite supérieure de l’intervalle de confiance à 95 % bilatéral pour le RRI était inférieure à 1,08 et que le taux global d’erreur de type I était : α = 0·05 (bilatéral); § La SG n’a pas atteint le seuil prédéfini de signification statistique (RRI : 0,84; p = 0,0419); ¥ Facteurs de stratification compris dans le modèle de Cox : région géographique (Asie à l’exception du Japon par rapport au reste du monde); EVM et/ou DEH (Oui ou Non) par système de réponse vocale/Web interactive (IxRS). Les valeurs p sont fournies à titre indicatif uniquement.`,
      strideNote: `# Schéma STRIDE : durvalumab (1 500 mg T4S) + trémélimumab (300 mg, une dose). L’efficacité de l’association durvalumab (1 500 mg par voie intraveineuse T4S) + trémélimumab (75 mg T4S × 4 doses) n’ayant pas été significativement différente de celle du durvalumab en monothérapie, l’admission à ce groupe a été fermée, et le protocole a été modifié pour que les patients soient affectés dans un rapport 1:1:1 au schéma STRIDE, au durvalumab et au sorafenib. Les données d’innocuité pour ce groupe ont été présentées.`,
      afp: `ALT = alanine aminotransférase; AST = aspartate aminotransférase; BCLC = Barcelona Clinic Liver Cancer; CHC = carcinome hépatocellulaire; DP = délai de progression; DPR = délai de progression radiologique; DPS = délai de progression symptomatique; DR = durée de la réponse; DEH = dissémination extrahépatique; ECOG = Eastern Cooperative Oncology Group; EGD = œsophagogastroduodénoscopie; EVM = envahissement veineux macroscopique; GGT = gammaglutamyl transférase; GI = gastro-intestinal; IC = intervalle de confiance; IP = indice de performance; mRECIST = critères RECIST modifiés; QdV = qualité de vie; RLP = réaction liée à la perfusion; RECIST = Response Evaluation Criteria in Solid Tumors (critères d’évaluation de la réponse des tumeurs solides); RRI = rapport des risques instantanés; SG = survie globale; SSO = soins de soutien optimaux; SSP = survie sans progression; T4S = toutes les 4 semaines; TMM = taux de maîtrise de la maladie; TRG = taux de réponse globale; VHB = virus de l’hépatite B; VHC = virus de l’hépatite C `,
      references: `1. Llovet JM, et al. N Engl J Med 2008;359:378-90. 2. SHARP. ClinicalTrials.gov. NCT00105443. 3. Kudo M, et al. Lancet 2018;391:1163-1173. 4. REFLECT. ClinicalTrials.gov. NCT01761266. 5. Finn RS, et al. N Engl J Med 2020; 382:1894-1905. 6. IMBrave150. ClinicalTrials.gov. NCT03434379. 7. Finn RS, et al. GI21. Gastrointestinal Cancers Symposium 2021. `,
      nexavar: `Monographie de NEXAVAR® (sorafenib). Bayer Inc. Mississauga, Ontario. 17 décembre 2014. `,
      levinma: `Monographie de LENVIMA® (lenvatinib). Eisai limitée. Mississauga, Ontario. 19 décembre 2018. `,
      tecentriq: `Monographie de Tecentriq® (atézolizumab). Hoffmann-La Roche Limitée. Mississauga, Ontario. 16 juin 2021.`,
      imjudo: `IMJUDO® (tremelimumab) Product Monograph. AstraZeneca Canada Inc. Mississauga, Ontario. August 31, 2023.`,
      rest: ` 11. Abou-Alfa G, et al. NEJM Evid 2022;1(8). 12. HIMALAYA. ClinicalTrials.gov. NCT03298451.`
    },
    sharpSafetyModal: {
      title: 'ÉTUDE SHARP : INNOCUITÉ',
      averseEvents:
        'Manifestations indésirables liées au médicament survenues chez plus de 10 % des sujets (tous grades confondus) et chez plus de 5 % des sujets (grade 3 ou 4) dans l’un ou l’autre groupe'
    },
    reflectSafetyModal: {
      title: 'ÉTUDE REFLECT : INNOCUITÉ',
      averseEvents:
        'Manifestations indésirables survenues chez au moins 15 % des patients de l’un ou l’autre groupe de traitement'
    },
    imbraveSafetyModal: {
      title: 'ÉTUDE IMBRAVE 150 : INNOCUITÉ',
      adverseEvents:
        'Manifestations indésirables liées au médicament survenues chez plus de 10 % des sujets (tous grades confondus) et chez plus de 5 % des sujets (grade 3 ou 4) dans l’un ou l’autre groupe',
      immuneMediated: 'Réactions indésirables à médiation immunitaire',
      adverseReactions: `Des réactions indésirables à médiation immunitaire, y compris des cas graves et mortels, sont survenues chez des patients recevant l’atézolizumab. Dans les essais cliniques, la plupart des réactions indésirables à médiation immunitaire ont été réversibles et enrayées par l’interruption du traitement par l’atézolizumab, l’administration de corticostéroïdes ou des soins de soutien. Des réactions indésirables à médiation immunitaire sont également survenues après l’administration de la dernière dose de l’atézolizumab. Des réactions indésirables à médiation immunitaire touchant plus d’un système de l’organisme peuvent se manifester simultanément.${'\n'}Des réactions indésirables à médiation immunitaire ayant nécessité une corticothérapie à action générale sont survenues chez 12,2 % des patients recevant l’atézolizumab et le bevacizumab pour le traitement du CHC. Se reporter à la monographie de Tecentriq pour obtenir la liste complète des réactions indésirables à médiation immunitaire, les consignes relatives à la modification de la dose et les directives de traitement. [Tecentriq® (Atezolizumab) Product Monograph 2021]`
    },
    himalayaSafetyModal: {
      title: 'ÉTUDE HIMALAYA : INNOCUITÉ ',
      teas: 'Manifestations indésirables liées au médicament survenues chez plus de 10 % des sujets (tous grades confondus) et chez plus de 2 % des sujets (grade 3 ou 4) dans l’un ou l’autre groupe'
    },
    stagingModal: {
      title: 'Stade du BCLC',
      veryEarly: 'Très précoce',
      singleNodule:
        '• Nodule unique ≤ 2cm\n• Fonction hépatique préservée \n• IP de 0\n',
      early: 'Précoce',
      earlyNodule:
        '• Jusqu’à 3 nodules ≤3cm\n• Fonction hépatique préservée \n• IP de 0\n',
      intermediate: 'Intermédiaire',
      multinodular:
        '• Multinodulaire\n• Fonction hépatique préservée \n• IP de 0\n',
      advanced: 'Avancé',
      portal:
        '• Envahissement de la veine porte et/ou DEH\n• Fonction hépatique préservée \n• IP de 1 ou 2\n',
      terminal: 'Terminal',
      tumor:
        '• Toute charge tumorale\n• Insuffisance hépatique au stade terminal \n• IP de 3 ou 4 \n',
      footNote1:
        'BCLC = Barcelona Clinic Liver Cancer; CHC = carcinome hépatocellulaire; DEH = dissémination extrahépatique; IP = indice de performance',
      footNote2:
        'D’après Reig M, et al. Journal of Hepatology 2021.https://doi.org/10.1016/j.jhep.2021.11.018.'
    },
    calculatorModal: {
      title: 'Calculateur de la classe de Child-Pugh\net du grade ALBI ',
      results: 'Résultats',
      tapToAdd: 'Cliquez ici pour ajouter à l’évaluation de votre patient',
      note: 'Veuillez remplir les champs ci-dessus',
      learnAboutALBI:
        'Pour en savoir plus sur le grade ALBI, consultez la section',
      linkNote: `Si vous souhaitez revenir au Résumé de l'évaluation après avoir examiné le contenu des Autres considérations, veuillez sélectionner le bouton Évaluation en haut de la page, et non le bouton « Retour » de votre navigateur`,
      notAvailable:
        'Les considérations thérapeutiques pour la classe C de Child-Pugh ne sont pas disponibles sur cette application Web.',
      section: ''
    },
    childPughCalculator: {
      Ascites: 'Ascites',
      Absent: 'Absent',
      Slight: 'Slight',
      Moderate: 'Moderate',
      Bilirubin: 'Bilirubine',
      Albumin: 'Albumine',
      'or INR': 'ou RNI',
      'Prothrombin time (seconds above control)':
        'Temps de prothrombine (secondes de plus que le groupe témoin)',
      Encephalopathy: 'Encéphalopathie',
      enterAlbumin:
        'Pour calculer le grade ALBI de votre patient, veuillez entrer la valeur numérique du taux d’albumine : ',
      or: 'ou',
      enterBilirubin:
        'Pour calculer le grade ALBI de votre patient, veuillez entrer la valeur numérique du taux de bilirubine :',
      footNote1:
        'ALBI = albumine-bilirubine \nRNI = rapport normalisé international\n',
      footNote2:
        'Child-Pugh Classification for Severity of Liver Disease. https://www.merckmanuals.com/medical-calculators/ChildTurPuScore.htm. Consulté le 2 novembre 2021.',
      footNote3:
        'Child-Pugh Score for Cirrhosis Mortality. https://www.mdcalc.com/child-pugh-score-cirrhosis-mortality. Consulté le 2 novembre 2021.',
      footNote4: `Johnson PJ, et al. J Clin Oncol. 2015;33(6):550-558.

ALBI (Albumin-Bilirubin) Grade for Hepatocellular Carcinoma (HCC). https://www.mdcalc.com/calc/10070/albi-albumin-bilirubin-grade-hepatocellular-carcinoma-hcc. Consulté le 14 septembre 2023.`
    },
    privacy: {
      date: 'Le 1er janvier 2022',
      paragraph1: `Bridge Medical Communication Inc. (« nous », « notre » ou « nos ») respecte la vie privée de ses utilisateurs (« utilisateur » ou « vous »). Cette politique de confidentialité explique comment nous recueillons, utilisons, divulguons et protégeons vos renseignements lorsque vous utilisez notre application mobile (l’« application mobile ») ou notre application Web (l’« application Web », et avec l’application mobile, les « applications »). Veuillez la lire attentivement. SI VOUS ÊTES EN DÉSACCORD AVEC LES MODALITÉS DE LA PRÉSENTE POLITIQUE DE CONFIDENTIALITÉ, VOUS DEVEZ VOUS ABSTENIR D’ACCÉDER À L’APPLICATION.

Nous nous réservons le droit d’apporter, à la présente Politique de confidentialité, des modifications, des corrections et des améliorations en tout temps et pour quelque raison que ce soit. Nous vous aviserons de toute modification en actualisant la date de la dernière mise à jour de notre Politique de confidentialité. Nous vous invitons à la consulter périodiquement pour être au courant des mises à jour. Si vous continuez à utiliser l’application après la date de publication de la Politique de confidentialité révisée, vous serez réputé(e) avoir été informé des modifications qu’elle contient et les avoir acceptées, et vous y serez assujetti(e).

Cette Politique de confidentialité ne s’applique pas à la boutique en ligne ou mobile tierce (collectivement les « plateformes de tiers ») à partir de laquelle vous installez l’application mobile, et les conditions d’utilisation de ces plateformes de tiers sont déterminées par le tiers qui administre chaque plateforme. Nous n’assumons aucune responsabilité à l’égard des données recueillies par un tiers.
`,
      collection: 'COLLECTE DE VOS RENSEIGNEMENTS',
      paragraph2:
        'Les applications ne recueillent ni ne conservent aucun renseignement personnel sur nos utilisateurs. La collecte porte uniquement sur les données des appareils, lesquelles ne sont recueillies que sous une forme agrégée. ',
      deviceData: 'Données recueillies par l’application mobile ',
      paragraph3:
        'Il s’agit des renseignements concernant votre appareil mobile, tels que le numéro d’identification, le modèle, le fabricant, la version du système d’exploitation, le numéro de téléphone, le pays, la localisation et toute autre donnée que vous choisissez de fournir.',
      deviceData2: 'Données recueillies par l’application Web',
      'paragraph3,25':
        'Lors de votre utilisation de l’application Web, nous recueillons des renseignements sur le type d’appareil utilisé, l’identifiant de l’appareil, le système d’exploitation, le type de navigateur, le pays, la localisation et toute donnée que vous choisissez de fournir. ',
      infoUse: 'UTILISATION DE VOS RENSEIGNEMENTS',
      mobileApp: 'Application mobile',
      'paragraph3,5':
        'Le fait de disposer de renseignements exacts sur l’usage que vous faites de votre appareil mobile nous permet de vous offrir une expérience fluide, personnalisée et satisfaisante. Plus précisément, nous pouvons utiliser les renseignements recueillis à propos de votre usage de l’application mobile pour : ',
      paragraph4:
        '1. améliorer l’efficacité et le fonctionnement de l’application mobile; \n 2. surveiller et analyser l’usage et les tendances en vue d’optimiser votre expérience d’utilisation de l’application mobile.',
      webApp: 'Application Web',
      'paragraph4,5':
        'Nous pouvons utiliser les renseignements recueillis par l’application Web pour :',

      'paragraph4,75':
        '1. apporter des améliorations à l’application Web; \n 2. surveiller et analyser les tendances d’utilisation pour améliorer l’expérience des utilisateurs avec l’application Web.',

      disclosure: 'DIVULGATION DE VOS RENSEIGNEMENTS',
      paragraph5:
        'Dans certaines situations, nous pouvons communiquer, sous forme agrégée, des renseignements que nous avons recueillis. Cette divulgation agrégée peut avoir lieu dans les circonstances décrites ci-dessous.',
      paragraph6: 'Tiers fournisseurs de services ',
      paragraph7:
        'Nous pouvons communiquer à des tiers qui fournissent des services pour nous ou en notre nom, y compris des services d’analyse et d’hébergement de données, des données sur les utilisateurs sous forme agrégée.',
      other: 'Autres tiers',
      paragraph8:
        'Nous pouvons communiquer des renseignements sur les utilisateurs à des investisseurs dans le but de réaliser des analyses commerciales générales. ',
      tracking: 'TECHNOLOGIES DE SUIVI ',
      analytics: 'Analyse des données ',
      paragraph9:
        'Nous pouvons également travailler en partenariat avec des tiers fournisseurs  ',
      googleAnalytics: '[comme Google Analytics]',
      'paragraph9,2':
        ' pour permettre l’utilisation de technologies de suivi dans les applications par l’entremise de témoins (cookies) de premier niveau et de troisième niveau afin, entre autres, d’analyser et de suivre l’usage que font les utilisateurs des applications, de déterminer la popularité de certains contenus et de mieux comprendre l’activité en ligne. En accédant aux applications, vous consentez à la collecte et à l’utilisation de vos renseignements par ces tiers fournisseurs. Nous vous invitons à prendre connaissance de leur politique de confidentialité et à communiquer directement avec eux pour obtenir des réponses à vos questions. Nous ne transférons aucun renseignement personnel à ces tiers fournisseurs. Toutefois, si vous refusez que vos renseignements soient recueillis et utilisés par des technologies de suivi, vous pouvez mettre à jour vos paramètres sur ',
      aboutAdsOptOutLink: 'https://optout.aboutads.info/?c=3&lang=fr',
      andAdobe: ' et sur ',
      adobeOptOutLink: 'https://www.adobe.com/ca_fr/privacy/opt-out.html',
      andGoogle: ', et installer le ',
      googleAnalyticsOptOut:
        'Module complémentaire de navigateur pour la désactivation de Google Analytics.',
      websites: 'SITES WEB DE TIERS',
      paragraph10:
        'Les applications peuvent contenir des liens vers des sites Web et des applications de tiers susceptibles de vous intéresser, notamment des publicités et des services externes, qui ne sont pas affiliés au nôtre. Si vous utilisez l’un de ces liens pour quitter les applications, les renseignements que vous fournissez à ces tiers ne sont pas couverts par la présente Politique de confidentialité, et nous ne pouvons garantir leur sécurité et leur confidentialité. Avant de visiter le site Web d’un tiers et de lui transmettre de l’information, vous devez vous informer des politiques et des pratiques en matière de protection de la vie privée (le cas échéant) du tiers responsable de ce site Web et prendre toutes les mesures nécessaires, à votre discrétion, pour protéger la confidentialité de vos renseignements. Nous ne sommes aucunement responsables du contenu ou des politiques et pratiques externes en matière de confidentialité et de sécurité, y compris en ce qui concerne les autres sites, services ou applications pouvant être liés aux applications en entrée ou en sortie.',
      contact: 'POUR NOUS JOINDRE',
      paragraph11:
        'Si vous avez des questions au sujet de la présente Politique de confidentialité, vous pouvez communiquer avec nous : ',
      paragraph12: 'Par courriel :',
      paragraph13:
        'Par la poste :\n2100, rue Bloor Ouest, bureau 6, C.P. 173 Toronto (Ontario)  M6S 5A5'
    }
  }
}

export default {
  en,
  fr
}
