import React from 'react'
import { useTailwind } from 'tailwind-rn'
import { View, Text, Image } from 'react-native'

import decorationImg from '../assets/decoration.png'

const Heading = ({ text, style, smaller, wraps }) => {
  const tw = useTailwind()
  return (
    <View style={[tw('flex flex-row justify-start items-start'), style]}>
      <Image
        source={decorationImg}
        style={{ width: 6, height: wraps ? 64 : 32, marginRight: 10 }}
      />
      <Text
        style={[
          tw('font-bold'),
          smaller ? tw('text-xl') : tw('text-2xl'),
          { color: '#506E7A' }
        ]}
      >
        {text}
      </Text>
    </View>
  )
}

export default Heading
