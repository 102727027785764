export const terms = {
  en: [
    {
      heading: 'Interpretation',
      text: `Within these Terms of Use, the capitalized words below have the meanings ascribed thereto:
“Applications” mean the Mobile Application and the Web Application;
“Company”, “we”, “us” or “our” means Bridge Medical Communications Inc.
“Funding Partner” means Eisai Canada Limited.
“Mobile Application” means the software named HCCcare provided by the Company downloaded by You for use on any mobile electronic device.
“Scientific Planning Committee” means Rachel Goodwin, MSc, MD, FRCPC, Howard Lim, PhD, MD, FRCPC, Brandon Meyers, MSc, MD, FRCPC, and Setareh Samimi, MD, FRCPC, responsible for the content presented within the application.
“Service” means the functions and features made available via either of the Applications.
“Web Application” means the software named HCCcare provided by the Company and which may be accessed by You on the Internet.
“You” means the individual accessing the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.`
    },
    {
      heading: 'Introduction',
      text: `Your access to and use of the Applications and the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to you and all visitors, users and others who access or use the Applications or the Service.

By using either of the Applications and the Service you acknowledge that you have read, understand, accept and agree to be legally bound by these Terms. If you disagree with or do not accept any part of the Terms then you may not download the Mobile Application, access the Web Application or sign up for, access or use the Service.`
    },
    {
      heading: 'Description of the Application and the Service',
      text: `The Applications are educational resources that were developed to support medical oncologists in Canada when selecting the most appropriate first line therapy for patients with hepatocellular carcinoma. The Service is an evidence-based reference tool that may be used by Canadian physicians to display treatment considerations when assessing various patient and disease factors.`
    },
    {
      heading: 'Changes to these Terms',
      text: `We reserve the right in our sole discretion to modify or update these Terms of Use at any time. When we do, we will revise the last updated date at the top of this page.
Your continued use of the Applications and the Service following the posting of changes to these Terms will be deemed your acceptance of those changes.
You acknowledge and agree that it is your responsibility to review the Applications and Service periodically and become aware of any changes. You may only use the Applications and Service for purposes that are expressly permitted herein.`
    },
    {
      heading: 'Privacy',
      text: `We respect your privacy. Please refer to our Privacy Policy which explains how we collect, use, and disclose information about your mobile device or other devices through which you access the Applications in aggregate form and your online activity (“User Information”). When you access or use the Service, you signify your agreement to our Privacy Policy.`
    },
    {
      heading: 'Consent to Receive Communications',
      text: `All notices that we send will be mailed or emailed to you at the contact information you provide to us.  You consent to receipt of electronic communications from us at the e-mail address you provide to us.`
    },
    {
      heading: 'Intellectual Property',
      text: `All right, title, and interest in and to the Applications and the Service (excluding Content and User Information provided by you) are and will remain our exclusive property. The Applications are protected by copyright, trademark, and other laws of both Canada and foreign countries.

Nothing in these Terms grants you any rights in the Applications or any right to use the HCCare name or any Company trade-marks, logos, domain names or branding. You agree not to adjust or circumvent or delete any intellectual property notices contained within the Applications and in particular in any digital rights or other security technology embedded or contained within the Applications or the Service.

The use or misuse of any trade-marks or any other Content provided as part of the Service except as provided in these Terms is strictly prohibited. Nothing contained as part of the Service shall be construed as granting, by implication, estoppel or otherwise, any license or right to use any trade-mark without our prior written permission.

Any feedback, comments, or suggestions you may provide regarding the Applications or Service is entirely voluntary and we will be free to use such feedback, comments or suggestions as we see fit and without any obligation or payment to you.`

    },
    {
      heading: 'Copyright',
      text: `Copyright in the Applications remains with the Company.  Your use of such copyright is set out in the rights provided to you hereunder.  Copyright in any Content remains with the person creating such Content.  You agree that if you contribute any Content, that the Company shall have the right to use of such Content without charge, license, fee or royalty, within the Applications and as part of the Service.`
    },
    {
      heading: 'Data Security',
      text: `The Company does not monitor or oversee the development of any Content and cannot guarantee the security of User Information transmitted through email and over the Internet. You therefore agree that your use of the Service is at your own risk and you agree to release the Company and the Funding Partner and their respective directors, shareholders, agents, employees, and third party partners from any claims arising out of or in any way connected with your use of the Service except to the extent any injury is directly due to the gross negligence of the Company or its personnel. You further waive all rights and benefits conferred by the laws of any jurisdiction that may limit the scope of this release.`
    },
    {
      heading: 'Warranty Disclaimer',
      text: `You agree that your downloading of the Mobile Application, accessing the Web Application and use of the Service is on an “as available” basis. The Content is provided for educational and informational purposes only.  Except as otherwise expressly required by applicable law, we make no representations, warranties, conditions or other terms (whether express or implied) in relation to the functionality of the Applications or the provision of the Service, including without limitation as to the completeness, accuracy and currency of any text, image, video, audio or other multimedia content, software or other information or material submitted to, subsisting on or accessible from the Applications (the “Content”). While we use our best efforts to make your access to and use of the Service safe, we do not represent or warrant that the Applications, the Service or the Content are free of viruses.

The Applications or Service may contain hyperlinks or references to third party websites, information or applications. Any such hyperlinks or references are provided for your convenience only. We have no control over third party websites, information or applications and accept no responsibility for any content, material or information contained in them.  The display of any hyperlink and reference to any third party website, information or application does not constitute an endorsement of such third party's website, information, application, products or services. Your use of a third party site, information, application, product or service may be governed by the terms and conditions of that third party.

We cannot and do not guarantee the accuracy of any information provided by the Scientific Planning Committee. You therefore acknowledge and agree that any reliance by you on any information provided by the Scientific Planning Committee through your use of the Applications or Service is solely between you and the Scientific Planning Committee and that we will not be responsible or liable for any losses or damages that you may incur as a result of your reliance on such information.`
    },
    {
      heading: 'Medical Information',
      text: `The Scientific Planning Committee, responsible for the Content, has made every attempt to ensure the accuracy and reliability of the Content. However, the Content is provided “as is” without warranty of any kind. The Scientific Planning Committee does not accept any responsibility or liability for the accuracy, content, completeness, legality, or reliability of the information contained in this Service. This Service offers scientific evidence published and referenced within and is designed for information and educational purposes only. You should not rely on this information as a substitute for, nor does it replace, your own professional medical advice or treatment decisions.

The Content is not intended to diagnose, treat, cure or prevent disease. Content is provided for informational purposes only.

Information offered by the Service is not comprehensive and does not cover all diseases, ailments, physical conditions, or their treatment.

Individuals are different and may react differently to different products. Content included within the Service are strictly the opinions of the Scientific Planning Committee based on the available research and evidence at the time of publishing and are not claims made by the Company or the Funding Partner, nor do they represent the position or view of the Company or Funding Partner.

The Company makes no guarantee or warranty with respect to any products or services sold. The Company is not responsible for any damages for information or services provided even if the Company has been advised of the possibility of damages.`
    },
    {
      heading: 'Indemnity',
      text: `You agree to defend, indemnify and hold harmless the Company and the Funding Partner, as well as their respective officers, directors, employees, agents, and third party contractors from and against any and all liabilities, claims, demands, losses and expenses whatsoever, including, without limitation, reasonable legal fees and disbursements, resulting directly or indirectly from your access to or use of the Applications, Service or Content or your violation of these Terms.`
    },
    {
      heading: 'Limitation of Liability',
      text: `Neither the Company nor the Funding Partner or their respective directors, employees, agents and third party partners will be liable for any damages of any kind, including without limitation, any direct, special, indirect, punitive, incidental or consequential damages, and including without limitation any loss or damages caused by or as a result of i) your reliance on the Applications, the Service or Content, ii) reliance on the information provided by the Scientific Planning Committee through the Applications and the Service, (iii) any error or inaccuracies in any Content, failure of performance or the unavailability of the Service for any reason, any representation or statement made within the Content, or any loss of data regardless of the cause and whether in an action arising in contract, tort (including negligence), or otherwise.

While we make commercially reasonable efforts to ensure that the Applications and the Service are available, we do not represent, warrant or guarantee in any way the continued availability or uninterrupted use of the Applications and the Service at all times.

These warranty disclaimers and limitations of liability shall apply even if we knew of or ought to have known of the possibility of such damages.`
    },
    {
      heading: 'Notices to Us',
      text: `Unless otherwise expressly stated in these Terms, all notices that you send to us must be in writing and sent to our address below via registered mail or via our contact form. `
    },
    {
      heading: 'Governing Law and Disputes',
      text: `The Service and the Applications are provided by the Company from its offices within the Province of Ontario, Canada. You agree that all matters relating to this Agreement, or your access or use of the Applications, the Service and the Content shall be governed by the laws of the Province of Ontario and the laws of Canada applicable therein, without regard to conflict of laws principles. You agree and hereby submit to the exclusive jurisdiction of the courts of the Province of Ontario on all matters relating to your access and use of the Applications, the Service or the Content, as well as any dispute that may arise therefrom.`
    },
    {
      heading: 'Termination',
      text: `You may not use the Applications or Service to engage in any illegal, offensive or unauthorized activities including tampering with our systems or transmitting harmful or offensive (e.g., violent, obscene, discriminatory, defamatory) communications or material.

Notwithstanding any provision of these Terms, the Company may at any time in its sole discretion suspend or terminate your access to the Service.  After any termination, you acknowledge that the Company will have no further obligation to provide download of the Mobile Application or access to the Web Application or the Service and all other rights granted to you by these Terms will immediately cease. The Company will not be liable to you or any third party for preventing the download of the Mobile Application or the termination of the Service. Any suspension or termination will not affect your obligations to the Company under these Terms which by their context are intended to and do survive such suspension or termination.`
    },
    {
      heading: 'Waiver and Severability',
      text: `The failure of the Company to enforce any right or provision of these Terms will not be deemed a waiver of such right or provision. In the event that any provision of these Terms is held to be invalid or unenforceable, the remaining provisions of these Terms will remain in full force and effect.`
    },
    {
      heading: 'Entire Agreement',
      text: `This document and the Privacy Policy and any guidelines provided by the Company on use of the Applications, the Service and the Content from time to time constitute the entire Agreement between you and the Company relating to your access to and use of the Applications, Service and Content.`
    }
  ],
  // FRENCH
  fr: [
    {
      heading: 'Interprétation',
      text: `Dans les présentes conditions d’utilisation (les « modalités »), les termes suivants ont le sens qui leur est attribué ci-après :
« applications » désigne l’application mobile et l’application Web;
« société », « nous », « notre » ou « nos » signifient Bridge Medical Communications Inc.;
« partenaire financier » signifie Eisai Limitée;
« application mobile » désigne le logiciel appelé HCCcare fourni par la société et que vous téléchargez pour l’utiliser sur un appareil électronique mobile;
« comité de planification scientifique » désigne Rachel Goodwin, M. Sc., M.D., FRCPC, Howard Lim, Ph. D., M.D., FRCPC, Brandon Meyers, M. Sc., M.D., FRCPC et Setareh Samimi, M.D., FRCPC, responsables du contenu présenté dans l’application;
« service » désigne les fonctionnalités et les caractéristiques offertes par l’entremise de l’une ou l’autre des applications;
« application Web » désigne le logiciel appelé HCCcare fourni par la société et auquel vous pouvez accéder par Internet;
« vous » désigne l’individu qui accède au service, ou une société ou une autre entité juridique au nom de laquelle cet individu accède au service ou l’utilise, selon le cas.`
    },
    {
      heading: 'Introduction',
      text: `Pour accéder aux applications ou au service et les utiliser, vous devez accepter les présentes modalités et vous y conformer. Ces modalités s’appliquent à vous et à l’ensemble des visiteurs, des utilisateurs et des autres personnes qui accèdent aux applications ou au service et les utilisent.

En utilisant l’une des applications et le service, vous reconnaissez que vous avez lu, compris et accepté les présentes modalités et que vous convenez d’être juridiquement lié par celles-ci. Si vous êtes en désaccord avec une partie des présentes modalités, vous ne pouvez ni télécharger l’application mobile, ni accéder à l’application Web, ni vous inscrire au service, y accéder ou l’utiliser.  `
    },
    {
      heading: 'Description de l’application et du service',
      text: `Les applications sont des ressources éducatives qui ont été élaborées pour aider les médecins oncologues du Canada à sélectionner le traitement de première intention le plus approprié pour les patients atteints d’un carcinome hépatocellulaire. Le service est un outil de référence qui est fondé sur des données probantes et qui peut être utilisé par les médecins canadiens pour afficher divers aspects liés aux traitements lors de l’évaluation de différents facteurs liés aux patients et à la maladie.`
    },
    {
      heading: 'Modification des présentes modalités',
      text: `Nous nous réservons le droit, à notre seule discrétion, de modifier ou de mettre à jour les présentes modalités en tout temps. Le cas échéant, nous actualiserons la date de la dernière mise à jour indiquée dans la partie supérieure de cette page.

Si vous continuez à utiliser les applications ou le service après la publication des modifications apportées aux présentes modalités, vous serez réputé(e) avoir accepté celles-ci.

Vous reconnaissez et convenez qu’il est de votre responsabilité de consulter périodiquement les applications et le service afin de prendre connaissance de toute modification pouvant y avoir été apportée. Vous ne pouvez utiliser les applications et le service qu’aux fins expressément permises par les présentes.`
    },
    {
      heading: 'Confidentialité',
      text: `Nous respectons votre vie privée. Veuillez vous reporter à notre Politique de confidentialité, qui explique comment nous recueillons, utilisons et divulguons les données de votre appareil mobile ou d’autres appareils utilisés pour accéder aux applications sous une forme agrégée et celles sur votre activité en ligne (« renseignements sur l’utilisateur »). En accédant au service ou en l’utilisant, vous signifiez que vous acceptez notre Politique de confidentialité.`
    },
    {
      heading: 'Consentement à recevoir des communications',
      text: `Tous les avis que nous envoyons vous seront transmis par la poste ou par courriel aux coordonnées que vous nous avez fournies. Vous consentez à recevoir des communications électroniques de notre part à l’adresse courriel que vous nous avez fournie.`
    },
    {
      heading: 'Propriété intellectuelle',
      text: `Tous les droits, titres et intérêts liés aux applications et au service (à l’exclusion du contenu et des renseignements sur l’utilisateur que vous avez fournis) sont et demeureront notre propriété exclusive. Les applications sont protégées par la législation sur le droit d’auteur et sur les marques de commerce ainsi que par d’autres lois du Canada et des pays étrangers.

Rien dans les présentes modalités ne vous confère quelque droit que ce soit d’utiliser le nom HCCcare ou une marque, un logo, un nom de domaine ou une image de marque de la société. Vous convenez de ne pas modifier, de ne pas contourner et de ne pas supprimer les avis de propriété intellectuelle figurant dans les applications et, en particulier, dans les technologies de gestion des droits numériques ou de sécurité qui font partie des applications ou du service.

Toute utilisation, abusive ou non, d’une marque de commerce ou de tout autre contenu fourni dans le cadre du service est strictement interdite, sauf indication contraire dans les présentes modalités. Aucun élément faisant partie du service ne peut être interprété comme conférant, implicitement, par préclusion ou autrement, une licence ou un droit d’utilisation d’une marque de commerce sans notre autorisation préalable écrite.

Si vous nous transmettez des rétroactions, commentaires et suggestions au sujet des applications ou du service, vous le faites à titre entièrement volontaire et nous sommes libres d’utiliser ces rétroactions, commentaires et suggestions comme bon nous semble et sans avoir aucune obligation de paiement ou d’autre nature envers vous.`
    },
    {
      heading: 'Droits d’auteur',
      text: `La société demeure titulaire du droit d’auteur dans les applications. Votre utilisation de ce droit d’auteur est régie par les présentes modalités. Toute personne ayant créé du contenu demeure titulaire du son droit d’auteur. Vous convenez que si vous produisez du contenu, la société est en droit d’utiliser ce contenu sans frais, licence, droit ou redevance, dans les applications et dans le cadre du service.`
    },
    {
      heading: 'Sécurité des données',
      text: `La société n’exerce aucune surveillance ni aucun contrôle sur l’élaboration du contenu et ne peut garantir la sécurité des renseignements sur l’utilisateur transmis par courriel et par Internet. Par conséquent, vous convenez d’utiliser le service à vos propres risques et de dégager de toute responsabilité la société et le partenaire financier, ainsi que leurs administrateurs, actionnaires, mandataires, employés et partenaires externes respectifs, relativement à toute réclamation découlant de votre utilisation du service ou s’y rapportant de quelque façon que ce soit, sauf dans la mesure où un préjudice est directement attribuable à la négligence grave de la société ou de son personnel. Vous renoncez également à tous les droits et avantages conférés par les lois de quelque État que ce soit qui pourraient restreindre la portée de cette renonciation.`
    },
    {
      heading: 'Exclusion de garantie',
      text: `Vous convenez que vous téléchargez l’application mobile, accédez à l’application Web et utilisez le service « selon leur disponibilité ». Le contenu est offert à des fins d’éducation et d’information seulement. À moins que la loi applicable ne l’exige expressément, nous ne faisons aucune déclaration, ne donnons aucune garantie et n’imposons aucune condition ou autre modalité (expresse ou implicite) en lien avec la fonctionnalité des applications ou la fourniture du service, y compris, sans s’y limiter, l’exhaustivité, l’exactitude et l’actualité de tout texte, image, contenu vidéo, audio ou multimédia, logiciel ou autre information ou document qui est transmis aux applications, y demeure ou est accessible à partir de celles-ci (le « contenu »). Même si nous mettons tout en œuvre pour sécuriser votre accès au service et votre utilisation de celui-ci, nous ne déclarons pas et ne garantissons pas que les applications, le service et le contenu sont exempts de virus.

Les applications et le service peuvent contenir des hyperliens ou des références pointant vers des sites Web, des renseignements ou des applications de tiers. Ces hyperliens et ces références sont présentés pour des raisons de commodité seulement. Nous n’exerçons aucun contrôle sur les sites Web, les renseignements et les applications de tiers et n’acceptons aucune responsabilité à l’égard du contenu, du matériel et des renseignements qui s’y retrouvent. L’affichage d’un hyperlien ou d’une référence pointant vers un site Web, une information ou une application d’un tiers ne constitue aucunement un cautionnement du site Web, de l’information ou de l’application, ou encore des produits ou des services de ce tiers. Votre utilisation d’un site, d’une information, d’une application, d’un produit ou d’un service d’un tiers peut être assujettie aux conditions de ce dernier.

Nous ne pouvons garantir l’exactitude de l’information fournie par le comité de planification scientifique. Par conséquent, vous reconnaissez et convenez que le fait de vous fier à une information fournie par le comité de planification scientifique dans le cadre de votre utilisation des applications ou du service ne concerne que vous et le comité de planification scientifique et que nous ne serons aucunement responsables de quelque perte ou dommage que ce soit que vous pourriez subir pour vous être fié à cette information.`
    },
    {
      heading: 'Renseignements médicaux',
      text: `Le comité de planification scientifique, qui est responsable du contenu, a mis tout en œuvre pour assurer l’exactitude et la fiabilité de celui-ci. Cependant, le contenu est offert « tel quel », sans aucune sorte de garantie. Le comité de planification scientifique décline toute responsabilité quant à l’exactitude, au contenu, à l’exhaustivité, à la légalité et à la fiabilité de l’information offerte dans le cadre du service. Ce service présente des données scientifiques probantes qui ont été publiées et dont la référence est indiquée, et est conçu à des fins d’information et d’éducation seulement. Cette information ne doit en aucun cas se substituer à vos propres conseils médicaux professionnels ou à vos décisions en matière de traitement.

Le contenu n’est destiné ni au diagnostic, ni au traitement, ni à la guérison, ni à la prévention d’une maladie. Il est offert à des fins d’information seulement.

L’information fournie par le service n’est pas exhaustive et ne couvre pas toutes les maladies, les affections et les pathologies ou leur traitement.

Chaque individu est différent et peut réagir différemment à un produit. Le contenu inclus dans le service représente uniquement l’opinion du comité de planification scientifique, laquelle est fondée sur les résultats des études et les données probantes disponibles au moment de la publication; il ne comprend aucune allégation et ne reflète aucunement la position ou le point de vue de la société ou du partenaire financier.

La société ne donne aucune garantie quant aux produits ou aux services vendus. La société n’est pas responsable des dommages relatifs à l’information ou aux services fournis, même si elle a été avisée de la possibilité de tels dommages.`
    },
    {
      heading: 'Indemnisation',
      text: `Vous vous engagez à défendre, à indemniser et à dégager de toute responsabilité la société et le partenaire financier ainsi que leurs dirigeants, administrateurs, employés, mandataires et tiers entrepreneurs respectifs à l’égard de l’ensemble des obligations, réclamations, demandes, pertes et dépenses de quelque nature que ce soit y compris, sans s’y limiter, des honoraires d’avocat et des débours raisonnables, découlant directement ou indirectement de votre accès aux applications, au service ou au contenu, de votre utilisation des applications, du service ou du contenu ou de votre violation des présentes modalités.`
    },
    {
      heading: 'Limitation de responsabilité',
      text: `Ni la société, ni le partenaire financier, ni leurs administrateurs, employés, mandataires et partenaires tiers respectifs ne seront tenus responsables de quelque dommage que ce soit y compris, sans s’y limiter, des dommages directs, particuliers, indirects, punitifs, accessoires ou consécutifs, et y compris, sans s’y limiter, de toute perte et de tout dommage subis en raison i) de la confiance que vous avez accordée aux applications, au service ou au contenu, ii) de la confiance que vous avez accordée à l’information fournie par le comité de planification scientifique par l’intermédiaire des applications et du service, iii) de la présence d’une erreur ou d’une inexactitude dans un contenu, d’un défaut de fonctionnement ou de l’indisponibilité du service pour quelque raison que ce soit, de toute affirmation ou déclaration faite dans le contenu, ou de toute perte de données, sans égard à la cause et que la cause d’action soit en responsabilité contractuelle, délictuelle (y compris la négligence) ou autre.

Même si nous prenons toutes les dispositions raisonnablement possibles sur le plan commercial pour assurer la disponibilité des applications et du service, nous ne faisons aucune déclaration et ne donnons aucune garantie quant à la disponibilité continue ou à l’utilisation sans interruption des applications et du service en tout temps.

Ces exclusions de garantie et ces limitations de responsabilité s’appliquent même si nous savions ou aurions dû savoir que de tels dommages étaient possibles.`
    },
    {
      heading: 'Avis à notre attention',
      text: `Sauf indication contraire expresse dans les présentes modalités, tous les avis que vous nous transmettez doivent être écrits et envoyés à notre adresse indiquée ci-dessous par courrier recommandé, ou par l’entremise de notre formulaire de contact.  `
    },
    {
      heading: 'Lois applicables et différends',
      text: `Le service et les applications sont fournis par la société à partir de ses bureaux situés dans la province d’Ontario, au Canada. Vous convenez que toutes les questions se rapportant à la présente entente, à votre accès aux applications, au service et au contenu ou à votre utilisation de ceux-ci sont régies par les lois de la province d’Ontario et par les lois du Canada qui s’y appliquent, sans égard aux principes en matière de conflits de lois. Par les présentes, vous convenez de vous en remettre à la compétence exclusive des tribunaux de la province d’Ontario pour toute question se rapportant à votre accès aux applications, au service et au contenu ou à votre utilisation de ceux-ci, ainsi que pour tout différend qui pourrait en découler.`
    },
    {
      heading: 'Résiliation',
      text: `Il vous est interdit d’utiliser les applications ou le service pour vous livrer à des activités illicites, offensantes ou non autorisées, y compris de porter atteinte à l’intégrité de nos systèmes ou de transmettre des communications ou du matériel préjudiciables ou offensants (p. ex. à caractère violent, obscène, discriminatoire, diffamatoire).

Malgré toute stipulation des présentes modalités, la société peut à tout moment et à sa seule discrétion suspendre ou résilier vos droits d’accès au service. Vous reconnaissez que, dès la résiliation, la société n’aura plus aucune autre obligation de vous offrir de télécharger l’application mobile ou d’accéder à l’application Web ou au service, et tous les autres droits qui vous avaient été accordés en vertu des présentes modalités seront immédiatement éteints. La société décline toute responsabilité envers vous ou un tiers quelconque découlant des mesures visant à empêcher le téléchargement de l’application mobile ou l’utilisation du service. La suspension ou la résiliation n’aura aucun effet sur vos obligations envers la société en vertu des présentes modalités qui, compte tenu de leur contexte, survivent à une telle suspension ou résiliation.`
    },
    {
      heading: 'Renonciation et divisibilité',
      text: `Le défaut de la part de la société d’exercer ou de faire appliquer un droit ou une stipulation des présentes modalités n’est pas réputé constituer une renonciation à ce droit ou à cette stipulation. Si une stipulation de ces modalités est déclarée nulle ou inapplicable, les autres stipulations de ces modalités demeureront pleinement en vigueur.`
    },
    {
      heading: 'Intégralité de l’entente',
      text: `Le présent document et la Politique de confidentialité, ainsi que toute directive donnée, le cas échéant, par la société au sujet de l’utilisation des applications, du service et du contenu, constituent l’intégralité de l’entente intervenue entre vous et la société concernant votre accès aux applications, au service et au contenu et votre utilisation de ceux-ci.`
    }
  ]
}
