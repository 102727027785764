import React from 'react'
import { useTailwind } from 'tailwind-rn'
import { View } from 'react-native'

const LineSquare = ({ color }) => {
  const tw = useTailwind()
  return (
    <View
      style={[
        {
          width: 6,
          height: 6,
          backgroundColor: '#506e7a',
          marginTop: 9
        }
      ]}
    ></View>
  )
}

export default LineSquare
