export const committeeMembers = [
  {
    name: 'Rachel Goodwin,',
    title: {
      en: 'MSc, MD, FRCPC',
      fr: 'M. Sc., M.D., FRCPC'
    },
    bio: {
      en: 'Associate Professor of Medicine, University of Ottawa, Division of Medical Oncology Ottawa, Ontario',
      fr: `Professeure agrégée de médecine,
Université d’Ottawa
Division d’oncologie médicale
Ottawa (Ontario)
      `
    }
  },
  {
    name: 'Howard Lim,',
    title: {
      en: 'PhD, MD, FRCPC',
      fr: 'Ph. D., M.D., FRCPC'
    },
    bio: {
      en: `Clinical Associate Professor, UBC
BC Cancer - Vancouver
Vancouver, British Columbia`,
      fr: `Professeur agrégé de clinique, Université de la Colombie-Britannique
BC Cancer – Vancouver
Vancouver (Colombie-Britannique)`
    }
  },
  {
    name: 'Brandon Meyers,',
    title: {
      en: 'MSc, MD, FRCPC',
      fr: 'M. Sc., M.D., FRCPC'
    },
    bio: {
      en: `Associate Professor, McMaster University, Medical Oncologist
Juravinski Cancer Centre
Hamilton, Ontario`,
      fr: `Professeur agrégé, Université McMaster, oncologue médical
Juravinski Cancer Centre
Hamilton (Ontario)`
    }
  },
  {
    name: 'Setareh Samimi,',
    title: {
      en: 'MD, FRCPC',
      fr: 'M.D., FRCPC'
    },
    bio: {
      en: `Hemato-oncologist
Professor associate at Université de Montréal
Sacré-Coeur de Montréal hospital
Montréal, Québec`,
      fr: `Hémato-oncologue
Professeure agrégée à l’Université de Montréal
Hôpital du Sacré-Cœur-de-Montréal
Montréal (Québec)`
    }
  }
]

export const childPughSurveyQuestions = [
  {
    name: 'Ascites',
    options: [
      { text: 'Absent', points: 1 },
      { text: 'Slight', points: 2 },
      { text: 'Moderate', points: 3 }
    ]
  },
  {
    name: 'Bilirubin',
    options: [
      { text: '<2 mg/dL (<34.2 μmol/L)', points: 1 },
      { text: '2 - 3 mg/dL (34.2 to 51.3 μmol/L)', points: 2 },
      { text: '>3 mg/dL (>51.3 μmol/L)', points: 3 }
    ]
  },
  {
    name: 'Albumin',
    options: [
      { text: '>3.5 g/dL (>35 g/L)', points: 1 },
      { text: '2.8 - 3.5 g/dL (28 - 35 g/L)', points: 2 },
      { text: '<2.8 g/dL (<28 g/L)', points: 3 }
    ]
  },
  {
    name: 'Prothrombin time (seconds above control)',
    options: [
      { text: '<4', points: 1 },
      { text: '4 to 6', points: 2 },
      { text: '>6', points: 3 }
    ]
  },
  {
    name: 'or INR',
    options: [
      { text: '<1.7', points: 1 },
      { text: '1.7 to 2.2', points: 2 },
      { text: '>2.3', points: 3 }
    ]
  },
  {
    name: 'Encephalopathy',
    options: [
      { text: 'None', points: 1 },
      {
        text: `Grade 1-2:
Altered mood/confusion
Inappropriate behavior, impending stupor, somnolence
`,
        points: 2
      },
      {
        text: `Grade 3-4
Markedly confused, stuporous but arousable
Comatose/unresponsive`,
        points: 3
      }
    ]
  }
]

export const monographLinks = {
  nexavar: {
    en: 'https://www.bayer.com/sites/default/files/2020-11/nexavar-pm-en.pdf',
    fr: 'https://www.bayer.com/sites/default/files/2020-11/nexavar-pm-fr.pdf'
  },
  lenvima: {
    en: 'https://ca.eisai.com/-/media/Files/CanadaEisai/LENVIMA-Product-Monograph-EN.pdf?hash=f43eb602-ffb4-469b-910b-6253e91084bc',
    fr: 'https://ca.eisai.com/-/media/Files/CanadaEisai/LENVIMA-Product-Monograph-FR.pdf?v=20210219&hash'
  },
  tecentriq: {
    en: 'https://www.rochecanada.com/content/dam/rochexx/roche-ca/products/ConsumerInformation/MonographsandPublicAdvisories/Tecentriq/Tecentriq_PM_E.pdf',
    fr: 'https://www.rochecanada.com/content/dam/rochexx/roche-ca/products/fr/ConsumerInformation/MonographsandPublicAdvisories/Tecentriq/Tecentriq_PM_F.pdf'
  },
  imjudo: {
    en: 'https://www.astrazeneca.ca/content/dam/az-ca/downloads/productinformation/imjudo-product-monograph-en.pdf',
    fr: 'https://www.astrazeneca.ca/content/dam/az-ca/frenchassets/Ourmedicines/imjudo-product-monograph-fr.pdf'
  }
}
