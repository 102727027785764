import React, { useContext, useState, useEffect } from 'react'
import { i18nContext } from './context'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { useT } from './i18n'
import 'react-native-gesture-handler'
import '@expo/match-media'
import { NavigationContainer } from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { createDrawerNavigator } from '@react-navigation/drawer'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { TailwindProvider } from 'tailwind-rn'
import { useMediaQuery } from 'react-responsive'
import utilities from './tailwind.json'

import HomeScreen from './screens/Home'
import AssessmentScreen from './screens/Assessment'
import AssessmentSummaryScreen from './screens/AssessmentSummary'
import AdditionalConsiderationsScreen from './screens/AdditionalConsiderations'
import AdditionalConsiderationsDesktopScreen from './screens/AdditionalConsiderationsDesktop'
import TrialSummariesScreen from './screens/TrialSummaries'
import DrawerScreen from './screens/Drawer'
import BackgroundScreen from './screens/Background'
import ReferencesScreen from './screens/References'
import TreatmentLogisticsScreen from './screens/TreatmentLogistics'
import PatientSupportProgramsScreen from './screens/PatientSupportPrograms'
import ResourcesScreen from './screens/Resources'
import LegalScreen from './screens/Legal'
import ALBIGrade from './screens/ALBIGrade'
import EmergingData from './screens/EmergingData'
import RealWorldEvidence from './screens/RealWorldEvidence'
import EtiologyLiverDisease from './screens/EtiologyLiverDisease'
import EvolutionControlArms from './screens/EvolutionControlArms'
import TreatmentStageMigration from './screens/TreatmentStageMigration'

const Drawer = createDrawerNavigator()

const Stack = createNativeStackNavigator()

const AssessmentStack = () => (
  <Stack.Navigator screenOptions={{ headerShown: false }}>
    <Stack.Screen
      options={{ title: 'Assessment' }}
      name="Assessment Main"
      component={AssessmentScreen}
    />
    <Stack.Screen
      name="Assessment Summary"
      component={AssessmentSummaryScreen}
    />
  </Stack.Navigator>
)

const AdditionalConsiderationsStack = () => (
  <Stack.Navigator screenOptions={{ headerShown: false }}>
    <Stack.Screen
      name="Additional Considerations Main"
      component={AdditionalConsiderationsScreen}
    />
    <Stack.Screen
      name="Treatment Logistics"
      component={TreatmentLogisticsScreen}
    />
    <Stack.Screen
      name="ALBI Grade"
      component={ALBIGrade}
    />
   <Stack.Screen
      name="Real-World Evidence"
      component={RealWorldEvidence}
    />
   <Stack.Screen
      name="Etiology of Liver Disease"
      component={EtiologyLiverDisease}
    />
   <Stack.Screen
      name="Evolution of Control Arms"
      component={EvolutionControlArms}
    />
   <Stack.Screen
      name="Treatment Stage Migration"
      component={TreatmentStageMigration}
    />
    <Stack.Screen
      name="Emerging Data"
      component={EmergingData}
    />
    <Stack.Screen
      name="Patient Support Programs"
      component={PatientSupportProgramsScreen}
    />
    <Stack.Screen name="Resources" component={ResourcesScreen} />
  </Stack.Navigator>
)

const Tab = createMaterialTopTabNavigator()

const MainTabs = () => (
  <Tab.Navigator
    tabBarPosition="bottom"
    tabBar={() => null}
    screenOptions={{ headerShown: false }}
  >
    <Tab.Screen
      name="Asessment Stack"
      options={{ title: 'Assessment' }}
      component={AssessmentStack}
    />
    <Tab.Screen
      name="Additional Considerations"
      component={AdditionalConsiderationsScreen}
    />
    <Tab.Screen name="Trial Summaries" component={TrialSummariesScreen} />
  </Tab.Navigator>
)

function DesktopStack() {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name="Home" component={HomeScreen} />
      <Stack.Screen
        options={{ title: 'Assessment' }}
        name="Assessment Stack"
        component={AssessmentStack}
      />
      <Stack.Screen
        name="Additional Considerations"
        component={AdditionalConsiderationsDesktopScreen}
      />
      <Stack.Screen name="Trial Summaries" component={TrialSummariesScreen} />
      <Stack.Screen name="Background" component={BackgroundScreen} />
      <Stack.Screen name="References" component={ReferencesScreen} />
      <Stack.Screen name="Legal" component={LegalScreen} />
    </Stack.Navigator>
  )
}

function NavDrawer() {
  return (
    <Drawer.Navigator
      screenOptions={{
        drawerType: 'front',
        drawerPosition: 'right',
        headerShown: false,
        drawerStyle:  {
          width: '100%',
          backgroundColor: 'rgba(0,0,0,.3)'
        }
      }}
      drawerContent={DrawerScreen}
    >
      <Drawer.Screen name="Home" component={HomeScreen} />
      <Drawer.Screen name="Assessment Stack" component={AssessmentStack} />
      <Drawer.Screen
        name="Additional Considerations"
        component={AdditionalConsiderationsStack}
      />
      <Drawer.Screen name="Trial Summaries" component={TrialSummariesScreen} />
      <Drawer.Screen name="Background" component={BackgroundScreen} />
      <Drawer.Screen name="References" component={ReferencesScreen} />
      <Drawer.Screen name="Legal" component={LegalScreen} />
    </Drawer.Navigator>
  )
}

export default function App() {
  const [lang, setLangInState] = useState('en')

   const isDesktop = useMediaQuery({
    minDeviceWidth: 1224
  })

  const setLang = async lang => {
    try {
      await AsyncStorage.setItem('lang', lang)
    }
    catch {
      console.log('none')
    }
    finally {
      setLangInState(lang)
    }
  }
  useEffect(() => {
    !(async function () {
      let lang = await AsyncStorage.getItem('lang')

      if (lang) setLang(lang)
    })()
  }, [])

  return (
    <TailwindProvider utilities={utilities}>
      <i18nContext.Provider value={{ lang, setLang }}>
        <NavigationContainer>
          { isDesktop
              ? <DesktopStack />
              : <NavDrawer />
          }
        </NavigationContainer>
      </i18nContext.Provider>
    </TailwindProvider>
  )
}
