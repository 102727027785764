import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

const ArrowDownSvg = props => (
  <Svg
    width="22"
    height="13"
    viewBox="0 0 22 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.21464 11.336C9.28743 11.5323 9.40269 11.7164 9.56043 11.8741C9.8778 12.1915 10.302 12.3369 10.7173 12.3104C11.1326 12.3369 11.5568 12.1915 11.8741 11.8741C12.0319 11.7164 12.1471 11.5323 12.2199 11.336L20.8741 2.68175C21.4599 2.09596 21.4599 1.14621 20.8741 0.560429C20.2884 -0.0253582 19.3386 -0.0253582 18.7528 0.560429L10.7173 8.59596L2.68175 0.560429C2.09596 -0.0253582 1.14622 -0.0253582 0.560429 0.560429C-0.0253582 1.14621 -0.0253582 2.09596 0.560429 2.68175L9.21464 11.336Z"
      fill="#506E7A"
    />
  </Svg>
)

export default ArrowDownSvg
