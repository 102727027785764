import * as React from "react"
import Svg, { SvgProps, G, Path } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
  <Svg width={172} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
    <G stroke="#506E7A" strokeWidth={2} fill="none" fillRule="evenodd">
      <Path d="M1 24V12h170v12M86.5 0v12" />
    </G>
  </Svg>
)

export default SvgComponent

