import React, { useContext } from 'react'
import { useTailwind } from 'tailwind-rn'
import { i18nContext } from '../context'
import { useTranslation } from '../i18n'
import { View, Text, Pressable, StyleSheet } from 'react-native'

const SwitchNav = ({ navigation, current }) => {
  const tw = useTailwind()
  const { lang } = useContext(i18nContext)
  const t = useTranslation(lang)

  return (
    <View
      style={[
        tw('w-full text-base rounded flex justify-between items-center flex-row'),
        styles.container
      ]}
    >
      <Pressable
        style={[
          tw('flex justify-center items-center'),
          styles.button,
          current == 'Assessment' && tw('rounded bg-white')
        ]}
        onPress={() =>
          navigation.navigate('Assessment Stack', { screen: 'Assessment Main' })
        }
      >
        <Text style={styles.text}>{t('assessment')}</Text>
      </Pressable>
      <Pressable
        style={[
          tw('flex justify-center items-center'),
          styles.button,
          current == 'Additional Considerations' && tw('rounded bg-white')
        ]}
        onPress={() => navigation.navigate('Additional Considerations')}
      >
        <Text style={styles.text}>{t('additionalConsiderations')}</Text>
      </Pressable>
      <Pressable
        style={[
          tw('flex justify-center items-center'),
          styles.button,
          current == 'Trial Summaries' && tw('rounded bg-white')
        ]}
        onPress={() => navigation.navigate('Trial Summaries')}
      >
        <Text style={styles.text}>{t('clinicalTrialSummaries')}</Text>
      </Pressable>
    </View>
  )
}

export default SwitchNav

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'rgba(194, 202, 204, .3)'
  },
  button: {
    width: '33%',
    height: 50
  },
  text: {
    fontSize: 12,
    textAlign: 'center',
    fontWeight: 'bold'
  }
})
