import React, { useContext, useRef } from 'react'
import * as Linking from 'expo-linking'
import { useTailwind } from 'tailwind-rn'
import { i18nContext } from '../context'
import { useTranslation } from '../i18n'
import { Text, View, ScrollView, TouchableOpacity } from 'react-native'

import Header from '../components/Header'
import SwitchNav from '../components/SwitchNav'
import Heading from '../components/Heading'
import UpTopButton from '../components/UpTopButton'
import Card from '../components/Card'
import ArrowLeftSvg from '../components/ArrowLeftSvg'


const Resources = ({ component, navigation }) => {
  let viewRef = useRef()
  const tw = useTailwind()
  const { lang, setLang } = useContext(i18nContext)
  const t = useTranslation(lang)

  const handlePhonePress = phone => Linking.openURL(`tel:${phone}`)
  const handleMailPress = mail => Linking.openURL(`mailto:${mail}`)


  if (component)
    return (
      <>
        <Heading text={t('pages.resources.title')} />
        <Card style={tw('mt-5')}>
          <Text style={[tw('text-lg font-bold'), { color: '#506e7a' }]}>
            {t('pages.resources.bther4u')}
          </Text>
          <View style={tw('flex flex-row justify-between')}>
            <View style={{ width: '50%' }}>
              <Text style={tw('mt-4 text-base font-bold')}>
                {t('pages.resources.reimbursement')}
              </Text>
              <Text style={tw('mt-4 text-base font-bold')}>
                {t('pages.resources.financialAssistance')}
              </Text>
              <Text style={tw('mt-4 text-base font-bold')}>
                {t('pages.resources.delivery')}
              </Text>
            </View>

            <View style={{ width: '45%' }}>
              <View style={tw('flex flex-row')}>
                <View style={tw('mr-10')}>
                  <Text style={tw('mt-4 text-base font-bold')}>
                    {t('pages.resources.phone')}
                  </Text>
                  <Text
                    onPress={() => handlePhonePress('+18443843748')}
                    style={tw('text-base')}
                  >
                    1.844.384.3748
                  </Text>
                </View>
                <View>
                  <Text style={tw('mt-4 text-base font-bold')}>
                    {t('pages.resources.fax')}
                  </Text>
                  <Text
                    onPress={() => handlePhonePress('+18443843748')}
                    style={tw('text-base')}
                  >
                    1.844.984.3748
                  </Text>
                </View>
              </View>
              <Text style={tw('mt-4 text-base font-bold')}>
                {t('pages.resources.email')}
              </Text>
              <Text
                onPress={() => handleMailPress('bther4u@patientassistance.ca')}
                style={tw('text-base')}
              >
                bther4u@patientassistance.ca
              </Text>
            </View>

          </View>
        </Card>

        <Card style={tw('mt-2 w-full')}>
          <Text style={[tw('text-lg font-bold'), { color: '#506e7a' }]}>
            {t('pages.resources.eisai')}
          </Text>
          <View style={tw('flex flex-row justify-between')}>
            <View style={{ width: '50%' }}>
              <Text style={tw('mt-4 text-base font-bold')}>
                {t('pages.resources.reimbursement')}
              </Text>
              <Text style={tw('mt-4 text-base font-bold')}>
                {t('pages.resources.financialAssistance')}
              </Text>
              <Text style={tw('mt-4 text-base font-bold')}>
                {t('pages.resources.delivery')}
              </Text>
            </View>
            <View style={{ width: '45%' }}>
              <View style={tw('flex flex-row')}>
                <View style={tw('mr-10')}>
                  <Text style={tw('mt-4 text-base font-bold')}>
                    {t('pages.resources.phone')}
                  </Text>
                  <Text
                    onPress={() => handlePhonePress('+18449800410')}
                    style={tw('text-base')}
                  >
                    1.844.980.0410
                  </Text>
                </View>
                <View>
                  <Text style={tw('mt-4 text-base font-bold')}>
                    {t('pages.resources.fax')}
                  </Text>
                  <Text
                    onPress={() => handlePhonePress('+18449800416')}
                    style={tw('text-base')}
                  >
                    1.844.980.0416
                  </Text>
                </View>
              </View>
              <View>
                <Text style={tw('mt-4 text-base font-bold')}>
                  {t('pages.resources.email')}
                </Text>
                <Text
                  onPress={() => handleMailPress('info@eisaicares.ca')}
                  style={tw('text-base')}
                >
                  info@eisaicares.ca
                </Text>
              </View>
            </View>
          </View>
        </Card>
        <Card style={tw('mt-2 w-full')}>
          <Text style={[tw('text-lg font-bold'), { color: '#506e7a' }]}>
            {t('pages.resources.oncare')}
          </Text>
          <View style={tw('flex flex-row justify-between')}>
            <View style={{ width: '50%' }}>
              <Text style={tw('mt-4 text-base font-bold')}>
                {t('pages.resources.reimbursementAid')}
              </Text>
              <Text style={tw('mt-4 text-base font-bold')}>
                {t('pages.resources.financialAid')}
              </Text>
              <Text style={tw('mt-4 text-base font-bold')}>
                {t('pages.resources.infusionSupport')}
              </Text>
            </View>

            <View style={{ width: '45%' }}>
              <View style={tw('flex flex-row')}>
                <View style={tw('mr-10')}>
                  <Text style={tw('mt-4 text-base font-bold')}>
                    {t('pages.resources.phone')}
                  </Text>
                  <Text
                    onPress={() => handlePhonePress('+18336512047')}
                    style={tw('text-base')}
                  >
                    1.833.651.2047
                  </Text>
                </View>
                <View>
                  <Text style={tw('mt-4 text-base font-bold')}>
                    {t('pages.resources.fax')}
                  </Text>
                  <Text
                    onPress={() => handlePhonePress('+18336512048')}
                    style={tw('text-base')}
                  >
                    1.833.651.2048
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </Card>
      </>
    )

  return (
    <View style={tw('flex-1')}>
      <Header navigation={navigation} />
      <ScrollView
        ref={viewRef}
        contentContainerStyle={tw('p-10 flex justify-start items-start')}
      >
        <View style={tw('w-full flex items-center')}>
          <SwitchNav
            navigation={navigation}
            current="Additional Considerations"
          />
        </View>
        <TouchableOpacity
          onPress={() => navigation.goBack()}
          style={tw('mt-7 flex flex-row items-center')}
        >
          <ArrowLeftSvg />
          <Text
            style={[
              tw('ml-4 text-base'),
              { textDecorationLine: 'underline', color: '#506e7a' }
            ]}
          >
            {t('additionalConsiderations')}
          </Text>
        </TouchableOpacity>
        <Heading style={tw('mt-10')} text={t('pages.resources.title')} />
        <Card style={tw('mt-5 w-full')}>
          <Text style={[tw('text-lg font-bold'), { color: '#506e7a' }]}>
            {t('pages.resources.bther4u')}
          </Text>
          <Text style={tw('mt-4 text-base font-bold')}>
            {t('pages.resources.reimbursement')}
          </Text>
          <Text style={tw('mt-4 text-base font-bold')}>
            {t('pages.resources.financialAssistance')}
          </Text>
          <Text style={tw('mt-4 text-base font-bold')}>
            {t('pages.resources.delivery')}
          </Text>
          <Text style={tw('mt-4 text-base font-bold')}>
            {t('pages.resources.phone')}
          </Text>
          <Text
            onPress={() => handlePhonePress('+18443843748')}
            style={tw('text-base')}
          >
            1.844.384.3748
          </Text>
          <Text style={tw('mt-4 text-base font-bold')}>
            {t('pages.resources.fax')}
          </Text>
          <Text
            onPress={() => handlePhonePress('+18443843748')}
            style={tw('text-base')}
          >
            1.844.984.3748
          </Text>
          <Text style={tw('mt-4 text-base font-bold')}>
            {t('pages.resources.email')}
          </Text>
          <Text
            onPress={() => handleMailPress('bther4u@patientassistance.ca')}
            style={tw('text-base')}
          >
            bther4u@patientassistance.ca
          </Text>
        </Card>
        <Card style={tw('mt-1 w-full')}>
          <Text style={[tw('text-lg font-bold'), { color: '#506e7a' }]}>
            {t('pages.resources.eisai')}
          </Text>
          <Text style={tw('mt-4 text-base font-bold')}>
            {t('pages.resources.reimbursement')}
          </Text>
          <Text style={tw('mt-4 text-base font-bold')}>
            {t('pages.resources.financialAssistance')}
          </Text>
          <Text style={tw('mt-4 text-base font-bold')}>
            {t('pages.resources.delivery')}
          </Text>
          <Text style={tw('mt-4 text-base font-bold')}>
            {t('pages.resources.phone')}
          </Text>
          <Text
            onPress={() => handlePhonePress('+18449800410')}
            style={tw('text-base')}
          >
            1.844.980.0410
          </Text>
          <Text style={tw('mt-4 text-base font-bold')}>
            {t('pages.resources.fax')}
          </Text>
          <Text
            onPress={() => handlePhonePress('+18449800416')}
            style={tw('text-base')}
          >
            1.844.980.0416
          </Text>
          <Text style={tw('mt-4 text-base font-bold')}>
            {t('pages.resources.email')}
          </Text>
          <Text
            onPress={() => handleMailPress('info@eisaicares.ca')}
            style={tw('text-base')}
          >
            info@eisaicares.ca
          </Text>
        </Card>
        <Card style={tw('mt-1 w-full')}>
          <Text style={[tw('text-lg font-bold'), { color: '#506e7a' }]}>
            {t('pages.resources.oncare')}
          </Text>
          <Text style={tw('mt-4 text-base font-bold')}>
            {t('pages.resources.reimbursementAid')}
          </Text>
          <Text style={tw('mt-4 text-base font-bold')}>
            {t('pages.resources.financialAid')}
          </Text>
          <Text style={tw('mt-4 text-base font-bold')}>
            {t('pages.resources.infusionSupport')}
          </Text>
          <Text style={tw('mt-4 text-base font-bold')}>
            {t('pages.resources.phone')}
          </Text>
          <Text
            onPress={() => handlePhonePress('+18336512047')}
            style={tw('text-base')}
          >
            1.833.651.2047
          </Text>
          <Text style={tw('mt-4 text-base font-bold')}>
            {t('pages.resources.fax')}
          </Text>
          <Text
            onPress={() => handlePhonePress('+18336512048')}
            style={tw('text-base')}
          >
            1.833.651.2048
          </Text>
        </Card>
        <View style={tw('mt-10 w-full flex items-end')}>
          <UpTopButton
            onPress={() =>
              viewRef.current.scrollTo({ x: 0, y: 0, animated: true })
            }
          />
        </View>
      </ScrollView>
    </View>
  )
}

export default Resources
