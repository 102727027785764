import React, { useContext, useState, useEffect } from 'react'
import { i18nContext } from '../context'
import { useTailwind } from 'tailwind-rn'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from '../i18n'
import AsyncStorage from '@react-native-async-storage/async-storage'
import {
  Image,
  Text,
  ScrollView,
  View,
  useWindowDimensions,
  TouchableOpacity
} from 'react-native'
import { StatusBar } from 'expo-status-bar'

import logoImg from '../assets/logo_big.png'
import logoFrenchImg from '../assets/logo_fr.png'

import Header from '../components/Header'
import Card from '../components/Card'
import Heading from '../components/Heading'
import PrimaryButton from '../components/PrimaryButton'
import DisclaimerModal from '../components/DisclaimerModal'
import UpdateNotificationModal from '../components/UpdateNotificationModal'

import media from '../constants/media'

const ConsentKey = 'consent'
const UpdateKey = 'update_alert'

const HomeScreen = ({ navigation, route }) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [updateModalVisible, setUpdateModalVisible] = useState(false)
  const { height, width } = useWindowDimensions()
  const tw = useTailwind()
  const { lang, setLang } = useContext(i18nContext)
  const t = useTranslation(lang)

  const isDesktop = useMediaQuery({
    minDeviceWidth: media.desktop
  })

  const isTablet = useMediaQuery({
    minDeviceWidth: media.tablet,
    maxDeviceWidth: media.desktop - 1
  })

  const toggleLang = () => lang == 'en'
    ? setLang('fr')
    : setLang('en')

  useEffect(() => {
    !(async function () {
      let hasConcent = await AsyncStorage.getItem(ConsentKey)
      if (!hasConcent) {
        setModalVisible(true)
      }
      let seenUpdates = await AsyncStorage.getItem(UpdateKey)
      if (!seenUpdates) {
        setUpdateModalVisible(true)
      }
    })()
  }, [modalVisible, updateModalVisible])

  const cardStyles = () => {
    if (isDesktop)
      return {
        flex: 1,
        maxWidth: 645,
        marginLeft: 30
      }

    if (isTablet)
      return {
        maxWidth: 462
      }

    return {}
  }

  return (
    <View style={tw('flex-1')}>
      <DisclaimerModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        onClickYes={() => AsyncStorage.setItem(ConsentKey, 'yes')}
      />
      <UpdateNotificationModal
        modalVisible={updateModalVisible}
        setModalVisible={setUpdateModalVisible}
        onClickYes={() => AsyncStorage.setItem(UpdateKey, 'yes')}
      />
      <Header isHome route={route} navigation={navigation} />
      <ScrollView>
        <View
        style={[
          tw(`${width <= 375 ? 'p-5' : 'p-8'} ${isDesktop ? 'flex-row justify-center' : 'justify-start' } flex-1 items-center`
          ),
          { marginTop: isDesktop ? '10%' : '' }
        ]}
        >
          <Image
            source={lang == 'en' ? logoImg : logoFrenchImg}
            style={[
              tw('mb-10'),
              isTablet ? { marginTop: 48, marginBottom: 80 } : {},
              {
                width: isDesktop ? 307 : 150,
                height: isDesktop ? 307 : 150
              }
            ]}
            />
            <Card style={cardStyles()}>
            <Heading text={t('title')} />
            <Text style={{ ...tw('mt-4 text-base'), color: '#414141' }}>
              {t('pages.index.intro')}
            </Text>
            <Text style={{ ...tw('mt-3 text-base'), color: '#414141' }}>
              {t('pages.index.trigger')}
            </Text>

            <PrimaryButton
              style={{ marginTop: 55 }}
              buttonStyle={tw('px-5')}
              text={t('pages.index.start')}
              onPress={() =>
                navigation.navigate('Assessment Stack', {
                  screen: 'Assessment Main',
                  params: { clear: true }
                })
              }
            />
            <Text style={tw('mt-10 text-center text-xs')}>
              {t('pages.index.lastUpdated')}
            </Text>
          </Card>
          <StatusBar style="auto" />
        </View>
      </ScrollView>
    </View>
  )
}

export default HomeScreen
