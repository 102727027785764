import * as React from "react"
import Svg, { G, Rect, Text, TSpan } from "react-native-svg"

const InfoIconSvg = (props) => (
  <Svg width={26} height={27} xmlns="http://www.w3.org/2000/svg" {...props}>
    <G transform="translate(1 1)" fill="none" fillRule="evenodd">
      <Rect
        stroke="#414141"
        strokeWidth={2}
        y={0.5}
        width={24}
        height={24}
        rx={12}
      />
      <Text fontFamily="Helvetica" fontSize={18} fill="#414141">
        <TSpan x={9.5} y={18}>
          {"i"}
        </TSpan>
      </Text>
    </G>
  </Svg>
)

export default InfoIconSvg

