import React, { useState } from 'react'
import { useTailwind } from 'tailwind-rn'
import { Text, TouchableOpacity } from 'react-native'

import Card from './Card'
import PlusSvg from './PlusSvg'
import MinusSvg from './MinusSvg'

const minusSVG = `
<svg width="40px" height="40px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Wireframe-/-Atoms-/-Buttons-/-Minus" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <rect id="Rectangle-Copy" fill="#506E7A" transform="translate(20.000000, 20.000000) scale(-1, 1) translate(-20.000000, -20.000000) " x="13" y="19" width="14" height="2" rx="1"></rect>
    </g>
</svg>
`

const plusSVG = `
<svg width="40px" height="40px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Wireframe-/-Atoms-/-Buttons-/-Plus" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <rect id="Rectangle-Copy" fill="#506E7A" transform="translate(20.000000, 20.000000) scale(-1, 1) translate(-20.000000, -20.000000) " x="13" y="19" width="14" height="2" rx="1"></rect>
        <rect id="Rectangle-Copy" fill="#506E7A" transform="translate(20.000000, 20.000000) scale(-1, 1) rotate(90.000000) translate(-20.000000, -20.000000) " x="13" y="19" width="14" height="2" rx="1"></rect>
    </g>
</svg>
`

const ExpandingCard = ({ heading, children, style, simple, bigger }) => {
  const tw = useTailwind()
  const [expanded, setExpanded] = useState(false)

  return (
    <Card style={[tw('w-full p-0'), style]}>
      <TouchableOpacity
        style={[tw(
          'flex w-full items-center justify-between flex-row px-5'
        ),
          bigger ? tw('py-5') : tw('py-2')
        ]}
        onPress={() => setExpanded(!expanded)}
      >
        <Text style={tw(`text-base ${simple ? '' : 'font-bold'}`)}>
          {heading}
        </Text>
        {expanded ? <MinusSvg /> : <PlusSvg xml={plusSVG} />}
      </TouchableOpacity>
      {expanded && <>{children}</>}
    </Card>
  )
}

export default ExpandingCard
