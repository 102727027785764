import React from 'react'
import { useTailwind } from 'tailwind-rn'
import { TouchableOpacity, Text } from 'react-native'
import { LinearGradient } from 'expo-linear-gradient'

const PrimaryButton = ({ disabled, text, onPress, style, buttonStyle }) => {
  const tw = useTailwind()
  return (
    <LinearGradient
      colors={disabled ? ['#e1e1e1', '#e1e1e1'] : ['#506E7A', '#9BAAB0']}
      start={{ x: 0, y: 0 }}
      end={{ x: 1, y: 0 }}
      style={[style, { borderRadius: 30 }]}
    >
      <TouchableOpacity
        disabled={disabled}
        style={[tw('py-5 px-11 flex justify-center items-center'), buttonStyle]}
        onPress={onPress}
      >
        <Text style={tw('text-white font-bold text-base')}>{text}</Text>
      </TouchableOpacity>
    </LinearGradient>
  )
}

export default PrimaryButton
