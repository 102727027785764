import React, { useContext, useRef, useState } from 'react'
import { useTailwind } from 'tailwind-rn'
import { i18nContext } from '../context'
import { useTranslation } from '../i18n'
import { useMediaQuery } from 'react-responsive'
import * as Linking from 'expo-linking'
import {
  Text,
  View,
  ScrollView,
  Platform,
  TouchableOpacity
} from 'react-native'

import Header from '../components/Header'
import SwitchNav from '../components/SwitchNav'
import Heading from '../components/Heading'
import ExpandingCard from '../components/ExpandingCard'
import UpTopButton from '../components/UpTopButton'
import SharpSafetyModal from '../components/SharpSafetyModal'
import ReflectSafetyModal from '../components/ReflectSafetyModal'
import ImbraveSafetyModal from '../components/ImbraveSafetyModal'
import HimalayaSafetyModal from '../components/HimalayaSafetyModal'
import InfoIconSvg from '../components/InfoIconSvg'

import { monographLinks } from '../constants'
import media from '../constants/media'

const infoIconSVG = `
<svg width="26px" height="27px" viewBox="0 0 26 27" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="UI" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="3.0_Clinical_Trial_Summaries_Safety" transform="translate(-312.000000, -1287.000000)">
            <g id="Group-Copy-8" transform="translate(20.000000, 917.000000)">
                <g id="Group-5-Copy-3" transform="translate(293.000000, 371.000000)">
                    <g id="Group-2">
                        <rect id="Rectangle-Copy-10" stroke="#414141" stroke-width="2" x="0" y="0.5" width="24" height="24" rx="12"></rect>
                        <text id="i" font-family="Helvetica" font-size="18" font-weight="normal" fill="#414141">
                            <tspan x="9.5" y="18">i</tspan>
                        </text>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
`

const withLabel = (label, text) => {
  const tw = useTailwind()

  return (
    <View style={tw('flex flex-row items-center')}>
      <Text style={tw('font-bold')}>{label}: </Text>
      <Text style={tw('text-base')}>{text}</Text>
    </View>
  )
}

// allPlatforms flag because some symobles are superscripted on iOS by default
export const withSuperscript = (
  text,
  superscript,
  allPlatforms,
  afterText = null
) => {
  const tw = useTailwind()

  return (
    <View style={tw('flex flex-row justify-start items-start')}>
      <Text style={tw('text-base')}>{text}</Text>
      <Text
        style={
          Platform.OS == 'android' || allPlatforms
            ? tw('text-xs')
            : tw('text-base')
        }
      >
        {superscript}
      </Text>
      {afterText && <Text style={tw('text-base')}>{afterText}</Text>}
    </View>
  )
}

const FourSection = ({ first, second, third, fourth, options }) => {
  const tw = useTailwind()

  return (
    <>
      <View style={[tw('p-5'), { backgroundColor: 'rgba(80, 110, 122, .2)' }]}>
        <View style={tw('flex flex-row justify-start items-start')}>
          <Text style={tw('text-base font-semibold')}>SHARP</Text>
          <Text style={tw('text-xs')}>1,2</Text>
        </View>
        {first}
      </View>
      <View style={[tw('p-5'), { backgroundColor: 'rgba(221, 110, 66, .2)' }]}>
        <View style={tw('flex flex-row justify-start items-start')}>
          <Text style={tw('text-base font-semibold')}>REFLECT</Text>
          <Text style={tw('text-xs')}>3,4</Text>
        </View>
        {second}
      </View>
      <View style={[tw('p-5'), { backgroundColor: 'rgba(242, 227, 188, .2)' }]}>
        <View style={tw('flex flex-row justify-start items-start')}>
          <Text style={tw('text-base font-semibold')}>IMBrave150</Text>
          <Text style={tw('text-xs')}>5-7</Text>
        </View>
        {third}
      </View>
      <View style={[tw('p-5'), { backgroundColor: 'rgba(129, 178, 154, .2)' }]}>
        <View style={tw('flex flex-row justify-start items-start')}>
          <Text style={tw('text-base font-semibold')}>HIMALAYA</Text>
          <Text style={tw('text-xs')}>11-13</Text>
        </View>
        {fourth}
      </View>
    </>
  )
}

const TrialSummaries = ({ route, navigation }) => {
  let viewRef = useRef()
  const tw = useTailwind()
  const { lang, setLang } = useContext(i18nContext)
  const t = useTranslation(lang)
  const isDesktop = useMediaQuery({
    minDeviceWidth: media.desktop
  })

  const isTablet = useMediaQuery({
    minDeviceWidth: media.tablet
  })

  const isBiggerScreen = isDesktop || isTablet

  const [sharpModalVisible, setSharpModalVisible] = useState(false)
  const [reflectModalVisible, setReflectModalVisible] = useState(false)
  const [imbraveModalVisible, setImbraveModalVisible] = useState(false)
  const [himalayaModalVisible, setHimalayaModalVisible] = useState(false)

  const handleLinkPress = product => {
    let url = monographLinks[product][lang]
    window.open(url, '_blank')
  }

  const renderText = text => <Text style={tw('text-base mt-2')}>{text}</Text>

  return (
    <View style={tw('flex-1')}>
      <Header route={route} navigation={navigation} />
      <ScrollView
        ref={viewRef}
        contentContainerStyle={
          isDesktop
            ? { maxWidth: 1400, margin: 'auto', marginTop: 70, padding: 40 }
            : tw('p-10 flex justify-start items-start')
        }
      >
        {!isDesktop && (
          <SwitchNav navigation={navigation} current="Trial Summaries" />
        )}
        <Heading
          style={[isDesktop ? { marginRight: '10%' } : tw('mt-10')]}
          text={t('clinicalTrialSummaries')}
        />
        <ExpandingCard
          heading={`${t('pages.trialSummaries.design')} (n)`}
          bigger={isBiggerScreen}
          style={tw('mt-5')}
        >
          <FourSection
            first={renderText('Sorafenib (299) vs. Placebo (303)')}
            second={renderText('Lenvatinib (478) vs. Sorafenib (476)')}
            third={renderText(
              'Atezolizumab + Bevacizumab (336) vs. Sorafenib (165)'
            )}
            fourth={
              <View style={tw('flex flex-row justify-start items-start')}>
                <View
                  style={tw('mt-2 flex flex-row justify-start items-start')}
                >
                  <Text style={tw('text-base')}>STRIDE</Text>
                  <Text style={tw('text-xs')}>#</Text>
                </View>
                {renderText(' (393) vs. Durvalumab (389) vs. Sorafenib (389)')}
              </View>
            }
          />
        </ExpandingCard>
        <ExpandingCard
          heading={`${t('pages.trialSummaries.inclusion')} (%)`}
          bigger={isBiggerScreen}
          style={tw('mt-1')}
        >
          <FourSection
            style={tw('mt-5')}
            first={
              isBiggerScreen ? (
                <View style={tw('flex justify-between flex-row')}>
                  <View style={{ width: '30%' }}>
                    <Text style={tw('mt-2 font-bold')}>
                      {t('pages.trialSummaries.bclcStage')}
                    </Text>
                    <Text style={tw('text-base')}>A (-), B (18), C (82)</Text>
                  </View>
                  <View style={{ width: '30%' }}>
                    <Text style={tw('mt-2 font-bold')}>
                      {t('pages.trialSummaries.childPughClass')}
                    </Text>
                    <Text style={tw('text-base')}>A (95), B (5)</Text>
                  </View>
                  <View style={{ width: '30%' }}>
                    <Text style={tw('mt-2 font-bold')}>
                      {t('pages.trialSummaries.ecogPs')}
                    </Text>
                    <Text style={tw('text-base')}>0 (54), 1 (38), 2 (8)</Text>
                  </View>
                </View>
              ) : (
                <>
                  <Text style={tw('mt-2 font-bold')}>
                    {t('pages.trialSummaries.bclcStage')}
                  </Text>
                  <Text style={tw('text-base')}>A (-), B (18), C (82)</Text>
                  <Text style={tw('mt-2 font-bold')}>
                    {t('pages.trialSummaries.childPughClass')}
                  </Text>
                  <Text style={tw('text-base')}>A (95), B (5)</Text>
                  <Text style={tw('mt-2 font-bold')}>
                    {t('pages.trialSummaries.ecogPs')}
                  </Text>
                  <Text style={tw('text-base')}>0 (54), 1 (38), 2 (8)</Text>
                </>
              )
            }
            second={
              isBiggerScreen ? (
                <View style={tw('flex justify-between flex-row')}>
                  <View style={{ width: '30%' }}>
                    <Text style={tw('mt-2 font-bold')}>
                      {t('pages.trialSummaries.bclcStage')}
                    </Text>
                    <Text style={tw('text-base')}>A (-), B (22), C (78)</Text>
                  </View>
                  <View style={{ width: '30%' }}>
                    <Text style={tw('mt-2 font-bold')}>
                      {t('pages.trialSummaries.childPughClass')}
                    </Text>
                    <Text style={tw('text-base')}>A (99), B (1)</Text>
                  </View>
                  <View style={{ width: '30%' }}>
                    <Text style={tw('mt-2 font-bold')}>
                      {t('pages.trialSummaries.ecogPs')}
                    </Text>
                    <Text style={tw('text-base')}>0 (64), 1 (36), 2 (-)</Text>
                  </View>
                </View>
              ) : (
                <>
                  <Text style={tw('mt-2 font-bold')}>
                    {t('pages.trialSummaries.bclcStage')}
                  </Text>
                  <Text style={tw('text-base')}>A (-), B (22), C (78)</Text>
                  <Text style={tw('mt-2 font-bold')}>
                    {t('pages.trialSummaries.childPughClass')}
                  </Text>
                  <Text style={tw('text-base')}>A (99), B (1)</Text>
                  <Text style={tw('mt-2 font-bold')}>
                    {t('pages.trialSummaries.ecogPs')}
                  </Text>
                  <Text style={tw('text-base')}>0 (64), 1 (36), 2 (-)</Text>
                </>
              )
            }
            third={
              isBiggerScreen ? (
                <View style={tw('flex justify-between flex-row')}>
                  <View style={{ width: '30%' }}>
                    <Text style={tw('mt-2 font-bold')}>
                      {t('pages.trialSummaries.bclcStage')}
                    </Text>
                    <Text style={tw('text-base')}>A (2), B (15), C (82)</Text>
                  </View>
                  <View style={{ width: '30%' }}>
                    <Text style={tw('mt-2 font-bold')}>
                      {t('pages.trialSummaries.childPughClass')}
                    </Text>
                    <Text style={tw('text-base')}>A (100), B (-)</Text>
                  </View>
                  <View style={{ width: '30%' }}>
                    <Text style={tw('mt-2 font-bold')}>
                      {t('pages.trialSummaries.ecogPs')}
                    </Text>
                    <Text style={tw('text-base')}>0 (62), 1 (38), 2 (-)</Text>
                  </View>
                </View>
              ) : (
                <>
                  <Text style={tw('mt-2 font-bold')}>
                    {t('pages.trialSummaries.bclcStage')}
                  </Text>
                  <Text style={tw('text-base')}>A (2), B (15), C (82)</Text>
                  <Text style={tw('mt-2 font-bold')}>
                    {t('pages.trialSummaries.childPughClass')}
                  </Text>
                  <Text style={tw('text-base')}>A (100), B (-)</Text>
                  <Text style={tw('mt-2 font-bold')}>
                    {t('pages.trialSummaries.ecogPs')}
                  </Text>
                  <Text style={tw('text-base')}>0 (62), 1 (38), 2 (-)</Text>
                </>
              )
            }
            fourth={
              isBiggerScreen ? (
                <View style={tw('flex justify-between flex-row')}>
                  <View style={{ width: '30%' }}>
                    <Text style={tw('mt-2 font-bold')}>
                      {t('pages.trialSummaries.bclcStage')}
                    </Text>
                    <Text style={tw('text-base')}>A (-), B (19), C (81)</Text>
                  </View>
                  <View style={{ width: '30%' }}>
                    <Text style={tw('mt-2 font-bold')}>
                      {t('pages.trialSummaries.childPughClass')}
                    </Text>
                    <Text style={tw('text-base')}>
                      A (98.5), B (1), Other (0.5)
                    </Text>
                  </View>
                  <View style={{ width: '30%' }}>
                    <Text style={tw('mt-2 font-bold')}>
                      {t('pages.trialSummaries.ecogPs')}
                    </Text>
                    <Text style={tw('text-base')}>0 (62), 1 (38), 2 (0.3)</Text>
                  </View>
                </View>
              ) : (
                <>
                  <Text style={tw('mt-2 font-bold')}>
                    {t('pages.trialSummaries.bclcStage')}
                  </Text>
                  <Text style={tw('text-base')}>A (-), B (19), C (81)</Text>
                  <Text style={tw('mt-2 font-bold')}>
                    {t('pages.trialSummaries.childPughClass')}
                  </Text>
                  <Text style={tw('text-base')}>
                    A (98.5), B (1), Other (0.5)
                  </Text>
                  <Text style={tw('mt-2 font-bold')}>
                    {t('pages.trialSummaries.ecogPs')}
                  </Text>
                  <Text style={tw('text-base')}>0 (62), 1 (38), 2 (0.3)</Text>
                </>
              )
            }
          />
        </ExpandingCard>
        <ExpandingCard
          heading={t('pages.trialSummaries.exclusion')}
          bigger={isBiggerScreen}
          style={tw('mt-1')}
        >
          <FourSection
            first={renderText(t('pages.trialSummaries.renalFailure'))}
            second={renderText(t('pages.trialSummaries.liverInvolvement'))}
            third={renderText(t('pages.trialSummaries.autoimmuneDisease'))}
            fourth={renderText(t('pages.trialSummaries.ascites'))}
          />
        </ExpandingCard>
        <ExpandingCard
          heading={t('pages.trialSummaries.endpoints')}
          style={tw('mt-1')}
          bigger={isBiggerScreen}
        >
          <FourSection
            style={tw('mt-5')}
            first={
              isBiggerScreen ? (
                <View style={tw('flex justify-between flex-row')}>
                  <View style={{ width: '30%' }}>
                    <Text style={tw('mt-2 font-bold')}>
                      {t('pages.trialSummaries.primary')}
                    </Text>
                    <Text style={tw('text-base')}>
                      {t('pages.trialSummaries.eval.os')},{' '}
                      {t('pages.trialSummaries.eval.ttsp')}
                    </Text>
                  </View>
                  <View style={{ width: '30%' }}>
                    <Text style={tw('mt-2 font-bold')}>
                      {t('pages.trialSummaries.secondary')}
                    </Text>
                    <View style={tw('flex flex-row')}>
                      {withSuperscript(
                        t('pages.trialSummaries.eval.ttrp'),
                        '†'
                      )}
                      <Text style={tw('text-base')}>, </Text>
                      {withSuperscript(t('pages.trialSummaries.eval.dcr'), '†')}
                      <Text style={tw('text-base')}>, </Text>
                      {withSuperscript(
                        t('pages.trialSummaries.eval.safety'),
                        ''
                      )}
                    </View>
                  </View>
                  <View style={{ width: '30%' }}></View>
                </View>
              ) : (
                <>
                  <Text style={tw('mt-2 font-bold')}>
                    {t('pages.trialSummaries.primary')}
                  </Text>
                  <Text style={tw('text-base')}>
                    {t('pages.trialSummaries.eval.os')},{' '}
                    {t('pages.trialSummaries.eval.ttsp')}
                  </Text>
                  <Text style={tw('mt-2 font-bold')}>
                    {t('pages.trialSummaries.secondary')}
                  </Text>
                  <View style={tw('flex flex-row')}>
                    {withSuperscript(t('pages.trialSummaries.eval.ttrp'), '†')}
                    <Text style={tw('text-base')}>, </Text>
                    {withSuperscript(t('pages.trialSummaries.eval.dcr'), '†')}
                    <Text style={tw('text-base')}>
                      , {t('pages.trialSummaries.eval.safety')}
                    </Text>
                  </View>
                </>
              )
            }
            second={
              isBiggerScreen ? (
                <View style={tw('flex justify-between flex-row')}>
                  <View style={{ width: '30%' }}>
                    <Text style={tw('mt-2 font-bold')}>
                      {t('pages.trialSummaries.primary')}
                    </Text>
                    <Text style={tw('text-base')}>
                      {t('pages.trialSummaries.eval.osNoInferiority')}
                    </Text>
                  </View>
                  <View style={{ width: '30%' }}>
                    <Text style={tw('mt-2 font-bold')}>
                      {t('pages.trialSummaries.secondary')}
                    </Text>
                    <View style={tw('flex flex-row')}>
                      {withSuperscript(t('pages.trialSummaries.eval.pfs'), '‡')}
                      {withSuperscript(
                        `, ${t('pages.trialSummaries.eval.ttp')}`,
                        '‡'
                      )}
                      {withSuperscript(
                        `, ${t('pages.trialSummaries.eval.orr')}`,
                        '‡'
                      )}
                      <Text style={tw('text-base')}>
                        , {t('pages.trialSummaries.eval.QoL')}
                      </Text>
                    </View>
                  </View>
                  <View style={{ width: '30%' }}></View>
                </View>
              ) : (
                <>
                  <Text style={tw('mt-2 font-bold')}>
                    {t('pages.trialSummaries.primary')}
                  </Text>
                  <Text style={tw('text-base')}>
                    {t('pages.trialSummaries.eval.osNoInferiority')}
                  </Text>
                  <Text style={tw('mt-2 font-bold')}>
                    {t('pages.trialSummaries.secondary')}
                  </Text>
                  <View style={tw('flex flex-row')}>
                    {withSuperscript(t('pages.trialSummaries.eval.pfs'), '‡')}
                    {withSuperscript(
                      `, ${t('pages.trialSummaries.eval.ttp')}`,
                      '‡'
                    )}
                    {withSuperscript(
                      `, ${t('pages.trialSummaries.eval.orr')}`,
                      '‡'
                    )}
                    <Text style={tw('text-base')}>
                      , {t('pages.trialSummaries.eval.QoL')}
                    </Text>
                  </View>
                </>
              )
            }
            third={
              isBiggerScreen ? (
                <View style={tw('flex justify-between flex-row')}>
                  <View style={{ width: '30%' }}>
                    <Text style={tw('mt-2 font-bold')}>
                      {t('pages.trialSummaries.primary')}
                    </Text>
                    <Text style={tw('text-base')}>
                      {t('pages.trialSummaries.eval.os')},{' '}
                      {t('pages.trialSummaries.eval.psfIndependent')}
                    </Text>
                  </View>
                  <View style={{ width: '30%' }}>
                    <Text style={tw('mt-2 font-bold')}>
                      {t('pages.trialSummaries.secondary')}
                    </Text>
                    <View style={tw('flex flex-row')}>
                      {withSuperscript(t('pages.trialSummaries.eval.or'), '†‡')}
                      {withSuperscript(
                        `, ${t('pages.trialSummaries.eval.pfs')}`,
                        '†‡'
                      )}
                      {withSuperscript(
                        `, ${t('pages.trialSummaries.eval.ttp')}`,
                        '†‡'
                      )}
                      {withSuperscript(
                        `, ${t('pages.trialSummaries.eval.dor')}`,
                        '†‡'
                      )}
                      {withSuperscript(
                        `, ${t('pages.trialSummaries.safety')}`,
                        ''
                      )}
                    </View>
                  </View>
                  <View style={{ width: '30%' }}></View>
                </View>
              ) : (
                <>
                  <Text style={tw('mt-2 font-bold')}>
                    {t('pages.trialSummaries.primary')}
                  </Text>
                  <Text style={tw('text-base')}>
                    {t('pages.trialSummaries.eval.os')},{' '}
                    {t('pages.trialSummaries.eval.psfIndependent')}
                  </Text>
                  <Text style={tw('mt-2 font-bold')}>
                    {t('pages.trialSummaries.secondary')}
                  </Text>
                  <View style={tw('flex flex-row')}>
                    {withSuperscript(t('pages.trialSummaries.eval.or'), '†‡')}
                    {withSuperscript(
                      `, ${t('pages.trialSummaries.eval.pfs')}`,
                      '†‡'
                    )}
                    {withSuperscript(
                      `, ${t('pages.trialSummaries.eval.ttp')}`,
                      '†‡'
                    )}
                    {withSuperscript(
                      `, ${t('pages.trialSummaries.eval.dor')}`,
                      '†‡'
                    )}
                    <Text style={tw('text-base')}>
                      , {t('pages.trialSummaries.safety')}
                    </Text>
                  </View>
                </>
              )
            }
            fourth={
              isBiggerScreen ? (
                <View style={tw('flex justify-between flex-row')}>
                  <View style={{ width: '30%' }}>
                    <Text style={tw('mt-2 font-bold')}>
                      {t('pages.trialSummaries.primary')}
                    </Text>
                    <View style={tw('flex flex-row')}>
                      <Text style={tw('text-base')}>
                        {t('pages.trialSummaries.eval.os')}, (
                      </Text>
                      {withSuperscript(
                        t('pages.trialSummaries.eval.stride'),
                        '#',
                        true
                      )}{' '}
                      <Text style={tw('text-base')}>
                        {' '}
                        {t('pages.trialSummaries.eval.vsSoraph')})
                      </Text>
                    </View>
                  </View>
                  <View style={{ width: '65%' }}>
                    <View style={tw('flex')}>
                      <Text style={tw('mt-2 font-bold')}>
                        {t('pages.trialSummaries.secondary')}
                      </Text>
                      <View style={tw('flex flex-row flex-1')}>
                        <Text style={tw('text-base')}>
                          {t('pages.trialSummaries.eval.osNoNothing')}
                        </Text>
                        <Text style={tw('text-base')}>
                          {' '}
                          {t('pages.trialSummaries.eval.oss')}
                        </Text>
                        {withSuperscript(
                          `, ${t('pages.trialSummaries.eval.pfs')}`,
                          '†'
                        )}
                        {withSuperscript(
                          `, ${t('pages.trialSummaries.eval.ttp')}`,
                          '†'
                        )}
                        {withSuperscript(
                          `, ${t('pages.trialSummaries.eval.orr')}`,
                          '†'
                        )}
                      </View>
                      <View style={tw('flex flex-row')}>
                        {withSuperscript(
                          `${t('pages.trialSummaries.eval.dcr')}`,
                          '†'
                        )}
                        {withSuperscript(
                          `, ${t('pages.trialSummaries.eval.dor')}`,
                          '†'
                        )}
                        {withSuperscript(
                          `, ${t('pages.trialSummaries.safety')}`,
                          ''
                        )}
                        {withSuperscript(
                          `, ${t('pages.trialSummaries.eval.pro')}`,
                          ''
                        )}
                        {withSuperscript(
                          `, ${t('pages.trialSummaries.eval.antidrug')}`,
                          ''
                        )}
                      </View>
                    </View>
                  </View>
                </View>
              ) : (
                <>
                  <Text style={tw('mt-2 font-bold')}>
                    {t('pages.trialSummaries.primary')}
                  </Text>
                  <View style={tw('flex flex-row')}>
                    <Text style={tw('text-base')}>
                      {t('pages.trialSummaries.eval.os')}, (
                    </Text>
                    {withSuperscript(
                      t('pages.trialSummaries.eval.stride'),
                      '#',
                      true
                    )}{' '}
                    <Text style={tw('text-base')}>
                      {' '}
                      {t('pages.trialSummaries.eval.vsSoraph')})
                    </Text>
                  </View>
                  <Text style={tw('mt-2 font-bold')}>
                    {t('pages.trialSummaries.secondary')}
                  </Text>
                  <View style={tw('flex flex-row')}>
                    <Text style={tw('text-base flex-1')}>
                      {t('pages.trialSummaries.eval.osNoNothing')}
                    </Text>
                    <Text style={tw('text-base')}>
                      {t('pages.trialSummaries.eval.oss')}
                    </Text>
                    {withSuperscript(
                      `, ${t('pages.trialSummaries.eval.pfs')}`,
                      '†'
                    )}
                    {withSuperscript(
                      `, ${t('pages.trialSummaries.eval.ttp')}`,
                      '†'
                    )}
                    {withSuperscript(
                      `, ${t('pages.trialSummaries.eval.orr')}`,
                      '†'
                    )}
                    {withSuperscript(
                      `, ${t('pages.trialSummaries.eval.dcr')}`,
                      '†'
                    )}
                    {withSuperscript(
                      `, ${t('pages.trialSummaries.eval.dor')}`,
                      '†'
                    )}
                    {withSuperscript(
                      `, ${t('pages.trialSummaries.safety')}`,
                      ''
                    )}
                    {withSuperscript(
                      `, ${t('pages.trialSummaries.eval.pro')}`,
                      ''
                    )}
                  </View>
                </>
              )
            }
          />
        </ExpandingCard>
        <ExpandingCard
          bigger={isBiggerScreen}
          heading={`${t('pages.trialSummaries.etilology')} (%)`}
          style={tw('mt-1')}
        >
          <FourSection
            style={tw('mt-5')}
            first={
              <View style={tw('mt-2')}>
                {withLabel(t('pages.trialSummaries.etilologyKeys.hbv'), '19')}
                {withLabel(t('pages.trialSummaries.etilologyKeys.hcv'), '29')}
                {withLabel(
                  t('pages.trialSummaries.etilologyKeys.nonViral'),
                  '----'
                )}
                {withLabel(
                  t('pages.trialSummaries.etilologyKeys.alcohol'),
                  '26'
                )}
                {withLabel(
                  t('pages.trialSummaries.etilologyKeys.unknown'),
                  '25'
                )}
              </View>
            }
            second={
              <View style={tw('mt-2')}>
                {withLabel(t('pages.trialSummaries.etilologyKeys.hbv'), '53')}
                {withLabel(t('pages.trialSummaries.etilologyKeys.hcv'), '19')}
                {withLabel(
                  t('pages.trialSummaries.etilologyKeys.nonViral'),
                  '----'
                )}
                {withLabel(
                  t('pages.trialSummaries.etilologyKeys.alcohol'),
                  '8'
                )}
                {withLabel(
                  t('pages.trialSummaries.etilologyKeys.unknown'),
                  '21'
                )}
              </View>
            }
            third={
              <View style={tw('mt-2')}>
                {withLabel(t('pages.trialSummaries.etilologyKeys.hbv'), '49')}
                {withLabel(t('pages.trialSummaries.etilologyKeys.hcv'), '21')}
                {withLabel(
                  t('pages.trialSummaries.etilologyKeys.nonViral'),
                  '30'
                )}
                {withLabel(
                  t('pages.trialSummaries.etilologyKeys.alcohol'),
                  '----'
                )}
                {withLabel(
                  t('pages.trialSummaries.etilologyKeys.unknown'),
                  '----'
                )}
              </View>
            }
            fourth={
              <View style={tw('mt-2')}>
                {withLabel(t('pages.trialSummaries.etilologyKeys.hbv'), '31')}
                {withLabel(t('pages.trialSummaries.etilologyKeys.hcv'), '28')}
                {withLabel(
                  t('pages.trialSummaries.etilologyKeys.nonViral'),
                  '41'
                )}
                {withLabel(
                  t('pages.trialSummaries.etilologyKeys.alcohol'),
                  '----'
                )}
                {withLabel(
                  t('pages.trialSummaries.etilologyKeys.unknown'),
                  '----'
                )}
              </View>
            }
          />
        </ExpandingCard>
        <ExpandingCard
          bigger={isBiggerScreen}
          heading={`${t('pages.trialSummaries.mviOrEhs')} (%)`}
          style={tw('mt-1')}
        >
          <FourSection
            first={renderText('70')}
            second={renderText('69')}
            third={renderText('77')}
            fourth={
              <View style={tw('mt-2')}>
                {withLabel(t('pages.trialSummaries.mvi'), '26')}
                {withLabel(t('pages.trialSummaries.ehs'), '53')}
              </View>
            }
          />
        </ExpandingCard>
        <ExpandingCard
          bigger={isBiggerScreen}
          heading={`${t('pages.trialSummaries.varices')} (%)`}
          style={tw('mt-1')}
        >
          <FourSection
            first={renderText(
              t('pages.trialSummaries.varicesKeys.notEvaluated')
            )}
            second={renderText(
              t('pages.trialSummaries.varicesKeys.notEvaluated')
            )}
            third={renderText(t('pages.trialSummaries.varicesKeys.present'))}
            fourth={renderText(
              t('pages.trialSummaries.varicesKeys.notEvaluated')
            )}
          />
        </ExpandingCard>
        <ExpandingCard
          bigger={isBiggerScreen}
          heading={t('pages.trialSummaries.medianOs')}
          style={tw('mt-1')}
        >
          <FourSection
            first={renderText(t('pages.trialSummaries.medianOsKeys.first'))}
            second={renderText(t('pages.trialSummaries.medianOsKeys.second'))}
            third={
              <>
                <Text style={tw('text-base')}>
                  {t('pages.trialSummaries.medianOsKeys.third')}
                </Text>
                {withSuperscript('p<0.0009', '¥')}
              </>
            }
            fourth={
              <>
                <Text style={tw('text-base')}>
                  {t('pages.trialSummaries.medianOsKeys.fourth')}
                </Text>
              </>
            }
          />
        </ExpandingCard>
        <ExpandingCard
          bigger={isBiggerScreen}
          heading={t('pages.trialSummaries.medianPfs')}
          style={tw('mt-1')}
        >
          <FourSection
            first={renderText(
              t('pages.trialSummaries.medianPfsKeys.notEndpoint')
            )}
            second={renderText(t('pages.trialSummaries.medianPfsKeys.reflect'))}
            third={
              <>
                <Text style={tw('text-base')}>
                  {lang == 'en' ? '6.9 vs 4.3' : '6,9 vs 4,3'}
                </Text>
                <Text style={tw('text-base')}>
                  {t('pages.trialSummaries.medianPfsKeys.imbrave')}
                </Text>
                {withSuperscript('p<0.0001', '¥', true)}
              </>
            }
            fourth={renderText(t('pages.trialSummaries.medianPfsKeys.hima'))}
          />
        </ExpandingCard>
        <ExpandingCard
          bigger={isBiggerScreen}
          heading={`${t('pages.trialSummaries.orr')} (%)`}
          style={tw('mt-1')}
        >
          <FourSection
            first={renderText('RECIST: 2 vs 1')}
            second={renderText(t('pages.trialSummaries.orrKeys.second'))}
            third={renderText(t('pages.trialSummaries.orrKeys.third'))}
            fourth={renderText(t('pages.trialSummaries.orrKeys.fourth'))}
          />
        </ExpandingCard>
        <ExpandingCard
          bigger={isBiggerScreen}
          heading={t('pages.trialSummaries.safety')}
          style={tw('mt-1')}
        >
          <FourSection
            first={
              <>
                <Text style={tw('text-base mt-2 font-bold')}>
                  {t('pages.trialSummaries.safetyKeys.commonAdverseEffects')}
                </Text>
                <Text style={tw('text-base mt-2')}>
                  {t('pages.trialSummaries.safetyKeys.first.diarrhea')}
                </Text>
                <View style={tw('flex flex-row items-center justify-between')}>
                  <Text style={tw('text-base mt-2 flex-1 mr-8')}>
                    {t('pages.trialSummaries.safetyKeys.first.effects')}
                  </Text>
                  <TouchableOpacity onPress={() => setSharpModalVisible(true)}>
                    <InfoIconSvg />
                  </TouchableOpacity>
                </View>
              </>
            }
            second={
              <>
                <Text style={tw('text-base mt-2 font-bold')}>
                  {t('pages.trialSummaries.safetyKeys.commonAdverseEffects')}
                </Text>
                <Text style={tw('text-base mt-2')}>
                  {t('pages.trialSummaries.safetyKeys.second.hypertension')}
                </Text>
                <View style={tw('flex flex-row items-center justify-between')}>
                  <Text style={tw('text-base mt-2 flex-1 mr-8')}>
                    {t('pages.trialSummaries.safetyKeys.second.effects')}
                  </Text>
                  <TouchableOpacity
                    onPress={() => setReflectModalVisible(true)}
                  >
                    <InfoIconSvg />
                  </TouchableOpacity>
                </View>
              </>
            }
            third={
              <>
                <Text style={tw('text-base mt-2 font-bold')}>
                  {t('pages.trialSummaries.safetyKeys.commonAdverseEffects')}
                </Text>
                <Text style={tw('text-base mt-2')}>
                  {t('pages.trialSummaries.safetyKeys.third.hypertension')}
                </Text>
                <View style={tw('flex flex-row items-center justify-between')}>
                  <Text style={tw('text-base mt-2 flex-1 mr-8')}>
                    {t('pages.trialSummaries.safetyKeys.third.effects')}
                  </Text>
                  <TouchableOpacity
                    onPress={() => setImbraveModalVisible(true)}
                  >
                    <InfoIconSvg />
                  </TouchableOpacity>
                </View>
              </>
            }
            fourth={
              <>
                <Text style={tw('text-base mt-2 font-bold')}>
                  {t('pages.trialSummaries.safetyKeys.commonAdverseEffects')}
                </Text>
                <Text style={tw('text-base mt-2')}>
                  {t('pages.trialSummaries.safetyKeys.fourth.diarrhea')}
                </Text>
                <View style={tw('flex flex-row items-center justify-between')}>
                  <Text style={tw('text-base mt-2 flex-1 mr-8')}>
                    {t('pages.trialSummaries.safetyKeys.fourth.effects')}
                  </Text>
                  <TouchableOpacity
                    onPress={() => setHimalayaModalVisible(true)}
                  >
                    <InfoIconSvg />
                  </TouchableOpacity>
                </View>
              </>
            }
          />
        </ExpandingCard>
        <ExpandingCard
          bigger={isBiggerScreen}
          heading={t('pages.trialSummaries.healthCanada')}
          style={tw('mt-1')}
        >
          <FourSection
            first={
              <>
                {renderText(t('pages.trialSummaries.healthCanadaKeys.first'))}
              </>
            }
            second={
              <>
                {renderText(t('pages.trialSummaries.healthCanadaKeys.second'))}
              </>
            }
            third={
              <>
                {renderText(t('pages.trialSummaries.healthCanadaKeys.third'))}
              </>
            }
            fourth={
              <Text style={tw('text-base mt-2 text-red')}>
                {renderText(t('pages.trialSummaries.healthCanadaKeys.fourth'))}
              </Text>
            }
          />
        </ExpandingCard>
        <Text style={tw('mt-5')}>{t('pages.trialSummaries.footnotes')}</Text>
        <Text style={tw('mt-5')}>
          {t('pages.trialSummaries.nonInferiority')}
        </Text>
        <Text style={tw('mt-5')}>{t('pages.trialSummaries.strideNote')}</Text>
        <Text style={tw('mt-5')}>{t('pages.trialSummaries.afp')}</Text>
        <Text style={tw('mt-5')}>
          {t('pages.trialSummaries.references')}
          8.{' '}
          <Text
            style={tw('underline')}
            onPress={() => handleLinkPress('nexavar')}
          >
            {t('pages.trialSummaries.nexavar')}
          </Text>
          9.{' '}
          <Text
            style={tw('underline')}
            onPress={() => handleLinkPress('lenvima')}
          >
            {t('pages.trialSummaries.levinma')}
          </Text>
          10.{' '}
          <Text
            style={tw('underline')}
            onPress={() => handleLinkPress('tecentriq')}
          >
            {t('pages.trialSummaries.tecentriq')}
          </Text>
          <Text style={tw('mt-5')}>{t('pages.trialSummaries.rest')}</Text> 13.{' '}
          <Text
            style={tw('underline')}
            onPress={() => handleLinkPress('imjudo')}
          >
            {t('pages.trialSummaries.imjudo')}
          </Text>
        </Text>
        <View style={tw('mt-10 w-full flex items-end')}>
          <UpTopButton
            onPress={() =>
              viewRef.current.scrollTo({ x: 0, y: 0, animated: true })
            }
          />
        </View>
      </ScrollView>
      <SharpSafetyModal
        modalVisible={sharpModalVisible}
        setModalVisible={setSharpModalVisible}
      />
      <ReflectSafetyModal
        modalVisible={reflectModalVisible}
        setModalVisible={setReflectModalVisible}
      />
      <ImbraveSafetyModal
        modalVisible={imbraveModalVisible}
        setModalVisible={setImbraveModalVisible}
      />
      <HimalayaSafetyModal
        modalVisible={himalayaModalVisible}
        setModalVisible={setHimalayaModalVisible}
      />
    </View>
  )
}

export default TrialSummaries
