import React, { useContext } from 'react'
import { i18nContext } from '../context'
import translations from './translations'

export const useTranslation = lang => value => {
  let keys = value.split('.')
  let content = translations[lang]

  if (!content) {
    throw new Error(`incorrect language ${lang}`)
  }

  while (keys.length) {
    let key = keys.shift()
    if (content[key] == undefined) {
      throw new Error(`could not find key ${key} in ${value}`)
    }
    content = content[key]

    if (keys.length) continue
    return content
  }
}
