import React, { useState, useContext, useRef } from 'react'
import { useTailwind } from 'tailwind-rn'
import { i18nContext } from '../context'
import { useTranslation } from '../i18n'
import { useMediaQuery } from 'react-responsive'
import { Text, View, TouchableOpacity } from 'react-native'
import media from '../constants/media'

import Header from '../components/Header'
import SwitchNav from '../components/SwitchNav'
import Heading from '../components/Heading'
import ExpandingCard from '../components/ExpandingCard'
import AssessmentSection from '../components/AssessmentSection'
import Card from '../components/Card'
import UpTopButton from '../components/UpTopButton'
import ArrowRightSvg from '../components/ArrowRightSvg'
import ArrowLeftSvg from '../components/ArrowLeftSvg'
import ArrowDownSvg from '../components/ArrowDownSvg'
import WithLayout from '../components/WithLayout'
import { ArrowUpGreySvg } from '../components/ArrowUpSvg'

const arrowUpSVG = `
<svg width="22px" height="13px" viewBox="0 0 22 13" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="UI" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="1.0_Assessment" transform="translate(-310.000000, -2644.000000)" fill="#506E7A">
            <g id="Group-3" transform="translate(286.000000, 2615.000000)">
                <g id="Wireframe-/-Atoms-/-Buttons-/-Back-To-Top" transform="translate(23.500000, 28.500000)">
                    <g id="Group" transform="translate(11.217514, 6.717514) rotate(90.000000) translate(-11.217514, -6.717514) translate(4.500000, -4.500000)">
                        <rect id="Rectangle" transform="translate(6.717514, 6.717514) scale(1, -1) rotate(45.000000) translate(-6.717514, -6.717514) " x="-1.28248558" y="5.21751442" width="16" height="3" rx="1.5"></rect>
                        <rect id="Rectangle-Copy" transform="translate(6.717514, 15.717514) rotate(45.000000) translate(-6.717514, -15.717514) " x="-1.28248558" y="14.2175144" width="16" height="3" rx="1.5"></rect>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
`

const arrowDownSVG = `
<svg width="22" height="13" viewBox="0 0 22 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.21464 11.336C9.28743 11.5323 9.40269 11.7164 9.56043 11.8741C9.8778 12.1915 10.302 12.3369 10.7173 12.3104C11.1326 12.3369 11.5568 12.1915 11.8741 11.8741C12.0319 11.7164 12.1471 11.5323 12.2199 11.336L20.8741 2.68175C21.4599 2.09596 21.4599 1.14621 20.8741 0.560429C20.2884 -0.0253582 19.3386 -0.0253582 18.7528 0.560429L10.7173 8.59596L2.68175 0.560429C2.09596 -0.0253582 1.14622 -0.0253582 0.560429 0.560429C-0.0253582 1.14621 -0.0253582 2.09596 0.560429 2.68175L9.21464 11.336Z" fill="#506E7A"/>
</svg>
`

export const ToAdditionalConsiderations = ({ text, navigation }) => {
  const tw = useTailwind()
  return (
    <TouchableOpacity
      onPress={() => navigation.navigate('Additional Considerations Main')}
      style={tw('mt-7 flex flex-row items-center')}
    >
      <ArrowLeftSvg />
      <Text
        style={[
          tw('ml-4 text-base'),
          { textDecorationLine: 'underline', color: '#506e7a' }
        ]}
      >
        {text}
      </Text>
    </TouchableOpacity>
  )
}

let emergingDataSubsections = [
  { name: 'Real-World Evidence', text: 'evidence' },
  { name: 'Etiology of Liver Disease', text: 'etiology' },
  { name: 'Evolution of Control Arms', text: 'evolution' },
  { name: 'Treatment Stage Migration', text: 'migration' }
]

const sections = [
  { name: 'Treatment Logistics', text: 'treatmentLogistics' },
  { name: 'ALBI Grade', text: 'albiGrade' },
  {
    name: 'Emerging Data',
    text: 'emergingData',
    subsections: emergingDataSubsections
  },
  { name: 'Patient Support Programs', text: 'supportPrograms' },
  { name: 'Resources', text: 'resources' }
]

const AdditionalConsiderations = ({ route, navigation }) => {
  let viewRef = useRef()
  const tw = useTailwind()
  const { lang, setLang } = useContext(i18nContext)
  const t = useTranslation(lang)
  const isDesktop = useMediaQuery({
    minDeviceWidth: media.desktop
  })

  const [selected, setSelected] = useState(sections[0].name)


  if (isDesktop)
    return (
    <View style={tw('flex-1')}>
      <Header route={route} navigation={navigation} />
      <WithLayout viewRef={viewRef}>
        <View>
          <Heading
            style={[ { marginRight: '10%' } ]}
            text={t('additionalConsiderations')}
          />
          <View
            style={[
              tw(`flex items-end`)
            ]}>
            {sections.map(({ name, text }) => (
              <Card style={tw('mt-1 w-full p-0')} key={name}>
                <TouchableOpacity
                  style={tw(
                    'flex w-full items-center justify-between flex-row py-5 px-5'
                  )}
                  onPress={() => navigation.navigate(name)}
                >
                  <Text style={tw('text-base')}>
                    {t(`pages.additionalConsiderations.${text}`)}
                  </Text>
                  <ArrowRightSvg />
                </TouchableOpacity>
              </Card>
            ))}
          </View>
        </View>

        <View style={{ maxWidth: '60%' }}>
        </View>
      </WithLayout>
    </View>
    )

  return (
    <View style={tw('flex-1')}>
      <Header route={route} navigation={navigation} />
      <WithLayout viewRef={viewRef}>
        {!isDesktop &&
          <View style={tw('w-full flex items-center')}>
            <SwitchNav
              navigation={navigation}
              current="Additional Considerations"
            />
          </View>
        }
        <Heading
          style={[ isDesktop ? { marginRight: '10%' } : tw('mt-10') ]}
          text={t('additionalConsiderations')}
        />
        <View
          style={[
            tw(`mt-5 w-full flex items-end`),
            isDesktop ? { maxWidth: '60%' } : {}
          ]}>
          {sections.map(({ name, subsections, text }) => (
            <Card style={tw('mt-1 w-full p-0')} key={name}>
              <TouchableOpacity
                style={tw(
                  'flex w-full items-center justify-between flex-row py-5 px-5'
                )}
                onPress={() => {
                  if (selected == name) return setSelected(null)
                  if (subsections?.length) return setSelected(name)
                  navigation.navigate(name)
                }}
              >
                <Text style={tw('text-base')}>
                  {t(`pages.additionalConsiderations.${text}`)}
                </Text>
                {subsections?.length ? (
                  selected == name ? (<ArrowUpGreySvg />) : (<ArrowDownSvg />)
                ) : (
                    <ArrowRightSvg />
                )}
              </TouchableOpacity>
              {selected == name &&
                subsections?.length &&
                subsections.map(s => (
                  <View
                    style={[
                      tw('mt-0 w-full p-0 border-solid rounded-none'),
                      { borderTopWidth: 1, borderColor: '#e1e1e1' }
                    ]}
                    key={s.name}
                  >
                    <TouchableOpacity
                      style={tw(
                        'flex w-full items-center justify-between flex-row py-5 px-5'
                      )}
                      onPress={() => navigation.navigate(s.name)}
                    >
                      <Text style={tw('ml-5 text-base')}>
                        {t(
                          `pages.additionalConsiderations.subsections.${s.text}`
                        )}
                      </Text>
                      <ArrowRightSvg />
                    </TouchableOpacity>
                  </View>
                ))}
            </Card>
          ))}
        </View>
      </WithLayout>
    </View>
  )
}

export default AdditionalConsiderations
