import React, { useRef, useState, useEffect, useContext } from 'react'
import { i18nContext } from '../context'
import { useTranslation } from '../i18n'
import { useTailwind } from 'tailwind-rn'
import { useMediaQuery } from 'react-responsive'
import { Text, View, ScrollView } from 'react-native'

import Header from '../components/Header'
import SwitchNav from '../components/SwitchNav'
import Heading from '../components/Heading'
import AssessmentSection from '../components/AssessmentSection'
import MultipleChoiceBox from '../components/MultipleChoiceBox'
import PrimaryButton from '../components/PrimaryButton'
import SmallButton from '../components/SmallButton'
import UpTopButton from '../components/UpTopButton'
import ClassificationModal from '../components/ChildPughClassificationModal'
import StagingModal from '../components/StagingModal'
import WithLayout from '../components/WithLayout'

import { patientFactors, diseaseFactors } from '../constants/assessment'
import media from '../constants/media'

const Assessment = ({ route, navigation }) => {
  const tw = useTailwind()
  const { lang, setLang } = useContext(i18nContext)
  const t = useTranslation(lang)

  let defaultState = [...patientFactors, ...diseaseFactors].reduce(
    (state, { name }) => ({ ...state, [name]: null }),
    {}
  )

  let [touched, setTouched] = useState(false)
  let [classificationModalVisible, setClassificationModalVisible] =
    useState(false)
  let [stagingModalVisible, setStagingModalVisible] = useState(false)
  let [surveyState, setSurveyState] = useState(defaultState)
  let viewRef = useRef()

  const isDesktop = useMediaQuery({
    minDeviceWidth: media.desktop
  })

  let allPatientFactorsFilled = patientFactors.every(({ name }) =>
    Boolean(surveyState[name])
  )

  let allDiseaseFactorsFilled = diseaseFactors.every(({ name }) =>
    Boolean(surveyState[name])
  )

  const clearState = () => {
    setSurveyState(defaultState)
    setTouched(false)
  }

  useEffect(() => {
    if (route.params?.clear) clearState()
  }, [route])

  const onOptionPress = ({ name, option }) => {
    setSurveyState({ ...surveyState, [name]: option })
  }

  const onSubmit = () => {
    if (!touched) setTouched(true)
    setTouched(true)

    let everythingFilled = allPatientFactorsFilled && allDiseaseFactorsFilled
    if (everythingFilled)
      navigation.navigate('Assessment Summary', { survey: surveyState })
  }

  const renderModalButton = name => {
    switch (name) {
      case 'BCLC Stage':
        return (
          <SmallButton
            onPress={() => setStagingModalVisible(true)}
            text={t('pages.assessment.stagingDefinitions')}
          />
        )
      case 'Child Pugh Class':
        return (
          <SmallButton
            onPress={() => setClassificationModalVisible(true)}
            text={t('pages.assessment.calculator')}
          />
        )
      default:
        return null
    }
  }

  let invalid = touched
    && (!allPatientFactorsFilled || !allDiseaseFactorsFilled)

  if (isDesktop) return (
    <View style={tw('flex-1')}>
      <Header route={route} navigation={navigation} />
      <WithLayout viewRef={viewRef} >
        <View
          nativeID="left"
          style={[
            {  position: 'fixed', marginRight: '15%', maxWidth: 515 },
            tw('flex-1')
          ]}
      >
          <Heading
            text={t('pages.assessment.patientAssessment')}
          />
          <Text style={tw('text-base mt-5')}>
            {t('pages.assessment.desktopIntro1')}
          </Text>
          <Text style={tw('text-base mt-2')}>
            {t('pages.assessment.desktopIntro2')}
            <Text style={tw('text-base font-bold')}>
              {t('pages.assessment.desktopIntro2Definitions')}
            </Text>
            {t('pages.assessment.desktopIntro3')}
            <Text style={tw('text-base font-bold')}>
              {t('pages.assessment.desktopIntro3Calculator')}{' '}
            </Text>
          </Text>
        </View>
        <View style={{ width: '55%', marginLeft: '45%' }}>
          <AssessmentSection
            style={tw(`${isDesktop ? '' : 'mt-10'}`)}
            hideHeading
            heading={t('pages.assessment.patientFactors')}
            description={t('pages.assessment.note') + ' :'}
          >
            {patientFactors.map((factor, i) => (
              <MultipleChoiceBox
                invalid={touched && !surveyState[factor.name]}
                onPress={onOptionPress}
                selected={surveyState[factor.name]}
                key={factor.name}
                options={factor.options}
                name={factor.name}
                sideButton={renderModalButton(factor.name)}
                style={tw(`${i == 0 ? '' : 'mt-5'}`)}
              />
            ))}
            {touched && !allPatientFactorsFilled && (
              <Text
                style={[
                  tw('mt-5 italic font-semibold text-base'),
                  { color: '#E07A5F' }
                ]}
              >
                {t('pages.assessment.error')}
              </Text>
            )}
          </AssessmentSection>
          <AssessmentSection
            style={tw('mt-10')}
            heading={t('pages.assessment.diseaseFactors')}
            description={t('pages.assessment.note') + ' :'}
          >
            {diseaseFactors.map(factor => (
              <MultipleChoiceBox
                invalid={touched && !surveyState[factor.name]}
                onPress={onOptionPress}
                selected={surveyState[factor.name]}
                key={factor.name}
                options={factor.options}
                name={factor.name}
                style={tw('mt-5')}
              />
            ))}
          </AssessmentSection>
          {invalid && (
            <Text
              style={[
                tw('mt-5 italic font-semibold text-base'),
                { color: '#E07A5F' }
              ]}
            >
              {t('pages.assessment.error')}
            </Text>
          )}
          <View style={tw('mt-10 w-full flex items-center')}>
            <PrimaryButton
              text={t('pages.assessment.summaryButton')}
              buttonStyle={!isDesktop && tw('py-4 px-5') }
              onPress={onSubmit}
            />
          </View>
          <View style={tw('mt-10 w-full flex items-end')}>
            <UpTopButton
              onPress={() =>
                document.querySelector('#root').scrollTo(0,0)
              }
          />
          </View>
        </View>
        <ClassificationModal
          navigation={navigation}
          modalVisible={classificationModalVisible}
          setModalVisible={setClassificationModalVisible}
          onSurveyResult={option =>
            onOptionPress({
              name: 'Child Pugh Class',
              option
            })
          }
        />
        <StagingModal
          modalVisible={stagingModalVisible}
          setModalVisible={setStagingModalVisible}
        />
      </WithLayout>
    </View>
  )

  return (
    <View style={tw('flex-1')}>
      <Header route={route} navigation={navigation} />
      <ScrollView
        ref={viewRef}
        contentContainerStyle={tw(`p-10 flex items-start justify-start`)}
    >
        <SwitchNav
          navigation={navigation}
          current="Assessment"
        />
        <View
          nativeID="left"
          style={[ tw('mt-10'), tw('flex-1') ]}>
          <Heading
            text={t('pages.assessment.patientAssessment')}
          />
          <Text style={tw('text-base mt-5')}>
            {t('pages.assessment.desktopIntro1')}
          </Text>
          <Text style={tw('text-base mt-2')}>
            {t('pages.assessment.desktopIntro2')}
            <Text style={tw('text-base font-bold')}>
              {t('pages.assessment.desktopIntro2Definitions')}
            </Text>
            {t('pages.assessment.desktopIntro3')}
            <Text style={tw('text-base font-bold')}>
              {t('pages.assessment.desktopIntro3Calculator')}
            </Text>
          </Text>
        </View>
        <View style={{ width: '100%' }}>
          <AssessmentSection
            style={tw(`${isDesktop ? '' : 'mt-10'}`)}
            hideHeading
            heading={t('pages.assessment.patientFactors')}
            description={t('pages.assessment.note') + ' :'}
          >
            {patientFactors.map((factor, i) => (
              <MultipleChoiceBox
                invalid={touched && !surveyState[factor.name]}
                onPress={onOptionPress}
                selected={surveyState[factor.name]}
                key={factor.name}
                options={factor.options}
                name={factor.name}
                sideButton={renderModalButton(factor.name)}
                style={tw(`${i == 0 ? '' : 'mt-5'}`)}
              />
            ))}
            {touched && !allPatientFactorsFilled && (
              <Text
                style={[
                  tw('mt-5 italic font-semibold text-base'),
                  { color: '#E07A5F' }
                ]}
              >
                {t('pages.assessment.error')}
              </Text>
            )}
          </AssessmentSection>
          <AssessmentSection
            style={tw('mt-10')}
            heading={t('pages.assessment.diseaseFactors')}
            description={t('pages.assessment.note') + ' :'}
          >
            {diseaseFactors.map(factor => (
              <MultipleChoiceBox
                invalid={touched && !surveyState[factor.name]}
                onPress={onOptionPress}
                selected={surveyState[factor.name]}
                key={factor.name}
                options={factor.options}
                name={factor.name}
                style={tw('mt-5')}
              />
            ))}
          </AssessmentSection>
          {invalid && (
            <Text
              style={[
                tw('mt-5 italic font-semibold text-base'),
                { color: '#E07A5F' }
              ]}
            >
              {t('pages.assessment.error')}
            </Text>
          )}
          <View style={tw('mt-10 w-full flex items-center')}>
            <PrimaryButton
              text={t('pages.assessment.summaryButton')}
              buttonStyle={!isDesktop && tw('py-4 px-5') }
              onPress={onSubmit}
            />
          </View>
          <View style={tw('mt-10 w-full flex items-end')}>
            <UpTopButton
            onPress={() =>
              viewRef.current.scrollTo({ x: 0, y: 0, animated: true })
            }
            />
          </View>
        </View>
        <ClassificationModal
          navigation={navigation}
          modalVisible={classificationModalVisible}
          setModalVisible={setClassificationModalVisible}
          onSurveyResult={option =>
            onOptionPress({
              name: 'Child Pugh Class',
              option
            })
          }
        />
        <StagingModal
          modalVisible={stagingModalVisible}
          setModalVisible={setStagingModalVisible}
        />
      </ScrollView>
    </View>
  )
}

export default Assessment
