export const treatmentOptions = [
  'Sorafenib',
  'Lenvatinib',
  'Atezolizumab + Bevacizumab',
  'Durvalumab + Tremelimumab'
]

export const treatmentLabels = {
  en: ['SOR', 'LEN', 'ATEZO + BEV', 'DURVA + TREME'],
  fr: ['SOR', 'LEN', 'ATÉZO + BEV', 'DURVA + TREME']
}

export const fullTreatmentLabels = {
  en: [
    'Sorafenib',
    'Lenvatinib',
    'Atezolizumab + Bevacizumab',
    'Durvalumab + Tremelimumab'
  ],
  fr: [
    'Sorafenib',
    'Lenvatinib',
    'Atézolizumab + Bevacizumab',
    'Durvalumab + Trémélimumab'
  ]
}

export const optionsByLang = {
  en: [
    { option: 'Sorafenib', label: 'SOR' },
    { option: 'Lenvatinib', label: 'LEN' },
    { option: 'Atezolizumab + Bevacizumab', label: 'ATEZO + BEV' },
    { option: 'Durvalumab + Tremelimumab', label: 'DURVA + TREME' }
  ],
  fr: [
    { option: 'Sorafenib', label: 'SOR' },
    { option: 'Lenvatinib', label: 'LEN' },
    { option: 'Atézolizumab + Bevacizumab', label: 'ATÉZO + BEV' },
    { option: 'Durvalumab + Trémélimumab', label: 'DURVA + TREME' }
  ]
}

export const indicatorColours = {
  green: '#81B29A',
  yellow: '#F2CC8F',
  red: '#E07A5F'
}

const template = {
  Sorafenib: {
    indicator: 'green',
    text: ``
  },
  Lenvatinib: {
    indicator: 'green',
    text: ``
  },
  'Atezolizumab + Bevacizumab': {
    indicator: 'green',
    text: ``
  },
  'Durvalumab + Tremelimumab': {
    indicator: 'green',
    text: ``
  }
}

const BCLCStage_B = {
  Sorafenib: {
    indicator: 'green',
    text: {
      en: `Patients with intermediate-stage HCC are candidates for locoregional treatment, including TACE (the backbone of treatment for these patients and has shown to improve survival in this population). [Kudo 2019]

Per real‐world clinical practice, latest ESMO guidelines apply <b>“treatment stage migration”</b>—recommending moving to another treatment (generally subsequent stage treatment) if current approach proves unfeasible. Patients with intermediate-stage HCC will generally stage migrate, as they will eventually become refractory to TACE and need to transition to systemic therapy. [Vogel 2021]

Systemic therapy is recommended for intermediate HCC treatment stage migration in patients not suitable for locoregional therapies. [Vogel 2021]

18% of patients in the sorafenib arm of SHARP were classified as BCLC stage B. [Llovet 2008]

Analysis of survival outcomes in patients with BCLC stage B disease in SHARP showed median overall survival of 14.5 months for sorafenib vs 11.4 months for placebo; HR: 0.72 (95% CI: 0.38-1.38). [Bruix 2012]

<xs>BCLC, Barcelona Clinic Liver Cancer; CI, confidence interval; ESMO, European Society for Medical Oncology; HCC, hepatocellular carcinoma; HR, hazard ratio; TACE, transarterial chemoembolization</xs>
    `,
      fr: `Les patients atteints d’un CHC de stade intermédiaire sont admissibles au traitement locorégional, y compris la CETA (qui est la pierre angulaire du traitement et qui améliore la survie chez ces patients). [Kudo 2019]

Conformément à la pratique en contexte réel, les plus récentes lignes directrices de l’ESMO recommandent <b>« la transition thérapeutique »</b>, c’est-à-dire le passage à un autre traitement (généralement le traitement pour le stade suivant), si l’approche thérapeutique actuelle n’est pas possible. Les patients atteints d’un CHC de stade intermédiaire vont généralement changer de traitement, car leur cancer deviendra éventuellement réfractaire à la CETA et nécessitera le passage à un traitement à action générale. [Vogel 2021]

Le traitement à action générale est recommandé pour la transition thérapeutique du CHC de stade intermédiaire chez les patients qui ne peuvent pas recevoir un traitement locorégional. [Vogel 2021]

Dans l’étude SHARP, 18 % des patients du groupe sorafenib présentaient un CHC de stade B selon la classification du BCLC. [Llovet 2008]

Les résultats de l’analyse de survie chez les patients de l’étude SHARP atteints d’un cancer de stade B selon la classification du BCLC ont montré que la survie médiane globale était de 14,5 mois dans le groupe sorafenib et de 11,4 mois dans le groupe placebo (RRI : 0,72; IC à 95 % : 0,38; 1,38). [Bruix 2012]

<xs>BCLC = Barcelona Clinic Liver Cancer; CETA = chimioembolisation transartérielle; CHC = carcinome hépatocellulaire; ESMO = European Society for Medical Oncology; IC = intervalle de confiance; RRI = rapport des risques instantanés</xs>
      `
    }
  },
  Lenvatinib: {
    indicator: 'green',
    text: {
      en: `Patients with intermediate-stage HCC are candidates for locoregional treatment, including TACE (the backbone of treatment and has shown to improve survival in this population). [Kudo 2019]

Per real‐world clinical practice, latest ESMO guidelines apply <b>“treatment stage migration”</b>—recommending moving to another treatment (generally subsequent stage treatment) if current approach proves unfeasible. Patients with intermediate-stage HCC will generally stage migrate, as they will eventually become refractory to TACE and need to transition to systemic therapy. [Vogel 2021]

Systemic therapy is recommended for intermediate HCC treatment stage migration in patients not suitable for locoregional therapies. [Vogel 2021]

22% of patients in the lenvatinib arm of REFLECT were classified as BCLC stage B. [Kudo 2018]

A proof-of-concept study of initial treatment with lenvatinib followed by TACE vs conventional TACE (cTACE) in BCLC stage B HCC patients with large or multinodular tumours exceeding up-to-7 criteria* showed significantly higher ORR in lenvatinib (73.3% vs. 33.3%; p < 0.001) and significantly longer median PFS than cTACE (16.0 vs. 3.0 months; p < 0.001). OS was significantly longer for lenvatinib vs cTACE (37.9 vs. 21.3 months; HR: 0.48, p < 0.01). [Kudo 2019]

<i>*(diameter [in cm] of largest tumour + number of tumours does not exceed 7; e.g., 1 nodule, 6 cm in size [1+6=7], 5 tumours up to 2 cm in size [5+2=7])</i>

<xs>BCLC, Barcelona Clinic Liver Cancer; ESMO, European Society for Medical Oncology; HCC, hepatocellular carcinoma; HR, hazard ratio; ORR, objective response rate; OS, overall survival; PFS, progression-free survival; TACE, transarterial chemoembolization</xs>
  `,
      fr: `Les patients atteints d’un CHC de stade intermédiaire sont admissibles au traitement locorégional, y compris la CETA (qui est la pierre angulaire du traitement et qui améliore la survie chez ces patients). [Kudo 2019]

Conformément à la pratique en contexte réel, les plus récentes lignes directrices de l’ESMO recommandent <b>« la transition thérapeutique »</b>, c’est-à-dire le passage à un autre traitement (généralement le traitement pour le stade suivant), si l’approche thérapeutique actuelle n’est pas possible. Les patients atteints d’un CHC de stade intermédiaire vont généralement changer de traitement, car leur cancer deviendra éventuellement réfractaire à la CETA et nécessitera le passage à un traitement à action générale. [Vogel 2021]

Le traitement à action générale est recommandé pour la transition thérapeutique du CHC de stade intermédiaire chez les patients qui ne peuvent pas recevoir un traitement locorégional. [Vogel 2021]

Dans l’étude REFLECT, 22 % des patients du groupe lenvatinib présentaient un CHC de stade B selon la classification du BCLC. [Kudo 2018]

D’après les résultats d’une étude de validation de principe comparant le traitement initial par le lenvatinib suivi d’une CETA à la CETA conventionnelle (CETAc) chez les patients atteints d’un CHC de stade B selon la classification du BCLC présentant des tumeurs volumineuses ou multinodulaires satisfaisant à au plus sept critères*, le TRO s’est avéré significativement plus élevé dans le groupe lenvatinib (73,3 % vs 33,3 %; p < 0,001) par rapport à la CETAc et la SSP médiane, significativement plus longue (16 mois vs 3 mois; p < 0,001). La SG était significativement plus longue dans le groupe lenvatinib que dans le groupe CETAc (37,9 mois vs 21,3 mois; RRI : 0,48; p < 0,01). [Kudo 2019]

<i>* (diamètre [en cm] de la tumeur la plus volumineuse + nombre de tumeurs satisfaisant à au plus sept critères; p. ex. 1 nodule de 6 cm [1 + 6 = 7], 5 tumeurs d’au plus 2 cm [5 + 2 = 7])</i>

<xs>BCLC = Barcelona Clinic Liver Cancer; CETA = chimioembolisation transartérielle; CHC = carcinome hépatocellulaire; ESMO = European Society for Medical Oncology; RRI = rapport des risques instantanés; SG = survie globale; SSP = survie sans progression; TRO = taux de réponse objective</xs>`
    }
  },
  'Atezolizumab + Bevacizumab': {
    indicator: 'green',
    text: {
      en: `Patients with intermediate-stage HCC are candidates for locoregional treatment, including TACE (the backbone of treatment for these patients and has shown to improve survival in this population). [Kudo 2019]

Per real‐world clinical practice, latest ESMO guidelines apply <b>“treatment stage migration”</b>—recommending moving to another treatment (generally subsequent stage treatment) if current approach proves unfeasible. Patients with intermediate-stage HCC will generally stage migrate, as they will eventually become refractory to TACE and need to transition to systemic therapy. [Vogel 2021]

Systemic therapy is recommended for intermediate HCC treatment stage migration in patients not suitable for locoregional therapies. [Vogel 2021]

15% of patients in the atezolizumab+bevacizumab arm of IMBrave were classified as BCLC stage B. [Finn 2020]

<xs>BCLC, Barcelona Clinic Liver Cancer; ESMO, European Society for Medical Oncology; HCC, hepatocellular carcinoma; TACE, transarterial chemoembolization</xs>
  `,
      fr: `Les patients atteints d’un CHC de stade intermédiaire sont admissibles au traitement locorégional, y compris la CETA (qui est la pierre angulaire du traitement et qui améliore la survie chez ces patients). [Kudo 2019]

Conformément à la pratique en contexte réel, les plus récentes lignes directrices de l’ESMO recommandent <b>« la transition thérapeutique »</b>, c’est-à-dire le passage à un autre traitement (généralement le traitement pour le stade suivant), si l’approche thérapeutique actuelle n’est pas possible. Les patients atteints d’un CHC de stade intermédiaire vont généralement changer de traitement, car leur cancer deviendra éventuellement réfractaire à la CETA et nécessitera le passage à un traitement à action générale. [Vogel 2021]

Le traitement à action générale est recommandé pour la transition thérapeutique du CHC de stade intermédiaire chez les patients qui ne peuvent pas recevoir un traitement locorégional. [Vogel 2021]

Dans l’étude IMBrave, 15 % des patients du groupe atézolizumab + bevacizumab présentaient un CHC de stade B selon la classification du BCLC. [Finn IMBrave150 2 020]

<xs>BCLC = Barcelona Clinic Liver Cancer; CETA = chimioembolisation transartérielle; CHC = carcinome hépatocellulaire; ESMO = European Society for Medical Oncology</xs>`
    }
  },
  'Durvalumab + Tremelimumab': {
    indicator: 'green',
    text: {
      en: `19.6% of patients in the STRIDE arm of HIMALAYA were classified as BCLC stage B. [Abou-Alfa 2022]

<xs>BCLC, Barcelona Clinic Liver Cancer</xs>`,
      fr: `Dans l’étude HIMALAYA, 19,6 % des patients du groupe STRIDE présentaient un CHC de stade B selon la classification du BCLC. [Abou-Alfa 2022]

<xs>BCLC = Barcelona Clinic Liver Cancer</xs>`
    }
  }
}

const BCLCStage_C = {
  Sorafenib: {
    indicator: 'green',
    text: {
      en: `ASCO guidelines recommend systemic therapy for patients with advanced HCC whose disease has invaded portal vasculature, whether within or outside the liver (vascular invasion) or whose tumor has spread beyond the liver (extrahepatic spread); BCLC stage C and good liver function (Child-Pugh A) and performance status (ECOG PS 0/1). [Gordan 2020]

Sorafenib is recommended for first-line treatment of patients with BCLC Stage C HCC. [Llovet 2008; NEXAVAR® Product Monograph 2014]

<xs>ASCO, American Society of Clinical Oncology; BCLC, Barcelona Clinic Liver Cancer; ECOG PS, Eastern Cooperative Oncology Group Performance Status; HCC, hepatocellular carcinoma</xs>
  `,
      fr: `Les lignes directrices de l’ASCO recommandent le traitement à action générale chez les patients atteints d’un CHC de stade avancé ayant atteint le réseau vasculaire de la veine porte, à l’intérieur ou à l’extérieur du foie (envahissement vasculaire), ou dont la tumeur s’est propagée au-delà du foie (dissémination extrahépatique); stade C selon la classification du BCLC, présentant une bonne fonction hépatique (classe A de Child-Pugh) et un bon indice de performance (IP ECOG de 0 ou 1). [Gordan 2020]

Le sorafenib est recommandé comme traitement de première intention chez les patients atteints de CHC de stade C selon la classification du BCLC. [Llovet 2008; NEXAVAR® Product Monograph 2014]

<xs>ASCO = American Society of Clinical Oncology; BCLC = Barcelona Clinic Liver Cancer; CHC = carcinome hépatocellulaire; IP ECOG = indice de performance de l’Eastern Cooperative Oncology Group</xs>`
    }
  },
  Lenvatinib: {
    indicator: 'green',
    text: {
      en: `ASCO guidelines recommend systemic therapy for patients with advanced HCC whose disease has invaded portal vasculature, whether within or outside the liver (vascular invasion) or whose tumor has spread beyond the liver (extrahepatic spread); BCLC stage C and good liver function (Child-Pugh A) and performance status (ECOG PS 0/1). [Gordan 2020]

Lenvatinib is recommended for first-line treatment of patients with BCLC stage C HCC. [Kudo 2018; Lenvima® Product Monograph 2019]

<xs>ASCO, American Society of Clinical Oncology; BCLC, Barcelona Clinic Liver Cancer; ECOG PS, Eastern Cooperative Oncology Group Performance Status; HCC, hepatocellular carcinoma; TACE, transarterial chemoembolization</xs>
  `,
      fr: `Les lignes directrices de l’ASCO recommandent le traitement à action générale chez les patients atteints d’un CHC de stade avancé ayant atteint le réseau vasculaire de la veine porte, à l’intérieur ou à l’extérieur du foie (envahissement vasculaire), ou dont la tumeur s’est propagée au-delà du foie (dissémination extrahépatique); stade C selon la classification du BCLC, présentant une bonne fonction hépatique (classe A de Child-Pugh) et un bon indice de performance (IP ECOG de 0 ou 1). [Gordan 2020]

Le lenvatinib est recommandé comme traitement de première intention chez les patients atteints de CHC de stade C selon la classification du BCLC. [Kudo 2018; Lenvima® Product Monograph 2019]

<xs>ASCO = American Society of Clinical Oncology; BCLC = Barcelona Clinic Liver Cancer; CETA = chimioembolisation transartérielle; CHC = carcinome hépatocellulaire; IP ECOG = indice de performance de l’Eastern Cooperative Oncology Group</xs>`
    }
  },
  'Atezolizumab + Bevacizumab': {
    indicator: 'green',
    text: {
      en: `ASCO guidelines recommend systemic therapy for patients with advanced HCC whose disease has invaded portal vasculature, whether within or outside the liver (vascular invasion) or whose tumor has spread beyond the liver (extrahepatic spread); BCLC stage C and good liver function (Child-Pugh A) and performance status (ECOG PS 0/1). [Gordan 2020]

Atezolizumab + bevacizumab is recommended for first-line treatment of patient with BCLC stage C HCC. [Finn 2020; TECENTRIQ® (atezolizumab) Product Monograph 2021]

<xs>ASCO, American Society of Clinical Oncology; BCLC, Barcelona Clinic Liver Cancer; ECOG PS, Eastern Cooperative Oncology Group Performance Status; HCC, hepatocellular carcinoma</xs>
  `,
      fr: `Les lignes directrices de l’ASCO recommandent le traitement à action générale chez les patients atteints d’un CHC de stade avancé dont le cancer a atteint le réseau vasculaire de la veine porte, à l’intérieur ou à l’extérieur du foie (envahissement vasculaire), ou dont la tumeur s’est propagée au-delà du foie (dissémination extrahépatique); stade C selon la classification du BCLC, présentant une bonne fonction hépatique (classe A de Child-Pugh) et un bon indice de performance (IP ECOG de 0 ou 1). [Gordan 2020]

L’association atézolizumab + bevacizumab est recommandée comme traitement de première intention chez les patients atteints de CHC de stade C selon la classification du BCLC. [Finn IMBrave 2020; TECENTRIQ® Product Monograph (atezolizumab) 2021]

<xs>ASCO = American Society of Clinical Oncology; BCLC = Barcelona Clinic Liver Cancer; CHC = carcinome hépatocellulaire; IP ECOG = indice de performance de l’Eastern Cooperative Oncology Group</xs>`
    }
  },
  'Durvalumab + Tremelimumab': {
    indicator: 'green',
    text: {
      en: `Durvalumab + tremelimumab is recommended for first-line treatment of patients with BCLC stage C HCC. [Abou-Alfa 2022; IMJUDO® (tremelimumab) Product Monograph 2023]

<xs>BCLC, Barcelona Clinic Liver Cancer; HCC, hepatocellular carcinoma</xs>`,
      fr: `L’association durvalumab + trémélimumab est recommandée comme traitement de première intention chez les patients atteints d’un CHC de stade C selon la classification du BCLC. [Abou-Alfa 2022; IMJUDO® (tremelimumab) Product Monograph 2023]

<xs>BCLC = Barcelona Clinic Liver Cancer; CHC = carcinome hépatocellulaire</xs>`
    }
  }
}

const ChildPughClass_A = {
  Sorafenib: {
    indicator: 'green',
    text: {
      en: `Systemic therapy is recommended for patients with advanced HCC and preserved liver function, Child-Pugh Class A. [Gordan 2020]

Patients included in SHARP had liver function score of Child-Pugh Class A. [Llovet 2008]

Sorafenib is recommended for first-line treatment for patients with preserved liver function Child-Pugh Class A. [Gordan 2020; NEXAVAR® Product Monograph 2014]

<xs>HCC, hepatocellular carcinoma</xs>
`,
      fr: `Le traitement à action générale est recommandé chez les patients atteints de CHC de stade avancé dont la fonction hépatique est préservée (classe A de Child-Pugh). [Gordan 2020]

Les patients de l’étude SHARP avaient une fonction hépatique de classe A de Child-Pugh. [Llovet 2008]

Le sorafenib est recommandé comme traitement de première intention chez les patients dont la fonction hépatique est préservée (classe A de Child-Pugh). [Gordan 2020; NEXAVAR® Product Monograph 2014]

<xs>CHC = carcinome hépatocellulaire</xs>`
    }
  },
  Lenvatinib: {
    indicator: 'green',
    text: {
      en: `Systemic therapy is recommended for patients with advanced HCC and preserved liver function, Child-Pugh Class A. [Gordan 2020]

Patients included in REFLECT had liver function score of Child-Pugh Class A. [Kudo 2018]

Lenvatinib is recommended for first-line treatment for patients with preserved liver function Child-Pugh Class A. [Gordan 2020; LENVIMA® Product Monograph 2019]

<xs>HCC, hepatocellular carcinoma</xs>
    `,
      fr: `Le traitement à action générale est recommandé chez les patients atteints de CHC de stade avancé dont la fonction hépatique est préservée (classe A de Child-Pugh). [Gordan 2020]

Les patients de l’étude REFLECT avaient une fonction hépatique de classe A de Child-Pugh. [Kudo 2018]

Le lenvatinib est recommandé comme traitement de première intention chez les patients dont la fonction hépatique est préservée (classe A de Child-Pugh). [Gordan 2020; LENVIMA® Product Monograph 2019]

<xs>CHC = carcinome hépatocellulaire</xs>`
    }
  },
  'Atezolizumab + Bevacizumab': {
    indicator: 'green',
    text: {
      en: `Systemic therapy is recommended for patients with advanced HCC and preserved liver function, Child-Pugh Class A. [Gordan 2020]

Patients included in IMBrave150 had liver function score of Child-Pugh Class A. [Finn 2020]

Atezolizumab + bevacizumab is recommended for first-line treatment for patients with preserved liver function Child-Pugh Class A. [Gordan 2020; TECENTRIQ® (atezolizumab) Product Monograph 2021]

<xs>HCC, hepatocellular carcinoma</xs>
    `,
      fr: `Le traitement à action générale est recommandé chez les patients atteints de CHC de stade avancé dont la fonction hépatique est préservée (classe A de Child-Pugh). [Gordan 2020]

Les patients de l’étude IMBrave150 avaient une fonction hépatique de classe A de Child-Pugh. [Finn IMBrave 2020]

L’association atézolizumab + bevacizumab est recommandée comme traitement de première intention chez les patients dont la fonction hépatique est préservée (classe A de Child-Pugh). [Gordan 2020; TECENTRIQ® (Atezolizumab) Product Monograph 2021]

<xs>CHC = carcinome hépatocellulaire</xs>`
    }
  },
  'Durvalumab + Tremelimumab': {
    indicator: 'green',
    text: {
      en: `Patients included in HIMALAYA had liver function score of Child-Pugh Class A (Child-Pugh A5: 75.1%; Child-Pugh A6: 23.4%). [Abou-Alfa 2022]

Durvalumab + tremelimumab is recommended for first-line treatment for patients with preserved liver function Child-Pugh Class A [IMJUDO® Product Monograph 2023].`,
      fr: `Les patients de l’étude HIMALAYA avaient une fonction hépatique de classe A de Child-Pugh (75,1 % des patients étaient dans la classe A5 de Child-Pugh et 23,4 %, dans la classe A6 de Child-Pugh). [Abou-Alfa 2022]

L’association durvalumab + trémélimumab est recommandée comme traitement de première intention chez les patients dont la fonction hépatique est préservée (classe A de Child-Pugh). [IMJUDO® Product Monograph 2023] `
    }
  }
}

const ChildPughClass_B = {
  Sorafenib: {
    indicator: 'yellow',
    text: {
      en: `Clinical trials supporting use of approved systemic therapies for advanced HCC were conducted with patients with good (Child-Pugh A) liver function. There is little data to guide treatment decisions with more severe (Child-Pugh B) liver function [SHARP Trial: 95% Child-Pugh A; 5% Child-Pugh B)]. [Llovet 2008]

ASCO guidelines recommend cautious approach to systematic therapy in advanced HCC patients with Child-Pugh Class B and good performance status. Discuss treatment options within a multidisciplinary team. [Gordan 2020]

Consider sorafenib in Child-Pugh B7 in absence of ascites. [WC5 Consensus Statement. September 2019]

<xs>ASCO, American Society of Clinical Oncology; HCC, hepatocellular carcinoma; ORR; objective response rate; OS, overall survival; PFS, progression free survival</xs>
    `,
      fr: `Les essais cliniques étayant l’utilisation de traitements à action générale approuvés pour le CHC de stade avancé ont été menés auprès de patients dont la fonction hépatique était normale (classe A de Child-Pugh). Il y a très peu de données pour orienter la prise de décisions thérapeutiques en présence d’insuffisance hépatique grave (classe B de Child-Pugh) (SHARP : 95 % des patients étaient dans la classe A de Child-Pugh et 5 %, dans la classe B de Child-Pugh). [Llovet 2008]

Les lignes directrices de l’ASCO recommandent de faire preuve de prudence pour instaurer un traitement à action générale chez les patients atteints de CHC de stade avancé de classe B de Child-Pugh ayant un bon indice de performance. Les options thérapeutiques doivent être évaluées par l’équipe multidisciplinaire. [Gordan 2020]

Le sorafenib peut être envisagé chez les patients qui ont un score B7 de Child-Pugh et qui n’ont pas d’ascites. [Consensus Statement. September 2019]

<xs>ASCO = American Society of Clinical Oncology; CHC = carcinome hépatocellulaire</xs>`
    }
  },
  Lenvatinib: {
    indicator: 'yellow',
    text: {
      en: `
Clinical trials supporting use of approved systemic therapies for advanced HCC were conducted with patients with good liver function (Child-Pugh A). There is little data to guide treatment decisions with more severe (Child-Pugh B) liver function [REFLECT: 99% Child-Pugh A; 1% Child-Pugh B)]. [Kudo 2018]

ASCO guidelines recommend cautious approach to systematic therapy in advanced HCC patients with Child-Pugh Class B and good performance status. Discuss treatment options within a multidisciplinary team. [Gordan 2020]

Consider lenvatinib in Child-Pugh B7 in absence of ascites in patients intolerant to sorafenib. [WC5 Consensus Statement. September 2019]

<xs>ASCO, American Society of Clinical Oncology; HCC, hepatocellular carcinoma; ORR; objective response rate; OS, overall survival; PFS, progression free survival</xs>`,
      fr: `Les essais cliniques étayant l’utilisation de traitements à action générale approuvés pour le CHC de stade avancé ont été menés auprès de patients dont la fonction hépatique était normale (classe A de Child-Pugh). Il y a très peu de données pour orienter la prise de décisions thérapeutiques en présence d’insuffisance hépatique grave (classe B de Child-Pugh) (étude REFLECT : 99 % des patients étaient dans la classe A de Child-Pugh et 1 %, dans la classe B de Child-Pugh). [Kudo REFLECT 2018]

Les lignes directrices de l’ASCO recommandent de faire preuve de prudence pour instaurer un traitement à action générale chez les patients atteints de CHC de stade avancé de classe B de Child-Pugh ayant un bon indice de performance. Les options thérapeutiques doivent être évaluées par l’équipe multidisciplinaire. [Gordan 2020]

Le lenvatinib peut être envisagé chez les patients intolérants au sorafénib qui ont un score Child-Pugh B7 mais n'ont pas d'ascite. [Consensus Statement. September 2019]

<xs>ASCO = American Society of Clinical Oncology; CHC = carcinome hépatocellulaire</xs>`
    }
  },
  'Atezolizumab + Bevacizumab': {
    indicator: 'yellow',
    text: {
      en: `Clinical trials supporting use of approved systemic therapies for advanced HCC were conducted with patients with good (Child-Pugh A) liver function. There is little data to guide treatment decisions with more severe (Child-Pugh B) liver function [IMBrave150: 72% Child-Pugh A5; 28% Child-Pugh A6)]. [Finn 2020]

ASCO guidelines recommend cautious approach to systematic therapy in advanced HCC patients with Child-Pugh Class B and good performance status. Discuss treatment options within a multidisciplinary team. [Gordan 2020]

<xs>ASCO, American Society of Clinical Oncology; HCC, hepatocellular carcinoma</xs>
    `,
      fr: `Les essais cliniques étayant l’utilisation de traitements à action générale approuvés pour le CHC de stade avancé ont été menés auprès de patients dont la fonction hépatique était normale (classe A de Child-Pugh). Il y a très peu de données pour orienter la prise de décisions thérapeutiques en présence d’insuffisance hépatique grave (classe B de Child-Pugh) (IMBrave150 : 72 % des patients étaient dans la classe A5 de Child-Pugh et 28 %, dans la classe A6 de Child-Pugh). [Finn 2020]

Les lignes directrices de l’ASCO recommandent de faire preuve de prudence pour instaurer un traitement à action générale chez les patients atteints de CHC de stade avancé de classe B de Child-Pugh ayant un bon indice de performance. Les options thérapeutiques doivent être évaluées par l’équipe multidisciplinaire. [Gordan 2020]

<xs>ASCO = American Society of Clinical Oncology; CHC = carcinome hépatocellulaire</xs>`
    }
  },
  'Durvalumab + Tremelimumab': {
    indicator: 'yellow',
    text: {
      en: `Clinical trials supporting use of approved systemic therapies for advanced HCC were conducted with patients with good (Child-Pugh A) liver function. There is little data to guide treatment decisions with more severe (Child-Pugh B) liver function [HIMALAYA: 75% Child-Pugh A5; 23% Child-Pugh A6; 1% Child-Pugh B]. [Abou-Alfa 2022]

<xs>HCC, hepatocellular carcinoma</xs>`,
      fr: `Les essais cliniques étayant l’utilisation de traitements à action générale approuvés pour le CHC de stade avancé ont été menés auprès de patients dont la fonction hépatique était normale (classe A de Child-Pugh). Il y a très peu de données pour orienter la prise de décisions thérapeutiques en présence d’insuffisance hépatique grave (classe B de Child-Pugh) [HIMALAYA : 75 % des patients étaient dans la classe A5 de Child-Pugh, 23 % étaient dans la classe A6 de Child-Pugh et 1 %, dans la classe B de Child-Pugh]. [Abou-Alfa 2022]

<xs>CHC = carcinome hépatocellulaire</xs>`
    }
  }
}

const ECOG_Performance_0 = {
  Sorafenib: {
    indicator: 'green',
    text: {
      en: `ASCO guidelines recommend systemic therapy for patients with unresectable HCC and good performance status (ECOG PS 0-1). [Gordan 2020]

Majority of patients in SHARP had ECOG PS 0-1 (92% ECOG PS 0-1; 8% ECOG PS 2). [Llovet 2008]

Align selection of patients for sorafenib treatment to patients included in the clinical trial. [Gordan 2020]

<xs>ASCO, American Society of Clinical Oncology; ECOG PS, Eastern Cooperative Oncology Group Performance Status; HCC, hepatocellular carcinoma</xs>
    `,
      fr: `Les lignes directrices de l’ASCO recommandent le traitement à action générale chez les patients atteints d’un CHC non résécable et ayant un bon indice de performance (IP ECOG 0 ou 1). [Gordan 2020]

La majorité des patients de l’étude SHARP avaient un IP ECOG de 0 ou 1 (92 % avaient un IP ECOG de 0 ou 1; 8 % avaient un IP ECOG de 2). [Llovet 2008]

Les patients à qui on décide d’administrer le sorafenib doivent correspondre aux patients de l’essai clinique. [Gordan 2020]

<xs>ASCO = American Society of Clinical Oncology; CHC = carcinome hépatocellulaire; IP ECOG = indice de performance de l’Eastern Cooperative Oncology Group</xs>`
    }
  },
  Lenvatinib: {
    indicator: 'green',
    text: {
      en: `ASCO guidelines recommend systemic therapy for patients with unresectable HCC and good performance status (ECOG PS 0-1). [Gordan 2020]

Patients enrolled in REFLECT had ECOG PS 0-1. [Kudo 2018]

Align selection of patients for lenvatinib treatment to patients included in the clinical trial. [Gordan 2020]

<xs>ASCO, American Society of Clinical Oncology; ECOG PS, Eastern Cooperative Oncology Group Performance Status; HCC, hepatocellular carcinoma</xs>
    `,
      fr: `Les lignes directrices de l’ASCO recommandent le traitement à action générale chez les patients atteints d’un CHC non résécable et ayant un bon indice de performance (IP ECOG 0 ou 1). [Gordan 2020]

Les patients de l’étude REFLECT avaient un IP ECOG de 0 ou 1. [Kudo 2018]

Les patients à qui on décide d’administrer le lenvatinib doivent correspondre aux patients de l’essai clinique. [Gordan 2020]

<xs>ASCO = American Society of Clinical Oncology; CHC = carcinome hépatocellulaire; IP ECOG = indice de performance de l’Eastern Cooperative Oncology Group</xs>`
    }
  },
  'Atezolizumab + Bevacizumab': {
    indicator: 'green',
    text: {
      en: `ASCO guidelines recommend systemic therapy for patients with unresectable HCC and good performance status (ECOG PS 0-1). [Gordan 2020]

Patients enrolled in IMBrave150 had ECOG PS 0-1. [Finn 2020]

Align selection of patients for atezolizumab + bevacizumab treatment with patients included in the clinical trial. [Gordan 2020]

<xs>ASCO, American Society of Clinical Oncology; ECOG PS, Eastern Cooperative Oncology Group Performance Status; HCC, hepatocellular carcinoma</xs>
    `,
      fr: `Les lignes directrices de l’ASCO recommandent le traitement à action générale chez les patients atteints d’un CHC non résécable et ayant un bon indice de performance (IP ECOG 0 ou 1). [Gordan 2020]

Les patients de l’étude IMBrave150 avaient un IP ECOG de 0 ou 1. [Finn 2020]

Les patients à qui on décide d’administrer l’association atézolizumab + bevacizumab doivent correspondre aux patients de l’essai clinique. [Gordan 2020]

<xs>ASCO = American Society of Clinical Oncology; CHC = carcinome hépatocellulaire; IP ECOG = indice de performance de l’Eastern Cooperative Oncology Group</xs>`
    }
  },
  'Durvalumab + Tremelimumab': {
    indicator: 'green',
    text: {
      en: `Majority of patients in the STRIDE arm of HIMALAYA had ECOG PS of 0-1 (62.1% ECOG PS 0; 37.7% ECOG PS 1; 0.3% ECOG PS 2). [Abou-Alfa 2022]

Patients enrolled in HIMALAYA had ECOG PS 0-1. [Abou-Alfa 2022]

<xs>ECOG PS, Eastern Cooperative Oncology Group Performance Status</xs>`,
      fr: `La majorité des patients du groupe STRIDE de l’étude HIMALAYA avaient un IP ECOG de 0 ou 1 (62,1 % avaient un IP ECOG de 0; 37,7 % avaient un IP ECOG de 1; 0,3 % avaient un IP ECOG de 2). [Abou-Alfa 2022]

Les patients de l’étude HIMALAYA avaient un IP ECOG de 0 ou 1. [Abou-Alfa 2022]

IP ECOG = indice de performance de l’Eastern Cooperative Oncology Group`
    }
  }
}

const ECOG_Performance_1 = {
  Sorafenib: {
    indicator: 'green',
    text: {
      en: `ASCO guidelines recommend systemic therapy for patients with unresectable HCC and good performance status (ECOG PS 0-1). [Gordan 2020]

Majority of patients in SHARP had ECOG PS 0-1 (92% ECOG PS 0-1; 8% ECOG PS 2). [Llovet 2008]

Align selection of patients for sorafenib treatment with patients included in the clinical trial. [Gordan 2020]

<xs>ASCO, American Society of Clinical Oncology; ECOG PS, Eastern Cooperative Oncology Group Performance Status; HCC, hepatocellular carcinoma</xs>
    `,
      fr: `Les lignes directrices de l’ASCO recommandent le traitement à action générale chez les patients atteints d’un CHC non résécable et ayant un bon indice de performance (IP ECOG 0 ou 1). [Gordan 2020]

La majorité des patients de l’étude SHARP avaient un IP ECOG de 0 ou 1 (92 % IP ECOG de 0 ou 1; 8 % IP ECOG de 2). [Llovet 2008]

Les patients à qui on décide d’administrer le sorafenib doivent correspondre aux patients de l’essai clinique. [Gordan 2020]

<xs>ASCO = American Society of Clinical Oncology; IP ECOG = indice de performance de l’Eastern Cooperative Oncology Group; CHC = carcinome hépatocellulaire</xs>`
    }
  },
  Lenvatinib: {
    indicator: 'green',
    text: {
      en: `ASCO guidelines recommend systemic therapy for patients with unresectable HCC and good performance status (ECOG PS 0-1). [Gordan 2020]

Patients enrolled in REFLECT had ECOG PS 0-1. [Kudo 2018]

Align selection of patients for lenvatinib treatment with patients included in the clinical trial. [Gordan 2020]

<xs>ASCO, American Society of Clinical Oncology; ECOG PS, Eastern Cooperative Oncology Group Performance Status; HCC, hepatocellular carcinoma</xs>
`,
      fr: `Les lignes directrices de l’ASCO recommandent le traitement à action générale chez les patients atteints d’un CHC non résécable et ayant un bon indice de performance (IP ECOG 0 ou 1). [Gordan 2020]

Les patients de l’étude REFLECT avaient un IP ECOG de 0 ou 1. [Kudo 2018]

Les patients à qui on décide d’administrer le lenvatinib doivent correspondre aux patients de l’essai clinique. [Gordan 2020]

<xs>ASCO = American Society of Clinical Oncology; CHC = carcinome hépatocellulaire; IP ECOG = indice de performance de l’Eastern Cooperative Oncology Group</xs>`
    }
  },
  'Atezolizumab + Bevacizumab': {
    indicator: 'green',
    text: {
      en: `ASCO guidelines recommend systemic therapy for patients with unresectable HCC and good performance status (ECOG PS 0-1). [Gordan 2020]

Patients enrolled in IMBrave150 had ECOG PS 0-1. [Finn 2020]

Align selection of patients for atezolizumab + bevacizumab treatment with patients included in the clinical trial. [Gordan 2020]

<xs>ASCO, American Society of Clinical Oncology; ECOG PS, Eastern Cooperative Oncology Group Performance Status; HCC, hepatocellular carcinoma</xs>
    `,
      fr: `Les lignes directrices de l’ASCO recommandent le traitement à action générale chez les patients atteints d’un CHC non résécable et ayant un bon indice de performance (IP ECOG de 0 ou 1). [Gordan 2020]

Les patients de l’étude IMBrave150 avaient un IP ECOG de 0 ou 1. [Finn 2020]

Les patients à qui on décide d’administrer l’association atézolizumab + bevacizumab doivent correspondre aux patients de l’essai clinique. [Gordan 2020]

<xs>ASCO = American Society of Clinical Oncology; CHC = carcinome hépatocellulaire; IP ECOG = indice de performance de l’Eastern Cooperative Oncology Group</xs>`
    }
  },
  'Durvalumab + Tremelimumab': {
    indicator: 'green',
    text: {
      en: `Majority of patients in the STRIDE arm of HIMALAYA had ECOG PS of 0-1 (62.1% ECOG PS 0; 37.7% ECOG PS 1; 0.3% ECOG PS 2). [Abou-Alfa 2022]

Patients enrolled in HIMALAYA had ECOG PS 0-1. [Abou-Alfa 2022]

<xs>ECOG PS, Eastern Cooperative Oncology Group Performance Status</xs>`,
      fr: `La majorité des patients du groupe STRIDE de l’étude HIMALAYA avaient un IP ECOG de 0 ou 1 (62,1 % des patients avaient un IP ECOG de 0; 37,7 % des patients avaient un IP ECOG de 1; 0,3 % des patients avaient un IP ECOG de 2). [Abou-Alfa 2022]

Les patients de l’étude HIMALAYA avaient un IP ECOG de 0 ou 1. [Abou-Alfa 2022]

IP ECOG = indice de performance de l’Eastern Cooperative Oncology Group`
    }
  }
}

const ECOG_Performance_2 = {
  Sorafenib: {
    indicator: 'yellow',
    text: {
      en: `ASCO guidelines recommend systemic therapy for patients with unresectable HCC and good performance status (ECOG PS 0-1). [Gordan 2020]

Majority of patients in SHARP had ECOG PS 0-1 (92% ECOG PS 0-1; 8% ECOG PS 2). [Llovet 2008]

Sorafenib is recommended treatment for the treatment of patients with ECOG PS 0‐2 and Child-Pugh A liver function. [Knox, J. WCGCCC Consensus Statements. September 2013]

<xs>ASCO, American Society of Clinical Oncology; BCLC, Barcelona Clinic Liver Cancer; ECOG PS, Eastern Cooperative Oncology Group Performance Status; HCC, hepatocellular carcinoma</xs>
    `,
      fr: `Les lignes directrices de l’ASCO recommandent le traitement à action générale chez les patients atteints d’un CHC non résécable et ayant un bon indice de performance (IP ECOG 0 ou 1). [Gordan 2020]

La majorité des patients de l’étude SHARP avaient un IP ECOG de 0 ou 1 (92 % IP ECOG de 0 ou 1; 8 % IP ECOG de 2). [Llovet 2008]

Le sorafenib est recommandé comme traitement chez les patients présentant un IP ECOG de 0 à 2 et une fonction hépatique de classe A de Child-Pugh. [Knox, J. WCGCCC Consensus Statements. September 2013]

<xs>ASCO = American Society of Clinical Oncology; BCLC = Barcelona Clinic Liver Cancer; CHC = carcinome hépatocellulaire; IP ECOG = indice de performance de l’Eastern Cooperative Oncology Group</xs>`
    }
  },
  Lenvatinib: {
    indicator: 'yellow',
    text: {
      en: `ASCO guidelines recommend systemic therapy for patients with unresectable HCC and good performance status (ECOG PS 0-1). [Gordan 2020]

Patients enrolled in REFLECT had ECOG PS 0-1. [Kudo 2018]

Align selection of patients for lenvatinib treatment with patients included in the clinical trial. [Gordan 2020]

<xs>ASCO, American Society of Clinical Oncology; ECOG PS, Eastern Cooperative Oncology Group Performance Status; HCC, hepatocellular carcinoma</xs>
    `,
      fr: `Les lignes directrices de l’ASCO recommandent le traitement à action générale chez les patients atteints d’un CHC non résécable et ayant un bon indice de performance (IP ECOG de 0 ou 1). [Gordan 2020]

Les patients de l’étude REFLECT avaient un IP ECOG de 0 ou 1. [Kudo 2018]

Les patients à qui on décide d’administrer le lenvatinib doivent correspondre aux patients de l’essai clinique. [Gordan 2020]

<xs>ASCO = American Society of Clinical Oncology; CHC = carcinome hépatocellulaire; IP ECOG = indice de performance de l’Eastern Cooperative Oncology Group</xs>`
    }
  },
  'Atezolizumab + Bevacizumab': {
    indicator: 'yellow',
    text: {
      en: `ASCO guidelines recommend systemic therapy for patients with unresectable HCC and good performance status (ECOG PS 0-1). [Gordan 2020]

Patients enrolled in IMBrave150 had ECOG PS 0-1. [Finn 2020]

Align selection of patients for atezolizumab + bevacizumab treatment with patients included in the clinical trial. [Gordan 2020]

<xs>ASCO, American Society of Clinical Oncology; ECOG PS, Eastern Cooperative Oncology Group Performance Status; HCC, hepatocellular carcinoma</xs>
    `,
      fr: `Les lignes directrices de l’ASCO recommandent le traitement à action générale chez les patients atteints d’un CHC non résécable et ayant un bon indice de performance (IP ECOG de 0 ou 1). [Gordan 2020]

Les patients de l’étude IMBrave150 avaient un IP ECOG de 0 ou 1. [Finn 2020]

Les patients à qui on décide d’administrer l’association atézolizumab + bevacizumab doivent correspondre aux patients de l’essai clinique. [Gordan 2020]

<xs>ASCO = American Society of Clinical Oncology; CHC = carcinome hépatocellulaire; IP ECOG = indice de performance de l’Eastern Cooperative Oncology Group</xs>`
    }
  },
  'Durvalumab + Tremelimumab': {
    indicator: 'yellow',
    text: {
      en: `Majority of patients in the STRIDE arm of HIMALAYA had ECOG PS of 0-1 (62.1% ECOG PS 0; 37.7% ECOG PS 1; 0.3% ECOG PS 2). [Abou-Alfa 2022]

Patients enrolled in HIMALAYA had ECOG PS 0-1. [Abou-Alfa 2022]

<xs>ECOG PS, Eastern Cooperative Oncology Group Performance Status</xs>`,
      fr: `La majorité des patients du groupe STRIDE de l’étude HIMALAYA avaient un IP ECOG de 0 ou 1 (62,1 % des patients avaient un IP ECOG de 0; 37,7 % des patients avaient un IP ECOG de 1; 0,3 % des patients avaient un IP ECOG de 2). [Abou-Alfa 2022]

Les patients de l’étude HIMALAYA avaient un IP ECOG de 0 ou 1. [Abou-Alfa 2022]

IP ECOG = indice de performance de l’Eastern Cooperative Oncology Group`
    }
  }
}

const LiverDisease_HBV = {
  Sorafenib: {
    indicator: 'green',
    text: {
      en: `SHARP included patients with hepatitis B (19%). [Llovet 2008]

Antiviral therapy should be considered for patients with HBV to prevent hepatic decompensation. [Roderburg 2016]

<xs>HBV, hepatitis B virus</xs>`,
      fr: `L’étude SHARP comptait des patients atteints d’hépatite B (19 %). [Llovet 2008]

Un traitement antiviral devrait être envisagé chez les patients atteints de VHB afin de prévenir la décompensation hépatique. [Roderburg 2016]

<xs>VHB = virus de l’hépatite B</xs>`
    }
  },
  Lenvatinib: {
    indicator: 'green',
    text: {
      en: `REFLECT included patients with hepatitis B (53%). [Kudo 2018]

Antiviral therapy should be considered for patients with HBV to prevent hepatic decompensation. [Roderburg 2016]

<xs>HBV, hepatitis B virus</xs>`,
      fr: `L’étude REFLECT comptait des patients atteints d’hépatite B (53 %). [Kudo 2018]

Un traitement antiviral devrait être envisagé chez les patients atteints de VHB afin de prévenir la décompensation hépatique. [Roderburg 2016]

<xs>VHB = virus de l’hépatite B</xs>`
    }
  },
  'Atezolizumab + Bevacizumab': {
    indicator: 'green',
    text: {
      en: `IMBrave150 included patients with hepatitis B (49%). [Finn 2020]

Patients with active hepatitis B virus: HBV DNA < 500 IU/mL obtained within 28 days prior to initiation of study treatment, and anti-HBV treatment (per local standard of care) for a minimum of 14 days prior to study entry and willingness to continue treatment for the length of the study was required. [Finn Suppl 2020]

Antiviral therapy should be considered for patients with HBV to prevent hepatic decompensation. [Roderburg 2016]

<xs>HBV, hepatitis B virus</xs>`,
      fr: `L’étude IMBrave150 comptait des patients atteints d’hépatite B (49 %). [Finn 2020]

Patients présentant une infection active par le virus de l’hépatite B : ADN du VHB < 500 UI/mL dans les 28 jours précédant l’instauration du traitement à l’étude, traitement anti-VHB (conformément aux normes de soins locales) au moins 14 jours avant le début de l’étude, acceptation de continuer le traitement antiviral pendant toute la durée de l’étude. [Finn Suppl 2020]

Un traitement antiviral devrait être envisagé chez les patients atteints de VHB afin de prévenir la décompensation hépatique. [Roderburg 2016]

<xs>UI = unité internationale; VHB = virus de l’hépatite B</xs>`
    }
  },
  'Durvalumab + Tremelimumab': {
    indicator: 'green',
    text: {
      en: `HIMALAYA included patients with HBV (31.0%). [Abou-Alfa 2022]

<xs>HBV, hepatitis B virus</xs>`,
      fr: `L’étude HIMALAYA comptait des patients atteints d’hépatite B (31,0 %). [Abou-Alfa 2022]

VHB = virus de l’hépatite B`
    }
  }
}

const LiverDisease_HCV = {
  Sorafenib: {
    indicator: 'green',
    text: {
      en: `SHARP included patients with hepatitis C (29%). [Llovet 2008] `,
      fr: `L’étude SHARP comptait des patients atteints d’hépatite C (29 %). [Llovet 2008] `
    }
  },
  Lenvatinib: {
    indicator: 'green',
    text: {
      en: `REFLECT included patients with hepatitis C (19%). [Kudo 2018]`,
      fr: `L’étude REFLECT comptait des patients atteints d’hépatite C (19 %). [Kudo 2018]`
    }
  },
  'Atezolizumab + Bevacizumab': {
    indicator: 'green',
    text: {
      en: `IMBrave150 included patients with hepatitis C (21%). [Finn 2020]`,
      fr: `L’étude IMBrave150 comptait des patients atteints d’hépatite C (21 %). [Finn 2020]`
    }
  },
  'Durvalumab + Tremelimumab': {
    indicator: 'green',
    text: {
      en: 'HIMALAYA included patients with hepatitis C (28.0%). [Abou-Alfa 2022] ',
      fr: `L’étude HIMALAYA comptait des patients atteints d’hépatite C (28,0 %). [Abou-Alfa 2022]`
    }
  }
}

const LiverDisease_CoInfection = {
  Sorafenib: {
    indicator: 'green',
    text: {
      en: `No exclusion criteria for patients with HBV-HCV co-infection in SHARP. [Llovet 2008]

<xs>HBV, hepatitis B virus; HCV, hepatitis C virus</xs>`,
      fr: `• Les patients présentant une co-infection par le VHB et le VHC n’étaient pas exclus de l’étude SHARP. [Llovet 2008, NCT00105443]

<xs>VHB = virus de l’hépatite B; VHC = virus de l’hépatite C</xs>`
    }
  },
  Lenvatinib: {
    indicator: 'green',
    text: {
      en: `No exclusion criteria for patients with HBV-HCV co-infection in REFLECT. [Kudo 2018]

<xs>HBV, hepatitis B virus; HCV, hepatitis C virus</xs>`,
      fr: `Les patients présentant une co-infection par le VHB et le VHC n’étaient pas exclus de l’étude REFLECT. [Kudo 2018, NCT01761266]

<xs>VHB = virus de l’hépatite B; VHC = virus de l’hépatite C</xs>`
    }
  },
  'Atezolizumab + Bevacizumab': {
    indicator: 'red',
    text: {
      en: `Patients with HBV-HCV co-infection were excluded from IMBrave150. [Finn 2020]

<xs>HBV, hepatitis B virus; HCV, hepatitis C virus</xs>
`,
      fr: `Les patients présentant une co-infection par le VHB et le VHC étaient exclus de l’étude IMBrave150. [Finn 2020]

<xs>VHB = virus de l’hépatite B; VHC = virus de l’hépatite C</xs>`
    }
  },
  'Durvalumab + Tremelimumab': {
    indicator: 'red',
    text: {
      en: `Patients with HBV-HCV co-infection were excluded from HIMALAYA. [Abou-Alfa 2022]

<xs>HBV, hepatitis B virus; HCV, hepatitis C virus</xs>`,
      fr: `Les patients présentant une co-infection par le VHB et le VHC étaient exclus de l’étude HIMALAYA. [Abou-Alfa 2022]

VHB = virus de l’hépatite B; VHC = virus de l’hépatite C`
    }
  }
}

const LiverDisease_NonViral = {
  Sorafenib: {
    indicator: 'green',
    text: {
      en: `Recent meta-analysis of impact of molecular and immunotherapies on outcome per HCC etiology showed no impact of etiology on outcome between TKI/anti-VEGF therapies. [Haber 2021]

HRs for OS were 0.81 (95% CI 0.71–0.92) in patients with viral etiologies and 0.82 (95% CI 0.67–1.01) in patients with nonviral etiologies. Difference in efficacy was not statistically significant (P = 0.8828). [Haber 2021]

SHARP included patients with alcohol (26%) and unknown/other etiology (25%) in the sorafenib arm. [Llovet 2008]

<xs>CI, confidence interval; HCC, hepatocellular carcinoma; HR, hazard ratio; ICI, immune checkpoint inhibitor; NASH, nonalcoholic steatohepatitis; OS, overall survival; TKI, tyrosine kinase inhibitors; VEGF, vascular endothelial growth factor</xs>`,
      fr: `Une méta-analyse récente visant à évaluer les effets des thérapies moléculaires et des immunothérapies sur les résultats par cause du CHC a montré que la cause n’influait pas sur les résultats et qu’il n’y avait pas de différence entre les traitements par des ITK et des anti-VEGF. [Haber 2021]

Les RRI de la SG étaient de 0,81 (IC à 95 % : 0,71; 0,92) chez les patients atteints d’un CHC de cause virale et de 0,82 (IC à 95 % : 0,67; 1,01) chez les patients atteints de CHC de cause non virale. La différence quant à l’efficacité n’était pas significative sur le plan statistique (p = 0,8828). [Haber 2021]

Le groupe sorafenib de l’étude SHARP comptait des patients atteints d’un CHC causé par l’alcool (26 %) et de cause inconnue/autre (25 %). [Llovet 2008]

<xs>CHC = carcinome hépatocellulaire; IC = intervalle de confiance; ITK = inhibiteur de la tyrosine kinase; RRI = rapport des risques instantanés; VEGF = facteur de croissance de l’endothélium des vaisseaux</xs>`
    }
  },
  Lenvatinib: {
    indicator: 'green',
    text: {
      en: `Recent meta-analysis of impact of molecular and immunotherapies on outcome per HCC etiology showed no impact of etiology on outcome between TKI/anti-VEGF therapies. [Haber 2021]

HRs for OS were 0.81 (95% CI 0.71–0.92) in patients with viral etiologies and 0.82 (95% CI 0.67–1.01) in patients with nonviral etiologies. Difference in efficacy was not statistically significant (P = 0.8828). [Haber 2021]

REFLECT included patients with alcohol (8%) and unknown/other etiology (21%) in the lenvatinib arm. [Kudo 2018]

<xs>CI, confidence interval; HCC, hepatocellular carcinoma; HR, hazard ratio; NASH, nonalcoholic steatohepatitis; ICI, immune checkpoint inhibitor; OS, overall survival; TKI, tyrosine kinase inhibitors; VEGF, vascular endothelial growth factor</xs>`,
      fr: `Une méta-analyse récente visant à évaluer l’impact de l’étiologie du CHC sur l’efficacité des thérapies moléculaires et des immunothérapies a montré que l’étiologie du CHC n’avait aucun impact sur les résultats obtenus avec les ITK/anti-VEGF. [Haber 2021]

Les RRI de la SG étaient de 0,81 (IC à 95 % : 0,71; 0,92) chez les patients atteints d’un CHC de cause virale et de 0,82 (IC à 95 % : 0,67; 1,01) chez les patients atteints d’un CHC de cause non virale. La différence quant à l’efficacité n’était pas significative sur le plan statistique (p = 0,8828). [Haber 2021]

Le groupe lenvatinib de l’étude REFLECT comptait des patients atteints d’un CHC causé par l’alcool (8 %) et de cause inconnue/autre (21 %). [Kudo 2018]

<xs>CHC = carcinome hépatocellulaire; IC = intervalle de confiance; ITK = inhibiteur de la tyrosine kinase; RRI = rapport des risques instantanés; VEGF = facteur de croissance de l’endothélium des vaisseaux</xs>`
    }
  },
  'Atezolizumab + Bevacizumab': {
    indicator: 'green',
    text: {
      en: `A meta-analysis of 3 randomized phase III clinical trials (IMBrave150, CheckMate-459, KEYNOTE-240) in >1,600 patients with advanced HCC showed that, although immunotherapy improved survival in overall population (HR 0.77; 95% CI 0.63–0.94), survival was superior to control in patients with HBV-related HCC (n = 574; P = 0.0008) and HCV-related HCC (n = 345; P = 0.04), but not in patients with non-viral HCC (n = 737; P = 0.39). [Pfister 2021; Finn (IMBrave150) 2020; Yau 2019; Finn (KEYNOTE-240) 2020]

Patients with HCC of viral etiology (HBV or HCV infection) showed a benefit from checkpoint inhibition (HR 0.64; 95% CI 0.48–0.94) whereas patients with HCC of non-viral etiology did not (HR 0.92; 95% CI 0.77–1.11; P of interaction = 0.03). [Pfister 2021]

A meta-analysis of impact of TKI/anti-VEGF and immunotherapies on NASH HCC showed pooled HR for OS in patients with non–viral-related HCC treated with ICIs was 0.92 (95% CI 0.77–1.11). [Haber 2021]

Difference in efficacy between viral- and non–viral-related HCC treated with ICIs was significant (P of heterogeneity = 0.0259). Results were supported by sensitivity analysis. Effect of immune checkpoint inhibitors was similar in hepatitis HBV- and HCV-related HCC (HR 0.64 [95% CI 0.49–0.83] vs HR 0.68 [95% CI 0.47–0.98], respectively). [Haber 2021]

IMBrave150 included patients with non-viral etiology (30%) in the atezolizumab + bevacizumab arm. [Finn 2020]

<xs>CI, confidence interval; HBV, hepatitis B virus; HCC, hepatocellular carcinoma; HCV, hepatitis C virus; HR, hazard ratio; ICI, immune checkpoint inhibitor; NASH, nonalcoholic steatohepatitis; OS, overall survival; TKI, tyrosine kinase inhibitors; VEGF, vascular endothelial growth factor</xs>`,
      fr: `Une méta-analyse de trois essais cliniques de phase III à répartition aléatoire (IMBrave150, CheckMate-459 et KEYNOTE-240) menés chez plus de 1 600 patients atteints d’un CHC avancé a montré que bien que l’immunothérapie ait amélioré le taux de survie dans la population globale (RRI : 0,77; IC à 95 % : 0,63; 0,94), la survie était supérieure à celle des patients atteints de CHC lié à une infection par le VHB (n = 574; p = 0,0008) et de CHC lié à une infection par le VHC (n = 345; p = 0,04), mais pas à celle des patients atteints de CHC de cause non virale (n = 737; p = 0,39). [Pfister 2021; Finn (IMBrave150) 2020; Yau 2019; Finn (KEYNOTE-240) 2020]

Les patients atteints de CHC de cause virale (infection par le VHB ou le VHC) ont bénéficié de l’inhibition des points de contrôle immunitaires  (RRI : 0,64; IC à 95 % : 0,48; 0,94), contrairement aux patients atteints de CHC de cause non virale (RRI : 0,92; IC à 95 % : 0,77; 1,11; p de l’interaction = 0,03). [Pfister 2021] 

Une méta-analyse visant à déterminer si l’étiologie du CHC avait une influence sur l’effet des traitements avec les ITK/anti-VEGF et les immunothérapies a montré que le RRI groupé de la SG chez les patients atteints de CHC de cause non virale ayant reçu des ICI était de 0,92 (IC à 95 % : 0,77; 1,11). [Haber 2021]

La différence quant à l’efficacité entre les patients atteints de CHC de cause virale et les patients atteints de CHC de cause non virale ayant reçu des ICI était significative (p de l’hétérogénéité = 0,0259). Les résultats étaient appuyés par l’analyse de sensibilité. L’effet des ICI était similaire chez les patients atteints de CHC lié à une infection par le VHB ou le VHC (RRI : 0,64 [IC à 95 % : 0,49; 0,83] vs 0,68 [IC à 95 % : 0,47; 0,98], respectivement). [Haber 2021]

Le groupe atézolizumab + bevacizumab de l’étude IMBrave150 comptait des patients atteints de CHC de cause non virale (30 %). [Finn 2020]

<xs>CHC = carcinome hépatocellulaire; IC = intervalle de confiance; ICI = inhibiteurs du point de contrôle immunitaire; ITK = inhibiteur de la tyrosine kinase; RRI = rapport des risques instantanés; SG = survie globale; SHNA = stéatohépatite non alcoolique; VHB = virus de l’hépatite B; VHC = virus de l’hépatite C; VEGF = facteur de croissance de l’endothélium des vaisseaux</xs>`
    }
  },
  'Durvalumab + Tremelimumab': {
    indicator: 'green',
    text: {
      en: `HIMALAYA included patients with non-viral etiology (41.0%) defined as no active viral hepatitis identified in the STRIDE arm. [Abou-Alfa 2022]`,
      fr: `Le groupe STRIDE de l’étude HIMALAYA comptait des patients atteints de CHC de cause non virale (41,0 %), défini comme une absence d’hépatite virale évolutive. [Abou-Alfa 2022]`
    }
  }
}

const AutoImmuneDisease_No = {
  Sorafenib: {
    indicator: 'green',
    text: {
      en: `Patients with active or a history of underlying autoimmune diseases were not excluded from SHARP. [Llovet 2008]`,
      fr: `Les patients atteints d’une maladie auto-immune évolutive ou présentant des antécédents de maladie auto immune n’étaient pas exclus de l’étude SHARP. [NCT00105443; Llovet 2008]`
    }
  },
  Lenvatinib: {
    indicator: 'green',
    text: {
      en: `Patients with active or a history of underlying autoimmune diseases were not excluded from REFLECT. [Kudo 2018]`,
      fr: `Les patients atteints d’une maladie auto-immune évolutive ou présentant des antécédents de maladie auto immune n’étaient pas exclus de l’étude REFLECT. [NCT01761266; Kudo 2018]`
    }
  },
  'Atezolizumab + Bevacizumab': {
    indicator: 'green',
    text: {
      en: `Patients with active or a history of underlying autoimmune diseases were excluded from IMBrave150. [Finn 2020] `,
      fr: `Les patients atteints d’une maladie auto-immune évolutive ou présentant des antécédents de maladie auto immune étaient exclus de l’étude IMBrave150. [Finn 2020; NCT03434379] `
    }
  },
  'Durvalumab + Tremelimumab': {
    indicator: 'green',
    text: {
      en: `Patients with active or prior documented autoimmune or inflammatory disorders (including inflammatory bowel disease (eg, colitis or Crohn's disease), diverticulitis, systemic lupus erythematosus, Sarcoidosis syndrome, or Wegener syndrome (granulomatosis with polyangiitis, Graves' disease, rheumatoid arthritis, hypophysitis, uveitis etc.) were excluded from HIMALAYA. [Abou-Alfa protocol 2022]`,
      fr: `Les patients ayant un trouble auto-immun ou inflammatoire évolutif ou ayant des antécédents documentés de trouble auto-immun ou inflammatoire (comprenant une maladie intestinale inflammatoire [p. ex. colite ou maladie de Crohn], une diverticulite, un lupus érythémateux disséminé, un syndrome de sarcoïdose ou un syndrome de Wegener [granulomatose avec polyangéite, maladie de Basedow, polyarthrite rhumatoïde, hypophysite, uvéite etc.]) étaient exclus de l’étude HIMALAYA. [Abou-Alfa protocol 2022]`
    }
  }
}

const AutoImmuneDisease_NoSuppresion = {
  Sorafenib: {
    indicator: 'green',
    text: {
      en: `Patients with active or a history of underlying autoimmune diseases were not excluded from SHARP. [Llovet 2008]`,
      fr: `Les patients atteints d’une maladie auto-immune évolutive ou présentant des antécédents de maladie auto immune n’étaient pas exclus de l’étude SHARP. [NCT00105443; Llovet 2008]`
    }
  },
  Lenvatinib: {
    indicator: 'green',
    text: {
      en: `Patients with active or a history of underlying autoimmune diseases were not excluded from REFLECT. [Kudo 2019]`,
      fr: `Les patients atteints d’une maladie auto-immune évolutive ou présentant des antécédents de maladie auto immune n’étaient pas exclus de l’étude REFLECT. [NCT01761266; Kudo 2019]`
    }
  },
  'Atezolizumab + Bevacizumab': {
    indicator: 'yellow',
    text: {
      en: `Clinical trials using ICIs have largely excluded patients with pre-existing autoimmune diseases due to concerns about increased toxicity and disease flares in patients with active autoimmune diseases. There are also concerns that ICIs will have less efficacy in these patients due to baseline immunosuppression. [Kennedy 2019; Pinter 2020]

A prospective study of the REISAMIC registry found significantly increased risk of irAEs in patients with autoimmune diseases. [Danlos 2018]

A systematic review of 49 studies with a total of 123 patients reported an exacerbation and/or irAE rate of 75%. [Abdel-Wahab 2018]

Emerging evidence from small retrospective case series shows that ICIs may be reasonably safe in some patients with autoimmune disease. Patients in these case series had a wide variety of autoimmune disorders, making it difficult to draw conclusions about safety regarding a specific disorder. [Tison 2018; Leonardi 2018; Menzies 2017; Danlos 2018; Johnson 2016; Gutzmer 2017; Richter 2018; Lee 2016.]

IMBrave150 excluded patients with active or a history of autoimmune disease or immune deficiency, including, but not limited to, myasthenia gravis, myositis, autoimmune hepatitis, systemic lupus erythematosus, rheumatoid arthritis, inflammatory bowel disease, antiphospholipid antibody syndrome, Wegener granulomatosis, Sjögren syndrome, Guillain-Barré syndrome, or multiple sclerosis. [Finn Suppl 2020]

Patients with eczema, psoriasis, lichen simplex chronicus, or vitiligo with dermatologic manifestations <u>only</u> (rash covering <10% of body surface area), and whose disease was well controlled at baseline, and required only low-potency topical corticosteroids were eligible. However, patients with acute exacerbations of the underlying condition, requiring psoralen plus ultraviolet A radiation, methotrexate, retinoids, biologic agents, oral calcineurin inhibitors, or high-potency or oral corticosteroids within the previous 12 months, were excluded. [Finn Suppl 2020]

<b>Depending on disease and medication used, consult with appropriate autoimmune sub-specialist before initiating therapy with ICIs.</b> [Kennedy 2019]

<b>Risks and benefits of treatment with ICIs should be considered for each patient, along with alternative treatment options and patient preferences.</b> [Kennedy 2019]

<b>Treatment with ICIs may be administered with informed patient consent.</b> [Kennedy 2019]

<xs>ICI, immune checkpoint inhibitor; irAE, immune-related adverse event</xs>`,
      fr: `Les patients atteints de maladie auto-immune préexistante sont généralement exclus des essais cliniques sur les ICI en raison d’inquiétudes concernant la toxicité accrue et les poussées de la maladie auto-immune chez ces patients. L’efficacité réduite des ICI liée à l’immunosuppression initiale soulève également des inquiétudes. [Kennedy 2019; Pinter 2 020]

D’après une analyse prospective du registre REISAMIC, le risque d’EII est significativement accru chez les patients atteints de maladie auto-immune. [Danlos 2018]

D’après une revue systématique des 49 études menées auprès d’un total de 123 patients, le taux d’exacerbation et/ou d’EII était de 75 %. [Abdel-Wahab 2018]

D’après de nouvelles données provenant de séries de cas de petite envergure, les ICI sont relativement sûrs chez certains patients atteints de maladie auto-immune. Les patients de ces séries de cas étant atteints de diverses maladies auto-immunes, il était difficile de tirer des conclusions au sujet de l’innocuité pour une maladie en particulier. [Tison 2018; Leonardi 2018; Menzies 2017; Danlos 2018; Johnson 2016; Gutzmer 2017; Richter 2018; Lee 2016.]

Étaient exclus de l’étude IMBrave150 les patients atteints de maladie auto-immune évolutive ou présentant des antécédents de maladie auto-immune ou d’immunodéficience, y compris myasthénie grave, myosite, hépatite auto-immune, lupus érythémateux disséminé, polyarthrite rhumatoïde, maladie intestinale inflammatoire, syndrome des antiphospholipides, granulomatose de Wegener, syndrome de Sjögren, syndrome de Guillain-Barré ou sclérose en plaques. [Finn Suppl 2020]

Les patients qui étaient atteints <u>seulement</u> d’eczéma, de psoriasis, de lichen simplex chronique ou de vitiligo accompagné de manifestations dermatologiques (éruptions cutanées couvrant < 10 % de la surface corporelle), dont la maladie était bien maîtrisée au début de l’étude et qui nécessitaient seulement l’utilisation de corticostéroïdes topiques peu puissants étaient admissibles. Les patients présentant des exacerbations aiguës de la maladie sous-jacente qui devaient recevoir du psoralène et des rayonnements ultraviolets, du méthotrexate, des rétinoïdes, des agents biologiques, des inhibiteurs de la calcineurine oraux ou des corticostéroïdes très puissants ou oraux dans les 12 mois précédents étaient toutefois exclus. [Finn Suppl 2020]

<b>Selon la maladie et les médicaments utilisés, il convient de consulter le sous-spécialiste de la maladie auto immune approprié avant d’entamer le traitement par des ICI. [Kennedy 2019]</b>

<b>Il faut tenir compte des risques et des bienfaits du traitement par les ICI pour chaque patient, ainsi que d'autres options thérapeutiques possibles et des préférences du patient. [Kennedy 2019]</b>

<b>Le traitement par des ICI peut être instauré si le patient donne son consentement éclairé. [Kennedy 2019]</b>

<xs>ICI = inhibiteurs du point de contrôle immunitaire; EII = effets indésirables d’origine immunitaire</xs>`
    }
  },
  'Durvalumab + Tremelimumab': {
    indicator: 'yellow',
    text: {
      en: `HIMALAYA excluded patients with active or prior documented autoimmune or inflammatory disorders (including inflammatory bowel disease (eg, colitis or Crohn's disease), diverticulitis, systemic lupus erythematosus, Sarcoidosis syndrome, or Wegener syndrome (granulomatosis with polyangiitis, Graves' disease, rheumatoid arthritis, hypophysitis, uveitis etc.). [Abou-Alfa protocol 2022]

Patients with vitiligo or alopecia, hypothyroidism (e.g., following Hashimoto syndrome) stable on hormone replacement, patients with any chronic skin condition that does not require systemic therapy, and patients with celiac disease controlled by diet alone were included in HIMALAYA as exceptions to the exclusion criteria. [Abou-Alfa protocol 2022]`,
      fr: `Étaient exclus de l’étude HIMALAYA les patients ayant un trouble auto-immun ou inflammatoire évolutif ou ayant des antécédents documentés de trouble auto-immun ou inflammatoire (comprenant une maladie intestinale inflammatoire [p. ex. colite ou maladie de Crohn], une diverticulite, un lupus érythémateux disséminé, un syndrome de sarcoïdose ou un syndrome de Wegener [granulomatose avec polyangéite, maladie de Basedow, polyarthrite rhumatoïde, hypophysite, uvéite, etc.]). [Abou-Alfa protocol 2022]

L’étude HIMALAYA comptait des patients atteints de vitiligo ou d’alopécie, d’hypothyroïdie (p. ex. secondaire à un syndrome de Hashimoto) dont l’état était stable avec une hormonothérapie substitutive, des patients atteints de toute affection cutanée chronique ne nécessitant pas de traitement à action générale et des patients atteints d’une maladie cœliaque maîtrisée par le régime alimentaire seul, comme exceptions aux critères d’exclusion. [Abou-Alfa protocol 2022]`
    }
  }
}

const AutoImmuneDisease_Neurologic = {
  Sorafenib: {
    indicator: 'green',
    text: {
      en: `Patients with active or a history of underlying autoimmune diseases were not excluded from SHARP. [Llovet 2008]`,
      fr: `Les patients atteints d’une maladie auto-immune évolutive ou présentant des antécédents de maladie auto immune n’étaient pas exclus de l’étude SHARP. [NCT00105443; Llovet 2008]`
    }
  },
  Lenvatinib: {
    indicator: 'green',
    text: {
      en: `Patients with active or a history of underlying autoimmune diseases were not excluded from REFLECT. [Kudo 2018]`,
      fr: `Les patients atteints d’une maladie auto-immune évolutive ou présentant des antécédents de maladie auto immune n’étaient pas exclus de l’étude REFLECT. [NCT01761266; Kudo 2018]`
    }
  },
  'Atezolizumab + Bevacizumab': {
    indicator: 'red',
    text: {
      en: `Clinical trials using ICIs have largely excluded patients with pre-existing autoimmune diseases due to concerns about increased toxicity and disease flares in patients with active autoimmune diseases. There are also concerns that ICIs will have less efficacy in these patients due to baseline immunosuppression. [Kennedy 2019; Pinter 2020]

A prospective study of the REISAMIC registry found significantly increased risk of irAEs in patients with autoimmune diseases. [Danlos 2018]

A systematic review of 49 studies with total 123 patients reported an exacerbation and/or irAE rate of 75%. [Abdel-Wahab 2018]

Emerging evidence from small retrospective case series shows that ICIs may be reasonably safe in some patients with autoimmune disease. Patients in these case series had a wide variety of autoimmune disorders, making it difficult to draw conclusions about safety regarding a specific disorder. [Tison 2018; Leonardi 2018; Menzies 2017; Danlos 2018; Johnson 2016; Gutzmer 2017; Richter 2018; Lee 2016.]

IMBrave150 excluded patients with active or a history of autoimmune disease or immune deficiency, including, but not limited to, myasthenia gravis, myositis, autoimmune hepatitis, systemic lupus erythematosus, rheumatoid arthritis, inflammatory bowel disease, antiphospholipid antibody syndrome, Wegener granulomatosis, Sjögren syndrome, Guillain-Barré syndrome, or multiple sclerosis. [Finn 2020]

<b>Patients with neurologic or neuromuscular autoimmune disease, or potentially life-threatening autoimmune disease, such as such as myasthenia gravis, should <u>not be considered</u> for treatment with ICIs.</b> [Kennedy 2019]

<xs>ICI, immune checkpoint inhibitor; irAE, immune-related adverse event</xs>`,
      fr: `Les patients atteints de maladie auto-immune préexistante sont généralement exclus des essais cliniques sur les ICI en raison d’inquiétudes concernant la toxicité accrue et les poussées de la maladie auto-immune chez ces patients. L’efficacité réduite des ICI liée à l’immunosuppression initiale soulève également des inquiétudes. [Kennedy 2019; Pinter 2 020]

D’après une analyse prospective du registre REISAMIC, le risque d’EII est significativement accru chez les patients atteints de maladie auto-immune. [Danlos 2018]

D’après une revue systématique des 49 études menées auprès d’un total de 123 patients, le taux d’exacerbation et/ou d’EII était de 75 %. [Abdel-Wahab 2018]

D’après de nouvelles données provenant de séries de cas de petite envergure, les ICI sont relativement sûrs chez les certains patients atteints de maladie auto-immune. Les patients de ces séries de cas étant atteints de diverses maladies auto-immunes, il était difficile de tirer des conclusions au sujet de l’innocuité pour une maladie en particulier. [Tison 2018; Leonardi 2018; Menzies 2017; Danlos 2018; Johnson 2016; Gutzmer 2017; Richter 2018; Lee 2016.]

Étaient exclus de l’étude IMBrave150 les patients atteints de maladie auto-immune évolutive ou présentant des antécédents de maladie auto-immune ou d’immunodéficience, y compris myasthénie grave, myosite, hépatite auto-immune, lupus érythémateux disséminé, polyarthrite rhumatoïde, maladie intestinale inflammatoire, syndrome des antiphospholipides, granulomatose de Wegener, syndrome de Sjögren, syndrome de Guillain-Barré ou sclérose en plaques. [Finn 2020]

<b>Le traitement par des ICI <u>ne devrait pas être envisagé</u> chez les patients atteints de maladie auto immune neurologique ou neuromusculaire ou de maladie auto-immune potentiellement mortelle, comme la myasthénie grave. [Kennedy 2019]</b>

<xs>ICI = inhibiteurs du point de contrôle immunitaire; EII = effets indésirables d’origine immunitaire</xs>`
    }
  },
  'Durvalumab + Tremelimumab': {
    indicator: 'red',
    text: {
      en: `HIMALAYA excluded patients with active or prior documented autoimmune or inflammatory disorders (including inflammatory bowel disease (eg, colitis or Crohn's disease), diverticulitis, systemic lupus erythematosus, Sarcoidosis syndrome, or Wegener syndrome (granulomatosis with polyangiitis, Graves' disease, rheumatoid arthritis, hypophysitis, uveitis etc.). [Abou-Alfa protocol 2022]

Patients with vitiligo or alopecia, hypothyroidism (e.g., following Hashimoto syndrome) stable on hormone replacement, patients with any chronic skin condition that does not require systemic therapy, and patients with celiac disease controlled by diet alone were included in HIMALAYA as exceptions to the exclusion criteria. [Abou-Alfa protocol 2022]`,
      fr: `Étaient exclus de l’étude HIMALAYA les patients ayant un trouble auto-immun ou inflammatoire évolutif ou ayant des antécédents documentés de trouble auto-immun ou inflammatoire (comprenant une maladie intestinale inflammatoire [p. ex. colite ou maladie de Crohn], une diverticulite, un lupus érythémateux disséminé, un syndrome de sarcoïdose ou un syndrome de Wegener [granulomatose avec polyangéite, maladie de Basedow, polyarthrite rhumatoïde, hypophysite, uvéite, etc.]). [Abou-Alfa protocol 2022]

L’étude HIMALAYA comptait des patients atteints de vitiligo ou d’alopécie, d’hypothyroïdie (p. ex. secondaire à un syndrome de Hashimoto) dont l’état était stable avec une hormonothérapie substitutive, des patients atteints de toute affection cutanée chronique ne nécessitant pas de traitement à action générale et des patients atteints d’une maladie cœliaque maîtrisée par le régime alimentaire seul, comme exceptions aux critères d’exclusion. [Abou-Alfa protocol 2022]`
    }
  }
}

const AutoImmuneDisease_PoorControl = {
  Sorafenib: {
    indicator: 'green',
    text: {
      en: `Patients with active or a history of underlying autoimmune diseases were not excluded from SHARP. [Llovet 2008]`,
      fr: `Les patients atteints d’une maladie auto-immune évolutive ou présentant des antécédents de maladie auto immune n’étaient pas exclus de l’étude SHARP. [NCT00105443; Llovet 2008]`
    }
  },
  Lenvatinib: {
    indicator: 'green',
    text: {
      en: `Patients with active or a history of underlying autoimmune diseases were not excluded from REFLECT. [Kudo 2018]`,
      fr: `Les patients atteints d’une maladie auto-immune évolutive ou présentant des antécédents de maladie auto immune n’étaient pas exclus de l’étude REFLECT. [NCT01761266; Kudo 2018]`
    }
  },
  'Atezolizumab + Bevacizumab': {
    indicator: 'red',
    text: {
      en: `Clinical trials using ICIs have largely excluded patients with pre-existing autoimmune diseases due to concerns about increased toxicity and disease flares in patients with active autoimmune diseases. There are also concerns that ICIs will have less efficacy in these patients due to baseline immunosuppression. [Kennedy 2019; Pinter 2020]

A prospective study of the REISAMIC registry found a significantly increased risk of irAEs in patients with autoimmune diseases. [Danlos 2018]

A systematic review of 49 studies with total 123 patients reported an exacerbation and/or irAE rate of 75%. [Abdel-Wahab 2018]

Emerging evidence from small retrospective case series shows that ICIs may be reasonably safe in some patients with autoimmune disease. Patients in these case series had a wide variety of autoimmune disorders, making it difficult to draw conclusions about safety regarding a specific disorder. [Tison 2018; Leonardi 2018; Menzies 2017; Danlos 2018; Johnson 2016; Gutzmer 2017; Richter 2018; Lee 2016.]

IMBrave150 excluded patients with active or a history of autoimmune disease or immune deficiency, including, but not limited to, myasthenia gravis, myositis, autoimmune hepatitis, systemic lupus erythematosus, rheumatoid arthritis, inflammatory bowel disease, antiphospholipid antibody syndrome, Wegener granulomatosis, Sjögren syndrome, Guillain-Barré syndrome, or multiple sclerosis. [Finn Suppl 2020]

Patients with eczema, psoriasis, lichen simplex chronicus, or vitiligo with dermatologic manifestations <u>only</u> (rash covering <10% of body surface area), and whose disease was well controlled at baseline, and required only low-potency topical corticosteroids were eligible for the trial. However, patients with acute exacerbations of the underlying condition, requiring psoralen plus ultraviolet A radiation, methotrexate, retinoids, biologic agents, oral calcineurin inhibitors, or high-potency or oral corticosteroids within the previous 12 months, were excluded. [Finn Suppl 2020]

<b>Avoid treatment with ICIs in patients with poor disease control or patients requiring high doses of immunosuppressant for control.</b> [Kennedy 2019]

<b>Risks and benefits of treatment with ICIs should be considered for each patient, along with alternative treatment options and patient preferences.</b> [Kennedy 2019]

<xs>ICI, immune checkpoint inhibitor; irAE, immune-related adverse event</xs>`,
      fr: `Les patients atteints de maladie auto-immune préexistante sont généralement exclus des essais cliniques sur les ICI en raison d’inquiétudes concernant la toxicité accrue et les poussées de la maladie auto-immune chez ces patients. L’efficacité réduite des ICI liée à l’immunosuppression initiale soulève également des inquiétudes. [Kennedy 2019; Pinter 2 020]

D’après une analyse prospective du registre REISAMIC, le risque d’EII est significativement accru chez les patients atteints de maladie auto-immune. [Danlos 2018]

D’après une revue systématique des 49 études menées auprès d’un total de 123 patients, le taux d’exacerbation et/ou d’EII était de 75 %. [Abdel-Wahab 2018]

D’après de nouvelles données provenant de séries de cas de petite envergure, les ICI sont relativement sûrs chez les certains patients atteints de maladie auto-immune. Les patients de ces séries de cas étant atteints de diverses maladies auto-immunes, il était difficile de tirer des conclusions au sujet de l’innocuité pour une maladie en particulier. [Tison 2018; Leonardi 2018; Menzies 2017; Danlos 2018; Johnson 2016; Gutzmer 2017; Richter 2018; Lee 2016.]

Étaient exclus de l’étude IMBrave150 les patients atteints de maladie auto-immune évolutive ou présentant des antécédents de maladie auto-immune ou d’immunodéficience, y compris myasthénie grave, myosite, hépatite auto-immune, lupus érythémateux disséminé, polyarthrite rhumatoïde, maladie intestinale inflammatoire, syndrome des antiphospholipides, granulomatose de Wegener, syndrome de Sjögren, syndrome de Guillain-Barré ou sclérose en plaques. [ Finn Suppl 2020]

Les patients qui étaient atteints <u>seulement</u> d’eczéma, de psoriasis, de lichen simplex chronique ou de vitiligo accompagné de manifestations dermatologiques (éruptions cutanées couvrant < 10 % de la surface corporelle), dont la maladie était bien maîtrisée au début de l’étude et qui nécessitaient seulement l’utilisation de corticostéroïdes topiques peu puissants étaient admissibles. Les patients présentant des exacerbations aiguës de la maladie sous-jacente qui devaient recevoir du psoralène et des rayonnements ultraviolets, du méthotrexate, des rétinoïdes, des agents biologiques, des inhibiteurs de la calcineurine oraux ou des corticostéroïdes très puissants ou oraux dans les 12 mois précédents étaient toutefois exclus. [ Finn Suppl 2020]

<b>Il faut éviter le traitement par des ICI chez les patients dont la maladie est mal maîtrisée ou requiert l’administration de doses élevées d’immunosuppresseurs pour être bien maîtrisée. [Kennedy 2019]</b>

<b>Il faut tenir compte des risques et des bienfaits du traitement par les ICI pour chaque patient, ainsi que d'autres options thérapeutiques possibles et des préférences du patient. [Kennedy 2019]</b>

<xs>ICI = inhibiteurs du point de contrôle immunitaire; EII = effets indésirables d’origine immunitaire</xs>`
    }
  },
  'Durvalumab + Tremelimumab': {
    indicator: 'red',
    text: {
      en: `HIMALAYA excluded patients with active or prior documented autoimmune or inflammatory disorders (including inflammatory bowel disease (eg, colitis or Crohn's disease), diverticulitis, systemic lupus erythematosus, Sarcoidosis syndrome, or Wegener syndrome (granulomatosis with polyangiitis, Graves' disease, rheumatoid arthritis, hypophysitis, uveitis etc.). [Abou-Alfa protocol 2022]

Patients with vitiligo or alopecia, hypothyroidism (e.g., following Hashimoto syndrome) stable on hormone replacement, patients with any chronic skin condition that does not require systemic therapy, and patients with celiac disease controlled by diet alone were included in HIMALAYA as exceptions to the exclusion criteria. [Abou-Alfa protocol 2022]`,
      fr: `Étaient exclus de l’étude HIMALAYA les patients ayant un trouble auto-immun ou inflammatoire évolutif ou ayant des antécédents documentés de trouble auto-immun ou inflammatoire (comprenant une maladie intestinale inflammatoire [p. ex. colite ou maladie de Crohn), une diverticulite, un lupus érythémateux disséminé, un syndrome de sarcoïdose ou un syndrome de Wegener [granulomatose avec polyangéite, maladie de Basedow, polyarthrite rhumatoïde, hypophysite, uvéite, etc.]). [Abou-Alfa protocol 2022]

L’étude HIMALAYA comptait des patients atteints de vitiligo ou d’alopécie, d’hypothyroïdie (p. ex. secondaire à un syndrome de Hashimoto) dont l’état était stable avec une hormonothérapie substitutive, des patients atteints de toute affection cutanée chronique ne nécessitant pas de traitement à action générale et des patients atteints d’une maladie cœliaque maîtrisée par le régime alimentaire seul, comme exceptions aux critères d’exclusion. [Abou-Alfa protocol 2022]`
    }
  }
}

const LiverTransplantYes = {
  Sorafenib: {
    indicator: 'yellow',
    text: {
      en: `Approximately 8% to 21% of patients who receive a liver transplant for early-stage HCC experience HCC recurrence. High-level evidence supporting systemic therapies in these patients is limited. [Mazzaferro 1996; Mazzaferro 2018]

No exclusion criteria for prior or planned liver transplant in SHARP. [Llovet 2008]

Data from a retrospective case control study (n=39) showed that sorafenib (n=5) is safe and effective in patients with recurrent HCC after liver transplantation (median survival from recurrence 21.3 vs.11.8 months, HR = 5.2, p = 0.0009). [Sposito 2013]

<xs>HCC, hepatocellular carcinoma; HR, hazard ratio</xs>
`,
      fr: `Le CHC réapparaît chez 8 % à 21 % des patients atteints de CHC de stade précoce ayant reçu une greffe de foie. Les données de haut niveau étayant les traitements à action générale chez ces patients sont limitées. [Mazzaferro 1996; Mazzaferro 2018]

Il n’y avait pas de critères excluant les patients ayant une greffe de foie antérieure ou prévue dans l’étude SHARP. [Llovet 2008; NCT00105443]

D’après les données d’une étude rétrospective de cas-témoins (N = 39), l’administration du sorafenib (n = 5) est sûre et efficace chez les patients dont le CHC réapparaît après une greffe de foie (survie médiane après la réapparition : 21,3 vs 11,8 mois; RRI = 5,2; p = 0,0009). [Sposito 2013]

<xs>CHC = carcinome hépatocellulaire; RRI = rapport des risques instantanés</xs>`
    }
  },
  Lenvatinib: {
    indicator: 'yellow',
    text: {
      en: `Approximately 8% to 21% of patients who receive a liver transplant for early-stage HCC experience HCC recurrence. High-level evidence supporting systemic therapies in these patients is limited. [Mazzaferro 1996; Mazzaferro 2018]

Patients with prior liver transplant were excluded from REFLECT. The efficacy and safety of lenvatinib in this setting is evolving. [Kudo 2018]

<xs>HCC, hepatocellular carcinoma</xs>`,
      fr: `Le CHC réapparaît chez 8 % à 21 % des patients atteints de CHC de stade précoce ayant reçu une greffe de foie. Les données de haut niveau étayant les traitements à action générale chez ces patients sont limitées. [Mazzaferro 1996; Mazzaferro 2018]

Les patients ayant reçu une greffe de foie étaient exclus de l’étude REFLECT. L’efficacité et l’innocuité du lenvatinib dans ce contexte sont en évolution. [Kudo 2018; NCT01761266]

<xs>CHC = carcinome hépatocellulaire</xs>`
    }
  },
  'Atezolizumab + Bevacizumab': {
    indicator: 'red',
    text: {
      en: `Although most Phase III clinical trials excluded patients with prior solid organ transplantation, consideration must be given to use of ICIs in patients with prior or planned solid organ transplantation, as immune checkpoint molecules, particularly PD-L1, play a role in establishing graft tolerance. [Pinter 2021]

Boosting immune system with ICIs may lead to organ rejection, while concomitant immunosuppressive therapy may inhibit efficacy of immune checkpoint inhibitors. [Pinter 2021]

Systematic review of 83 published cases of patients with varying cancers – melanoma (55%), HCC (14%) and skin squamous cell carcinoma (12%) and solid organ transplant. Patients had history of kidney transplantation (64%), liver (29%) and heart transplantation (7%). 74% received anti-PD-L1 antibodies, 16% had anti-CTLA-4 treatment and 11% were treated with a combination. [d’Izarny-Gargas 2020]

<li>Mean time from transplantation to initiation of immunotherapy was shorter in liver recipients (5.6 years) than patients with prior kidney (10.8 years) and heart (12.2 years) transplantation.</li>
<li>Allograft rejection occurred in 40% of patients (kidney, 43%; liver, 38%; heart, 17%) after a mean time of 5.6 weeks.</li>
<li>Concomitant immunosuppressive had no impact on risk of rejection.</li>
<li>Factors independently associated with lower risk of rejection included ≥1 concomitant immunosuppressant other than steroids as well as time since transplantation ≥8 years. History of prior rejection was associated with increased risk of rejection.</li>
<li>Only a few patients showed complete (6%) or partial (23%) recovery with salvage immunosuppressive therapy (mostly methylprednisolone), but majority (71%) developed end-stage organ failure (75% in liver recipients).</li>
<li>Out of 48 patients with a reported cause of death, 19% died from organ rejection.</li>
<li>Overall, 28% of patients showed a response to ICI treatment, and 19% of cases were still alive without signs of rejection and tumour progression.</li>

Patients who had prior allogeneic stem cell or solid organ transplantation were excluded from IMBrave150. [Finn Suppl 2020]

<xs>CTLA-4, cytotoxic T-lymphocyte–associated antigen 4; HCC, hepatocellular carcinoma; ICI, immune checkpoint inhibitor; PD-L1, programmed death-ligand</xs>`,
      fr: `Même si les patients ayant des antécédents de greffe d’organe plein étaient exclus de la plupart des essais cliniques de phase III, il faut envisager l’utilisation d’ICI chez les patients ayant une greffe d’organe plein antérieure ou prévue, car les molécules agissant comme point de contrôle immunitaire, en particulier le PD-L1, jouent un rôle dans la tolérance à la greffe. [Pinter 2 021]

La stimulation du système immunitaire par l’administration d’ICI peut provoquer le rejet de l’organe, mais l’administration concomitante d’agents immunosuppresseurs peut nuire à leur efficacité. [Pinter 2 021]

Une revue systématique de 83 cas publiés a été menée chez des patients atteints de divers types de cancers (mélanome 55 %, CHC 14 % et carcinome épidermoïde de la peau 12 %) et ayant reçu une greffe d’organe solide. Les patients avaient des antécédents de greffe de rein (64 %), de foie (29 %) et de cœur (7 %). Dans l’ensemble, 74 % des patients ont reçu des anticorps anti-PD-L1, 16 % un traitement anti-CTLA-4 et 11 % un traitement d’association. [d’Izarny-Gargas 2020]

<li>Le délai moyen entre la greffe et l’instauration de l’immunothérapie était plus court chez les patients recevant une greffe de foie (5,6 ans) que chez les patients recevant une greffe de reins (10,8 ans) et de cœur (12,2 ans).</li>
<li>Un rejet d’allogreffe est survenu chez 40 % des patients (rein : 43 %; foie : 38 %; cœur : 17 %) après une durée moyenne de 5,6 semaines.</li>
<li>L’administration concomitante d’immunosuppresseurs n’avait aucune incidence sur le risque de rejet.</li>
<li>Les facteurs associés de façon indépendante à une réduction du risque de rejet comprenaient l’administration concomitante de ≥ 1 immunosuppresseurs autres que les stéroïdes et un délai depuis la greffe ≥ 8 ans. Les antécédents de rejet étaient associés à une augmentation du risque de rejet.</li>
<li>Seuls quelques patients ont montré des signes de rémission complète (6 %) ou partielle (23 %) après un traitement de sauvetage immunosuppresseur (principalement par la méthylprednisolone). La majorité des patients (71 %) ont présenté une insuffisance organique terminale (75 % chez les patients ayant reçu une greffe de foie).</li>
<li>Sur les 48 patients dont la cause de décès a été déclarée, 19 % sont décédés d’un rejet d’organe.</li>
<li>Dans l’ensemble, 28 % des patients ont répondu au traitement par les ICI, et 19 % d’entre eux étaient encore en vie et ne présentaient pas de signes de rejet ou d’évolution de la tumeur.</li>

Les patients ayant déjà reçu une greffe de cellules souches allogénique étaient exclus de l’étude IMBrave150. [Finn Suppl 2020]

<xs>CHC = carcinome hépatocellulaire; CTLA-4 = antigène humain CTLA-4; ICI = inhibiteurs du point de contrôle immunitaire; PD-L1 = voie du ligand 1 de mort programmée</xs>`
    }
  },
  'Durvalumab + Tremelimumab': {
    indicator: 'red',
    text: {
      en: `HIMALAYA did not include patients with prior allogenic organ transplantation (e.g., liver transplant). [Abou-Alfa Protocol 2022]`,
      fr: 'L’étude HIMALAYA ne comptait aucun patient ayant des antécédents de greffe d’organe allogénique (p. ex. greffe de foie). [Abou-Alfa Protocol 2022]'
    }
  }
}

const LiverTransplantNo = {
  Sorafenib: {
    indicator: 'green',
    text: {
      en: `Unspecified whether patients with prior transplant were excluded from SHARP. [Llovet 2008]`,
      fr: `On ignore si les patients ayant des antécédents de greffe étaient exclus de l’étude SHARP. [Llovet 2008]  `
    }
  },
  Lenvatinib: {
    indicator: 'green',
    text: {
      en: `REFLECT did not include patients with prior transplant. [Kudo 2018]`,
      fr: `Il n’y avait pas de patients ayant des antécédents de greffe dans l’étude REFLECT. [NCT01761266]`
    }
  },
  'Atezolizumab + Bevacizumab': {
    indicator: 'green',
    text: {
      en: `IMBrave150 did not include patients with prior transplant. [Finn Suppl 2020]`,
      fr: `Il n’y avait pas de patients ayant des antécédents de greffe dans l’étude IMBrave150. [Finn Suppl 2020]`
    }
  },
  'Durvalumab + Tremelimumab': {
    indicator: 'green',
    text: {
      en: `HIMALAYA did not include patients with prior allogenic organ transplantation (e.g., liver transplant). [Abou-Alfa Protocol 2022]`,
      fr: `L’étude HIMALAYA ne comptait aucun patient ayant des antécédents de greffe d’organe allogénique (p. ex. greffe de foie). [Abou-Alfa Protocol 2022]`
    }
  }
}

const VaricesYes = {
  Sorafenib: {
    indicator: 'green',
    text: {
      en: `
Variceal bleeding is life-threatening in patients with cirrhosis. [Congly 2020] Gastroesophageal varices may occur in up to 40% of patients with compensated cirrhosis and 70% of patients with decompensated cirrhosis. [Kovalak 2007] Evaluation and intervention to prevent variceal bleeding are critical for patients with cirrhosis. [Congly 2020] The American Association for the Study of Liver Diseases (AASLD) recommends a screening endoscopy in high-risk patients.* [Garcia-Tsao 2017]

No requirement for EGD of varices within the Canadian Product Monograph. [NEXAVAR® Product Monograph 2014]

SHARP excluded patients with clinically significant gastrointestinal bleeding within 30 days prior to study entry. [ClinicalTrials.gov]

*Patients with an LS <20 kPa and platelet count >150,000/mm³ have a very low probability (<5%) of having high-risk varices, and EDG can be circumvented. In patients who do not meet these criteria, screening endoscopy for diagnosis of gastroesophageal varices is recommended when the diagnosis of cirrhosis is made. [Garcia-Tsao 2017]

<xs>EGD, esophagogastroduodenoscopy; kPa, kilopascals; LS, liver stiffness</xs>
`,
      fr: `L’hémorragie des varices est potentiellement mortelle chez les patients atteints de cirrhose. [Congly 2020] Des varices gastro-œsophagiennes peuvent survenir chez jusqu’à 40 % des patients atteints de cirrhose compensée et 70 % des patients atteints de cirrhose décompensée. [Kovalak 2007] Une évaluation et une intervention visant à prévenir l’hémorragie des varices sont essentielles chez les patients atteints de cirrhose. [Congly 2020] La American Association for the Study of Liver Diseases (AASLD) recommande une endoscopie de dépistage chez les patients à risque élevés*. [Garcia-Tsao 2017]

L’EGD des varices n’est pas requise d’après la monographie de produit canadienne. [NEXAVAR® Product Monograph 2014]

Étaient exclus de l’étude SHARP les patients ayant présenté une hémorragie gastro-intestinale cliniquement importante dans les 30 jours précédant le début de cette étude. [ClinicalTrials.gov]

<xs>* Les risques de varices comportant un risque élevé d’hémorragie sont très faibles (< 5 %) chez les patients présentant une RH < 20 kPa et une numération plaquettaire > 150 000/mm³. L’EGD peut donc être évitée. Chez les patients qui ne répondent pas à ces critères, une endoscopie de dépistage est recommandée pour diagnostiquer les varices gastro-œsophagiennes chez les patients atteints de cirrhose. [Garcia-Tsao 2017]</xs>

<xs>EGD = œsophagogastroduodénoscopie; kPa = kilopascal; RH = raideur hépatique</xs>`
    }
  },
  Lenvatinib: {
    indicator: 'green',
    text: {
      en: `
Variceal bleeding is life-threatening in patients with cirrhosis. [Congly 2020]. Gastroesophageal varices may occur in up to 40% of patients with compensated cirrhosis and 70% of patients with decompensated cirrhosis [Kovalak 2007]. Evaluation and intervention to prevent variceal bleeding are critical for patients with cirrhosis. [Congly 2020] The American Association for the Study of Liver Diseases (AASLD) recommends a screening endoscopy in high-risk patients.* [Garcia-Tsao 2017]

No requirement for EGD of varices within the Canadian Product Monograph. [Lenvima® Product Monograph 2019]

REFLECT excluded patients with a gastrointestinal bleeding event within 28 days prior to randomization. Patients with gastric or esophageal varices that required interventional treatment within 28 days prior to randomization were also excluded. [ClinicalTrials.gov]

*Patients with an LS <20 kPa and platelet count >150,000/mm³ have a very low probability (<5%) of having high-risk varices, and EDG can be circumvented. In patients who do not meet these criteria, screening endoscopy for diagnosis of gastroesophageal varices is recommended when the diagnosis of cirrhosis is made. [Garcia-Tsao 2017]

<xs>EGD, esophagogastroduodenoscopy; kPa, kilopascals; LS, liver stiffness</xs>
`,
      fr: `L’hémorragie des varices est potentiellement mortelle chez les patients atteints de cirrhose. [Congly 2020] Des varices gastro-œsophagiennes peuvent survenir chez jusqu’à 40 % des patients atteints de cirrhose compensée et 70 % des patients atteints de cirrhose décompensée. [Kovalak 2007] Une évaluation et une intervention visant à prévenir l’hémorragie des varices sont essentielles chez les patients atteints de cirrhose. [Congly 2020] La American Association for the Study of Liver Diseases (AASLD) recommande une endoscopie de dépistage chez les patients à risque élevés*. [Garcia-Tsao 2017]

L’EGD des varices n’est pas requise d’après la monographie de produit canadienne. [Lenvima® Product Monograph 2019]

Les patients ayant présenté une hémorragie gastro-intestinale dans les 28 jours précédant la répartition aléatoire étaient exclus de l’étude REFLECT. Les patients atteints de varices gastriques ou œsophagiennes ayant eu besoin d’un traitement interventionnel dans les 28 jours précédant la répartition aléatoire en étaient également exclus. [ClinicalTrials.gov]

<xs>* Les risques de varices comportant un risque élevé d’hémorragie sont très faibles (< 5 %) chez les patients présentant une RH < 20 kPa et une numération plaquettaire > 150 000/mm<sup>3</sup>. L’EGD peut donc être évitée. Chez les patients qui ne répondent pas à ces critères, une endoscopie de dépistage est recommandée pour diagnostiquer les varices gastro-œsophagiennes chez les patients atteints de cirrhose. [Garcia-Tsao 2017]</xs>

<xs>EGD = œsophagogastroduodénoscopie; kPa = kilopascal; RH = raideur hépatique</xs>`
    }
  },
  'Atezolizumab + Bevacizumab': {
    indicator: 'yellow',
    text: {
      en: `
Variceal bleeding is life-threatening in patients with cirrhosis. [Congly 2020]. Gastroesophageal varices may occur in up to 40% of patients with compensated cirrhosis and 70% of patients with decompensated cirrhosis [Kovalak 2007]. Evaluation and intervention to prevent variceal bleeding are critical for patients with cirrhosis. [Congly 2020] The American Association for the Study of Liver Diseases (AASLD) recommends a screening endoscopy in high-risk patients.* [Garcia-Tsao 2017]

Due to the risk of bleeding in patients with HCC and liver cirrhosis and esophageal varices, carefully select patients in clinical practice, prior to initiation of atezolizumab + bevacizumab treatment. [Gordan 2020]

IMBrave150 excluded patients with untreated or incompletely treated varices with bleeding or high risk for bleeding. [Finn 2020]

<b>Patients must undergo EGD, and all size of varices (small to large) must be assessed and treated, per local standard of care, prior to atezolizumab + bevacizumab treatment initiation.</b> [Finn Suppl 2020 protocol]

<b>Patients in IMBrave150 were required to have undergone EGD within 6 months of trial initiation.</b> [Finn Suppl 2020]

Patients were required to have a platelet count ≥ 75 x 10⁹/L (75,000/μL) without transfusion to be included. [Finn Suppl 2020]

Patients currently or recently (within 10 days prior to study treatment start) treated with oral or parenteral anticoagulants or thrombolytic agents for therapeutic (vs prophylactic) purpose were excluded from IMBrave150. [Finn Suppl 2020]

*Patients with an LS <20 kPa and platelet count >150,000/mm³ have a very low probability (<5%) of having high-risk varices, and EDG can be circumvented. In patients who do not meet these criteria, screening endoscopy for diagnosis of gastroesophageal varices is recommended when the diagnosis of cirrhosis is made. [Garcia-Tsao 2017]

<xs>EGD, esophagogastroduodenoscopy; kPa, kilopascals; LS, liver stiffness</xs>
`,
      fr: `L’hémorragie des varices est potentiellement mortelle chez les patients atteints de cirrhose. [Congly 2020] Des varices gastro-œsophagiennes peuvent survenir chez jusqu’à 40 % des patients atteints de cirrhose compensée et 70 % des patients atteints de cirrhose décompensée. [Kovalak 2007] Une évaluation et une intervention visant à prévenir l’hémorragie des varices sont essentielles chez les patients atteints de cirrhose. [Congly 2020] La American Association for the Study of Liver Diseases (AASLD) recommande une endoscopie de dépistage chez les patients à risque élevés*. [Garcia-Tsao 2017]

En raison du risque d’hémorragie chez les patients atteints de CHC, de cirrhose hépatique et de varices œsophagiennes, il faut sélectionner les patients avec prudence en pratique clinique avant d’instaurer le traitement par l’association atézolizumab + bevacizumab. [Gordan]

Les patients atteints de varices non traitées ou partiellement traitées associées à une hémorragie ou à un risque élevé d’hémorragie étaient exclus de l’étude IMBrave150. [NCT03434379; Finn 2020]

<b>Les patients doivent subir une EGD, et toutes les varices (de petite à grande taille) doivent être évaluées et traitées conformément aux normes de soins locales avant d’instaurer le traitement par l’association atézolizumab + bevacizumab. [Finn Suppl 2020 protocol]</b>

<b>Les patients de l’étude IMBrave150 devaient avoir subi une EGD dans les six mois précédant le début de l’étude. [Finn Suppl 2020]</b>

Pour être admissibles à l’étude, les patients devaient avoir une numération plaquettaire ≥ 75 x 109/L (75 000/mL) sans transfusion. [Finn Suppl 2020]

Les patients recevant ou ayant reçu (dans les dix jours précédant l’instauration du traitement à l’étude) des anticoagulants ou des thrombolytiques oraux ou parentéraux à des fins thérapeutiques (et non prophylactiques) étaient exclus de l’étude IMBrave150. [Finn Suppl 2020]

<xs>* Les risques de varices comportant un risque élevé d’hémorragie sont très faibles (< 5 %) chez les patients présentant une RH < 20 kPa et une numération plaquettaire > 150 000/mm<sup>3</sup>. L’EGD peut donc être évitée. Chez les patients qui ne répondent pas à ces critères, une endoscopie de dépistage est recommandée pour diagnostiquer les varices gastro-œsophagiennes chez les patients atteints de cirrhose. [Garcia-Tsao 2017]</xs>

<xs>CHC = carcinome hépatocellulaire; EGD = œsophagogastroduodénoscopie; kPa = kilopascal; RH = raideur hépatique</xs>`
    }
  },
  'Durvalumab + Tremelimumab': {
    indicator: 'green',
    text: {
      en: `HIMALAYA excluded patients with active or prior documented GI bleeding (e.g., esophageal varices or ulcer bleeding) within 12 months. [Abou-Alfa protocol 2022]

Patients in HIMALAYA with a history of GI bleeding within the past 12 months or assessed as high risk for esophageal variceal by the Investigator were required to have undergone adequate endoscopic therapy according to institutional standards. [Abou-Alfa protocol 2022]

Patients were required to have a platelet count ≥75000/μL without transfusions, infusions, or growth factor support administered within 14 days of starting the first dose. [Abou-Alfa protocol 2022]

<xs>GI, gastrointestinal</xs>`,
      fr: `Étaient exclus de l’étude HIMALAYA les patients qui présentaient une hémorragie GI (p. ex. hémorragie de varices œsophagiennes ou d’ulcères œsophagiens) en cours ou avaient des antécédents documentés d’une telle hémorragie dans les 12 mois précédents. [Abou-Alfa protocol 2022]

Les patients de l’étude HIMALAYA ayant des antécédents d’hémorragie GI dans les 12 mois précédents ou un risque de varices œsophagiennes jugé élevé par l’investigateur devaient avoir reçu un traitement endoscopique adéquat conformément aux normes locales. [Abou-Alfa protocol 2022]

Les patients devaient avoir une numération plaquettaire ≥ 75 000/μL sans transfusions, ni perfusions ni traitement d’appoint par des facteurs de croissance dans les 14 jours précédant l’administration de la première dose. [Abou-Alfa protocol 2022]

<xs>GI = gastro-intestinale</xs>`
    }
  }
}

const VaricesNo = {
  Sorafenib: {
    indicator: 'green',
    text: {
      en: `
Patients without elevated bleed risk were included in SHARP. [Llovet 2008]

No warnings or precautions specific to patients <u>without</u> elevated risk of bleeding within the Canadian Product Monograph for sorafenib. [NEXAVAR® Product Monograph 2014]

Sorafenib can be used in these patients. [NEXAVAR® Product Monograph 2014]
`,
      fr: `Les patients ne présentant pas un risque élevé d’hémorragie étaient inclus dans l’étude SHARP. [Llovet 2008]

La monographie canadienne du sorafenib ne comporte aucune mise en garde ni précaution relativement aux patients <u>ne présentant pas</u> un risque élevé d’hémorragie. [NEXAVAR® Product Monograph 2014]

Le sorafenib peut être utilisé chez ces patients. [NEXAVAR® Product Monograph 2014]`
    }
  },
  Lenvatinib: {
    indicator: 'green',
    text: {
      en: `
Patients without elevated bleed risk were included in REFLECT. [Kudo 2018]

No warnings or precautions specific to patients <u>without</u> elevated risk of bleeding within the Canadian Product Monograph for lenvatinib. [Lenvima® Product Monograph 2019]

Lenvatinib can be used in these patients. [Lenvima® Product Monograph 2019]
    `,
      fr: `Les patients ne présentant pas un risque élevé d’hémorragie étaient inclus dans l’étude REFLECT. [Kudo 2018; NCT01761266]

La monographie canadienne du lenvatinib ne comporte aucune mise en garde ni précaution relativement aux patients <u>ne présentant pas</u> un risque élevé d’hémorragie. [Lenvima® Product Monograph 2019]

Le lenvatinib peut être utilisé chez ces patients. [Lenvima® Product Monograph 2019]`
    }
  },
  'Atezolizumab + Bevacizumab': {
    indicator: 'green',
    text: {
      en: `Patients without elevated bleed risk were included in IMBrave150. [Finn 2020]

No warnings or precautions specific to patients <u>without</u> elevated risk of bleeding within the Canadian Product Monograph for atezolizumab + bevacizumab. [Tecentriq® Product Monograph 2021]
Atezolizumab + bevacizumab can be used in these patients. [Tecentriq® Product Monograph 2021]
    `,
      fr: `Les patients ne présentant pas un risque élevé d’hémorragie étaient inclus dans l’étude IMBrave150. [Finn 2020]

La monographie canadienne de l’association atézolizumab + bevacizumab ne comporte aucune mise en garde ni précaution relativement aux patients <u>ne présentant pas</u> un risque élevé d’hémorragie. [Tecentriq® Product Monograph 2021]

L’association atézolizumab + bevacizumab peut être utilisée chez ces patients. [Tecentriq® Product Monograph 2021]`
    }
  },
  'Durvalumab + Tremelimumab': {
    indicator: 'green',
    text: {
      en: `Patients without elevated bleeding risk were included in HIMALAYA. [Abou-Alfa protocol 2022]`,
      fr: `Les patients ne présentant pas un risque élevé d’hémorragie étaient inclus dans l’étude HIMALAYA. [Abou-Alfa protocol 2022]`
    }
  }
}

export const summary = {
  'BCLC Stage': {
    B: BCLCStage_B,
    C: BCLCStage_C
  },
  'Child Pugh Class': {
    A: ChildPughClass_A,
    B: ChildPughClass_B
  },
  'ECOG Performance Status': {
    0: ECOG_Performance_0,
    1: ECOG_Performance_1,
    2: ECOG_Performance_2
  },
  'Etiology of Liver Disease': {
    HBV: LiverDisease_HBV,
    HCV: LiverDisease_HCV,
    'Co-infection with HBV and HCV': LiverDisease_CoInfection,
    'Non-viral HCC': LiverDisease_NonViral
  },
  'Autoimmune Disease (AID)': {
    'No autoimmune disease': AutoImmuneDisease_No,
    'No immunosuppression or low level of immunosuppression with good control of underlying AID':
      AutoImmuneDisease_NoSuppresion,
    'Autoimmune neurologic/neuromuscular disease or life-threatening AID':
      AutoImmuneDisease_Neurologic,
    'Patients with poor disease control or requiring high doses of immunosuppressant for control':
      AutoImmuneDisease_PoorControl
  },
  'Prior or Planned Liver Transplant': {
    Yes: LiverTransplantYes,
    No: LiverTransplantNo
  },
  'Untreated or incompletely treated varices with bleeding or high risk for bleeding':
    {
      Yes: VaricesYes,
      No: VaricesNo
    }
}
