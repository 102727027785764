import React, { useState, useContext } from 'react'
import Modal from 'react-native-modal'
import { useTailwind } from 'tailwind-rn'
import { i18nContext } from '../context'
import { useTranslation } from '../i18n'
import { useMediaQuery } from 'react-responsive'
import { ScrollView, Text, View, TouchableOpacity } from 'react-native'
import media from '../constants/media'
import ModalCloseButton from './ModalCloseButton'

const rows = {
  en: [
    [
      'Adverse Event, n (%)',
      'Any Grade',
      'Grade 3/4',
      'Any Grade',
      'Grade 3/4'
    ],
    ['Hypertension', '98 (29.8)', '50 (15.2)', '38 (24.4)', '19 (12.2)'],
    ['Fatigue', '67 (20.4)', '8 (2.4)', '29 (18.6)', '5 (3.2)'],
    ['Proteinuria', '66 (20.1)', '10 (3.0)', '11 (7.1)', '1 (0.6)'],
    [
      'Aspartate aminotransferase increase',
      '64 (19.5)',
      '23 (7.0)',
      '26 (16.7)',
      '8 (5.1)'
    ],
    ['Pruritus', '64 (19.5)', 0, '15 (9.6)', 0],
    ['Diarrhea', '62 (18.8)', '6 (1.8)', '77 (49.4)', '8 (5.1)'],
    ['Decreased appetite', '58 (17.6)', '4 (1.2)', '38 (24.4)', '6 (3.8)'],
    ['Pyrexia', '59 (17.9)', '4 (1.2)', '15 (9.6)', '2 (1.3)'],
    ['Rash', '41 (12.5)', '0', '27 (17.3)', '4 (2.6)'],
    [
      'Blood bilirubin increase',
      '43 (13.1)',
      '8 (2.4)',
      '22 (14.1)',
      '10 (6.4)'
    ],
    ['Abdominal pain', '40 (12.2)', '4 (1.2)', '27 (17.3)', '4 (2.6)'],
    ['Nausea', '40 (12.2)', '1 (0.3)', '25 (16.0)', '1 (0.6)'],
    [
      'Palmar–plantar erythro-\ndysesthesia syndrome',
      '3 (0.9)',
      '0',
      '75 (48.1)',
      '13 (8.3)'
    ]
  ],
  fr: [
    [
      'Manifestation indésirable, n (%)',
      'Tout grade',
      'Grade 3 ou 4',
      'Tout grade',
      'Grade 3 ou 4'
    ],
    ['Hypertension', '98 (29.8)', '50 (15.2)', '38 (24.4)', '19 (12.2)'],
    ['Fatigue', '67 (20.4)', '8 (2.4)', '29 (18.6)', '5 (3.2)'],
    ['Protéinurie', '66 (20.1)', '10 (3.0)', '11 (7.1)', '1 (0.6)'],
    [
      'Élévation du taux d’aspartate aminotransférase',
      '64 (19.5)',
      '23 (7.0)',
      '26 (16.7)',
      '8 (5.1)'
    ],
    ['Prurit', '64 (19.5)', 0, '15 (9.6)', 0],
    ['Diarrhée', '62 (18.8)', '6 (1.8)', '77 (49.4)', '8 (5.1)'],
    ['Perte d’appétit', '58 (17.6)', '4 (1.2)', '38 (24.4)', '6 (3.8)'],
    ['Pyrexie', '59 (17.9)', '4 (1.2)', '15 (9.6)', '2 (1.3)'],
    ['Éruption cutanée', '41 (12.5)', '0', '27 (17.3)', '4 (2.6)'],
    [
      'Augmentation du taux sanguin de bilirubine',
      '43 (13.1)',
      '8 (2.4)',
      '22 (14.1)',
      '10 (6.4)'
    ],
    ['Douleur abdominale', '40 (12.2)', '4 (1.2)', '27 (17.3)', '4 (2.6)'],
    ['Nausées', '40 (12.2)', '1 (0.3)', '25 (16.0)', '1 (0.6)'],
    [
      'Érythro-\ndysesthésie palmo-plantaire',
      '3 (0.9)',
      '0',
      '75 (48.1)',
      '13 (8.3)'
    ]
  ]
}

const ImbraveSafetyModal = ({ modalVisible, setModalVisible }) => {
  const tw = useTailwind()
  const { lang, setLang } = useContext(i18nContext)
  const t = useTranslation(lang)
  const isDesktop = useMediaQuery({
    minDeviceWidth: media.desktop
  })

  return (
    <Modal
      animationType="slide"
      transparent={true}
      isVisible={modalVisible}
      propagateSwipe
      onRequestClose={() => {
        setModalVisible(!modalVisible)
      }}
    >
      <ModalCloseButton
        setModalVisible={setModalVisible}
        offset={268}
      />
      <ScrollView
        style={[
          tw('bg-white px-4 mt-10 mb-10 pb-20 rounded py-5'),
          {
            [isDesktop ? 'width' : 'maxWidth']: 512,
            margin: 'auto'
          }
        ]}>
        <View
          style={[
            tw('pb-5 flex justify-start flex-row items-center'),
            { borderBottomWidth: 1, borderColor: '#f1f1f1' }
          ]}
        >
          <Text style={tw('text-base font-bold')}>
            {t('pages.imbraveSafetyModal.title')}
          </Text>
        </View>
        <View style={{ ...styles.row, ...styles.firstRow }}>
          <View style={styles.firstCell}>
            <Text style={styles.text}>
              {t('pages.imbraveSafetyModal.adverseEvents')}
            </Text>
          </View>
          <View style={{ ...styles.cell, width: '34%' }}>
            <Text style={styles.text}>
              Atezolizumab + {'\n'}Bevacizumab {'\n'}(N=329)
            </Text>
          </View>
          <View style={{ ...styles.cell, width: '34%' }}>
            <Text style={styles.text}>Sorafenib{'\n'}(N=156)</Text>
          </View>
        </View>

        {rows[lang].map((row, i) => (
          <View key={i} style={styles.row}>
            {row.map((cell, index) => (
              <View
                key={`${cell}-${index}`}
                style={[
                  index == 0 ? styles.firstCell : styles.cell,
                  tw('text-left')
                ]}
              >
                <Text
                  style={[
                    styles.text,
                    i == 0 ? { fontWeight: 'bold' } : null,
                    index !== 0 && tw('text-left')
                  ]}
                >
                  {cell}
                </Text>
              </View>
            ))}
          </View>
        ))}
        <View style={styles.row}>
          <View
            style={{
              ...styles.cell,
              ...styles.firstCell,
              width: '100%'
            }}
          >
            <Text style={[styles.text, tw('font-bold')]}>
              {t('pages.imbraveSafetyModal.immuneMediated')}
            </Text>
            <Text style={styles.text}>
              {t('pages.imbraveSafetyModal.adverseReactions')}
            </Text>
          </View>
        </View>

        <Text style={tw('mt-5 mb-40')}>
          Finn RS, et al. N Engl J Med 2020; 382:1894-1905.
        </Text>
      </ScrollView>
    </Modal>
  )
}

export default ImbraveSafetyModal

const styles = {
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  firstRow: {
    borderTopWidth: 1,
    borderColor: '#000'
  },
  cell: {
    padding: 1,
    textAlign: 'left',
    width: '17%',
    borderBottomWidth: 1,
    borderRightWidth: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: '#000',
    fontSize: 9
  },
  firstCell: {
    paddingLeft: 4,
    paddingRight: 4,
    paddingTop: 8,
    paddingBottom: 8,
    width: '32%',
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    borderColor: '#000'
  },
  lastCell: {
    borderRightWidth: 1,
    borderColor: '#000'
  },
  text: {
    fontSize: 11
  }
}
