import React, { useContext } from 'react'
import { useTailwind } from 'tailwind-rn'
import { useMediaQuery } from 'react-responsive'
import { i18nContext } from '../context'
import { useTranslation } from '../i18n'
import {
  View,
  Image,
  TouchableOpacity,
  useWindowDimensions,
  Platform,
  StatusBar,
  Text
} from 'react-native'

import { SafeAreaView } from 'react-native-safe-area-context'

import { LinearGradient } from 'expo-linear-gradient'
import logoImg from '../assets/main_logo_small.png'
import logoFrenchImg from '../assets/logo_fr.png'
import hamburgerImg from '../assets/hamburger.png'
import arrowLeft from '../assets/arrow_left.png'
import XSvg from './xSvg'

const links = [
  { text: 'home', path: 'Home' },
  { text: 'assessment', path: 'Assessment Stack' },
  {
    text: 'additionalConsiderations',
    path: 'Additional Considerations'
  },
  { text: 'clinicalTrialSummaries', path: 'Trial Summaries' },
  { text: 'background', path: 'Background' },
  { text: 'references', path: 'References' },
  { text: 'legal', path: 'Legal' }
]

const DesktopLink = ({ text, isActive, onPress }) => {
  const { lang } = useContext(i18nContext)
  const t = useTranslation(lang)
  const tw = useTailwind()

  const isSmallDesktop = useMediaQuery({
    minDeviceWidth: 1200,
    maxDeviceWidth: 1380
  })

  return (
    <TouchableOpacity
      style={[
        tw('py-5 px-3 flex justify-start items-start'),
        {
          borderBottomWidth: isActive ? 6 : 0,
          borderBottomColor: isActive ? '#DD6E42' : '#fff',
          backgroundColor: isActive ? '#fff' : 'initial',
        }
      ]}
      onPress={onPress}
    >
      <Text
        style={[
          tw(`${ isSmallDesktop ? 'text-sm' :  'text-base'} font-bold`),
          { color: isActive ? '#506E7A' : '#fff' }
        ]}
      >
        {t(text)}
      </Text>
    </TouchableOpacity>
  )
}

const Header = ({ navigation, isHome, isDrawer, ...props }) => {
  const renderLink = ({ text, path, stack }, index) => {
    let isActive = props.route?.name == path
    if (path == 'Assessment Stack')
      isActive = props.route?.name.includes('Assessment')

    if (path == 'Additional Considerations')
      isActive = props.route?.name.includes('Additional Considerations')

    return (
      <DesktopLink
        key={path}
        text={text}
        isActive={isActive}
        onPress={() => navigation.navigate(path)}
      />
    )
  }

  const { height } = useWindowDimensions()
  const tw = useTailwind()
  const { lang, setLang } = useContext(i18nContext)
  const t = useTranslation(lang)

  const isDesktop = useMediaQuery({
    minDeviceWidth: 1224
  })

  const toggleLang = () => (lang == 'en' ? setLang('fr') : setLang('en'))

  let isSmallerPhone = height < 800
  let isWeb = Platform.OS == 'web'

  const getPaddingTop = () => {
    if (Platform.OS === 'android') return StatusBar.currentHeight
    if (isSmallerPhone) return 32
    return 0
  }

  let paddingTop = 0
  let paddingBottom = 0

  if (isSmallerPhone || isWeb) paddingBottom = 16

  const renderTop = () => {
    if (isSmallerPhone) return
  }

  const renderLeftAction = () => {
    if (isDesktop)
      return (
        <TouchableOpacity
          style={[
            tw('absolute px-3 py-1'),
            { left: 20, background: '#81B29A'  }
          ]}
          onPress={toggleLang}
        >
          <Text style={tw('text-center font-bold text-white text-xs')}>
            {lang == 'en' ? 'Français' : 'English'}
          </Text>
        </TouchableOpacity>
      )

    if (isHome)
      return (
        <TouchableOpacity
          style={[
            tw('absolute left-5 top-5 px-3 py-1'),
            { background: '#81B29A'  }
          ]}
          onPress={toggleLang}
        >
          <Text style={tw('text-center text-white text-xl')}>
            {lang == 'en' ? 'Français' : 'English'}
          </Text>
        </TouchableOpacity>
      )

    if (!isDrawer)
      return (
        <TouchableOpacity
          onPress={() => navigation.goBack()}
          style={[ { width: 20 }, tw('absolute left-5 top-5')]}
        >
          <Image source={arrowLeft} style={tw('h-7')} />
        </TouchableOpacity>
      )
  }

  if (isDesktop) return (
    <View
      style={[
        tw(`flex justify-center items-center`),
        {
          position: 'fixed',
          left: 0,
          right: 0,
          top: 0,
          paddingLeft: 20,
          paddingRight: 20,
          zIndex: 1
        }
      ]}
    >
      <LinearGradient
        colors={['#506E7A', '#81B29A']}
        style={tw('absolute inset-0')}
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 0 }}
      />
      <View style={tw('flex flex-1 w-full flex-row items-center justify-center')}>
        {renderLeftAction()}
        <TouchableOpacity
          onPress={() => navigation.navigate({ name: 'Home' })}
        >
          <Image
            source={lang == 'en' ? logoImg : logoFrenchImg}
            style={{
              marginRight: 18,
              width: 40,
              height: 40
            }}
          />
        </TouchableOpacity>
        <View style={tw('flex flex-row justify-center')}>
          { links.map(renderLink) }
        </View>
      </View>
    </View>
  )

  return (
    <SafeAreaView
      style={[
        tw(`relative flex justify-center items-center`),
        { paddingTop, paddingBottom }
      ]}
    >
      <LinearGradient
        colors={['#506E7A', '#81B29A']}
        style={tw('absolute inset-0')}
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 0 }}
      />
      {renderLeftAction()}
      <TouchableOpacity
        onPress={() => navigation.navigate({ name: 'Home' })}
        style={tw('px-5')}
      >
        <Image
          source={lang == 'en' ? logoImg : logoFrenchImg}
          style={{
            marginTop: 8,
            width: Platform.OS == 'android' ? 40 : 48,
            height: Platform.OS == 'android' ? 40 : 48
          }}
        />
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => navigation.toggleDrawer()}
        style={tw('absolute right-5 top-5')}
      >
        {isDrawer ? (
          <XSvg />
        ) : (
          <Image source={hamburgerImg} style={tw('w-10 h-7')} />
        )}
      </TouchableOpacity>
    </SafeAreaView>
  )
}

export default Header
