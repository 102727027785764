import React, { useContext } from 'react'
import { i18nContext } from '../context'
import { useTranslation } from '../i18n'
import { useTailwind } from 'tailwind-rn'
import { StyleSheet, Text, View } from 'react-native'

import Card from './Card'
import Checkbox from './Checkbox'

const keyz = {
  'BCLC Stage': 'Stade BCLC',
  'Child Pugh Class': 'Classification de Child-Pugh',
  'ECOG Performance Status': 'Indice de performance ECOG',
  'Etiology of Liver Disease': 'Étiologie de la maladie hépatique',
  'Autoimmune Disease (AID)': 'Maladie auto-immune',
  'Prior or Planned Liver Transplant': 'Greffe de foie antérieure ou prévue',
  'Untreated or incompletely treated varices with bleeding or high risk for bleeding':
    'Varices non traitées ou partiellement traitées associées à une hémorragie ou à un risque élevé d’hémorragie',
  Yes: 'Oui',
  No: 'Non',
  HBV: 'VHB',
  HCV: 'VHC',
  'Co-infection with HBV and HCV': 'Co-infection par le VHB et le VHC',
  'Non-viral HCC': 'CHC non viral',
  'No autoimmune disease': 'Aucune maladie auto-immune',
  'No immunosuppression or low level of immunosuppression with good control of underlying AID':
    'Aucune immunosuppression ou légère immunosuppression avec bonne maîtrise de la maladie auto-immune sous-jacente',
  'Autoimmune neurologic/neuromuscular disease or life-threatening AID':
    'Maladie auto-immune neurologique ou neuromusculaire ou potentiellement mortelle',
  'Patients with poor disease control or requiring high doses of immunosuppressant for control':
    'Maladie mal maîtrisée ou nécessitant des doses élevées d’immunosuppression pour atteindre une bonne maîtrise'
}

const mapKey = (key, lang) => (lang == 'en' ? key : keyz[key] || key)

const MultipleChoiceBox = ({
  sideButton,
  invalid,
  selected,
  name,
  style,
  options,
  onPress
}) => {
  const tw = useTailwind()
  const { lang, setLang } = useContext(i18nContext)

  return (
    <Card style={[style, tw('p-0')]}>
      <View
        style={[
          styles.borderBottom,
          tw(`px-7 py-5 ${sideButton ? 'flex flex-row items-center justify-between' : ''}`)
        ]}
      >
        <Text
          style={[
            tw('text-base font-bold'),
            invalid ? { color: '#E07A5F' } : { color: '#414141' }
          ]}
        >
          {mapKey(name, lang)}
        </Text>
        {sideButton}
      </View>
      {options.map(i => (
        <View key={i} style={[styles.borderBottom, tw('flex flex-row py-5')]}>
          <Checkbox
            checked={selected == i}
            testID={`${name}-${i}`}
            text={mapKey(i, lang)}
            onPress={() => onPress({ name, option: i })}
          />
        </View>
      ))}
    </Card>
  )
}

const styles = StyleSheet.create({
  borderBottom: {
    borderColor: '#E1E1E1',
    borderBottomWidth: 1,
    paddingLeft: '7%',
    paddingRight: '15%'
  }
})

export default MultipleChoiceBox
