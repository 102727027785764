import React, { useContext } from 'react'
import { useTailwind } from 'tailwind-rn'
import * as Linking from 'expo-linking'
import { Text, View, ScrollView } from 'react-native'
import { i18nContext } from '../context'
import { useTranslation } from '../i18n'

const PrivacyPolicy = () => {
  const tw = useTailwind()
  const { lang, setLang } = useContext(i18nContext)
  const t = useTranslation(lang)

  const openURL = url => window.open(url, '_blank')

  const handleMailPress = mail => Linking.openURL(`mailto:${mail}`)
  const handleGAPress = mail =>
    openURL(`https://support.google.com/analytics/answer/6004245?hl=${lang}`)
  const handleGAOptOutPress = mail =>
    openURL(`https://tools.google.com/dlpage/gaoptout?hl=${lang}`)

  return (
    <View>
      <Text style={tw('text-base font-bold')}>HCCcare</Text>
      <Text style={tw('text-base')}>{t('pages.privacy.date')}</Text>
      <Text style={tw('mt-2 text-base')}>{t('pages.privacy.paragraph1')}</Text>
      <Text style={tw('mt-7 text-base font-bold')}>
        {t('pages.privacy.collection')}
      </Text>
      <Text style={tw('text-base')}>{t('pages.privacy.paragraph2')}</Text>
      <Text style={tw('mt-5 text-base font-bold')}>
        {t('pages.privacy.deviceData')}
      </Text>
      <Text style={tw('text-base')}>{t('pages.privacy.paragraph3')}</Text>
      <Text style={tw('mt-5 text-base font-bold')}>
        {t('pages.privacy.deviceData2')}
      </Text>
      <Text style={tw('text-base')}>
        {t('pages.privacy.paragraph3,25')}
      </Text>
      <Text style={tw('mt-10 text-base font-bold')}>
        {t('pages.privacy.infoUse')}
      </Text>

      <Text style={tw('mt-5 text-base font-bold')}>
        {t('pages.privacy.mobileApp')}
      </Text>

      <Text style={tw('text-base')}>{t('pages.privacy.paragraph3,5')}</Text>
      <Text style={tw('text-base')}>{t('pages.privacy.paragraph4')}</Text>
      <Text style={tw('mt-5 text-base font-bold')}>
        {t('pages.privacy.webApp')}
      </Text>
      <Text style={tw('text-base')}>{t('pages.privacy.paragraph4,5')}</Text>
      <Text style={tw('text-base')}>{t('pages.privacy.paragraph4,75')}</Text>

      <Text style={tw('mt-10 text-base font-bold')}>
        {t('pages.privacy.disclosure')}
      </Text>
      <Text style={tw('mt-5 text-base')}>{t('pages.privacy.paragraph5')}</Text>
      <Text style={tw('mt-5 text-base font-bold')}>
        {t('pages.privacy.paragraph6')}
      </Text>
      <Text style={tw('text-base')}>{t('pages.privacy.paragraph7')}</Text>
      <Text style={tw('mt-5 text-base font-bold')}>
        {t('pages.privacy.other')}
      </Text>
      <Text style={tw('text-base')}>{t('pages.privacy.paragraph8')}</Text>
      <Text style={tw('mt-10 text-base font-bold')}>
        {t('pages.privacy.tracking')}
      </Text>
      <Text style={tw('mt-5 text-base font-bold')}>
        {t('pages.privacy.analytics')}
      </Text>
      <Text style={tw('text-base')}>
        {t('pages.privacy.paragraph9')}
        <Text style={tw('underline')} onPress={handleGAPress}>
          {t('pages.privacy.googleAnalytics')}
        </Text>
        <Text>{t('pages.privacy.paragraph9,2')}</Text>
        <Text
          style={tw('underline')}
          onPress={() =>
            openURL(t('pages.privacy.aboutAdsOptOutLink'))
          }>
          {t('pages.privacy.aboutAdsOptOutLink')}
        </Text>
        <Text>{t('pages.privacy.andAdobe')}</Text>
        <Text
          style={tw('underline')}
          onPress={() =>
              openURL(t('pages.privacy.adobeOptOutLink'))
          }>
          {t('pages.privacy.adobeOptOutLink')}
        </Text>
        <Text>{t('pages.privacy.andGoogle')}</Text>
        <Text style={tw('underline')} onPress={handleGAOptOutPress}>
          {t('pages.privacy.googleAnalyticsOptOut')}
        </Text>
      </Text>
      <Text style={tw('mt-10 text-base font-bold')}>
        {t('pages.privacy.websites')}
      </Text>
      <Text style={tw('text-base')}>{t('pages.privacy.paragraph10')}</Text>
      <Text style={tw('mt-10 text-base font-bold')}>
        {t('pages.privacy.contact')}
      </Text>
      <Text style={tw('mt-2 text-base')}>{t('pages.privacy.paragraph11')}</Text>
      <Text style={tw('mt-5 text-base')}>{t('pages.privacy.paragraph12')}</Text>
      <Text
        onPress={() => handleMailPress('support@bridgemedcomms.com')}
        style={tw('text-base')}
      >
        support@bridgemedcomms.com
      </Text>
      <Text style={tw('mt-5 text-base')}>{t('pages.privacy.paragraph13')}</Text>
    </View>
  )
}

export default PrivacyPolicy
