import React, { useState, useContext, useRef } from 'react'
import { useTailwind } from 'tailwind-rn'
import { useMediaQuery } from 'react-responsive'
import * as Linking from 'expo-linking'
import { Text, View, ScrollView, TouchableOpacity } from 'react-native'
import { i18nContext } from '../context'
import { useTranslation } from '../i18n'

import Header from '../components/Header'
import Heading from '../components/Heading'
import ExpandingCard from '../components/ExpandingCard'
import PrivacyPolicy from '../components/PrivacyPolicy'
import UpTopButton from '../components/UpTopButton'
import Card from '../components/Card'
import WithLayout from '../components/WithLayout'
import ArrowRightSvg from '../components/ArrowRightSvg'

import { terms } from '../constants/terms'
import media from '../constants/media'

const ActiveCard = ({ active, children }) => {
  const tw = useTailwind()

  if (active)
    return (
      <Card style={tw('w-full p-0')} key={'Terms'}>
        { children }
      </Card>
    )
  return children
}

const Legal = ({ route, navigation }) => {
  let viewRef = useRef()
  const tw = useTailwind()
  const [ selected, setSelected ] = useState('Terms')
  const { lang, setLang } = useContext(i18nContext)
  const t = useTranslation(lang)
  const isDesktop = useMediaQuery({
    minDeviceWidth: media.desktop
  })

  const renderSection = () => {
    switch(selected) {
      case 'Terms':
        return (
          <>
          {terms[lang].map(({ heading, text }, index) => (
            <View key={heading} style={tw('p-5 flex-1 flex-row')}>
              <Text style={tw('font-bold mr-5 text-base')}>{index + 1}.</Text>
              <View style={tw('flex-1')}>
                <Text style={tw('font-bold pr-10 text-base')}>{heading}</Text>
                <Text style={tw('mt-2 text-base pr-12')}>{text}</Text>
              </View>
            </View>
          ))}

          <View style={tw('px-5 pb-5 mt-10')}>
            <Text style={tw('text-base font-bold')}>
              {t('pages.legal.contactUs')}
            </Text>
            <Text style={tw('mt-2 text-base')}>
              {t('pages.legal.questions')}
            </Text>
            <Text style={tw('mt-5 text-base')}>{t('pages.legal.byEmail')}</Text>
            <Text
              onPress={() => handleMailPress('support@bridgemedcomms.com')}
              style={tw('text-base')}
            >
              support@bridgemedcomms.com
            </Text>
            <Text style={tw('mt-5 text-base')}>
              {t('pages.legal.inWriting')} {t('pages.legal.address')}
            </Text>
          </View>
        </>
      )
      case 'Privacy':
        return (
          <View style={tw('p-5 pt-2 ')}>
            <PrivacyPolicy />
          </View>
        )
    }
  }

  const handleMailPress = mail => Linking.openURL(`mailto:${mail}`)


  if (isDesktop) return (
    <View style={tw('flex-1')}>
      <Header route={route} navigation={navigation} />
      <WithLayout>
        <View style={[
            tw('flex-1'),
            { marginRight: '10%', width: 460, position: 'fixed' }
          ]}
        >
          <Heading
            style={tw('mb-10')}
            text={t('legal')}
          />
          <ActiveCard active={selected == 'Terms'}>
            <TouchableOpacity
              style={tw(
                'flex w-full items-center justify-between flex-row py-5 px-5'
              )}
              onPress={() => setSelected('Terms')}
            >
              <Text style={tw('text-base')}>
                {t(`pages.legal.terms`)}
              </Text>
              { selected == 'Terms' && <ArrowRightSvg /> }
            </TouchableOpacity>
          </ActiveCard>
          <ActiveCard active={selected == 'Privacy'}>
            <TouchableOpacity
              style={tw(
                'flex w-full items-center justify-between flex-row py-5 px-5'
              )}
              onPress={() => setSelected('Privacy')}
            >
              <Text style={tw('text-base')}>
                {t(`pages.legal.privacy`)}
              </Text>
              { selected == 'Privacy' && <ArrowRightSvg /> }
            </TouchableOpacity>
          </ActiveCard>
        </View>
        <View ref={viewRef} style={{ width: '55%', marginLeft: '45%' }}>
          { renderSection () }
          <View style={tw('mt-10 w-full flex items-end')}>
            <UpTopButton
              onPress={() =>
                  // hacky
                  document.querySelector('#root').scrollTo(0,0)
              } />
          </View>
        </View>
      </WithLayout>
    </View>
  )

  return (
    <View style={tw('flex-1')}>
      <Header route={route} navigation={navigation} />
      <ScrollView
        ref={viewRef}
        contentContainerStyle={tw('px-7 py-10 flex justify-start items-start')}
      >
        <Heading text={t('legal')} />
        <ExpandingCard heading={t('pages.legal.terms')} style={tw('mt-5')}>
          {terms[lang].map(({ heading, text }, index) => (
            <View key={heading} style={tw('p-5 flex-row')}>
              <Text style={tw('font-bold mr-5 text-base')}>{index + 1}.</Text>
              <View style={tw('flex-1')}>
                <Text style={tw('font-bold pr-10 text-base')}>{heading}</Text>
                <Text style={tw('mt-2 text-base pr-12')}>{text}</Text>
              </View>
            </View>
          ))}

          <View style={tw('px-5 pb-5 mt-10')}>
            <Text style={tw('text-base font-bold')}>
              {t('pages.legal.contactUs')}
            </Text>
            <Text style={tw('mt-2 text-base')}>
              {t('pages.legal.questions')}
            </Text>
            <Text style={tw('mt-5 text-base')}>{t('pages.legal.byEmail')}</Text>
            <Text
              onPress={() => handleMailPress('support@bridgemedcomms.com')}
              style={tw('text-base')}
            >
              support@bridgemedcomms.com
            </Text>
            <Text style={tw('mt-5 text-base')}>
              {t('pages.legal.inWriting')} {t('pages.legal.address')}
            </Text>
          </View>
        </ExpandingCard>
        <ExpandingCard heading={t('pages.legal.privacy')} style={tw('mt-1')}>
          <View style={tw('p-5 pt-2 ')}>
            <PrivacyPolicy />
          </View>
        </ExpandingCard>
        <View style={tw('mt-10 w-full flex items-end')}>
          <UpTopButton
            onPress={() =>
              viewRef.current.scrollTo({ x: 0, y: 0, animated: true })
            }
          />
        </View>
      </ScrollView>
    </View>
  )
}

export default Legal
