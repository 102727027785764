import * as React from "react"
import Svg, { SvgProps, G, Rect, Path } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
  <Svg width={26} height={27} xmlns="http://www.w3.org/2000/svg" {...props}>
    <G
      transform="translate(1 1.5)"
      stroke="#414141"
      strokeWidth={2}
      fill="none"
      fillRule="evenodd"
    >
      <Rect fill="#FFF" width={24} height={24} rx={12} />
      <Path d="M8 15.985 16.485 7.5M8 7.5l8.485 8.485" />
    </G>
  </Svg>
)

export default SvgComponent

