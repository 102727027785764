import * as React from "react"
import Svg, { G, Rect } from "react-native-svg"

const MinusSvg = (props) => (
  <Svg
    width={12}
    height={13}
    viewBox="0 0 22 13"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <G transform="rotate(90 11.218 10.718)" fill="#506E7A" fillRule="evenodd">
      <Rect
        transform="scale(1 -1) rotate(45 22.935 0)"
        x={-1.282}
        y={5.218}
        width={16}
        height={3}
        rx={1.5}
      />
      <Rect
        transform="rotate(45 6.718 15.718)"
        x={-1.282}
        y={14.218}
        width={16}
        height={3}
        rx={1.5}
      />
    </G>
  </Svg>
)

export default MinusSvg

