import React, { useContext, useRef } from 'react'
import { useTailwind } from 'tailwind-rn'
import { i18nContext } from '../context'
import { useTranslation } from '../i18n'
import { useMediaQuery } from 'react-responsive'
import { Text, View, ScrollView } from 'react-native'

import Header from '../components/Header'
import Heading from '../components/Heading'
import PersonCard from '../components/PersonCard'
import UpTopButton from '../components/UpTopButton'
import WithLayout from '../components/WithLayout'

import { committeeMembers } from '../constants'
import media from '../constants/media'

const Background = ({ route, navigation }) => {
  let viewRef = useRef()
  const tw = useTailwind()
  const { lang, setLang } = useContext(i18nContext)
  const t = useTranslation(lang)

  const isDesktop = useMediaQuery({
    minDeviceWidth: media.desktop
  })

  const isTablet = useMediaQuery({
    minDeviceWidth: media.tablet,
    maxDeviceWidth: media.desktop - 1
  })

  if (isDesktop) return (
    <View style={tw('flex-1')}>
      <Header route={route} navigation={navigation} />
      <WithLayout>
        <View style={tw('flex-1')}>
          <Heading
            style={[ { marginRight: '10%' } ]}
            text={t('background')}
          />
          <Text style={[tw('mt-4 pr-8 text-base'), { color: '#414141' }]}>
            {t('pages.background.intro')}
          </Text>
        </View>
        <View style={{ width: '60%' }}>
          <Heading
            style={tw('pr-8')}
            text={t('pages.background.committee')}
          />
            <View style={tw('flex flex-row flex-wrap justify-between items-center')}>
            {committeeMembers.map((person, i) => (
              <PersonCard
              style={[
                tw('mt-5'),
                { width: '48%', height: 260 }
              ]}
                key={person.name}
                name={person.name}
                title={person.title[lang]}
                bio={person.bio[lang]}
              />
            ))}
          </View>
        </View>
      </WithLayout>
    </View>
  )

  return (
    <View style={tw('flex-1')}>
      <Header route={route} navigation={navigation} />
      <ScrollView
        ref={viewRef}
        contentContainerStyle={
          tw('p-10 flex justify-start items-start')
        }
      >
        <Heading text={t('background')} />
        <View>

          <Text style={[tw('mt-4 pr-8 text-base'), { color: '#414141' }]}>
            {t('pages.background.intro')}
          </Text>

          <Heading
            style={tw('mt-10 pr-8')}
            text={t('pages.background.committee')}
          />

          { isTablet
            ? (
            <View style={tw('flex flex-row flex-wrap justify-between items-center')}>
            {committeeMembers.map((person, i) => (
              <PersonCard
              style={[
                tw('mt-5'),
                { width: '48%', height: 260 }
              ]}
                key={person.name}
                name={person.name}
                title={person.title[lang]}
                bio={person.bio[lang]}
              />
            ))}
          </View>
            )
            : (
              committeeMembers.map((person, i) => (
                <PersonCard
                  style={i == 0 ? tw('mt-10') : tw('mt-5')}
                  key={person.name}
                  name={person.name}
                  title={person.title[lang]}
                  bio={person.bio[lang]}
                />
              ))
            )
          }

          <View style={tw('mt-10 w-full flex items-end')}>
            <UpTopButton
              onPress={() => viewRef
                .current
                .scrollTo({ x: 0, y: 0, animated: true })
              }
            />
          </View>
        </View>
      </ScrollView>
    </View>
  )
}

export default Background
