import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
  <Svg width={25} height={25} xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path
      d="m12.374 10.253 9.193-9.192a1.5 1.5 0 1 1 2.121 2.121l-9.192 9.192 9.192 9.193a1.5 1.5 0 1 1-2.121 2.121l-9.193-9.192-9.192 9.192a1.5 1.5 0 1 1-2.121-2.121l9.192-9.193-9.192-9.192a1.5 1.5 0 0 1 2.121-2.121l9.192 9.192Z"
      fill="#fff"
      fillRule="evenodd"
    />
  </Svg>
)

export default SvgComponent

