import * as React from "react"
import Svg, { SvgProps, Path } from "react-native-svg"

const ArrowLeftSvg = props => (
  <Svg width={13} height={22} xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path
      d="M1.599 12.72a1.496 1.496 0 0 1-.974-1.503 1.496 1.496 0 0 1 .974-1.502l8.654-8.654a1.5 1.5 0 0 1 2.121 2.121L4.34 11.218l8.035 8.035a1.5 1.5 0 0 1-2.121 2.121L1.599 12.72Z"
      fill="#506E7A"
      fillRule="evenodd"
    />
  </Svg>
)

export default ArrowLeftSvg

