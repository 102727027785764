import React, { useState } from 'react'
import { useTailwind } from 'tailwind-rn'
import { Pressable, StyleSheet, Text, View } from 'react-native'
import { Ionicons } from '@expo/vector-icons'

const CheckboxBasic = ({ text, onPress }) => {
  const tw = useTailwind()
  const [checked, onChange] = useState(false)

  function onCheckmarkPress() {
    onChange(!checked)
    onPress?.(!checked)
  }

  return (
    <Pressable
      accessible={true}
      accessibilityLabel="Checkbox"
      style={tw('flex flex-row justify-start items-center py-5')}
      onPress={onCheckmarkPress}
    >
      <View style={styles.checkboxBase}>
        {checked && (
          <View style={styles.checkboxCheckedInner}>
            <Ionicons name="checkmark" size={20} color="#81B29A" />
          </View>
        )}
      </View>

      <Text style={tw('text-base font-medium ml-4')}>{text}</Text>
    </Pressable>
  )
}

export default CheckboxBasic

const styles = StyleSheet.create({
  checkboxBase: {
    width: 26,
    height: 26,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    borderWidth: 2,
    borderColor: '#506E7A',
    backgroundColor: '#fff'
  }
})
