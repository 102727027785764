export const patientFactors = [
  {
    name: 'BCLC Stage',
    options: ['B', 'C']
  },
  {
    name: 'Child Pugh Class',
    options: ['A', 'B']
  },
  {
    name: 'ECOG Performance Status',
    options: ['0', '1', '2']
  }
]

export const diseaseFactors = [
  {
    name: 'Etiology of Liver Disease',
    options: ['HBV', 'HCV', 'Co-infection with HBV and HCV', 'Non-viral HCC']
  },
  {
    name: 'Autoimmune Disease (AID)',
    options: [
      'No autoimmune disease',
      'No immunosuppression or low level of immunosuppression with good control of underlying AID',
      'Autoimmune neurologic/neuromuscular disease or life-threatening AID',
      'Patients with poor disease control or requiring high doses of immunosuppressant for control'
    ]
  },
  {
    name: 'Prior or Planned Liver Transplant',
    options: ['Yes', 'No']
  },
  {
    name: 'Untreated or incompletely treated varices with bleeding or high risk for bleeding',
    options: ['Yes', 'No']
  }
]

export const combinedFactors = [...patientFactors, ...diseaseFactors]
