import React, { useEffect } from 'react'
import { ScrollView, View } from 'react-native'
import { useTailwind } from 'tailwind-rn'
import { useMediaQuery } from 'react-responsive'

import media from '../constants/media'

const WithLayout = ({ children, viewRef }) => {
  const tw = useTailwind()
  const isDesktop = useMediaQuery({
    minDeviceWidth: media.desktop
  })

  if (isDesktop) return (
    <View
      ref={viewRef}
      nativeID="layout"
      style={[
        tw(`p-10 flex items-start relative flex-row justify-between' `),
        {
          width: '100%',
          maxWidth: 1400,
          margin: 'auto',
          marginTop: 70,
          overflowY: 'scroll'
        }
      ]}
    >
        { children }
    </View>
  )

  return (
    <View
      ref={viewRef}
      nativeID="layout"
      style={[
        tw(`p-10 flex items-start justify-start`)
      ]}
    >
        { children }
    </View>
  )
}

export default WithLayout
