import React from 'react'
import { useTailwind } from 'tailwind-rn'
import { TouchableOpacity } from 'react-native'
import { LinearGradient } from 'expo-linear-gradient'

import ArrowUpSvg from './ArrowUpSvg'

const PrimaryButton = ({ onPress, style }) => {
  const tw = useTailwind()
  return (
    <LinearGradient
      colors={['#506E7A', '#9BAAB0']}
      start={{ x: 0, y: 0 }}
      end={{ x: 1, y: 0 }}
      style={[style, { borderRadius: 4, width: 69, height: 69 }]}
    >
      <TouchableOpacity
        style={tw('flex flex-1 justify-center items-center')}
        onPress={onPress}
      >
        <ArrowUpSvg />
      </TouchableOpacity>
    </LinearGradient>
  )
}

export default PrimaryButton
