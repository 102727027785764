import * as React from "react"
import Svg, { Path } from "react-native-svg"

const ArrowRightSvg = (props) => (
  <Svg width={13} height={22} xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path
      d="M11.644 9.522a1.496 1.496 0 0 1 .974 1.503 1.496 1.496 0 0 1-.974 1.503l-8.655 8.654a1.5 1.5 0 0 1-2.12-2.121l8.035-8.036L.868 2.99A1.5 1.5 0 0 1 2.99.868l8.655 8.654Z"
      fill="#506E7A"
      fillRule="evenodd"
    />
  </Svg>
)

export default ArrowRightSvg

