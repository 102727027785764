import React from 'react'
import { useTailwind } from 'tailwind-rn'
import { View, StyleSheet } from 'react-native'

const Card = ({ children, style, transparent }) => {
  const tw = useTailwind()
  let baseStyle = !transparent
    ? [tw('rounded border-solid bg-white'), styles.card]
    : [{ padding: 30 }]

  return (
    <View style={[ ...baseStyle, style ]}>
      {children}
    </View>
  )
}

export default Card

const styles = StyleSheet.create({
  card: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 5
    },
    shadowOpacity: 0.2,
    shadowRadius: 10,
    elevation: 10,
    padding: 30
  }
})
