import React, { useState, useContext, useRef, useEffect } from 'react'
import { useTailwind } from 'tailwind-rn'
import { i18nContext } from '../context'
import { useTranslation } from '../i18n'
import { useMediaQuery } from 'react-responsive'
import { Text, ScrollView, View, TouchableOpacity } from 'react-native'

import Header from '../components/Header'
import SwitchNav from '../components/SwitchNav'
import Heading from '../components/Heading'
import ExpandingCard from '../components/ExpandingCard'
import AssessmentSection from '../components/AssessmentSection'
import Card from '../components/Card'
import UpTopButton from '../components/UpTopButton'
import ArrowRightSvg from '../components/ArrowRightSvg'
import WithLayout from '../components/WithLayout'
import LineSquare from '../components/LineSquare'

import media from '../constants/media'

import PatientSupportPrograms from '../screens/PatientSupportPrograms'
import TreatmentLogistics from '../screens/TreatmentLogistics'
import ALBIGrade from '../screens/ALBIGrade'
import EmergingData from '../screens/EmergingData'
import Resources from '../screens/Resources'
import RealWorldEvidence from '../screens/RealWorldEvidence'
import EtiologyLiverDisease from '../screens/EtiologyLiverDisease'
import EvolutionControlArms from '../screens/EvolutionControlArms'
import TreatmentStageMigration from '../screens/TreatmentStageMigration'
import ArrowUpSvg, { ArrowUpGreySvg } from '../components/ArrowUpSvg'

let emergingDataSubsections = [
  { name: 'Real-World Evidence', text: 'evidence' },
  { name: 'Etiology of Liver Disease', text: 'etiology' },
  { name: 'Evolution of Control Arms', text: 'evolution' },
  { name: 'Treatment Stage Migration', text: 'migration' }
]

const sections = [
  { name: 'Treatment Logistics', text: 'treatmentLogistics' },
  { name: 'ALBI Grade', text: 'albiGrade' },
  {
    name: 'Emerging Data',
    text: 'emergingData',
    subsections: emergingDataSubsections
  },
  { name: 'Patient Support Programs', text: 'supportPrograms' },
  { name: 'Resources', text: 'resources' }
]

const AdditionalConsiderations = ({ route, navigation }) => {
  let viewRef = useRef()
  const tw = useTailwind()

  const [selected, setSelected] = useState(sections[0].name)
  const [selectedSubsection, setSelectedSubsection] = useState(null)
  const { lang, setLang } = useContext(i18nContext)
  const t = useTranslation(lang)
  const isDesktop = useMediaQuery({
    minDeviceWidth: media.desktop
  })

  useEffect(() => {
    let preselected = route.params?.tab
    if (!preselected) return

    setSelected(preselected)

    let section = sections.find(s => s.name == preselected)
    if (!section.subsections?.length) return

    let subsectionPreselected = route.params?.subsection
    if (subsectionPreselected) {
      setSelectedSubsection(subsectionPreselected)
    }
  }, [route.params?.tab, route.params?.subsection])

  const renderSection = () => {
    switch (selected) {
      case 'Treatment Logistics':
        return <TreatmentLogistics component />
      case 'ALBI Grade':
        return <ALBIGrade component />
      case 'Emerging Data':
        return <EmergingData component />
      case 'Patient Support Programs':
        return <PatientSupportPrograms component />
      case 'Resources':
        return <Resources component />
    }
  }

  const renderSubSection = () => {
    switch (selectedSubsection) {
      case 'Real-World Evidence':
        return <RealWorldEvidence component />
      case 'Etiology of Liver Disease':
        return <EtiologyLiverDisease component />
      case 'Evolution of Control Arms':
        return <EvolutionControlArms component />
      case 'Treatment Stage Migration':
        return <TreatmentStageMigration component />
    }
  }
  return (
    <View style={tw('flex-1')}>
      <Header route={route} navigation={navigation} />
      <WithLayout viewRef={viewRef}>
        <View
          style={[
            tw('flex-1'),
            {
              marginRight: '10%',
              width: 460,
              position: 'fixed',
              maxHeight: '81vh',
              overflowY: 'auto'
            }
          ]}
        >
          <Heading text={t('additionalConsiderations')} />
          <ScrollView>
            <View style={[tw(`mt-4 flex items-end`)]}>
              {sections.map(({ name, text, subsections }) => (
                <>
                  <Card
                    transparent={selected != name}
                    style={tw('mt-1 w-full p-0')}
                    key={name}
                  >
                    <TouchableOpacity
                      style={tw(
                        'flex w-full items-center justify-between flex-row py-3 px-5'
                      )}
                      onPress={() => {
                        setSelected(name)
                        if (subsections?.length)
                          setSelectedSubsection(subsections[0].name)
                        else setSelectedSubsection(null)
                      }}
                    >
                      <Text style={tw('text-base')}>
                        {t(`pages.additionalConsiderations.${text}`)}
                      </Text>
                      {selected == name &&
                        (!subsections?.length ? (
                          <ArrowRightSvg />
                        ) : (
                          null
                        ))}
                    </TouchableOpacity>
                  </Card>
                  {selected == name &&
                    subsections?.length &&
                    subsections.map((s, i) => (
                      <Card
                        style={[
                          tw('mt-0 w-full p-0 rounded-none shadow-transparent'),
                          {
                            backgroundColor:
                              selectedSubsection == s.name ? '#F1F1F1' : '#fff'
                          }
                        ]}
                        key={s.name}
                      >
                        <TouchableOpacity
                          style={tw(
                            'pl-8 flex w-full items-center justify-between flex-row py-3 px-5'
                          )}
                          onPress={() => setSelectedSubsection(s.name)}
                        >
                          <Text style={tw('ml-5 text-base')}>
                            {t(
                              `pages.additionalConsiderations.subsections.${s.text}`
                            )}
                          </Text>
                          <ArrowRightSvg />
                        </TouchableOpacity>
                      </Card>
                    ))}
                </>
              ))}
            </View>
          </ScrollView>
        </View>

        <View style={{ width: '55%', marginLeft: '45%' }}>
          {renderSection()}
          {renderSubSection()}
        </View>
      </WithLayout>
    </View>
  )
}

export default AdditionalConsiderations
