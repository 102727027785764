import React from 'react'
import { View } from 'react-native'

const LayoutLeft = props => (
  <View
    style={[
      tw('flex-1'),
      { marginRight: 30 }
    ]}
  >
    { props.children }
  </View>
)

export default LayoutLeft
