import React, { useContext, useState } from 'react'
import Modal from 'react-native-modal'
import { useTailwind } from 'tailwind-rn'
import { i18nContext } from '../context'
import { useTranslation } from '../i18n'
import { useMediaQuery } from 'react-responsive'
import { ScrollView, Text, View, TouchableOpacity } from 'react-native'

import ModalCloseButton from './ModalCloseButton'
import ChartLineSvg from './ChartLineSvg'
import PrimaryButton from './PrimaryButton'

import media from '../constants/media'

const Line = () => (
  <View
    style={{
      borderLeftWidth: 2,
      borderColor: '#506e7a',
      height: 24,
      width: 1,
      position: 'absolute',
      top: -24,
      left: '50%'
    }}
  />
)

const Box = ({ text, type }) => {
  const tw = useTailwind()

  let colours = {
    dark: '#506E7A',
    light: '#C0D6DF'
  }

  return (
    <View
      style={[
        tw('rounded flex justify-center items-center py-3'),
        { backgroundColor: colours[type] }
      ]}
    >
      <Text
        style={[
          tw(`text-base font-bold`),
          { color: type == 'dark' ? '#fff' : '#506E7A' }
        ]}
      >
        {text}
      </Text>
    </View>
  )
}

const StagingModal = ({ modalVisible, setModalVisible }) => {
  const tw = useTailwind()
  const { lang, setLang } = useContext(i18nContext)
  const t = useTranslation(lang)
  const isDesktop = useMediaQuery({
    minDeviceWidth: media.desktop
  })

  return (
    <Modal
      animationType="slide"
      transparent={true}
      isVisible={modalVisible}
      propagateSwipe
      onRequestClose={() => {
        setModalVisible(!modalVisible)
      }}
    >
      <ModalCloseButton
        offset={290}
        setModalVisible={setModalVisible}
      />
      <ScrollView
        style={[
          tw('bg-white px-4 mt-10 pb-20 relative rounded py-5'),
          {
            [isDesktop ? 'width' : 'maxWidth']: 560,
            margin: 'auto'
          }
        ]}>
        <View
          style={[
            tw('pb-5 flex justify-start flex-row items-center'),
            { borderBottomWidth: 1, borderColor: '#f1f1f1' }
          ]}
        >
          <Text style={tw('text-base font-bold')}>
            {t('pages.stagingModal.title')}
          </Text>
        </View>
        <Box text="BCLC-0-A" type="dark" />
        <View style={tw('flex flex-row relative justify-between my-6')}>
          <ChartLineSvg
            style={[tw('absolute'), { top: -24, left: isDesktop ? '34%' : '21%' }]}
          />
          <View style={{ width: '47%' }}>
            <Box text={t('pages.stagingModal.veryEarly')} type="light" />
            <Text style={tw('mt-2 text-xs')}>
              {t('pages.stagingModal.singleNodule')}
            </Text>
          </View>
          <View style={{ width: '47%' }}>
            <Box text={t('pages.stagingModal.early')} type="light" />
            <Text style={tw('mt-2 text-xs')}>
              {t('pages.stagingModal.earlyNodule')}
            </Text>
          </View>
        </View>
        <Box text="BCLC-B" type="dark" />
        <View style={tw('relative mt-6')}>
          <Line />
          <Box text={t('pages.stagingModal.intermediate')} type="light" />
        </View>
        <Text style={tw('mt-2 text-xs')}>
          {t('pages.stagingModal.multinodular')}
        </Text>
        <View style={tw('mt-7')}>
          <Box text="BCLC-C" type="dark" />
        </View>
        <View style={tw('relative mt-6')}>
          <Line />
          <Box text={t('pages.stagingModal.advanced')} type="light" />
        </View>
        <Text style={tw('mt-2 text-xs')}>{t('pages.stagingModal.portal')}</Text>
        <View style={tw('mt-6')}>
          <Box text="BCLC-D" type="dark" />
        </View>
        <View style={tw('relative mt-6')}>
          <Line />
          <Box text={t('pages.stagingModal.terminal')} type="light" />
        </View>
        <Text style={tw('mt-2 text-xs')}>{t('pages.stagingModal.tumor')}</Text>
        <Text style={{ marginTop: 30, fontSize: 9 }}>
          {t('pages.stagingModal.footNote1')}
        </Text>
        <Text style={{ marginTop: 10, marginBottom: 50, fontSize: 9 }}>
          {t('pages.stagingModal.footNote2')}
        </Text>
      </ScrollView>
    </Modal>
  )
}

export default StagingModal
