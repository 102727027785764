import React, { useState } from 'react'
import { useTailwind } from 'tailwind-rn'
import { View, Text, TouchableOpacity } from 'react-native'
import { indicatorColours } from '../constants/assessment-results'

import Card from './Card'
import PlusSvg from './PlusSvg'
import MinusSvg from './MinusSvg'

const Circle = ({ color }) => {
  const tw = useTailwind()
  return (
    <View
      style={[
        tw('rounded-full w-4 h-4'),
        { backgroundColor: indicatorColours[color] }
      ]}
    ></View>
  )
}

const Indicator = ({ color, style, label = null }) => {
  const tw = useTailwind()
  return (
    <View
      style={[
        tw('relative py-1 flex flex-row justify-center items-center px-5 '),
        {
          borderColor: '#E1E1E1',
          borderLeftWidth: 1
        },
        style
      ]}
    >
      {label && (
        <View style={[tw('absolute h-10 flex items-end justify-end -top-14')]}>
          <Text style={[tw('text-center font-semibold'), { fontSize: 11 }]}>
            {label.split(' ').join('\n')}
          </Text>
        </View>
      )}
      <Circle color={color} />
    </View>
  )
}

const ExpandingSummaryCard = ({
  isFirst,
  isDesktop,
  labels,
  heading,
  children,
  indicators,
  style
}) => {
  const tw = useTailwind()
  const [expanded, setExpanded] = useState(false)

  return (
    <Card style={[tw('w-full p-0'), style]}>
      <TouchableOpacity
        style={[
          tw(`flex w-full items-center ${isDesktop ? 'justify-start py-3' : 'justify-between py-2'} flex-row pl-5`),
          {
            borderColor: '#E1E1E1',
            borderBottomWidth: 1
          }
        ]}
        onPress={() => setExpanded(!expanded)}
      >
        <Text style={[
          tw('flex-1 text-xs font-bold'),
          isDesktop ? { width: '60%' } : {}
        ]}>{heading}</Text>
        {expanded ? (
          <MinusSvg style={{ marginRight: 8 }} />
        ) : (
          <PlusSvg style={{ marginRight: 8 }} />
        )}

        {indicators.map((indicator, index) => (
          <Indicator
            key={`${heading}-${indicator}-${index}`}
            label={isFirst && labels[index]}
            color={indicator}
            style={isDesktop ? { width: '12%' } : {}}
          />
        ))}
      </TouchableOpacity>
      {expanded && <>{children}</>}
    </Card>
  )
}

export default ExpandingSummaryCard
