import React, { useContext, useRef } from 'react'
import { useTailwind } from 'tailwind-rn'
import { i18nContext } from '../context'
import { useTranslation } from '../i18n'
import { useMediaQuery } from 'react-responsive'
import { Text, View, ScrollView } from 'react-native'
import * as Linking from 'expo-linking'

import Header from '../components/Header'
import Heading from '../components/Heading'
import UpTopButton from '../components/UpTopButton'

import { references } from '../constants/references'
import { monographLinks } from '../constants'
import media from '../constants/media'

const References = ({ route, navigation }) => {
  let viewRef = useRef()
  const tw = useTailwind()
  const { lang, setLang } = useContext(i18nContext)
  const t = useTranslation(lang)
  const isDesktop = useMediaQuery({
    minDeviceWidth: media.desktop
  })

  let alphabet = Array.from(Array(26), (e, i) => String.fromCharCode(i + 65))

  const matchToAlphabet = index => {
    if (alphabet[index]) return alphabet[index]

    if (index > 25) {
      // will generate something like:
      // AA, AB, AC ... BA, BB, BC ...
      return alphabet[Math.floor(index / 52)] + alphabet[index % 26]
    }
  }

  const handleLinkPress = product => {
    let url = monographLinks[product][lang]
    window.open(url, '_blank')
  }

  const hasMonograph = reference => {
    let ref = reference.toLowerCase()
    return (
      ref.startsWith('nexavar') ||
      ref.startsWith('tecentriq') ||
      ref.startsWith('lenvima') ||
      ref.startsWith('imjudo')
    )
  }

  const handleReferenceClick = reference => () => {
    console.log(reference)
    let ref = reference.toLowerCase()
    switch (true) {
      case ref.startsWith('nexavar'):
        return handleLinkPress('nexavar')
      case ref.startsWith('tecentriq'):
        return handleLinkPress('tecentriq')
      case ref.startsWith('lenvima'):
        return handleLinkPress('lenvima')
      case ref.startsWith('imjudo'):
        return handleLinkPress('imjudo')
      default:
        null
    }
  }

  return (
    <View style={tw('flex-1')}>
      <Header route={route} navigation={navigation} />
      <ScrollView
        ref={viewRef}
        contentContainerStyle={[
          tw('px-7 py-10 flex justify-start items-start'),
          isDesktop ? tw('p-10') : tw('px-7 py-10'),
          isDesktop ? { maxWidth: 1400, margin: 'auto', marginTop: 70 } : {}
        ]}
      >
        <Heading text={t('references')} />
        {references.map((reference, index) => {
          let key = matchToAlphabet(index).toLowerCase()

          return (
            <View
              key={key}
              style={[
                { wordBreak: 'break-word' },
                tw('mt-5 flex flex-row items-start')
              ]}
            >
              <Text
                onPress={
                  hasMonograph(reference) && handleReferenceClick(reference)
                }
                style={
                  hasMonograph(reference)
                    ? tw('text-base underline')
                    : tw('text-base')
                }
              >
                {reference}
              </Text>
            </View>
          )
        })}
        <View style={tw('mt-10 w-full flex items-end')}>
          <UpTopButton
            onPress={() =>
              viewRef.current.scrollTo({ x: 0, y: 0, animated: true })
            }
          />
        </View>
      </ScrollView>
    </View>
  )
}

export default References
