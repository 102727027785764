import React, { useState, useContext } from 'react'
import Modal from 'react-native-modal'
import { useTailwind } from 'tailwind-rn'
import { i18nContext } from '../context'
import { useTranslation } from '../i18n'
import { useMediaQuery } from 'react-responsive'
import { ScrollView, Text, View, TouchableOpacity } from 'react-native'
import media from '../constants/media'
import ModalCloseButton from './ModalCloseButton'

const rows = {
  en: [
    ['n (%)', 'Any Grade', 'Grade 3/4', 'Any Grade', 'Grade 3/4'],
    ['Diarrhea', '103 (26.5)', '17 (4.4)', '167 (44.7)', '16 (4.3)'],
    ['Constipation', '36 (9.3)', '0', '35 (9.4)', '0'],
    ['Abdominal pain', '46 (11.9)', '5 (1.3)', '63 (16.8)', '12 (3.2)'],
    ['Nausea', '47 (12.1)', '0', '53 (14.2)', '0'],
    ['Pruritus', '89 (22.9)', '0', '24 (6.4)', '1 (0.3)'],
    ['Rash', '87 (22.4)', '6 (1.5)', '51 (13.6)', '4 (1.1)'],
    ['Alopecia', '2 (0.5)', '0', '53 (14.2)', '0'],
    [
      'Palmar-plantar erythrodysesthesia syndrome',
      '3 (0.8)',
      '0',
      '174 (46.5)',
      '34 (9.1)'
    ],
    [
      'Aspartate aminotransferase increased',
      '48 (12.4)',
      '20 (5.2)',
      '24 (6.4)',
      '12 (3.2)'
    ],
    [
      'Alanine aminotransferase increased',
      '36 (9.3)',
      '10 (2.6)',
      '20 (5.3)',
      '7 (1.9)'
    ],
    ['Amylase increased', '29 (7.5)', '14 (3.6)', '10 (2.7)', '4 (1.1)'],
    ['Blood bilirubin increased', '20 (5.2)', '3 (0.8)', '29 (7.8)', '8 (2.1)'],
    [
      'Gamma-glutamyltransferase increased',
      '18 (4.6)',
      '8 (2.1)',
      '19 (5.1)',
      '7 (1.9)'
    ],
    ['Lipase increased', '34 (8.8)', '24 (6.2)', '15 (4.0)', '11 (2.9)'],
    ['Decreased appetite', '66 (17.0)', '5 (1.3)', '67 (17.9)', '3 (0.8)'],
    ['Asthenia', '39 (10.1)', '7 (1.8)', '44 (11.8)', '10 (2.7)'],
    ['Fatigue', '66 (17.0)', '8 (2.1)', '71 (19.0)', '11 (2.9)'],
    ['Pyrexia', '50 (12.9)', '1 (0.3)', '33 (8.8)', '0'],
    ['Edema peripheral', '33 (8.5)', '2 (0.5)', '19 (5.1)', '0'],
    ['Cough', '30 (7.7)', '0', '22 (5.9)', '1 (0.3)'],
    ['Insomnia', '40 (10.3)', '1 (0.3)', '16 (4.3)', '0'],
    ['Hypothyroidism', '47 (12.1)', '0', '16 (4.3)', '0'],
    ['Hypertension', '23 (5.9)', '7 (1.8)', '68 (18.2)', '23 (6.1)'],
    ['Anemia', '36 (9.3)', '11 (2.8)', '33 (8.8)', '12 (3.2)'],
    ['Hyperkalemia', '20 (5.2)', '6 (1.5)', '13 (3.5)', '9 (2.4)'],
    ['Hypokalemia', '13 (3.4)', '4 (1.0)', '12 (3.2)', '2 (0.5)'],
    ['Hyponatremia', '21 (5.4)', '16 (4.1)', '15 (4.0)', '11 (2.9)']
  ],
  fr: [
    ['Manifestation indésirable, n (%)', 'Tout grade', 'Grade 3 ou 4', 'Tout grade', 'Grade 3 ou 4'],
    ['Diarrhée', '103 (26,5)', '17 (4,4)', '167 (44,7)', '16 (4,3)'],
    ['Constipation', '36 (9,3)', '0', '35 (9,4)', '0'],
    ['Douleur abdominale	', '46 (11,9)', '5 (1,3)', '63 (16,8)', '12 (3,2)'],
    ['Nausées', '47 (12,1)', '0', '53 (14,2)', '0'],
    ['Prurit', '89 (22,9)', '0', '24 (6,4)', '1 (0,3)'],
    ['Éruption cutanée', '87 (22,4)', '6 (1,5)', '51 (13,6)', '4 (1,1)'],
    ['Alopécie', '2 (0,5)', '0', '53 (14,2)', '0'],
    [
      'Érythrodysesthésie palmo-plantaire',
      '3 (0,8)',
      '0',
      '174 (46,5)',
      '34 (9,1)'
    ],
    [
      'Hausse du taux d’aspartate aminotransférase',
      '48 (12,4)',
      '20 (5,2)',
      '24 (6,4)',
      '12 (3,2)'
    ],
    [
      'Hausse du taux d’alanine aminotransférase',
      '36 (9,3)',
      '10 (2,6)',
      '20 (5,3)',
      '7 (1,9)'
    ],
    [`Hausse du taux d'amylase`, '29 (7,5)', '14 (3,6)', '10 (2,7)', '4 (1,1)'],
    ['Hausse du taux sanguin de bilirubine', '20 (5,2)', '3 (0,8)', '29 (7,8)', '8 (2,1)'],
    [
      'Hausse du taux de gamma-glutamyltransférase',
      '18 (4,6)',
      '8 (2,1)',
      '19 (5,1)',
      '7 (1,9)'
    ],
    ['Hausse du taux de lipase', '34 (8,8)', '24 (6,2)', '15 (4,0)', '11 (2,9)'],
    ['Diminution de l’appétit', '66 (17,0)', '5 (1,3)', '67 (17,9)', '3 (0,8)'],
    ['Asthenia', '39 (10,1)', '7 (1,8)', '44 (11,8)', '10 (2,7)'],
    ['Fatigue', '66 (17,0)', '8 (2,1)', '71 (19,0)', '11 (2,9)'],
    ['Pyrexie', '50 (12,9)', '1 (0,3)', '33 (8,8)', '0'],
    ['Œdème périphérique', '33 (8,5)', '2 (0,5)', '19 (5,1)', '0'],
    ['Toux', '30 (7,7)', '0', '22 (5,9)', '1 (0,3)'],
    ['Insomnie', '40 (10,3)', '1 (0,3)', '16 (4,3)', '0'],
    ['Hypothyroïdie', '47 (12,1)', '0', '16 (4,3)', '0'],
    ['Hypertension', '23 (5,9)', '7 (1,8)', '68 (18,2)', '23 (6,1)'],
    ['Anémie', '36 (9,3)', '11 (2,8)', '33 (8,8)', '12 (3,2)'],
    ['Hyperkaliémie', '20 (5,2)', '6 (1,5)', '13 (3,5)', '9 (2,4)'],
    ['Hypokaliémie', '13 (3,4)', '4 (1,0)', '12 (3,2)', '2 (0,5)'],
    ['Hyponatrémie', '21 (5,4)', '16 (4,1)', '15 (4,0)', '11 (2,9)']
  ]
}

const HimalayaSafetyModal = ({ modalVisible, setModalVisible }) => {
  const tw = useTailwind()
  const { lang, setLang } = useContext(i18nContext)
  const t = useTranslation(lang)
  const isDesktop = useMediaQuery({
    minDeviceWidth: media.desktop
  })

  return (
    <Modal
      animationType="slide"
      transparent={true}
      isVisible={modalVisible}
      propagateSwipe
      onRequestClose={() => {
        setModalVisible(!modalVisible)
      }}
    >
      <ModalCloseButton setModalVisible={setModalVisible} offset={268} />
      <ScrollView
        style={[
          tw('bg-white px-4 mt-10 mb-10 pb-20 rounded py-5'),
          {
            [isDesktop ? 'width' : 'maxWidth']: 512,
            margin: 'auto'
          }
        ]}
      >
        <View
          style={[
            tw('pb-5 flex justify-start flex-row items-center'),
            { borderBottomWidth: 1, borderColor: '#f1f1f1' }
          ]}
        >
          <Text style={tw('text-base font-bold')}>
            {t('pages.himalayaSafetyModal.title')}
          </Text>
        </View>
        <View style={{ ...styles.row, ...styles.firstRow }}>
          <View style={styles.firstCell}>
            <Text style={styles.text}>
              {t('pages.himalayaSafetyModal.teas')}
            </Text>
          </View>
          <View style={{ ...styles.cell, width: '34%' }}>
            <Text style={styles.text}>STRIDE{'\n'}(N=388)</Text>
          </View>
          <View style={{ ...styles.cell, width: '34%' }}>
            <Text style={styles.text}>Sorafenib{'\n'}(N=374)</Text>
          </View>
        </View>

        {rows[lang].map((row, i) => (
          <View key={i} style={styles.row}>
            {row.map((cell, index) => (
              <View
                key={`${cell}-${index}`}
                style={[
                  index == 0 ? styles.firstCell : styles.cell,
                  tw('text-left')
                ]}
              >
                <Text
                  style={[
                    styles.text,
                    i == 0 ? { fontWeight: 'bold' } : null,
                    index !== 0 && tw('text-left')
                  ]}
                >
                  {cell}
                </Text>
              </View>
            ))}
          </View>
        ))}

        <Text style={tw('mt-5 mb-40')}>
          Abou-Alfa G, et al. NEJM Evid 2022;1(8).
        </Text>
      </ScrollView>
    </Modal>
  )
}

export default HimalayaSafetyModal

const styles = {
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  firstRow: {
    borderTopWidth: 1,
    borderColor: '#000'
  },
  cell: {
    padding: 1,
    textAlign: 'left',
    width: '17%',
    borderBottomWidth: 1,
    borderRightWidth: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: '#000',
    fontSize: 9
  },
  firstCell: {
    paddingLeft: 4,
    paddingRight: 4,
    paddingTop: 8,
    paddingBottom: 8,
    width: '32%',
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    borderColor: '#000'
  },
  lastCell: {
    borderRightWidth: 1,
    borderColor: '#000'
  },
  text: {
    fontSize: 11
  }
}
