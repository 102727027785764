import React from 'react'
import { useTailwind } from 'tailwind-rn'
import { Text, View } from 'react-native'

const AssessmentSection = ({ style, heading, hideHeading, description, children }) => {
  const tw = useTailwind()

  const renderHeading = () => {
    if (hideHeading) return null
    return (
      <>
        {heading ? <Text style={tw('text-lg font-bold')}>{heading}</Text> : null }
        <Text style={tw(`${heading ? 'mt-2' : ''} text-base`)}>{description}</Text>
      </>
    )
  }

  return (
    <View style={[style, tw('w-full')]}>
      {renderHeading()}
      {children}
    </View>
  )
}

export default AssessmentSection
