import React from 'react'
import { useTailwind } from 'tailwind-rn'
import { TouchableOpacity, Text } from 'react-native'
import { LinearGradient } from 'expo-linear-gradient'

const SmallButton = ({ disabled, text, onPress, style }) => {
  const tw = useTailwind()
  return (
    <LinearGradient
      colors={disabled ? ['#e1e1e1', '#e1e1e1'] : ['#506E7A', '#9BAAB0']}
      start={{ x: 0, y: 0 }}
      end={{ x: 1, y: 0 }}
      style={[style, { borderRadius: 12 }]}
    >
      <TouchableOpacity
        disabled={disabled}
        style={[
          tw('px-3 flex justify-center items-center'),
          { paddingTop: 5, paddingBottom: 5 }
        ]}
        onPress={onPress}
      >
        <Text style={[{ fontSize: 10 }, tw('text-white font-bold')]}>
          {text}
        </Text>
      </TouchableOpacity>
    </LinearGradient>
  )
}

export default SmallButton
