import React, { useContext, useState } from 'react'
import Modal from 'react-native-modal'
import { useTailwind } from 'tailwind-rn'
import { i18nContext } from '../context'
import { useTranslation } from '../i18n'
import { useMediaQuery } from 'react-responsive'
import { ScrollView, Text, View, TouchableOpacity } from 'react-native'
import media from '../constants/media'
import ModalCloseButton from './ModalCloseButton'


const rows = {
  en: [
    [
      'Adverse Event, n (%)',
      'Any Grade',
      'Grade 3/4',
      'Any Grade',
      'Grade 3/4'
    ],
    ['Hypertension', '201 (42)', '111 (23)', '144 (30)', '68 (14)'],
    ['Diarrhea', '184 (39)', '20 (4)', '220 (46)', '20 (4)'],
    ['Decreased appetite', '162 (34)', '22 (5)', '127 (27)', '6 (1)'],
    ['Decreased weight', '147 (31)', '36 (8)', '106 (22)', '14 (3)'],
    ['Fatigue', '141 (30)', '18 (4)', '119 (25)', '17 (4)'],
    [
      'Palmar-plantar erythro-\ndysesthesia syndrome',
      '128 (27)',
      '14 (3)',
      '249 (52)',
      '54 (11)'
    ],
    ['Proteinuria', '117 (25)', '27 (6)', '54 (11)', '8 (2)'],
    ['Dysphonia', '113 (24)', '1 (<1)', '57 (12)', '0 (0)'],
    ['Nausea', '93 (20)', '4 (1)', '68 (14)', '4 (1)']
  ],
  fr: [
    [
      'Manifestation indésirable, n (%)',
      'Tout grade',
      'Grade 3 ou 4',
      'Tout grade',
      'Grade 3 ou 4'
    ],
    ['Hypertension', '201 (42)', '111 (23)', '144 (30)', '68 (14)'],
    ['Diarrhée', '184 (39)', '20 (4)', '220 (46)', '20 (4)'],
    ['Perte d’appétit', '162 (34)', '22 (5)', '127 (27)', '6 (1)'],
    ['Perte de poids', '147 (31)', '36 (8)', '106 (22)', '14 (3)'],
    ['Fatigue', '141 (30)', '18 (4)', '119 (25)', '17 (4)'],
    [
      'Érythro-\ndysesthésie palmo-plantaire',
      '128 (27)',
      '14 (3)',
      '249 (52)',
      '54 (11)'
    ],
    ['Protéinurie', '117 (25)', '27 (6)', '54 (11)', '8 (2)'],
    ['Dysphonie', '113 (24)', '1 (<1)', '57 (12)', '0 (0)'],
    ['Nausées', '93 (20)', '4 (1)', '68 (14)', '4 (1)']
  ]
}

const SharpSafetyModal = ({ modalVisible, setModalVisible }) => {
  const tw = useTailwind()
  const { lang, setLang } = useContext(i18nContext)
  const t = useTranslation(lang)
  const isDesktop = useMediaQuery({
    minDeviceWidth: media.desktop
  })

  const isTablet = useMediaQuery({
    minDeviceWidth: media.tablet,
    maxDeviceWidth: media.desktop - 1
  })

  return (
    <Modal
      animationType="slide"
      transparent={true}
      isVisible={modalVisible}
      propagateSwipe
      onRequestClose={() => {
        setModalVisible(!modalVisible)
      }}
    >
      <ModalCloseButton
        setModalVisible={setModalVisible}
        offset={268}
        style={{ marginTop: 68 }}
      />
      <ScrollView
        style={[
          tw('bg-white px-4 mt-20 mb-10 rounded py-5'),
          {
            [isDesktop ? 'width' : 'maxWidth'] : 512,
            margin: 'auto'
          }
        ]}>
        <View
          style={[
            tw('pb-5 flex justify-start flex-row items-center'),
            { borderBottomWidth: 1, borderColor: '#f1f1f1' }
          ]}
        >
          <Text style={tw('text-base font-bold')}>
            {t('pages.reflectSafetyModal.title')}
          </Text>
        </View>
        <View style={{ ...styles.row, ...styles.firstRow }}>
          <View style={styles.firstCell}>
            <Text style={styles.text}>
              {t('pages.reflectSafetyModal.averseEvents')}
            </Text>
          </View>
          <View style={{ ...styles.cell, width: '34%' }}>
            <Text style={styles.text}>Lenvatinib{'\n'}(n = 476)</Text>
          </View>
          <View style={{ ...styles.cell, width: '34%' }}>
            <Text style={styles.text}>Sorafenib{'\n'}(n = 475)</Text>
          </View>
        </View>

        {rows[lang].map((row, i) => (
          <View key={i} style={styles.row}>
            {row.map((cell, index) => (
              <View
                key={`${cell}-${index}`}
                style={[
                  index == 0 ? styles.firstCell : styles.cell,
                  tw('text-left')
                ]}
              >
                <Text
                  style={[
                    styles.text,
                    i == 0 ? { fontWeight: 'bold' } : null,
                    index !== 0 && tw('text-center')
                  ]}
                >
                  {cell}
                </Text>
              </View>
            ))}
          </View>
        ))}

        <Text style={tw('mt-5 mb-20')}>
          Kudo M et al. Lancet. 2018;391:1163-1173.
        </Text>
      </ScrollView>
    </Modal>
  )
}

export default SharpSafetyModal

const styles = {
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  firstRow: {
    borderTopWidth: 1,
    borderColor: '#000'
  },
  cell: {
    padding: 0,
    textAlign: 'left',
    width: '17%',
    borderBottomWidth: 1,
    borderRightWidth: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: '#000',
    fontSize: 9
  },
  firstCell: {
    paddingLeft: 4,
    paddingRight: 4,
    paddingTop: 8,
    paddingBottom: 8,
    width: '32%',
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    borderColor: '#000'
  },
  lastCell: {
    borderRightWidth: 1,
    borderColor: '#000'
  },
  text: {
    fontSize: 11
  }
}
