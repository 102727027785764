import React from 'react'
import { useTailwind } from 'tailwind-rn'
import { Pressable, StyleSheet, Text, View } from 'react-native'
import { Ionicons } from '@expo/vector-icons'

const Checkbox = ({ testID, checked, text, onPress }) => {
  const tw = useTailwind()

  function onCheckmarkPress() {
    onPress?.(!checked)
  }

  return (
    <Pressable
      testID={testID}
      accessible={true}
      accessibilityLabel="Checkbox"
      style={tw('flex flex-row flex-1 justify-start items-center')}
      onPress={onCheckmarkPress}
    >
      <View
        accessible={true}
        accessibilityLabel="Check"
        style={styles.checkboxBase}
      >
        {checked && (
          <View style={styles.checkboxCheckedInner}>
            <Ionicons
              style={{ display: 'none' }}
              name="checkmark"
              size={24}
              color="#81B29A"
            />
          </View>
        )}
      </View>
      <Text style={tw('text-base ml-5')}>{text}</Text>
    </Pressable>
  )
}

export default Checkbox

const styles = StyleSheet.create({
  checkboxBase: {
    width: 26,
    height: 26,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 12,
    borderWidth: 2,
    borderColor: '#506E7A',
    backgroundColor: '#fff'
  },
  checkboxCheckedInner: {
    backgroundColor: '#81B29A',
    width: 14,
    height: 14,
    borderWidth: 1,
    borderRadius: 12,
    borderColor: '#fff'
  }
})
