import React, { useContext } from 'react'
import { useTailwind } from 'tailwind-rn'
import { i18nContext } from '../context'
import { useMediaQuery } from 'react-responsive'
import { View, StyleSheet } from 'react-native'

import Header from '../components/Header'
import DrawerButton from '../components/DrawerButton'

import media from '../constants/media'

const links = [
  { text: 'home', path: 'Home' },
  { text: 'assessment', path: 'Assessment Stack' },
  {
    text: 'additionalConsiderations',
    path: 'Additional Considerations'
  },
  { text: 'clinicalTrialSummaries', path: 'Trial Summaries' },
  { text: 'background', path: 'Background' },
  { text: 'references', path: 'References' },
  { text: 'legal', path: 'Legal' }
]

const Drawer = ({ navigation, ...props }) => {

  // react hooks (useMediaQuery) doesnt seem to work in
  // this component 🤷
  let isTablet = window.innerWidth >= media.tablet
    && window.innerWidth < media.desktop

  const renderLink = ({ text, path, stack }, index) => {
    return (
      <DrawerButton
        key={path}
        text={text}
        isActive={props.state.index == index}
        onPress={() => navigation.navigate(path)}
      />
    )
  }

  return (
    <View style={styles.flex1}>
      <Header isDrawer navigation={navigation} />
      <View style={[styles.drawer, isTablet ? styles.tabletDrawer : {} ]}>{links.map(renderLink)}</View>
    </View>
  )
}

export default Drawer

const styles = StyleSheet.create({
  flex1: {
    flex: 1
  },
  drawer: {
    backgroundColor: '#fff',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 5
    },
    shadowOpacity: 0.2,
    shadowRadius: 10,
    elevation: 10,
    flex: 1,
    width: '95%',
    alignSelf: 'flex-end'
  },
  tabletDrawer: {
    width: '55%',
  }
})
