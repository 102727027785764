import React from 'react'
import { useTailwind } from 'tailwind-rn'
import { View, Text, Image } from 'react-native'
import Card from '../components/Card'

import decorationImg from '../assets/decoration.png'

const PersonCard = ({ name, title, bio, style }) => {
  const tw = useTailwind()
  return (
    <Card style={[ tw('w-full'), style ]}>
      <View style={tw('flex flex-row justify-start items-start')}>
        <Image
          source={decorationImg}
          style={{ width: 6, height: 60, marginRight: 10 }}
        />
        <View>
          <Text style={[tw('text-2xl font-bold'), { color: '#414141' }]}>
            {name}
          </Text>
          <Text style={[tw('text-lg font-bold'), { color: '#414141' }]}>
            {title}
          </Text>
        </View>
      </View>
      <Text style={[tw('mt-4 pr-8 text-base'), { color: '#414141' }]}>
        {bio}
      </Text>
    </Card>
  )
}

export default PersonCard
