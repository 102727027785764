import React, { useContext, useState } from 'react'
import Modal from 'react-native-modal'
import { useTailwind } from 'tailwind-rn'
import { i18nContext } from '../context'
import { useTranslation } from '../i18n'
import { useMediaQuery } from 'react-responsive'
import { ScrollView, Text, View, TouchableOpacity } from 'react-native'
import ModalCloseButton from './ModalCloseButton'
import PrimaryButton from './PrimaryButton'
import ChildPughSurvey from './ChildPughSurvey'

import media from '../constants/media'

const ClassificationModal = ({
  navigation,
  modalVisible,
  setModalVisible,
  onSurveyResult
}) => {
  const tw = useTailwind()
  const { lang, setLang } = useContext(i18nContext)
  const t = useTranslation(lang)

  const isDesktop = useMediaQuery({
    minDeviceWidth: media.desktop
  })

  const isTablet = useMediaQuery({
    minDeviceWidth: media.tablet,
    maxDeviceWidth: media.desktop - 1
  })

  let deviceHeight = window.innerHeight + (window.innerHeight / 100 * 20)

  return (
    <Modal
      animationType="slide"
      transparent={true}
      isVisible={modalVisible}
      propagateSwipe
      onRequestClose={() => {
        setModalVisible(!modalVisible)
      }}
      deviceHeight={deviceHeight}
    >

    <ModalCloseButton
      setModalVisible={setModalVisible}
      offset={243}
    />
      <ScrollView
        style={[
          tw('bg-white mt-10 pb-20 rounded py-5'),
          {
            [isDesktop ? 'width' : 'maxWidth']: 462,
            maxHeight: '90%',
            margin: 'auto'
          }
        ]}>
        <View
          style={[
            tw('px-7 pb-5 flex justify-start flex-row items-center'),
            { borderBottomWidth: 1, borderColor: '#f1f1f1' }
          ]}
        >
          <Text style={tw('text-base font-bold')}>
            {t('pages.calculatorModal.title')}
          </Text>
        </View>
        <ChildPughSurvey
          navigation={navigation}
          hasCallback={Boolean(onSurveyResult)}
          onSurveyResult={option => {
            if (onSurveyResult) onSurveyResult(option)
            setModalVisible(false)
          }}
          forceClose={() => setModalVisible(false)}
        />
      </ScrollView>
    </Modal>
  )
}

export default ClassificationModal
